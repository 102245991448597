export class Tab {

  readonly title: string;
  readonly enabled: boolean;
  readonly onClick?: () => void;

  private constructor(title: string, enabled: boolean, onClick?: () => void) {
    this.title = title;
    this.enabled = enabled;
    this.onClick = onClick;
  }

  public static of(title: string, enabled: boolean = true, onClick?: () => void): Tab {
    return new Tab(title, enabled, onClick);
  }

  public withEnabled(enabled: boolean): Tab {
    return new Tab(this.title, enabled, this.onClick);
  }
}
