import {Injectable} from "@angular/core";
import {Objects} from "../tools/objects";
import {SessionService} from "./session.service";
import {AbstractStorageService} from "./abstract-storage.service";

@Injectable({
  providedIn: 'root'
})
export class UserStorageService extends AbstractStorageService {

  constructor(private sessionService: SessionService) {
    super();
  }

  public override set<A>(key: string, value: A | undefined): void {
    const userId = this.getUserId();
    super.set(userId + '.' + key, value);
  }

  public override get<A>(key: string): A | undefined {
    const userId = this.getUserId();
    return super.get(userId + '.' + key);
  }

  public override remove(key: string): void {
    const userId = this.getUserId();
    super.remove(userId + '.' + key);
  }

  private getUserId(): number {
    const userId = this.sessionService.session?.userId;
    if (Objects.isNull(userId)) {
      throw new Error('User ID not found');
    }
    return userId!;
  }
}
