import {League} from "../../models/league";
import {SearchTools} from "../../tools/search.tools";

export interface LeaguesData {
  loading: boolean;
  leagues: League[];
  filteredLeagues: League[];
  search?: string;
  canWrite: boolean;
}

export class LeaguesState {
  readonly data: LeaguesData;

  constructor(data: LeaguesData) {
    this.data = data;
  }

  public static create(): LeaguesState {
    return new LeaguesState({
      loading: true,
      leagues: [],
      filteredLeagues: [],
      canWrite: false,
    });
  }

  public refresh(leagues: League[]): LeaguesState {
    return new LeaguesState({
      ...this.data,
      loading: false,
      leagues: leagues.sort((a, b) => a.displayName.localeCompare(b.displayName)),
    }).filterLeagues();
  }

  public withLoading(loading: boolean): LeaguesState {
    return new LeaguesState({
      ...this.data,
      loading: loading,
    });
  }

  public withSearch(value: string | undefined) {
    return new LeaguesState({
      ...this.data,
      search: value,
    }).filterLeagues();
  }

  public withCanWrite(canWrite: boolean): LeaguesState {
    return new LeaguesState({
      ...this.data,
      canWrite: canWrite,
    });
  }

  private filterLeagues(): LeaguesState {
    return new LeaguesState({
      ...this.data,
      filteredLeagues: SearchTools.filter(this.data.leagues, league => league.displayName, this.data.search),
    });
  }
}
