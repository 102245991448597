<div class="page">
  <app-loading *ngIf="(state$ | async)!.data.loading"></app-loading>
  <div class="cards" *ngIf="!(state$ | async)!.data.loading">
    <div class="card">
      <div class="card-header">
        <div class="subtitle">
          <app-icon name="diversity"></app-icon>
          League Info
        </div>
      </div>
      <div class="form">
        <div class="field">
          <label class="field-label" for="displayName">League Name</label>
          <input type="text" class="field-text" id="displayName"
                 [disabled]="!(state$ | async)!.canEdit"
                 [value]="(state$ | async)!.data.displayName"
                 (input)="withDisplayName($event)"/>
          <div class="field-error" *ngIf="(state$ | async)!.data.displayNameError">
            {{(state$ | async)!.data.displayNameError}}
          </div>
        </div>
        <div class="field">
          <label class="field-label" for="home">Home League</label>
          <input type="text" class="field-text" id="home"
                 [value]="(state$ | async)!.data.home ? 'Yes' : 'No'"
                 disabled/>
        </div>
      </div>
    </div>

    <div class="card" *ngIf="(state$ | async)!.data.league">
      <div class="card-header">
        <div class="subtitle">
          <app-icon name="groups"></app-icon>
          Teams
        </div>
      </div>

      <div class="list">
        <div class="list-item-secondary" *ngFor="let team of (state$ | async)!.data.teams" (click)="goToTeam(team.id)">
          <div class="list-text-secondary">
            {{ team.displayName }}
          </div>
        </div>
        <div class="list-item-secondary" *ngIf="(state$ | async)!.data.teams.length === 0">
          <div class="list-text-message">
            No teams found
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
