import {Component, ElementRef, EventEmitter, HostListener, Output, ViewChild} from "@angular/core";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {

  @ViewChild("search") searchField!: ElementRef;

  visible = false;

  @Output()
  public searchEvent: EventEmitter<string | undefined> = new EventEmitter<string | undefined>();

  public toggle(): void {
    this.visible = !this.visible;
    if (this.visible) {
      setTimeout(() => this.searchField.nativeElement.focus());
    } else {
      this.clear();
    }
  }

  public clear(): void {
    this.search(undefined);
    this.visible = false;
    this.searchField.nativeElement.value = '';
  }

  public search(value: string | undefined): void {
    value = !!value ? value.trim() : undefined;
    value = !!value && value.length > 0 ? value : undefined;
    this.searchEvent.emit(value);
  }

  public onInput(event: any): void {
    this.search(event.target.value);
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleEscapePress(event: KeyboardEvent): void {
    if (!this.visible) {
      return;
    }
    this.clear();
  }

}
