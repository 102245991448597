import {Component, EventEmitter, Input, Output} from "@angular/core";
import {ColorTools} from "../../tools/color.tools";
import {TrackSegment} from "../../models/track-segment";
import {LapInfo} from "../../models/lapInfo";

@Component({
  selector: 'app-track',
  templateUrl: './track.component.html',
  styleUrls: ['./track.component.scss']
})
export class TrackComponent {

  private readonly black: string = '#000000';
  private readonly trackColor = '#141f21';
  private readonly insideColor = '#25393e';

  private static readonly TRACK_WIDTH_MIN = 64;
  private static readonly TRACK_WIDTH_DEFAULT = 512;
  private static readonly TRACK_WIDTH_MAX = 512;

  @Input()
  public set trackWidth(trackWidth: number) {
    const boundedWidth = Math.max(Math.min(trackWidth, TrackComponent.TRACK_WIDTH_MAX), TrackComponent.TRACK_WIDTH_MIN);
    this.trackSize = this.calculateTrackSize(boundedWidth);
  }

  @Input()
  public set lapInfos(lapInfos: LapInfo[]) {
    this.lapInfoMap = this.calculateLapInfoMap(lapInfos);
  };

  @Input()
  public editable: boolean = false;

  @Input()
  public set mainColor(mainColor: string) {
    this._mainColor = mainColor === '#000000' ? ColorTools.toHex('white') : mainColor;
  }

  public get mainColor(): string {
    return this._mainColor;
  }

  @Output()
  public clickEvent: EventEmitter<TrackSegment> = new EventEmitter<TrackSegment>()

  @Output()
  public undoEvent: EventEmitter<void> = new EventEmitter<void>()

  public lapInfoMap: Map<String, number> = new Map<String, number>();
  private trackSize = this.calculateTrackSize(TrackComponent.TRACK_WIDTH_DEFAULT);
  private _mainColor: string = ColorTools.toHex('white');

  public onClick(segment: number, lane: number): void {
    if (!this.editable) {
      return;
    }
    this.clickEvent.emit({segment, lane: lane});
  }

  public onUndo(): void {
    if (!this.editable) {
      return;
    }
    this.undoEvent.emit();
  }

  public getDisplayValue(segment: number, lane: number): string {
    const value = this.getValue(segment, lane);
    return !this.editable || value === 0 ? '' : value.toString();
  }

  public getValue(segment: number, lane: number): number {
    return this.lapInfoMap.get(`${segment}-${lane}`) || 0;
  }

  public getMaxValue(): number {
    let max = 3;
    for (let value of this.lapInfoMap.values()) {
      max = Math.max(max, value);
    }
    return max;
  }

  public getCellStyle(): string {
    let result = "";
    result += this.width(4);
    result += this.height(4);
    result += this.position("relative");
    return result;
  }

  public getStyle(segment: number, lane: number): string {
    switch (segment) {
      case 0:
        return this.getStyleForStraight(segment, lane);
      case 1:
        return this.getStyleForCurve(segment, "bottom", "right", lane);
      case 2:
        return this.getStyleForCurve(segment, "top", "right", lane);
      case 3:
        return this.getStyleForStraight(segment, lane);
      case 4:
        return this.getStyleForCurve(segment, "top", "left", lane);
      case 5:
        return this.getStyleForCurve(segment, "bottom", "left", lane);
    }
    return "";
  }

  private getStyleForLane(segment: number, lane: number): string {
    const value = this.getValue(segment, lane);
    const max = this.getMaxValue();
    const brightness = Math.max(0, Math.min(max, value)) / max;
    const backgroundColor = lane === 0 ? this.insideColor : ColorTools.mixHexColors(this.trackColor, this.mainColor, brightness);
    const color = ColorTools.mixHexColors(backgroundColor, this.black, 0.7);

    let result = "";
    result += lane === 0 ? '' : this.cursor(this.editable ? "pointer" : "default");
    result += this.display("flex");
    result += this.position("absolute");
    result += this.color(color);
    result += this.backgroundColor(backgroundColor);
    return result;
  }

  private getStyleForCurve(segment: number, vertical: string, horizontal: string, lane: number): string {
    let result = "";
    result += this.getStyleForLane(segment, lane);
    result += vertical === "top" ? this.bottom(0) : this.top(0);
    result += horizontal === "left" ? this.right(0) : this.left(0);
    result += this.justifyContent(horizontal === "left" ? "flex-start" : "flex-end");
    result += this.alignItems(vertical === "top" ? "flex-start" : "flex-end");
    result += this.border(vertical, this.toLineOpacity(lane));
    result += this.border(horizontal, this.toLineOpacity(lane));
    result += this.radius(vertical + "-" + horizontal, lane + 1);
    result += this.width(lane + 1);
    result += this.height(lane + 1);
    result += this.padding(vertical, lane + 1);
    result += this.padding(horizontal, lane + 1);
    return result;
  }

  private getStyleForStraight(segment: number, lane: number): string {
    let result = "";
    result += this.getStyleForLane(segment, lane);
    result += this.width(4);
    result += this.height(1);
    result += this.top(segment === 3 ? 3 - lane : lane);
    result += this.border(segment === 3 ? "top" : "bottom", this.toLineOpacity(lane));
    result += segment === 0 && lane > 0 ? this.border("left") : '';
    result += segment === 0 && lane > 0 ? this.border("right") : '';
    result += this.justifyContent("center");
    result += this.alignItems("center");
    return result;
  }

  private cursor(type: string): string {
    return `cursor: ${type}; `;
  }

  private display(type: string): string {
    return `display: ${type}; `;
  }

  private position(position: string): string {
    return `position: ${position}; `;
  }

  private top(multiplier: number): string {
    return `top: ${multiplier * this.trackSize}px; `;
  }

  private bottom(multiplier: number): string {
    return `bottom: ${multiplier * this.trackSize}px; `;
  }

  private left(multiplier: number): string {
    return `left: ${multiplier * this.trackSize}px; `;
  }

  private right(multiplier: number): string {
    return `right: ${multiplier * this.trackSize}px; `;
  }

  private border(position: string, opacity: number = 1): string {
    const opacityString = Math.round(opacity * 99);
    return `border-${position}: 1px solid ${this.mainColor}${opacityString}; `;
  }

  private radius(position: string, multiplier: number): string {
    return `border-${position}${position === '' ? '' : '-'}radius: ${multiplier * this.trackSize}px; `;
  }

  private width(multiplier: number): string {
    return `width: ${multiplier * this.trackSize}px; `;
  }

  private height(multiplier: number): string {
    return `height: ${multiplier * this.trackSize}px; `;
  }

  private padding(position: string, multiplier: number): string {
    if (multiplier === 1) {
      multiplier = 0;
    } else if (multiplier === 2) {
      multiplier = 0.75;
    } else if (multiplier === 3) {
      multiplier = 1.05;
    } else if (multiplier === 4) {
      multiplier = 1.35;
    }
    return `padding-${position}: ${this.trackSize * multiplier}px; `;
  }

  private justifyContent(position: string): string {
    return `justify-content: ${position}; `;
  }

  private alignItems(position: string): string {
    return `align-items: ${position}; `;
  }

  public color(color: string): string {
    return `color: ${color}; `;
  }

  private backgroundColor(color: string): string {
    return `background-color: ${color}; `;
  }

  private calculateLapInfoMap(lapInfos: LapInfo[]): Map<String, number> {
    const countsMap = new Map<string, number>();
    lapInfos.forEach(lapInfo => {
      const key = `${lapInfo.segment}-${lapInfo.lane}`;
      if (countsMap.has(key)) {
        countsMap.set(key, countsMap.get(key)! + 1);
      } else {
        countsMap.set(key, 1);
      }
    });
    return countsMap;
  }

  private isTrackLine(lane: number): boolean {
    return lane === 0 || lane === 3;
  }

  private toLineOpacity(lane: number): number {
    return this.isTrackLine(lane) ? 1 : 0.3;
  }

  private calculateTrackSize(boundedWidth: number) {
    return (boundedWidth - 32) / 12;
  }
}
