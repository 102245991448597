import {Component} from "@angular/core";
import {PageService} from "../../services/page.service";
import {RouterTools} from "../../tools/router.tools";
import {Router} from "@angular/router";

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent {

  constructor(private pageService: PageService,
              private router: Router) {
    this.pageService.invoke(page => page.reset('Events')
      .withBackButton(() => RouterTools.goHome(this.router)));
  }
}
