import {Injectable} from "@angular/core";
import {Observable, tap} from "rxjs";
import {User} from "../models/user";
import { HttpClient } from "@angular/common/http";
import {environment} from "../../environment";
import {SessionService} from "./session.service";
import {UserPayload} from "../models/user-payload";


@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userApiUrl = environment.backendUrl + '/user';

  constructor(private http: HttpClient,
              private sessionService: SessionService) {
  }

  public getCurrentUser(): Observable<User> {
    const options = this.sessionService.createHttpOptions();
    return this.http.get<User>(this.userApiUrl + '/current.php', options);
  }

  public postCurrentUser(user: UserPayload): Observable<User> {
    const options = this.sessionService.createHttpOptions();
    return this.http.post<User>(this.userApiUrl + '/current.php', user, options)
      .pipe(tap(() => this.sessionService.refresh()));
  }

  public getUsers(): Observable<User[]> {
    const options = this.sessionService.createHttpOptions();
    return this.http.get<User[]>(this.userApiUrl + '/users.php', options);
  }

  public getUser(id: number): Observable<User> {
    const options = this.sessionService.createHttpOptions();
    return this.http.get<User>(this.userApiUrl + '/users.php?id=' + id, options);
  }

  public getUserByUsername(username: string): Observable<User> {
    const options = this.sessionService.createHttpOptions();
    return this.http.get<User>(this.userApiUrl + '/users.php?username=' + username, options);
  }

  public createUser(user: UserPayload): Observable<User> {
    const options = this.sessionService.createHttpOptions();
    return this.http.post<User>(this.userApiUrl + '/users.php', user, options);
  }

  public updateUser(id: number, user: UserPayload): Observable<User> {
    const options = this.sessionService.createHttpOptions();
    return this.http.put<User>(this.userApiUrl + '/users.php?id=' + id, user, options);
  }

  public deleteUser(id: number): Observable<User> {
    const options = this.sessionService.createHttpOptions();
    return this.http.delete<User>(this.userApiUrl + '/users.php?id=' + id, options);
  }
}
