import {AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild} from "@angular/core";
import {SvgService} from "../../services/svg.service";

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements AfterViewInit {

  @Input()
  public set name(name: string) {
    this._name = name;
    this.loadIcon();
  }

  public get name(): string {
    return this._name;
  }

  @ViewChild('iconDiv') iconDiv!: ElementRef;

  private _name: string = 'home';
  private viewInitialized = false;

  constructor(private appIcon: ElementRef, private svgService: SvgService) {
  }

  ngAfterViewInit() {
    this.viewInitialized = true;
    this.loadIcon();
  }

  @HostListener('window:resize')
  public onResize() {
    this.loadIcon();
  }

  private loadIcon() {
    if (!this.viewInitialized) {
      return;
    }
    this.svgService.oneByName(this.name).subscribe(svg => {
      this.iconDiv.nativeElement.innerHTML = svg;

      // Get with/height from app-icon
      const width = this.appIcon.nativeElement.offsetWidth;
      const height = this.appIcon.nativeElement.offsetHeight;

      // Set iconDiv size
      this.iconDiv.nativeElement.style.width = width + 'px';
      this.iconDiv.nativeElement.style.height = height + 'px';

      // Set svg width
      const svgElement = this.iconDiv.nativeElement.querySelector('svg');
      if (svgElement) {
        svgElement.setAttribute('width', width);
        svgElement.setAttribute('height', height);
      }

      // Set fill
      const pathElement = this.iconDiv.nativeElement.querySelector('path');
      if (pathElement) {
        pathElement.setAttribute('fill', 'currentColor');
      }
    });
  }
}
