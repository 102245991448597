import {User} from "../../models/user";

export interface ProfileData {
  username: string;
  displayName: string;
  pronouns: string;
  oldPassword: string;
  newPassword: string;
  newPassword2: string;
  dirty: boolean;
  isTemporaryPassword: boolean;
  valid: boolean;
  usernameError?: string;
  displayNameError?: string;
  oldPasswordError?: string;
  newPasswordError?: string;
  newPassword2Error?: string;
  loading: boolean;
}

export class ProfileState {
  readonly data: ProfileData;

  constructor(data: ProfileData) {
    this.data = data;
  }

  public static create(): ProfileState {
    return new ProfileState({
      username: '',
      displayName: '',
      pronouns: '',
      oldPassword: '',
      newPassword: '',
      newPassword2: '',
      dirty: false,
      isTemporaryPassword: true,
      valid: false,
      usernameError: '',
      displayNameError: '',
      oldPasswordError: '',
      newPasswordError: '',
      newPassword2Error: '',
      loading: true,
    });
  }

  public get canSave(): boolean {
    return this.data.dirty && this.data.valid;
  }

  public refresh(user: User): ProfileState {
    return new ProfileState({
      ...this.data,
      username: user.username,
      displayName: user.displayName,
      pronouns: user.pronouns,
      dirty: false,
      isTemporaryPassword: user.isTemporaryPassword || false,
      loading: false,
    }).validate();
  }

  public withLoading(loading: boolean): ProfileState {
    return new ProfileState({
      ...this.data,
      loading: loading,
    });
  }

  public withUsername(username: string): ProfileState {
    return new ProfileState({
      ...this.data,
      username: username,
      dirty: true,
    }).validate();
  }

  public withDisplayName(displayName: string): ProfileState {
    return new ProfileState({
      ...this.data,
      displayName: displayName,
      dirty: true,
    }).validate();
  }

  public withPronouns(pronouns: string): ProfileState {
    return new ProfileState({
      ...this.data,
      pronouns: pronouns,
      dirty: true,
    }).validate();
  }

  public withOldPassword(oldPassword: string): ProfileState {
    return new ProfileState({
      ...this.data,
      oldPassword: oldPassword,
      dirty: true,
    }).validate();
  }

  public withNewPassword(newPassword: string): ProfileState {
    return new ProfileState({
      ...this.data,
      newPassword: newPassword,
      dirty: true,
    }).validate();
  }

  public withNewPassword2(newPassword2: string): ProfileState {
    return new ProfileState({
      ...this.data,
      newPassword2: newPassword2,
      dirty: true,
    }).validate();
  }

  private validate(): ProfileState {
    let usernameError = '';
    if (!this.data.username) {
      usernameError = 'Username is required';
    } else if (this.data.username.length < 3) {
      usernameError = 'Username must be at least 3 characters';
    } else if (this.data.username.length > 32) {
      usernameError = 'Username must be at most 32 characters';
    } else if (!/^[a-zA-Z0-9_]+$/.test(this.data.username)) {
      usernameError = 'Username must contain only letters, numbers, and underscores';
    }

    let displayNameError = '';
    if (!this.data.displayName) {
      displayNameError = 'Display name is required';
    } else if (this.data.displayName.length < 3) {
      displayNameError = 'Display name must be at least 3 characters';
    }

    let newPasswordError = '';
    if (this.data.newPassword && this.data.newPassword.length < 8) {
      newPasswordError = 'Password must be at least 8 characters';
    } else if (this.data.newPassword && this.data.oldPassword === this.data.newPassword) {
      newPasswordError = 'New password must be different from old password';
    }

    let newPassword2Error = '';
    if (this.data.newPassword && this.data.newPassword !== this.data.newPassword2) {
      newPassword2Error = 'Passwords must match';
    }

    return new ProfileState({
      ...this.data,
      usernameError: usernameError,
      displayNameError: displayNameError,
      oldPasswordError: '',
      newPasswordError: newPasswordError,
      newPassword2Error: newPassword2Error,
      valid: !usernameError && !displayNameError && !newPasswordError && !newPassword2Error,
    })
  }
}
