import {Test} from "../../models/learning/domain/test";
import {Action} from "../../models/action";
import {TestQuestion} from "../../models/learning/domain/test-question";
import {TestSubmission} from "../../models/learning/domain/test-submission";
import {DateTools} from "../../tools/date.tools";
import {User} from "../../models/user";
import {TestTools} from "../../tools/test.tools";
import {TestCategory} from "../../models/learning/domain/test-category";
import {TestDifficulty} from "../../models/learning/domain/test-difficulty";

export interface TestSubmissionInfo {
  submission: TestSubmission;
  displayName: string;
  score: number;
}

export interface TestData {
  userId?: number;
  testId?: number;
  test?: Test;
  submissions: TestSubmission[];
  submissionInfos: TestSubmissionInfo[];
  users: User[];
  actions: Action[];
  owner?: User;
  displayName: string;
  displayNameError: string;
  description: string;
  category?: TestCategory;
  difficulty?: TestDifficulty;
  difficulties: TestDifficulty[];
  isMandatory: boolean;
  isPractice: boolean;
  isStudySession: boolean;
  deadline?: Date;
  isPublic: boolean;
  dirty: boolean;
  categories: TestCategory[];
  valid: boolean;
  loading: boolean;
}

export class TestState {
  readonly data: TestData;

  constructor(data: TestData) {
    this.data = data;
  }

  public static create(): TestState {
    return new TestState({
      userId: undefined,
      testId: undefined,
      test: undefined,
      submissions: [],
      actions: [],
      submissionInfos: [],
      users: [],
      owner: undefined,
      displayName: '',
      displayNameError: '',
      description: '',
      category: undefined,
      difficulty: undefined,
      difficulties: TestDifficulty.VALUES,
      isMandatory: false,
      isPractice: false,
      isStudySession: false,
      deadline: undefined,
      isPublic: false,
      dirty: false,
      categories: TestCategory.VALUES,
      valid: true,
      loading: true,
    });
  }

  public get canDelete(): boolean {
    return this.data.actions.includes(Action.DELETE);
  }

  public get canEdit(): boolean {
    return this.data.actions.includes(Action.EDIT);
  }

  public get canSave(): boolean {
    return this.data.dirty && this.data.valid;
  }

  public get canPracticeOrFillIn(): boolean {
    return this.canPractice || this.canFillIn;
  }

  public get canPractice(): boolean {
    return !!this.data.test && !this.data.dirty && this.data.isPractice;
  }

  public get canStudy(): boolean {
    return !!this.data.test && !this.data.dirty && this.data.test.isStudySession;
  }

  public get canFillIn(): boolean {
    return !!this.data.test && !this.data.isPractice && this.data.submissions.find(submission => submission.userId === this.data.userId) === undefined;
  }

  public get showResults(): boolean {
    return !!this.data.test && (this.data.submissions.length > 0 || !this.data.isPractice);
  }

  public get showQuestions(): boolean {
    return !!this.data.test && (this.data.isPractice || this.data.isStudySession);
  }

  public get ownerName(): string {
    return this.data.owner?.displayName || '?';
  }

  public get showDescription(): boolean {
    return this.canEdit || !!this.data.description;
  }

  public get isPracticeEnabled(): boolean {
    return this.canEdit && !this.data.isMandatory;
  }

  public get isStudySessionEnabled(): boolean {
    return this.canEdit && !this.data.isMandatory;
  }

  public refresh(userId: number, test: Test | undefined, questions: TestQuestion[], submissions: TestSubmission[], users: User[], actions: Action[]): TestState {
    return new TestState({
      ...this.data,
      userId: userId,
      test: test,
      submissions: submissions,
      users: users,
      submissionInfos: this.createSubmissionInfos(userId, questions, submissions, users),
      actions: actions,
      owner: !test ? users.find(user => user.id === userId) : users.find(user => user.id === test.owner),
      displayName: !test ? '' : test.displayName,
      description: !test ? '' : test.description,
      category: !test ? TestCategory.OTHER : test.category,
      difficulty: !test ? TestDifficulty.UNKNOWN : test.difficulty,
      isMandatory: !test ? false : test.isMandatory,
      isPractice: !test ? false : test.isPractice,
      isStudySession: !test ? false : test.isStudySession,
      isPublic: !test ? false : test.isPublic,
      deadline: DateTools.fromUtcString(test?.deadline),
      displayNameError: '',
      dirty: false,
      loading: false,
    }).validate();
  }

  public withLoading(loading: boolean): TestState {
    return new TestState({
      ...this.data,
      loading: loading,
    });
  }

  public withQueryId(testId?: number): TestState {
    return new TestState({
      ...this.data,
      testId: testId,
    });
  }

  public withDisplayName(displayName: string): TestState {
    return new TestState({
      ...this.data,
      displayName: displayName,
      dirty: true,
    }).validate();
  }

  public withDescription(description: string): TestState {
    return new TestState({
      ...this.data,
      description: description,
      dirty: true,
    }).validate();
  }

  public withCategory(category?: TestCategory): TestState {
    return new TestState({
      ...this.data,
      category: category,
      dirty: true,
    }).validate();
  }

  public withDifficulty(difficulty?: TestDifficulty): TestState {
    return new TestState({
      ...this.data,
      difficulty: difficulty,
      dirty: true
    }).validate();
  }

  public withIsMandatory(isMandatory: boolean): TestState {
    return new TestState({
      ...this.data,
      isMandatory: isMandatory,
      isPractice: false,
      isStudySession: false,
      dirty: true,
    }).validate();
  }

  public withIsPractice(isPractice: boolean): TestState {
    return new TestState({
      ...this.data,
      isPractice: isPractice,
      dirty: true,
    }).validate();
  }

  public withIsStudySession(isStudySession: boolean): TestState {
    return new TestState({
      ...this.data,
      isStudySession: isStudySession,
      dirty: true,
    }).validate();
  }

  public withIsPublic(isPublic: boolean): TestState {
    return new TestState({
      ...this.data,
      isPublic: isPublic,
      dirty: true,
    }).validate();
  }

  private validate(): TestState {
    let displayNameError = '';
    if (!this.data.displayName) {
      displayNameError = 'Test name is required';
    } else if (this.data.displayName.length < 3) {
      displayNameError = 'Test name must be at least 3 characters';
    }

    return new TestState({
      ...this.data,
      displayNameError: displayNameError,
      valid: !displayNameError,
    });
  }

  private createSubmissionInfos(userId: number, questions: TestQuestion[], testSubmissions: TestSubmission[], users: User[]): TestSubmissionInfo[] {
    return testSubmissions.map(submission => {
      const user = users.find(user => user.id === submission.userId);
      const correctAnswers = submission.answers.filter(answer => TestTools.isCorrect(questions, answer)).length;
      const totalAnswers = submission.answers.length;
      const score = Math.round(correctAnswers / totalAnswers * 100);
      return {
        submission: submission,
        displayName: user!.displayName + (userId === user!.id ? ' (your result)' : ''),
        score: score,
      };
    }).sort((a, b) => a.displayName.localeCompare(b.displayName));
  }
}
