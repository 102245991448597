import {League} from "../../models/league";
import {Team} from "../../models/team";
import {TeamLine} from "../../models/team-line";
import {Action} from "../../models/action";

export interface TeamLineData {
  leagueId?: number;
  league?: League;
  teamId?: number;
  team?: Team;
  lineId?: number;
  line?: TeamLine;
  actions: Action[];
  displayName: string;
  displayNameError: string;
  isActive: boolean;
  dirty: boolean;
  valid: boolean;
  loading: boolean;
}

export class TeamLineState {
  readonly data: TeamLineData;

  constructor(data: TeamLineData) {
    this.data = data;
  }

  public static create(): TeamLineState {
    return new TeamLineState({
      leagueId: undefined,
      league: undefined,
      teamId: undefined,
      team: undefined,
      lineId: undefined,
      line: undefined,
      actions: [],
      displayName: '',
      displayNameError: '',
      isActive: false,
      dirty: false,
      valid: true,
      loading: true,
    });
  }

  public get canEdit(): boolean {
    return this.data.actions.includes(Action.EDIT);
  }

  public get canSave(): boolean {
    return this.data.dirty && this.data.valid;
  }

  public get canDelete(): boolean {
    return this.data.actions.includes(Action.DELETE);
  }

  public refresh(league: League | undefined, team: Team | undefined, line: TeamLine | undefined, actions: Action[]): TeamLineState {
    return new TeamLineState({
      ...this.data,
      league: league,
      team: team,
      line: line,
      actions: actions,
      displayName: line?.displayName || '',
      displayNameError: '',
      isActive: !!line ? line.isActive : true,
      dirty: false,
      loading: false,
    }).validate();
  }

  public withLoading(loading: boolean): TeamLineState {
    return new TeamLineState({
      ...this.data,
      loading: loading,
    });
  }

  public withQueryIds(leagueId: number | undefined, teamId: number | undefined, lineId: number | undefined): TeamLineState {
    return new TeamLineState({
      ...this.data,
      leagueId: leagueId,
      teamId: teamId,
      lineId: lineId,
    });
  }

  public withDisplayName(displayName: string): TeamLineState {
    return new TeamLineState({
      ...this.data,
      displayName: displayName,
      dirty: true,
    }).validate();
  }

  public withIsActive(isActive: boolean): TeamLineState {
    return new TeamLineState({
      ...this.data,
      isActive: isActive,
      dirty: true,
    });
  }

  private validate(): TeamLineState {
    let displayNameError = '';
    if (!this.data.displayName) {
      displayNameError = 'Derby name is required';
    } else if (this.data.displayName.length < 3) {
      displayNameError = 'Derby name must be at least 3 characters';
    }

    return new TeamLineState({
      ...this.data,
      displayNameError: displayNameError,
      valid: !displayNameError,
    });
  }
}
