import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SessionService} from './session.service';
import {Scope} from "../models/scope";
import {SessionTools} from "../tools/session.tools";
import {RouterTools} from "../tools/router.tools";


@Injectable({
  providedIn: 'root'
})
class PermissionsService {

  constructor(private router: Router,
              private sessionService: SessionService) {
  }

  checkScope(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    const scope = route.data['scope'] as Scope;
    return this.sessionService.session$.pipe(
      map(session => {
        // Check session
        if (!session) {
          return false;
        }

        // Check temporary password
        if (session?.isTemporaryPassword) {
          if (scope === Scope.PROFILE_READ) {
            return true;
          }
          RouterTools.goToProfile(this.router);
          return false;
        }

        // Check scope
        const hasAccess = !scope ? true : SessionTools.hasScope(session, scope);
        if (!hasAccess) {
          this.router.navigate(['/access-denied']);
          return false;
        }
        return true;
      })
    );
  }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
  return inject(PermissionsService).checkScope(next, state);
}
