export class DialogOptions {

  public static CONFIRM: string = 'OK';
  public static CANCEL: string = 'Cancel';

  public readonly title: string;
  public readonly message: string;
  public readonly onConfirm: () => void;
  public readonly onCancel: () => void;
  public readonly confirmText: string;
  public readonly cancelText: string;

  constructor(title: string, message: string, onConfirm: () => void, onCancel: () => void, confirmText: string, cancelText: string) {
    this.title = title;
    this.message = message;
    this.onConfirm = onConfirm;
    this.onCancel = onCancel;
    this.confirmText = confirmText;
    this.cancelText = cancelText;
  }

  public static create(title: string, message: string): DialogOptions {
    return new DialogOptions(title, message, () => {}, () => {}, DialogOptions.CONFIRM, DialogOptions.CANCEL);
  }

  public withConfirm(onConfirm: () => void, confirmText: string = DialogOptions.CONFIRM): DialogOptions {
    return new DialogOptions(this.title, this.message, onConfirm, this.onCancel, confirmText, this.cancelText);
  }

  public withCancel(onCancel: () => void, cancelText: string = DialogOptions.CANCEL): DialogOptions {
    return new DialogOptions(this.title, this.message, this.onConfirm, onCancel, this.confirmText, cancelText);
  }
}
