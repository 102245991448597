import {Component, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {RouterTools} from "../../tools/router.tools";
import {SessionService} from "../../services/session.service";
import {PageButton, PageState} from "../../models/page.state";
import {BehaviorSubject} from "rxjs";
import {PageService} from "../../services/page.service";
import {Scope} from "../../models/scope";
import {Tab} from "../../models/tab";
import {ScreenService} from "../../services/screen.service";
import {SearchComponent} from "../search/search.component";
import {StateSubject} from "../../tools/state.subject";

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent {

  @ViewChild("search1") searchField?: SearchComponent;
  @ViewChild("search2") searchField2?: SearchComponent;

  protected readonly Scope = Scope;

  page$: StateSubject<PageState>;

  constructor(private router: Router,
              private sessionService: SessionService,
              private screenService: ScreenService,
              private pageService: PageService) {
    this.page$ = pageService.page$;
    this.sessionService.session$.subscribe(session => {
      this.pageService.invoke(page => page.withUserSession(session));
    });
    this.screenService.size$.subscribe(size => {
      this.pageService.invoke(page => page.withScreenSize(size));
    });
    this.page$.get(page => page.data.title).subscribe(() => {
      this.searchField?.clear();
      this.searchField2?.clear();
    });
  }

  public goHome(): void {
    RouterTools.goHome(this.router);
  }

  public goToProfile(): void {
    RouterTools.goToProfile(this.router);
  }

  public goBack(): void {
    const page = this.pageService.page;
    page.data.backButton?.onClick();
  }

  public goTo(path: string): void {
    this.router.navigate([path]);
  }

  public isPathActive(path: string): boolean {
    return this.router.url.startsWith(path);
  }

  public onTabClick(tab: Tab): void {
    if (tab.onClick && tab.enabled) {
      tab.onClick();
    }
  }

  public onButtonClick(button: PageButton) {
    if (button.onClick) {
      button.onClick();
    }
  }

  public onSearch(query: string | undefined): void {
    const page = this.pageService.page;
    if (!page.data.search) {
      return;
    }
    page.data.search(query);
  }
}
