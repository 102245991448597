import {Team} from "../../models/team";
import {League} from "../../models/league";
import {Game} from "../../models/game";
import {SearchTools} from "../../tools/search.tools";
import {DateTools} from "../../tools/date.tools";
import {Objects} from "../../tools/objects";

export interface GameInfo {
  game: Game;
  startDate?: Date;
  team1?: Team;
  team2?: Team;
  league1?: League;
  league2?: League;
  displayName: string;
  group?: string;
  hasScore: boolean;
}

export interface GamesData {
  leagues: League[];
  teams: Team[];
  games: Game[];
  gameInfos: GameInfo[];
  filteredGameInfos: GameInfo[];
  search?: string;
  canWrite: boolean;
  loading: boolean;
}

export class GamesState {
  readonly data: GamesData;

  constructor(data: GamesData) {
    this.data = data;
  }

  public static create(): GamesState {
    return new GamesState({
      leagues: [],
      teams: [],
      games: [],
      gameInfos: [],
      filteredGameInfos: [],
      canWrite: false,
      loading: true,
    });
  }

  public refresh(leagues: League[], teams: Team[], games: Game[]): GamesState {
    return new GamesState({
      ...this.data,
      leagues: leagues,
      teams: teams,
      games: games,
      loading: false,
    }).updateGameInfos().filterGames();
  }

  public withLoading(loading: boolean): GamesState {
    return new GamesState({
      ...this.data,
      loading: loading,
    });
  }

  public withSearch(value: string | undefined): GamesState {
    return new GamesState({
      ...this.data,
      search: value,
    }).filterGames();
  }

  public withCanWrite(canWrite: boolean): GamesState {
    return new GamesState({
      ...this.data,
      canWrite: canWrite,
    });
  }

  private updateGameInfos(): GamesState {
    const gameInfos: GameInfo[] = [];
    let prevGroup = undefined;
    for (const game of this.data.games) {
      const team1 = this.data.teams.find(team => team.id === game.teamId1);
      const team2 = this.data.teams.find(team => team.id === game.teamId2);
      const league1 = !team1 ? undefined : this.data.leagues.find(league => league.id === team1.leagueId);
      const league2 = !team2 ? undefined : this.data.leagues.find(league => league.id === team2.leagueId);
      const startDate = game.startDate;
      const candidateGroup = DateTools.toMonthYear(startDate);
      const curGroup = prevGroup !== candidateGroup ? candidateGroup : '';
      prevGroup = candidateGroup;
      gameInfos.push({
        game: game,
        startDate: startDate,
        team1: team1,
        team2: team2,
        league1: league1,
        league2: league2,
        displayName: `${team1?.displayName} vs ${team2?.displayName}`,
        group: curGroup,
        hasScore: Objects.isNotNull(game.score1) && Objects.isNotNull(game.score2)
      });
    }

    return new GamesState({
      ...this.data,
      gameInfos: gameInfos
    });
  }

  private filterGames(): GamesState {
    const textF = (gameInfo: GameInfo) => '' + gameInfo.team1?.displayName + gameInfo.team2?.displayName + gameInfo.league1?.displayName + gameInfo.league2?.displayName;
    return new GamesState({
      ...this.data,
      filteredGameInfos: SearchTools.filter(this.data.gameInfos.map(game => {
        return {
          ...game,
          group: Objects.isEmpty(this.data.search) ? game.group : undefined,
        };

      }), textF, this.data.search),
    });
  }
}
