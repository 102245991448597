import {Component, OnInit} from "@angular/core";
import {TestService} from "../../services/test.service";
import {NotificationService} from "../../services/notification.service";
import {Message} from "../../models/message";
import {TestState} from "./test.state";
import {ActivatedRoute, Router} from "@angular/router";
import {RouterTools} from "../../tools/router.tools";
import {StateSubject} from "../../tools/state.subject";
import {forkJoin, Observable, of} from "rxjs";
import {DialogService} from "../../services/dialog.service";
import {DialogOptions} from "../../models/dialog-options";
import {TestSubmissionService} from "../../services/test-submission.service";
import {TestQuestionService} from "../../services/test-question.service";
import {DateTools} from "../../tools/date.tools";
import {SessionService} from "../../services/session.service";
import {Scope} from "../../models/scope";
import {UserService} from "../../services/user.service";
import {Objects} from "../../tools/objects";
import {Tabs} from "../../models/tabs";
import {Tab} from "../../models/tab";
import {PageService} from "../../services/page.service";
import {PageButton} from "../../models/page.state";
import {Test} from "../../models/learning/domain/test";
import {TestCategory} from "../../models/learning/domain/test-category";
import {SelectInfo} from "../../models/select-info";
import {TestDifficulty} from "../../models/learning/domain/test-difficulty";

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  state$: StateSubject<TestState> = new StateSubject<TestState>(TestState.create());
  testsManage$: Observable<boolean>;

  constructor(private testService: TestService,
              private testQuestionService: TestQuestionService,
              private testSubmissionService: TestSubmissionService,
              private userService: UserService,
              private notificationService: NotificationService,
              private dialogService: DialogService,
              private sessionService: SessionService,
              private pageService: PageService,
              private route: ActivatedRoute,
              private router: Router) {
    this.state$.subscribe(state => this.pageService.invoke(page => {
      const tabs: Tab[] = [];
      if (state.showQuestions) {
        tabs.push(Tab.of("Info", true));
        tabs.push(Tab.of("Questions", Objects.isNotNull(state.data.test), () => RouterTools.goToTestQuestions(this.router, state.data.testId!)));
      }
      return page.reset(Objects.isNotNull(state.data.testId) ? state.data.test?.displayName : 'New Test')
        .withBackButton(() => RouterTools.goToTests(this.router))
        .withTabs(Tabs.of(tabs, 0))
        .withActions(this.getButtons(state));
    }));
    this.testsManage$ = this.sessionService.hasScope(Scope.TESTS_MANAGE);
  }

  private getButtons(state: TestState): PageButton[] {
    return [
      {
        text: 'Save',
        icon: 'save',
        visible: state.canEdit,
        enabled: state.canSave,
        active: true,
        primary: true,
        onClick: () => this.save()
      },
      {
        text: 'Delete',
        icon: 'delete',
        visible: state.canDelete,
        enabled: !!state.data.test?.id,
        active: false,
        primary: false,
        onClick: () => this.delete()
      },
      {
        text: state.data.isPractice ? 'Practice' : 'Fill In',
        icon: 'edit',
        visible: state.canPracticeOrFillIn,
        enabled: !state.data.dirty,
        active: false,
        primary: false,
        onClick: () => this.goToFillIn()
      },
      {
        text: 'Study',
        icon: 'cards',
        visible: state.canStudy,
        enabled: !state.data.dirty,
        active: false,
        primary: false,
        onClick: () => this.goToStudy()
      }
    ];
  }

  ngOnInit(): void {
    RouterTools.observeParamMap(this.route, params => {
      const testId = RouterTools.toNumber(params, 'testId');
      this.state$.invoke(state => state.withQueryId(testId));
    });
    this.state$.get(state => '' + state.data.testId).subscribe(() => {
      this.refresh();
    });
  }

  public refresh(): void {
    const state = this.state$.getValue();
    const testId = state.data.testId;
    const userId = Objects.toNumber(this.sessionService.session!.userId)!;

    forkJoin([
      testId ? this.testService.oneById(testId) : of(undefined),
      testId ? this.testQuestionService.allByTestId(testId) : of([]),
      testId ? this.testSubmissionService.allByTestId(testId) : of([]),
      this.userService.getUsers(),
      this.testService.getActions(testId),
    ]).subscribe({
      next: ([test, questions, submissions, users, actions]) => {
        this.state$.invoke(state => state.refresh(userId, test, questions, submissions, users, actions));
      },
      error: () => {
        this.notificationService.show(Message.error(`Failed to load test`));
        this.state$.invoke(state => state.withLoading(false));
      }
    });
  }

  public save(): void {
    const state = this.state$.getValue();
    let test = {
      displayName: state.data.displayName,
      description: state.data.description,
      category: state.data.category,
      difficulty: state.data.difficulty,
      isMandatory: state.data.isMandatory,
      isPractice: state.data.isPractice,
      isStudySession: state.data.isStudySession,
      deadline: DateTools.toUtcString(state.data.deadline),
      isPublic: state.data.isPublic,
    } as Partial<Test>;

    if (!state.data.test) {
      this.testService.create(test).subscribe({
        next: test => {
          RouterTools.goToTest(this.router, test.id);
        },
        error: () => {
          this.notificationService.show(Message.error('Failed to create test'));
        }
      });
      return;
    }

    test = {
      id: state.data.test.id,
      ...test,
    };
    this.testService.update(state.data.test.id, test).subscribe({
      next: () => this.refresh(),
      error: () => {
        this.notificationService.show(Message.error('Failed to update test'));
      }
    });
  }

  public delete() {
    this.dialogService.show(DialogOptions
      .create('Delete Test', 'Are you sure you want to delete this test?')
      .withConfirm(() => this.doDelete()));
  }

  private doDelete(): void {
    const state = this.state$.getValue();
    if (!state.data.test) {
      return;
    }
    this.testService.delete(state.data.test.id).subscribe({
      next: () => {
        this.notificationService.show(Message.success('Test deleted'));
        RouterTools.goToTests(this.router);
      },
      error: () => {
        this.notificationService.show(Message.error('Failed to delete test'));
      }
    });
  }

  public deleteSubmission(testSubmissionId: number): void {
    this.dialogService.show(DialogOptions
      .create('Delete Result', 'Are you sure you want to delete this result?')
      .withConfirm(() => this.doDeleteSubmission(testSubmissionId)));
  }

  private doDeleteSubmission(testSubmissionId: number): void {
    this.testSubmissionService.delete(testSubmissionId).subscribe({
      next: () => {
        this.notificationService.show(Message.success('Result deleted'));
        this.refresh();
      },
      error: () => {
        this.notificationService.show(Message.error('Failed to delete result'));
      }
    });
  }

  public goToSubmission(testSubmissionId: number): void {
    const state = this.state$.getValue();
    RouterTools.goToTestSubmission(this.router, state.data.testId!, testSubmissionId);
  }

  public goToStudy(): void {
    const state = this.state$.getValue();
    RouterTools.goToTestStudy(this.router, state.data.testId!);
  }

  public goToFillIn(): void {
    const state = this.state$.getValue();
    RouterTools.goToTestSubmissionNew(this.router, state.data.testId!);
  }

  public withDisplayName(event: any): void {
    this.state$.invoke(state => state.withDisplayName(event.target.value));
  }

  public withDescription(event: any): void {
    this.state$.invoke(state => state.withDescription(event.target.value));
  }

  public withCategory(category?: TestCategory): void {
    this.state$.invoke(state => state.withCategory(category));
  }

  public withDifficulty(difficulty?: TestDifficulty): void {
    this.state$.invoke(state => state.withDifficulty(difficulty));
  }

  public withIsMandatory(checked: boolean): void {
    this.state$.invoke(state => state.withIsMandatory(checked));
  }

  public withIsPractice(checked: boolean): void {
    this.state$.invoke(state => state.withIsPractice(checked));
  }

  public withIsStudySession(checked: boolean): void {
    this.state$.invoke(state => state.withIsStudySession(checked));
  }

  public withIsPublic(isPublic: boolean): void {
    this.state$.invoke(state => state.withIsPublic(isPublic));
  }

  public categoryRenderer(): (value?: TestCategory) => SelectInfo {
    return value => !value ? SelectInfo.empty() : SelectInfo.of([value.displayName]);
  }

  public difficultyRenderer(): (value?: TestDifficulty) => SelectInfo {
    return value => !value ? SelectInfo.empty() : SelectInfo.of([value.displayName]);
  }
}
