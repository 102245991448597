import {GameDto} from "../dto/game-dto";
import {Game} from "../../game";
import {DateTools} from "../../../tools/date.tools";

export class GameMapper {

  public static fromDto(test: GameDto): Game {
    return {
      ...test,
      startDate: DateTools.fromUtcString(test.startDate)
    };
  }

  public static toDto(test: Partial<Game>): Partial<GameDto> {
    return {
      ...test,
      startDate: DateTools.toUtcString(test.startDate)
    };
  }
}
