export class Objects {


  public static isNull(obj: any): boolean {
    return obj === null || obj === undefined;
  }

  public static isNotNull(obj: any): boolean {
    return !Objects.isNull(obj);
  }

  public static ifNull(obj: any, defaultValue: any): any {
    return Objects.isNull(obj) ? defaultValue : obj;
  }

  public static toNumber(value: any): number | undefined {
    try {
      return Objects.isNull(value) || isNaN(Number(value)) ? undefined : Number(value);
    } catch (e) {
      return undefined;
    }
  }

  public static shuffle<A>(numbers: A[]): A[] {
    const randoms: {sort: number, value: A}[] = [];
    for (let i = 0; i < numbers.length; i++) {
      const number = Objects.random();
      randoms.push({sort: number, value: numbers[i]});
    }
    return randoms.sort((a, b) => a.sort - b.sort).map(random => random.value);
  }

  public static random(): number {
    return (Math.round((new Date().getTime()) % 10) / 10 + Math.random()) / 2;
  }

  static isEmpty(search: string | undefined): boolean {
    return Objects.isNull(search) || search!.trim().length === 0;
  }
}
