import {BehaviorSubject, distinctUntilChanged, map, Observable, Observer, Subscribable, Unsubscribable} from "rxjs";
import equal from 'fast-deep-equal';
import {toSignal} from "@angular/core/rxjs-interop";
import {Signal} from "@angular/core";

export class StateSubject<S> implements Subscribable<S> {
  private state$: BehaviorSubject<S>

  constructor(initialState: S) {
    this.state$ = new BehaviorSubject<S>(initialState);
  }

  public getValue(): S {
    return this.state$.getValue();
  }

  public subscribe(observerOrNext?: Partial<Observer<S>> | ((value: S) => void)): Unsubscribable {
    return this.state$.subscribe(observerOrNext);
  }

  public get<R>(mapFunction: (state: S) => R): Observable<R> {
    return this.state$.pipe(
      map(mapFunction),
      distinctUntilChanged((previous, current) => equal(previous, current))
    );
  }

  public signal<R>(mapFunction: (state: S) => R): Signal<R> {
    return toSignal(this.get(mapFunction), {initialValue: mapFunction(this.getValue())});
  }

  public getSignal<X, R>(mapFunction: (state: S) => X, mapTo: (value: X) => R): Signal<R> {
    return toSignal(this.get(mapFunction).pipe(map(mapTo)), {initialValue: mapTo(mapFunction(this.getValue()))});
  }

  public invoke(reducer: (state: S) => S): void {
    const current = this.state$.getValue();
    const next = reducer(current);
    if (equal(current, next)) {
      return;
    }
    this.state$.next(next);
  }
}
