export enum Scope {
  HOME_READ = "home-read",
  EVENTS_READ = "events-read",
  CLASSES_READ = "classes-read",
  CLASSES_WRITE = "classes-write",
  TRAININGS_READ = "trainings-read",
  TRAININGS_WRITE = "trainings-write",
  SKILLS_READ = "skills-read",
  SKILLS_WRITE = "skills-write",
  TESTS_READ = "tests-read",
  TESTS_WRITE = "tests-write",
  TESTS_MANAGE = 'tests-manage',
  LEAGUES_READ = "leagues-read",
  LEAGUES_WRITE = "leagues-write",
  TEAMS_READ = "teams-read",
  TEAMS_WRITE = "teams-write",
  SKATERS_READ = "skaters-read",
  SKATERS_WRITE = "skaters-write",
  GAMES_READ = "games-read",
  GAMES_WRITE = "games-write",
  JAMS_READ = "jams-read",
  JAMS_WRITE = "jams-write",
  LIVE_READ = "live-read",
  PROFILE_READ = "profile-read",
  PROFILE_WRITE = "profile-write",
  USERS_READ = "users-read",
  USERS_WRITE = "users-write",
}
