import {TestQuestion} from "../models/learning/domain/test-question";
import {TestSubmission} from "../models/learning/domain/test-submission";
import {TestAnswer} from "../models/learning/domain/test-answer";
import {Test} from "../models/learning/domain/test";
import {TestCategory} from "../models/learning/domain/test-category";
import {TestDto} from "../models/learning/dto/test-dto";
import {TestQuestionDto} from "../models/learning/dto/test-question-dto";
import {TestSubmissionDto} from "../models/learning/dto/test-submission-dto";

export class TestTools {

  public static isCorrect(questions: TestQuestion[], candidate: TestAnswer, completeAnswer: boolean = true): boolean {
    const question = questions.find(question => question.id === candidate.testQuestionId);
    if (!question) {
      return false;
    }
    for (let i = 0; i < question.answers.length; i++) {
      const hasAnswersAsCorrect = candidate.answers.includes(i);
      const isAnswerCorrect = question.answers[i].isCorrect;
      if (!completeAnswer && hasAnswersAsCorrect && !isAnswerCorrect) {
        return false;
      }
      if (completeAnswer && hasAnswersAsCorrect !== isAnswerCorrect) {
        return false;
      }
    }
    return true;
  }
}
