<div class="page-with-actions">
  <app-loading *ngIf="(state$ | async)!.data.loading"></app-loading>
  <div class="cards" *ngIf="!(state$ | async)!.data.loading">
    <div class="card">
      <div class="card-header">
        <div class="subtitle">
          <app-icon name="psychology"></app-icon>
          Test info
        </div>
      </div>
      <div class="form">
        <div class="field">
          <label class="field-label" for="displayName">Name</label>
          <input type="text" class="field-text" id="displayName"
                 [disabled]="!(state$ | async)!.canEdit"
                 [value]="(state$ | async)!.data.displayName"
                 (input)="withDisplayName($event)"/>
          <div class="field-error" *ngIf="(state$ | async)!.data.displayNameError">
            {{(state$ | async)!.data.displayNameError}}
          </div>
        </div>
        <div class="field" *ngIf="(state$ | async)!.canEdit">
          <label class="field-label" for="owner">Owner</label>
          <input type="text" class="field-text" id="owner"
                 disabled
                 [value]="(state$ | async)!.ownerName"/>
        </div>
        <div class="field" *ngIf="(state$ | async)!.showDescription">
          <label class="field-label" for="description">Description</label>
          <textarea class="field-text" id="description"
                    [disabled]="!(state$ | async)!.canEdit"
                    [value]="(state$ | async)!.data.description"
                    (input)="withDescription($event)" appAutoResize></textarea>
        </div>
        <div class="field-columns-50-50">
          <div class="field">
            <label class="field-label" for="category">Category</label>
            <app-select id="category"
                        [disabled]="!(state$ | async)!.canEdit"
                        [values]="(state$ | async)!.data.categories"
                        (selectEvent)="withCategory($event)"
                        [renderer]="categoryRenderer()"
                        [includeEmpty]="false"
                        [selection]="(state$ | async)!.data.category"></app-select>
          </div>
          <div class="field">
            <label class="field-label" for="difficulty">Difficulty</label>
            <app-select id="difficulty"
                        [disabled]="!(state$ | async)!.canEdit"
                        [values]="(state$ | async)!.data.difficulties"
                        (selectEvent)="withDifficulty($event)"
                        [renderer]="difficultyRenderer()"
                        [includeEmpty]="false"
                        [selection]="(state$ | async)!.data.difficulty"></app-select>
          </div>
        </div>
        <div class="field-columns-50-50" *ngIf="testsManage$ | async">
          <div class="field-group">
            <div class="field">
              <app-checkbox text="Public"
                            textFaded="Visible to other users"
                            [disabled]="!(state$ | async)!.canEdit"
                            [checked]="(state$ | async)!.data.isPublic"
                            (checked)="withIsPublic($event)"></app-checkbox>
            </div>
            <div class="field">
              <app-checkbox text="Mandatory"
                            textFaded="Show on home page"
                            [disabled]="!(state$ | async)!.canEdit"
                            [checked]="(state$ | async)!.data.isMandatory"
                            (checked)="withIsMandatory($event)"></app-checkbox>
            </div>
          </div>
          <div class="field-group">
            <div class="field">
              <app-checkbox text="Practice"
                            textFaded="Infinite retries and don't save results"
                            [disabled]="!(state$ | async)!.isPracticeEnabled"
                            [checked]="(state$ | async)!.data.isPractice"
                            (checked)="withIsPractice($event)"></app-checkbox>
            </div>
            <div class="field">
              <app-checkbox text="Study"
                            textFaded="Enable flash cards"
                            [disabled]="!(state$ | async)!.isStudySessionEnabled"
                            [checked]="(state$ | async)!.data.isStudySession"
                            (checked)="withIsStudySession($event)"></app-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card" *ngIf="(state$ | async)!.showResults">
      <div class="card-header">
        <div class="subtitle">
          <app-icon name="edit"></app-icon>
          Results
        </div>
      </div>

      <div class="list">
        <div class="list-item-secondary list-item-disabled" *ngFor="let info of (state$ | async)!.data.submissionInfos">
          <div class="list-text-secondary" (click)="goToSubmission(info.submission.id!)">
            <div>
              {{ info.displayName }}
            </div>
            <div>
              {{ info.score }}%
            </div>
          </div>
          <div class="list-actions">
            <button class="list-action-icon" (click)="deleteSubmission(info.submission.id!)">
              <app-icon name="delete"></app-icon>
            </button>
          </div>
        </div>
        <div class="list-item-secondary" *ngIf="(state$ | async)!.data.submissions.length === 0">
          <div class="list-text-message">
            No results submitted
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
