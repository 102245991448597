<div class="page">
  <app-loading *ngIf="(state$ | async)!.data.loading"></app-loading>
  <div class="cards" *ngIf="!(state$ | async)!.data.loading">
    <div class="card">
      <div class="card-header">
        <div class="subtitle">
          <app-icon name="question"></app-icon>
          Question Info
        </div>
        <div class="header-actions">
          <button class="button-icon-secondary"
                  [disabled]="(state$ | async)!.previousQuestionId === undefined"
                  (click)="goToPreviousQuestion()">
            <app-icon name="arrow_backward"></app-icon>
          </button>
          <button class="button-icon-secondary"
                  [disabled]="(state$ | async)!.nextQuestionId === undefined"
                  (click)="goToNextQuestion()">
            <app-icon name="arrow_forward"></app-icon>
          </button>
        </div>
      </div>
      <div class="form">
        <div class="field">
          <label class="field-label" for="question">Question</label>
          <textarea class="field-text" id="question"
                    [disabled]="!(state$ | async)!.canEdit"
                    [value]="(state$ | async)!.data.question"
                    (input)="withQuestion($event)"
                    #primary appAutoResize></textarea>
          <div class="field-error" *ngIf="(state$ | async)!.data.questionError">
            {{(state$ | async)!.data.questionError}}
          </div>
        </div>

        <div class="field">
          <label class="field-label">Answers</label>
          <ng-container *ngFor="let i of answerIndices">
            <div class="field-text-with-actions" *ngIf="(state$ | async)!.data.answers.length > i">
              <textarea class="field-text" id="wrong-answer{{i}}"
                        [disabled]="!(state$ | async)!.canEdit"
                        [value]="(state$ | async)!.data.answers[i].text"
                        (input)="withAnswerText(i, $event)"
                        appAutoResize></textarea>
              <button class="button-icon-secondary"
                      [disabled]="!(state$ | async)!.canEdit"
                      (click)="toggleAnswerIsCorrect(i)">
                <app-icon [name]="(state$ | async)!.data.answers[i].isCorrect ? 'check_circle' : 'circle'"></app-icon>
              </button>
              <button class="button-icon-secondary" *ngIf="(state$ | async)!.canDeleteAnswer" (click)="deleteAnswer(i)">
                <app-icon name="delete"></app-icon>
              </button>
            </div>
          </ng-container>
          <div class="field-error" *ngIf="(state$ | async)!.data.answersError">
            {{(state$ | async)!.data.answersError}}
          </div>
          <div class="field-info" *ngIf="(state$ | async)!.canEdit">
            All Answers will be automatically sorted alphabetically, so the order you enter them in doesn't matter.
          </div>
          <div class="field-text-with-actions">
            <div></div>
            <button class="button-icon-secondary" *ngIf="(state$ | async)!.canAddAnswer"
                    (click)="addAnswer()">
              <app-icon name="add"></app-icon>
            </button>
          </div>
        </div>
        <div class="field">
          <label class="field-label" for="rationale">Rationale</label>
          <textarea class="field-text" id="rationale"
                    [disabled]="!(state$ | async)!.canEdit"
                    [value]="(state$ | async)!.data.rationale"
                    (input)="withRationale($event)"
                    appAutoResize></textarea>
        </div>
      </div>
    </div>
  </div>
</div>
