import {Directive, OnDestroy} from "@angular/core";
import {Subscriptions} from "../tools/subscriptions";

@Directive()
export abstract class AbstractComponent implements OnDestroy {

  protected subscriptions: Subscriptions = new Subscriptions();

  protected constructor() {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
