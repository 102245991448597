import {Component, OnInit} from "@angular/core";
import {TeamLineState} from "./team-line.state";
import {StateSubject} from "../../tools/state.subject";
import {forkJoin, of} from "rxjs";
import {LeagueService} from "../../services/league.service";
import {TeamService} from "../../services/team.service";
import {TeamLineService} from "../../services/team-line.service";
import {RouterTools} from "../../tools/router.tools";
import {ActivatedRoute, Router} from "@angular/router";
import {Message} from "../../models/message";
import {NotificationService} from "../../services/notification.service";
import {DialogService} from "../../services/dialog.service";
import {DialogOptions} from "../../models/dialog-options";
import {PageService} from "../../services/page.service";

@Component({
  selector: 'app-team-line',
  templateUrl: './team-line.component.html',
  styleUrls: ['./team-line.component.scss']
})
export class TeamLineComponent implements OnInit {

  state$: StateSubject<TeamLineState> = new StateSubject<TeamLineState>(TeamLineState.create());

  constructor(private leagueService: LeagueService,
              private teamService: TeamService,
              private teamLineService: TeamLineService,
              private route: ActivatedRoute,
              private pageService: PageService,
              private router: Router,
              private notificationService: NotificationService,
              private dialogService: DialogService) {
    this.state$.subscribe(state => {
      this.pageService.invoke(page => page.reset(state.data.line?.displayName || 'New Line')
        .withBackButton(() => RouterTools.goToTeamLines(this.router, state.data.leagueId!, state.data.teamId!))
        .withActions([
          {
            text: 'Save',
            icon: 'save',
            visible: state.canEdit,
            enabled: state.canSave,
            active: true,
            primary: true,
            onClick: () => this.save()
          },
          {
            text: 'Delete',
            icon: 'delete',
            visible: state.canDelete,
            enabled: !!state.data.line?.id,
            active: false,
            primary: false,
            onClick: () => this.delete()
          }
        ])
      );
    });
  }

  ngOnInit() {
    RouterTools.observeParamMap(this.route, params => {
      const leagueId = RouterTools.toNumber(params, 'leagueId');
      const teamId = RouterTools.toNumber(params, 'teamId');
      const lineId = RouterTools.toNumber(params, 'lineId');
      this.state$.invoke(state => state.withQueryIds(leagueId, teamId, lineId));
    });
    this.state$.get(state => state.data.leagueId + '' + state.data.teamId + state.data.lineId).subscribe(() => {
      this.refresh();
    });
  }

  public refresh(): void {
    const state = this.state$.getValue();
    const leagueId = state.data.leagueId;
    const teamId = state.data.teamId;
    const lineId = state.data.lineId;

    if (!leagueId) {
      this.state$.invoke(state => state.refresh(undefined, undefined, undefined, []));
      return;
    }

    forkJoin([
      this.leagueService.getLeague(leagueId),
      !teamId ? of(undefined) : this.teamService.getTeam(teamId),
      !lineId ? of(undefined) : this.teamLineService.getTeamLine(lineId),
      this.teamLineService.getActions(lineId)
    ]).subscribe({
      next: ([league, team, line, actions]) => {
        this.state$.invoke(state => state.refresh(league, team, line, actions));
      },
      error: () => {
        this.notificationService.show(Message.error(`Failed to load line`));
        this.state$.invoke(state => state.withLoading(false));
      }
    });
  }

  public save(): void {
    const state = this.state$.getValue();
    if (!state.data.line) {
      const newLine = {
        teamId: state.data.teamId,
        displayName: state.data.displayName,
        isActive: state.data.isActive,
      };
      this.teamLineService.createTeamLine(newLine).subscribe({
        next: line => {
          RouterTools.goToTeamLine(this.router, state.data.leagueId!, state.data.teamId!, line.id);
        },
        error: () => {
          this.notificationService.show(Message.error(`Failed to create line`));
        }
      });
      return;
    }

    const updatedLine = {
      id: state.data.lineId,
      teamId: state.data.teamId,
      displayName: state.data.displayName,
      isActive: state.data.isActive,
    };
    this.teamLineService.updateTeamLine(state.data.line.id, updatedLine).subscribe({
      next: () => this.refresh(),
      error: () => {
        this.notificationService.show(Message.error(`Failed to update line`));
      }
    });
  }

  public delete(): void {
    this.dialogService.show(DialogOptions
      .create('Delete Line', 'Are you sure you want to delete this line?')
      .withConfirm(() => this.doDelete()));
  }

  private doDelete(): void {
    const state = this.state$.getValue();
    if (!state.data.line) {
      return;
    }
    this.teamLineService.deleteTeamLine(state.data.line.id).subscribe({
      next: () => {
        RouterTools.goToTeam(this.router, state.data.leagueId!, state.data.teamId!);
        this.notificationService.show(Message.success('Line deleted'));
      },
      error: () => {
        this.notificationService.show(Message.error('Failed to delete line'));
      }
    });
  }

  public withDisplayName(event: any): void {
    this.state$.invoke(state => state.withDisplayName(event.target.value));
  }

  public withIsActive(isActive: boolean): void {
    this.state$.invoke(state => state.withIsActive(isActive));
  }
}
