<div class="jams-page">
  <div class="jams-content">
    <ng-container class="card" *ngFor="let periodIndex of periodIndices">
      <div class="jam-list" #jamList>
        <div class="list-group">
          Period {{ periodIndex + 1 }}
        </div>
        <div class="jam-list-header">
          <div>#</div>
          <div [style]="'border-color: ' + backgroundColors$()![0] + ';'">
            {{ showPivot$() ? 'Pivot' : 'Line' }}
          </div>
          <div [style]="'border-color: ' + backgroundColors$()![0] + ';'">
            Jammer
          </div>
          <div [style]="'border-color: ' + backgroundColors$()![0] + ';'">
            P#
          </div>
          <div [style]="'border-color: ' + backgroundColors$()![0] + ';'">
            SP
          </div>
          <div [style]="'border-color: ' + backgroundColors$()![0] + ';'">
            Score
          </div>
          <div>Lead</div>
          <div [style]="'border-color: ' + backgroundColors$()![1] + ';'">
            Score
          </div>
          <div [style]="'border-color: ' + backgroundColors$()![1] + ';'">
            SP
          </div>
          <div [style]="'border-color: ' + backgroundColors$()![1] + ';'">
            P#
          </div>
          <div [style]="'border-color: ' + backgroundColors$()![1] + ';'">
            Jammer
          </div>
          <div [style]="'border-color: ' + backgroundColors$()![1] + ';'">
            {{ showPivot$() ? 'Pivot' : 'Line' }}
          </div>
          <div>&nbsp;</div>
        </div>
        <ng-container *ngFor="let jamIndex of jamIndices$()![periodIndex]">
          <div class="jam-list-row"
               [class.jam-list-row-selected]="(state$ | async)!.isMoreVisible(periodIndex, jamIndex)">
            <div>{{ jamIndex + 1 }}</div>
            <div class="jam-list-row-field"
                 [class.div-disabled]="!canEditJams$()">
              <ng-container *ngIf="showPivot$(); else showLineTeam1">
                <app-select title="Select Pivot"
                            [transparent]="true"
                            [selection]="blockers$()![periodIndex][jamIndex][0][0]"
                            [renderer]="skaterRenderer()"
                            (selectEvent)="withBlocker(periodIndex, jamIndex, 0, 0, $event)"
                            [values]="pivotOptions$()![0]"
                            [moreValues]="blockerOptions$()![0]"
                            [highlights]="skaterHighlights$()![0][1]"></app-select>
              </ng-container>
              <ng-template #showLineTeam1>
                <app-select title="Select Line"
                            [transparent]="true"
                            [selection]="team1Line$()![periodIndex][jamIndex]"
                            [renderer]="lineRenderer()"
                            (selectEvent)="withTeam1Line(periodIndex, jamIndex, $event)"
                            [values]="lineOptions$()![0]"
                            [highlights]="lineHighlight$()![0]"></app-select>
              </ng-template>
            </div>
            <div class="jam-list-row-field"
                 [class.div-disabled]="!canEditJams$()">
              <app-select title="Select Jammer"
                          [transparent]="true"
                          [selection]="team1Jammers$()![periodIndex][jamIndex]"
                          [renderer]="skaterRenderer()"
                          (selectEvent)="withTeam1Jammer(periodIndex, jamIndex, $event)"
                          [values]="jammerOptions$()![0]"
                          [moreValues]="blockerOptions$()![0]"
                          [highlights]="skaterHighlights$()![0][0]"></app-select>
            </div>
            <div class="jam-list-row-field"
                 [class.div-disabled]="!canEditJams$()">
              <app-select title="Select Penalty Count"
                          [includeEmpty]="false"
                          [transparent]="true"
                          [selection]="team1Penalties$()![periodIndex][jamIndex]"
                          (selectEvent)="withJammerPenaltyCount(periodIndex, jamIndex, 0, $event)"
                          [values]="penaltyCounts"></app-select>
            </div>
            <div
              [class.div-disabled]="!canEditJams$()">
              <app-checkbox [checked]="team1StarPasses$()![periodIndex][jamIndex]"
                            (checked)="withTeam1StarPass(periodIndex, jamIndex, $event)"></app-checkbox>
            </div>
            <div class="score-container"
                 [class.div-disabled]="!canEditJams$()">
              <button class="score-button" (click)="decreaseTeam1Score(periodIndex, jamIndex)">
                <app-icon name="remove"></app-icon>
              </button>
              <input type="text" class="score-field"
                     [value]="team1Scores$()![periodIndex][jamIndex]"
                     (input)="withTeam1Score(periodIndex, jamIndex, $event)"/>
              <button class="score-button" (click)="increaseTeam1Score(periodIndex, jamIndex)">
                <app-icon name="add"></app-icon>
              </button>
            </div>
            <div class="lead-container"
                 [class.div-disabled]="!canEditJams$()">
              <button class="lead-button"
                      [class.lead-button-selected]="leads$()![periodIndex][jamIndex] === 1"
                      (click)="withLead(periodIndex, jamIndex, 1)">
                <app-icon name="arrow_backward"></app-icon>
              </button>
              <button class="lead-button"
                      [class.lead-button-selected]="leads$()![periodIndex][jamIndex] === 0"
                      (click)="withLead(periodIndex, jamIndex, 0)">
                <app-icon name="close"></app-icon>
              </button>
              <button class="lead-button"
                      [class.lead-button-selected]="leads$()![periodIndex][jamIndex] === 2"
                      (click)="withLead(periodIndex, jamIndex, 2)">
                <app-icon name="arrow_forward"></app-icon>
              </button>
            </div>
            <div [class.div-disabled]="!canEditJams$()">
              <button class="score-button" (click)="decreaseTeam2Score(periodIndex, jamIndex)">
                <app-icon name="remove"></app-icon>
              </button>
              <input type="text" class="score-field"
                     [value]="team2Scores$()![periodIndex][jamIndex]"
                     (input)="withTeam2Score(periodIndex, jamIndex, $event)"/>
              <button class="score-button" (click)="increaseTeam2Score(periodIndex, jamIndex)">
                <app-icon name="add"></app-icon>
              </button>
            </div>
            <div [class.div-disabled]="!canEditJams$()">
              <app-checkbox [checked]="team2StarPasses$()![periodIndex][jamIndex]"
                            (checked)="withTeam2StarPass(periodIndex, jamIndex, $event)"></app-checkbox>
            </div>
            <div class="jam-list-row-field"
                 [class.div-disabled]="!canEditJams$()">
              <app-select title="Select Penalty Count"
                          [transparent]="true"
                          [includeEmpty]="false"
                          [selection]="team2Penalties$()![periodIndex][jamIndex]"
                          (selectEvent)="withJammerPenaltyCount(periodIndex, jamIndex, 1, $event)"
                          [values]="penaltyCounts"></app-select>
            </div>
            <div class="jam-list-row-field"
                 [class.div-disabled]="!canEditJams$()">
              <app-select title="Select Jammer"
                          [transparent]="true"
                          [selection]="team2Jammers$()![periodIndex][jamIndex]"
                          [renderer]="skaterRenderer()"
                          (selectEvent)="withTeam2Jammer(periodIndex, jamIndex, $event)"
                          [values]="jammerOptions$()![1]"
                          [moreValues]="blockerOptions$()![1]"
                          [highlights]="skaterHighlights$()![1][0]"></app-select>
            </div>
            <div class="jam-list-row-field"
                 [class.div-disabled]="!canEditJams$()">
              <ng-container *ngIf="showPivot$(); else showLineTeam2">
                <app-select title="Select Pivot"
                            [transparent]="true"
                            [selection]="blockers$()![periodIndex][jamIndex][1][0]"
                            [renderer]="skaterRenderer()"
                            (selectEvent)="withBlocker(periodIndex, jamIndex, 1, 0, $event)"
                            [values]="pivotOptions$()![1]"
                            [moreValues]="blockerOptions$()![1]"
                            [highlights]="skaterHighlights$()![1][1]"></app-select>
              </ng-container>
              <ng-template #showLineTeam2>
                <app-select title="Select Line"
                            [transparent]="true"
                            [selection]="team2Line$()![periodIndex][jamIndex]"
                            [renderer]="lineRenderer()"
                            (selectEvent)="withTeam2Line(periodIndex, jamIndex, $event)"
                            [values]="lineOptions$()![1]"
                            [highlights]="lineHighlight$()![1]"></app-select>
              </ng-template>
            </div>
            <div>
              <button class="button-more" (click)="withMore(periodIndex, jamIndex)">
                <app-icon
                  [name]="(state$ | async)!.isMoreVisible(periodIndex, jamIndex) ? 'arrow_down' : 'arrow_left'"></app-icon>
              </button>
            </div>
          </div>
          <ng-container *ngIf="(state$ | async)!.isMoreVisible(periodIndex, jamIndex)">
            <div class="jam-actions" (click)="$event.stopPropagation()"
                 [class.div-disabled]="!canEditJams$()">
              <div class="jam-actions-lineup">
                <div>
                  <div class="jam-actions-label">Jammer</div>
                  <app-select title="Select Jammer"
                              [transparent]="true"
                              [selection]="team1Jammers$()![periodIndex][jamIndex]"
                              [renderer]="skaterRenderer()"
                              (selectEvent)="withTeam1Jammer(periodIndex, jamIndex, $event)"
                              [values]="jammerOptions$()![0]"
                              [moreValues]="blockerOptions$()![0]"
                              [highlights]="skaterHighlights$()![0][0]"></app-select>
                </div>
                <div>
                  <div class="jam-actions-label">Blocker 1 (Pivot)</div>
                  <app-select title="Select Pivot"
                              [transparent]="true"
                              [selection]="blockers$()![periodIndex][jamIndex][0][0]"
                              [renderer]="skaterRenderer()"
                              (selectEvent)="withBlocker(periodIndex, jamIndex, 0, 0, $event)"
                              [values]="pivotOptions$()![0]"
                              [moreValues]="blockerOptions$()![0]"
                              [highlights]="skaterHighlights$()![0][1]"></app-select>
                </div>
                <div>
                  <div class="jam-actions-label">Blocker 2</div>
                  <app-select title="Select Blocker"
                              [transparent]="true"
                              [selection]="blockers$()![periodIndex][jamIndex][0][1]"
                              [renderer]="skaterRenderer()"
                              (selectEvent)="withBlocker(periodIndex, jamIndex, 0, 1, $event)"
                              [values]="blockerOptions$()![0]"
                              [highlights]="skaterHighlights$()![0][2]"></app-select>
                </div>
                <div>
                  <div class="jam-actions-label">Blocker 3</div>
                  <app-select title="Select Blocker"
                              [transparent]="true"
                              [selection]="blockers$()![periodIndex][jamIndex][0][2]"
                              [renderer]="skaterRenderer()"
                              (selectEvent)="withBlocker(periodIndex, jamIndex, 0, 2, $event)"
                              [values]="blockerOptions$()![0]"
                              [highlights]="skaterHighlights$()![0][3]"></app-select>
                </div>
                <div>
                  <div class="jam-actions-label">Blocker 4</div>
                  <app-select title="Select Blocker"
                              [transparent]="true"
                              [selection]="blockers$()![periodIndex][jamIndex][0][3]"
                              [renderer]="skaterRenderer()"
                              (selectEvent)="withBlocker(periodIndex, jamIndex, 0, 3, $event)"
                              [values]="blockerOptions$()![0]"
                              [highlights]="skaterHighlights$()![0][4]"></app-select>
                </div>
              </div>
              <div class="jam-actions-content">
                <app-track [editable]="(state$ | async)!.getHeatMapEditable(periodIndex, jamIndex, 0)"
                           [mainColor]="(state$ | async)!.getTeamColor(0)"
                           [trackWidth]="trackWidth$()"
                           [lapInfos]="(state$ | async)!.getLapInfos(periodIndex, jamIndex, 0)"
                           (clickEvent)="withLap(periodIndex, jamIndex, 0, $event)"
                           (undoEvent)="undoLap(periodIndex, jamIndex, 0)"
                ></app-track>
              </div>
              <div class="jam-actions-content">
                <app-track [editable]="(state$ | async)!.getHeatMapEditable(periodIndex, jamIndex, 1)"
                           [mainColor]="(state$ | async)!.getTeamColor(1)"
                           [trackWidth]="trackWidth$()"
                           [lapInfos]="(state$ | async)!.getLapInfos(periodIndex, jamIndex, 1)"
                           (clickEvent)="withLap(periodIndex, jamIndex, 1, $event)"
                           (undoEvent)="undoLap(periodIndex, jamIndex, 1)"
                ></app-track>
              </div>
              <div class="jam-actions-lineup">
                <div>
                  <div class="jam-actions-label-right">Jammer</div>
                  <app-select title="Select Jammer"
                              [transparent]="true"
                              [selection]="team2Jammers$()![periodIndex][jamIndex]"
                              [renderer]="skaterRenderer()"
                              (selectEvent)="withTeam2Jammer(periodIndex, jamIndex, $event)"
                              [values]="jammerOptions$()![1]"
                              [moreValues]="blockerOptions$()![1]"
                              [highlights]="skaterHighlights$()![1][0]"></app-select>
                </div>
                <div>
                  <div class="jam-actions-label-right">Blocker 1 (Pivot)</div>
                  <app-select title="Select Pivot"
                              [transparent]="true"
                              [selection]="blockers$()![periodIndex][jamIndex][1][0]"
                              [renderer]="skaterRenderer()"
                              (selectEvent)="withBlocker(periodIndex, jamIndex, 1, 0, $event)"
                              [values]="pivotOptions$()![1]"
                              [moreValues]="blockerOptions$()![1]"
                              [highlights]="skaterHighlights$()![1][1]"></app-select>
                </div>
                <div>
                  <div class="jam-actions-label-right">Blocker 2</div>
                  <app-select title="Select Blocker"
                              [transparent]="true"
                              [selection]="blockers$()![periodIndex][jamIndex][1][1]"
                              [renderer]="skaterRenderer()"
                              (selectEvent)="withBlocker(periodIndex, jamIndex, 1, 1, $event)"
                              [values]="blockerOptions$()![1]"
                              [highlights]="skaterHighlights$()![1][2]"></app-select>
                </div>
                <div>
                  <div class="jam-actions-label-right">Blocker 3</div>
                  <app-select title="Select Blocker"
                              [transparent]="true"
                              [selection]="blockers$()![periodIndex][jamIndex][1][2]"
                              [renderer]="skaterRenderer()"
                              (selectEvent)="withBlocker(periodIndex, jamIndex, 1, 2, $event)"
                              [values]="blockerOptions$()![1]"
                              [highlights]="skaterHighlights$()![1][3]"></app-select>
                </div>
                <div>
                  <div class="jam-actions-label-right">Blocker 4</div>
                  <app-select title="Select Blocker"
                              [transparent]="true"
                              [selection]="blockers$()![periodIndex][jamIndex][1][3]"
                              [renderer]="skaterRenderer()"
                              (selectEvent)="withBlocker(periodIndex, jamIndex, 1, 3, $event)"
                              [values]="blockerOptions$()![1]"
                              [highlights]="skaterHighlights$()![1][3]"></app-select>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <div class="jam-list-footer">
          <div class="jam-list-footer-delete">
            <button class="button-secondary" (click)="deleteLastJam(periodIndex)"
                    *ngIf="canEditJams$()">
              <app-icon name="delete"></app-icon>
              Last Jam
            </button>
          </div>
          <div class="jam-list-footer-score" *ngIf="periodIndex === 0">
            {{ periodScores$()![0][0] }}
          </div>
          <div class="jam-list-footer-score" *ngIf="periodIndex === 1">
            {{ totalScores$()![0] }}
            <div>({{ periodScores$()![0][1] }})</div>
          </div>
          <div class="jam-list-footer-actions">
            <button class="button-secondary" (click)="newJam(periodIndex)"
                    *ngIf="canEditJams$()">
              <app-icon name="add"></app-icon>
            </button>
          </div>
          <div class="jam-list-footer-score" *ngIf="periodIndex === 0">
            {{ periodScores$()![1][0] }}
          </div>
          <div class="jam-list-footer-score" *ngIf="periodIndex === 1">
            {{ totalScores$()![1] }}
            <div>({{ periodScores$()![1][1] }})</div>
          </div>
          <div class="jam-list-footer-finish">
            <button class="button-secondary"
                    [class.button-secondary]="!isFinished$()"
                    [class.button-primary]="isFinished$()"
                    [disabled]="!canEdit$()"
                    (click)="toggleGameFinished()" *ngIf="periodIndex === 1">
              {{ isFinished$() ? 'Game Finished' : 'Game Ongoing ...' }}
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
