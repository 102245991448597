export class SelectInfo {
  readonly color?: string;
  readonly text: string[];

  private constructor(color?: string, text: string[] = ['']) {
    this.color = color;
    this.text = text;
  }

  public static empty(): SelectInfo {
    return new SelectInfo();
  }

  public static of(texts: string[]): SelectInfo {
    return new SelectInfo(undefined, texts);
  }

  public withColor(color: string): SelectInfo {
    return new SelectInfo(color, this.text);
  }
}
