<div class="page">
  <div class="cards">
    <div class="card">
      <div class="card-header">
        <div class="subtitle">
          <app-icon name="school"></app-icon>
          All Classes
        </div>
        <div class="header-actions">
          <button class="button-icon-secondary">
            <app-icon name="add"></app-icon>
          </button>
        </div>
      </div>
      <div class="list">
        <div class="list-group">
          Skating
        </div>
        <div class="list-item-secondary">
          <div class="list-text-secondary">
            Start to Skate (UA)
          </div>
          <div class="list-actions">
            <button class="list-action-icon" disabled>
              <app-icon name="check"></app-icon>
            </button>
          </div>
        </div>
        <div class="list-item-secondary">
          <div class="list-text-secondary">
            Start to Derby
          </div>

          <div class="list-actions">
            <button class="list-action-icon" disabled>
              <app-icon name="check"></app-icon>
            </button>
          </div>
        </div>
        <div class="list-group">
          Contact
        </div>
        <div class="list-item-secondary">
          <div class="list-text-secondary">
            Start to Scrimmage
          </div>
          <div class="list-actions">
            <button class="list-action-icon" disabled>
              <app-icon name="check"></app-icon>
            </button>
          </div>
        </div>
        <div class="list-item-secondary">
          <div class="list-text-secondary">
            Contact Safe
          </div>
          <div class="list-actions">
            <button class="list-action-icon">
              <app-icon name="favorite_full"></app-icon>
            </button>
          </div>
        </div>
        <div class="list-item-secondary">
          <div class="list-text-secondary">
            Jammer Class
          </div>
          <div class="list-actions">
            <button class="list-action-icon">
              <app-icon name="favorite_full"></app-icon>
            </button>
          </div>
        </div>
        <div class="list-group">
          Officials
        </div>
        <div class="list-item-secondary">
          <div class="list-text-secondary">
            Officials Class
          </div>
          <div class="list-actions">
            <button class="list-action-icon">
              <app-icon name="favorite"></app-icon>
            </button>
          </div>
        </div>
        <div class="list-group">
          Bootcamps
        </div>
        <div class="list-item-secondary">
          <div class="list-text-secondary">
            The Big Boot 2024: Officials
          </div>
          <div class="list-actions">
            <button class="list-action-icon">
              <app-icon name="favorite"></app-icon>
            </button>
          </div>
        </div>
        <div class="list-item-secondary">
          <div class="list-text-secondary">
            The Big Boot 2024: Skaters
          </div>
          <div class="list-actions">
            <button class="list-action-icon">
              <app-icon name="favorite"></app-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
