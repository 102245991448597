import {Test} from "../../models/learning/domain/test";
import {Action} from "../../models/action";
import {TestQuestion} from "../../models/learning/domain/test-question";
import {DateTools} from "../../tools/date.tools";
import {TestQuestionAnswer} from "../../models/learning/domain/test-question-answer";

export interface TestQuestionsData {
  userId?: number;
  testId?: number;
  test?: Test;
  questions: TestQuestion[];
  actions: Action[];
  displayName: string;
  displayNameError: string;
  description: string;
  isMandatory: boolean;
  isPractice: boolean;
  isStudySession: boolean;
  deadline?: Date;
  dirty: boolean;
  loading: boolean;
}

export class TestQuestionsState {
  readonly data: TestQuestionsData;

  constructor(data: TestQuestionsData) {
    this.data = data;
  }

  public static create(): TestQuestionsState {
    return new TestQuestionsState({
      userId: undefined,
      testId: undefined,
      test: undefined,
      questions: [],
      actions: [],
      displayName: '',
      displayNameError: '',
      description: '',
      isMandatory: false,
      isPractice: false,
      isStudySession: false,
      deadline: undefined,
      dirty: false,
      loading: true,
    });
  }

  public get canDelete(): boolean {
    return this.data.actions.includes(Action.DELETE);
  }

  public get canEdit(): boolean {
    return this.data.actions.includes(Action.EDIT);
  }

  public get showQuestions(): boolean {
    return !!this.data.test && (this.data.isPractice || this.data.isStudySession);
  }

  public refresh(userId: number, test: Test | undefined, questions: TestQuestion[], actions: Action[]): TestQuestionsState {
    return new TestQuestionsState({
      ...this.data,
      userId: userId,
      test: test,
      questions: questions.sort((a, b) => a.question.localeCompare(b.question)),
      actions: actions,
      displayName: !test ? '' : test.displayName,
      description: !test ? '' : test.description,
      isMandatory: !test ? false : test.isMandatory,
      isPractice: !test ? false : test.isPractice,
      isStudySession: !test ? false : test.isStudySession,
      deadline: DateTools.fromUtcString(test?.deadline),
      dirty: false,
      loading: false,
    });
  }

  public withLoading(loading: boolean): TestQuestionsState {
    return new TestQuestionsState({
      ...this.data,
      loading: loading,
    });
  }

  public withQueryId(testId?: number): TestQuestionsState {
    return new TestQuestionsState({
      ...this.data,
      testId: testId,
    });
  }

  public toQuestionText(): string {
    return this.data.questions.map(question => {
      const questionText = question.question;
      const answers = question.answers;
      return `${questionText};${answers.map(answer => (answer.isCorrect ? 'correct:' : '') + answer.text).join(';')}`;
    }).join('\n');
  }

  public questionAnswers(index: number): TestQuestionAnswer[] {
    const question = this.data.questions[index];
    return question.answers || [];
  }
}
