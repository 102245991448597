import {League} from "../../models/league";
import {Team} from "../../models/team";
import {Skater} from "../../models/skater";
import {Action} from "../../models/action";


export interface SkaterData {
  leagueId?: number;
  league?: League;
  teamId?: number;
  team?: Team;
  skaterId?: number;
  skater?: Skater;
  actions: Action[];
  derbyName: string;
  derbyNameError: string;
  derbyNumber: string;
  derbyNumberError: string;
  isActive: boolean;
  isJammer: boolean;
  isCaptain: boolean;
  isPivot: boolean;
  dirty: boolean;
  valid: boolean;
  loading: boolean;
}

export class SkaterState {
  readonly data: SkaterData;

  constructor(data: SkaterData) {
    this.data = data;
  }

  public static create(): SkaterState {
    return new SkaterState({
      leagueId: undefined,
      league: undefined,
      teamId: undefined,
      team: undefined,
      skaterId: undefined,
      skater: undefined,
      actions: [],
      derbyName: '',
      derbyNameError: '',
      derbyNumber: '',
      derbyNumberError: '',
      isActive: false,
      isJammer: false,
      isCaptain: false,
      isPivot: false,
      dirty: false,
      valid: true,
      loading: true,
    });
  }

  public get canEdit(): boolean {
    return this.data.actions.includes(Action.EDIT);
  }

  public get canSave(): boolean {
    return this.data.dirty && this.data.valid;
  }

  public get canAddAnother(): boolean {
    return !this.data.skater && this.data.actions.includes(Action.EDIT);
  }

  public get canDelete(): boolean {
    return this.data.actions.includes(Action.DELETE);
  }

  public refresh(league: League | undefined, team: Team | undefined, skater: Skater | undefined, actions: Action[]): SkaterState {
    return new SkaterState({
      ...this.data,
      league: league,
      team: team,
      skater: skater,
      actions: actions,
      derbyName: skater?.derbyName || '',
      derbyNameError: '',
      derbyNumber: skater?.derbyNumber.toString() || '',
      derbyNumberError: '',
      isActive: !!skater ? skater.isActive : true,
      isJammer: !!skater ? skater.isJammer : false,
      isCaptain: !!skater ? skater.isCaptain : false,
      isPivot: !!skater ? skater.isPivot : false,
      dirty: false,
      loading: false,
    }).validate();
  }

  public withLoading(loading: boolean): SkaterState {
    return new SkaterState({
      ...this.data,
      loading: loading,
    });
  }

  public withQueryIds(leagueId: number | undefined, teamId: number | undefined, skaterId: number | undefined): SkaterState {
    return new SkaterState({
      ...this.data,
      leagueId: leagueId,
      teamId: teamId,
      skaterId: skaterId,
    });
  }

  public withDerbyName(derbyName: string): SkaterState {
    return new SkaterState({
      ...this.data,
      derbyName: derbyName,
      dirty: true,
    }).validate();
  }

  public withDerbyNumber(derbyNumber: string): SkaterState {
    return new SkaterState({
      ...this.data,
      derbyNumber: derbyNumber,
      dirty: true,
    }).validate();
  }

  public withIsActive(isActive: boolean): SkaterState {
    return new SkaterState({
      ...this.data,
      isActive: isActive,
      dirty: true,
    });
  }

  public withIsJammer(isJammer: boolean): SkaterState {
    return new SkaterState({
      ...this.data,
      isJammer: isJammer,
      dirty: true,
    });
  }

  public withIsCaptain(isCaptain: boolean): SkaterState {
    return new SkaterState({
      ...this.data,
      isCaptain: isCaptain,
      dirty: true,
    });
  }

  public withIsPivot(isPivot: boolean): SkaterState {
    return new SkaterState({
      ...this.data,
      isPivot: isPivot,
      dirty: true,
    });
  }

  private validate(): SkaterState {
    let derbyNameError = '';
    if (!this.data.derbyName) {
      derbyNameError = 'Derby name is required';
    } else if (this.data.derbyName.length < 3) {
      derbyNameError = 'Derby name must be at least 3 characters';
    }

    let derbyNumberError = '';
    if (!this.data.derbyNumber) {
      derbyNumberError = 'Derby number is required';
    } else if (isNaN(Number(this.data.derbyNumber))) {
      derbyNumberError = 'Derby number must only contain numbers';
    }

    return new SkaterState({
      ...this.data,
      derbyNameError: derbyNameError,
      derbyNumberError: derbyNumberError,
      valid: !derbyNameError && !derbyNumberError,
    });
  }
}
