import {Objects} from "../tools/objects";

export abstract class AbstractStorageService {

  private prefix = 'rollerderbypro';

  public set<A>(key: string, value: A | undefined): void {
    const fullKey = this.prefix + '.' + key;
    if (Objects.isNull(value)) {
      localStorage.removeItem(fullKey);
      return;
    }
    localStorage.setItem(fullKey, JSON.stringify(value));
  }

  public get<A>(key: string): A | undefined {
    const item = localStorage.getItem(this.prefix + '.' + key);
    return !item ? undefined : JSON.parse(item);
  }

  public remove(key: string): void {
    localStorage.removeItem(this.prefix + '.' + key);
  }
}
