<div class="page-with-actions">
  <app-loading *ngIf="(state$ | async)!.data.loading"></app-loading>
  <div class="cards" *ngIf="!(state$ | async)!.data.loading">
    <div class="card" *ngIf="(state$ | async)!.showQuestions">
      <div class="list">
        <ng-container *ngFor="let question of (state$ | async)!.data.questions; let i = index">
          <ng-container *ngIf="i === 0">
            <div class="list-candidates-header">
              <div>Question</div>
              <div>Answer</div>
            </div>
          </ng-container>

          <div class="list-candidates-item" (click)="goToQuestion(question.id)">
            <div>{{question.question}}</div>
            <div>
              <ng-container *ngFor="let answer of (state$ | async)!.questionAnswers(i)">
                <div [class.list-candidate-correct]="answer.isCorrect"
                     [class.list-candidate-incorrect]="!answer.isCorrect">
                  {{ answer.text }}
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <div class="list-item-secondary" *ngIf="(state$ | async)!.data.questions.length === 0">
          <div class="list-text-message">
            No questions found
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
