import {Component, OnInit} from "@angular/core";
import {ProfileState} from "./profile.state";
import {UserService} from "../../services/user.service";
import {NotificationService} from "../../services/notification.service";
import {Message} from "../../models/message";
import {UserPayload} from "../../models/user-payload";
import {User} from "../../models/user";
import {StateSubject} from "../../tools/state.subject";
import {PageService} from "../../services/page.service";
import {Router} from "@angular/router";
import {RouterTools} from "../../tools/router.tools";
import {SessionService} from "../../services/session.service";


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  state$: StateSubject<ProfileState> = new StateSubject<ProfileState>(ProfileState.create());

  constructor(private userService: UserService,
              private notificationService: NotificationService,
              private pageService: PageService,
              private sessionService: SessionService,
              private router: Router) {
    this.state$.subscribe(state => {
      this.pageService.invoke(page => page
        .reset(page.data.session?.displayName || 'Profile')
        .withBackButton(state.data.isTemporaryPassword ? undefined : () => RouterTools.goHome(this.router))
        .withActions([
          {
            text: 'Save',
            icon: 'save',
            visible: true,
            enabled: state.canSave,
            active: true,
            primary: true,
            onClick: () => this.save()
          },
          {
            text: 'Logout',
            icon: 'logout',
            visible: true,
            enabled: !state.data.dirty,
            active: false,
            primary: false,
            onClick: () => this.logout()
          }
        ]));
    });
  }

  ngOnInit(): void {
    this.refresh();
  }

  public refresh(): void {
    this.userService.getCurrentUser().subscribe({
      next: user => this.withRefresh(user),
      error: () => {
        this.notificationService.show(Message.error('Failed to load user profile'));
        this.state$.invoke(state => state.withLoading(false));
      }
    });
  }

  private logout(): void {
    this.sessionService.logout();
  }

  public save(): void {
    const state = this.state$.getValue();
    const temporaryPasswordChanged = state.data.isTemporaryPassword && !!state.data.newPassword;
    const user: UserPayload = {
      username: state.data.username,
      displayName: state.data.displayName,
      pronouns: state.data.pronouns,
      oldPassword: state.data.oldPassword,
      newPassword: state.data.newPassword
    };
    this.userService.postCurrentUser(user).subscribe({
      next: user => {
        if (temporaryPasswordChanged) {
          setTimeout(() => RouterTools.goHome(this.router), 500);
          this.notificationService.show(Message.success('Password updated'));
        } else {
          this.withRefresh(user);
          this.notificationService.show(Message.success('User profile saved'));
        }
      },
      error: () => {
        this.notificationService.show(Message.error('Failed to save user profile'));
      }
    });
  }

  public withUsername(event: any): void {
    this.state$.invoke(state => state.withUsername(event.target.value));
  }

  public withDisplayName(event: any): void {
    this.state$.invoke(state => state.withDisplayName(event.target.value));
  }

  public withPronouns(event: any): void {
    this.state$.invoke(state => state.withPronouns(event.target.value));
  }

  public withOldPassword(event: any): void {
    this.state$.invoke(state => state.withOldPassword(event.target.value));
  }

  public withNewPassword(event: any): void {
    this.state$.invoke(state => state.withNewPassword(event.target.value));
  }

  public withNewPassword2(event: any): void {
    this.state$.invoke(state => state.withNewPassword2(event.target.value));
  }

  public withRefresh(user: User): void {
    this.state$.invoke(state => state.refresh(user));
  }
}
