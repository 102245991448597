import {Component, Input} from "@angular/core";
import {Tabs} from "../../models/tabs";
import {Tab} from "../../models/tab";

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent {

  @Input() tabs: Tabs = Tabs.of([]);


  constructor() {
  }

  public goBack(): void {
    if (this.tabs.goBack) {
      this.tabs.goBack();
    }
  }

  onClick(tab: Tab) {
    if (tab.enabled && tab.onClick) {
      tab.onClick();
    }
  }
}
