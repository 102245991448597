import {Component, OnInit} from "@angular/core";
import {TeamLinesState} from "./team-lines.state";
import {LeagueService} from "../../services/league.service";
import {TeamService} from "../../services/team.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Message} from "../../models/message";
import {NotificationService} from "../../services/notification.service";
import {RouterTools} from "../../tools/router.tools";
import {StateSubject} from "../../tools/state.subject";
import {forkJoin, of} from "rxjs";
import {TeamLineService} from "../../services/team-line.service";
import {TeamLine} from "../../models/team-line";
import {PageService} from "../../services/page.service";
import {Tabs} from "../../models/tabs";
import {Tab} from "../../models/tab";

@Component({
  selector: 'app-team-lines',
  templateUrl: './team-lines.component.html',
  styleUrls: ['./team-lines.component.scss']
})
export class TeamLinesComponent implements OnInit {

  state$: StateSubject<TeamLinesState> = new StateSubject<TeamLinesState>(TeamLinesState.create());

  constructor(private leagueService: LeagueService,
              private teamService: TeamService,
              private teamLineService: TeamLineService,
              private route: ActivatedRoute,
              private pageService: PageService,
              private router: Router,
              private notificationService: NotificationService) {
    this.state$.subscribe(state => {
      this.pageService.invoke(page => page.reset(state.data.team?.displayName || 'New Team')
        .withBackButton(() => RouterTools.goToLeague(this.router, state.data.leagueId!))
        .withTabs(Tabs.of([
          Tab.of("Info", true, () => RouterTools.goToTeam(this.router, state.data.leagueId!, state.data.teamId!)),
          Tab.of("Lines", true),
          Tab.of("Skaters", true, () => RouterTools.goToSkaters(this.router, state.data.leagueId!, state.data.teamId!)),
        ], 1))
        .withActions([
          {
            text: 'New Line',
            icon: 'add',
            visible: state.canEdit,
            enabled: true,
            active: true,
            primary: true,
            onClick: () => this.newLine()
          }
        ])
        .withSearch((query?: string) => this.search(query))
      );
    })
  }

  ngOnInit(): void {
    RouterTools.observeParamMap(this.route, params => {
      const leagueId = RouterTools.toNumber(params, 'leagueId');
      const teamId = RouterTools.toNumber(params, 'teamId');
      this.state$.invoke(state => state.withQueryIds(leagueId, teamId));
    });
    this.state$.get(state => state.data.leagueId + ' ' + state.data.teamId).subscribe(() => {
      this.refresh();
    });
  }

  public refresh(): void {
    const state = this.state$.getValue();
    const leagueId = state.data.leagueId;
    const teamId = state.data.teamId;

    forkJoin({
      league: !!leagueId ? this.leagueService.getLeague(leagueId) : of(undefined),
      team: !!teamId ? this.teamService.getTeam(teamId) : of(undefined),
      lines: !!teamId ? this.teamLineService.getTeamLinesByTeamId(teamId) : of([]),
      actions: this.teamService.getActions(teamId),
    }).subscribe({
      next: ({league, team, lines, actions}) => {
        this.state$.invoke(state => state.refresh(league, team, lines, actions));
      },
      error: () => {
        this.notificationService.show(Message.error('Failed to load team'));
        this.state$.invoke(state => state.withLoading(false));
      }
    });
  }

  public withShowInactiveLines(showInactiveLines: boolean): void {
    this.state$.invoke(state => state.withShowInactiveLines(showInactiveLines));
  }


  public newLine(): void {
    RouterTools.goToTeamLineNew(this.router, this.state$.getValue().data.leagueId!, this.state$.getValue().data.teamId!);
  }

  public goToTeamLine(teamLine: TeamLine) {
    const leagueId = this.state$.getValue().data.leagueId!;
    const teamId = this.state$.getValue().data.teamId!;
    RouterTools.goToTeamLine(this.router, leagueId, teamId, teamLine.id);
  }

  public search(value: string | undefined): void {
    this.state$.invoke(state => state.withSearch(value));
  }
}
