import {Injectable} from "@angular/core";
import {PageState} from "../models/page.state";
import {Router} from "@angular/router";
import {StateSubject} from "../tools/state.subject";

@Injectable({
  providedIn: 'root'
})
export class PageService {

  private _page$: StateSubject<PageState>;

  constructor(router: Router) {
    this._page$ = new StateSubject<PageState>(PageState.create(router));
  }

  public get page$(): StateSubject<PageState> {
    return this._page$;
  }

  public get page(): PageState {
    return this._page$.getValue();
  }

  public invoke(reducer: (page: PageState) => PageState): void {
    this._page$.invoke(reducer);
  }
}
