import {Component, OnInit} from "@angular/core";
import {LeagueService} from "../../services/league.service";
import {NotificationService} from "../../services/notification.service";
import {Message} from "../../models/message";
import {LeagueState} from "./league.state";
import {ActivatedRoute, Router} from "@angular/router";
import {TeamService} from "../../services/team.service";
import {RouterTools} from "../../tools/router.tools";
import {StateSubject} from "../../tools/state.subject";
import {forkJoin, of} from "rxjs";
import {DialogService} from "../../services/dialog.service";
import {DialogOptions} from "../../models/dialog-options";
import {PageService} from "../../services/page.service";

@Component({
  selector: 'app-league',
  templateUrl: './league.component.html',
  styleUrls: ['./league.component.scss']
})
export class LeagueComponent implements OnInit {

  state$: StateSubject<LeagueState> = new StateSubject<LeagueState>(LeagueState.create());

  constructor(private leagueService: LeagueService,
              private teamService: TeamService,
              private notificationService: NotificationService,
              private dialogService: DialogService,
              private route: ActivatedRoute,
              private pageService: PageService,
              private router: Router) {
    this.state$.subscribe(state => {
      this.pageService.invoke(page => page.reset(state.data.league?.displayName || 'New League')
        .withBackButton(() => RouterTools.goToLeagues(this.router))
        .withActions([
          {
            text: 'Save',
            icon: 'save',
            visible: state.canEdit,
            enabled: state.canSave,
            active: true,
            primary: true,
            onClick: () => this.save()
          },
          {
            text: 'Delete',
            icon: 'delete',
            visible: state.canDelete,
            enabled: !!state.data.league?.id,
            active: false,
            primary: false,
            onClick: () => this.delete()
          },
          {
            text: 'New Team',
            icon: 'add',
            visible: state.canEdit,
            enabled: !state.data.dirty,
            active: false,
            primary: false,
            onClick: () => this.newTeam()
          }
        ]))
    });
  }

  ngOnInit(): void {
    RouterTools.observeParamMap(this.route, params => {
      const leagueId = RouterTools.toNumber(params, 'leagueId');
      this.state$.invoke(state => state.withQueryId(leagueId));
    });
    this.state$.get(state => '' + state.data.leagueId).subscribe(() => {
      this.refresh();
    });
  }

  public refresh(): void {
    const state = this.state$.getValue();
    const leagueId = state.data.leagueId;

    forkJoin([
      leagueId ? this.leagueService.getLeague(leagueId) : of(undefined),
      leagueId ? this.teamService.getTeamsByLeagueId(leagueId) : of([]),
      this.leagueService.getActions(leagueId),
    ]).subscribe({
      next: ([league, teams, actions]) => {
        this.state$.invoke(state => state.refresh(league, teams, actions));
      },
      error: () => {
        this.notificationService.show(Message.error(`Failed to load league`));
        this.state$.invoke(state => state.withLoading(false));
      }
    });
  }

  public save(): void {
    const state = this.state$.getValue();
    if (!state.data.league) {
      const newLeague = {
        displayName: state.data.displayName,
        home: false,
      };
      this.leagueService.createLeague(newLeague).subscribe({
        next: league => {
          RouterTools.goToLeague(this.router, league.id);
        },
        error: () => {
          this.notificationService.show(Message.error('Failed to create league'));
        }
      });
      return;
    }

    const updatedLeague = {
      id: state.data.league.id,
      displayName: state.data.displayName,
      home: state.data.home,
    };
    this.leagueService.updateLeague(state.data.league.id, updatedLeague).subscribe({
      next: () => this.refresh(),
      error: () => {
        this.notificationService.show(Message.error('Failed to update league'));
      }
    });
  }

  public delete() {
    this.dialogService.show(DialogOptions
      .create('Delete League', 'Are you sure you want to delete this league?')
      .withConfirm(() => this.doDelete()));
  }

  private doDelete(): void {
    const state = this.state$.getValue();
    if (!state.data.league) {
      return;
    }
    this.leagueService.deleteLeague(state.data.league.id).subscribe({
      next: () => {
        this.notificationService.show(Message.success('League deleted'));
        RouterTools.goToLeagues(this.router);
      },
      error: () => {
        this.notificationService.show(Message.error('Failed to delete league'));
      }
    });
  }

  public goToTeam(teamId: number): void {
    const state = this.state$.getValue();
    if (!state.data.league || !state.data.leagueId) {
      return;
    }
    RouterTools.goToTeam(this.router, state.data.leagueId, teamId);
  }

  public newTeam(): void {
    const state = this.state$.getValue();
    if (!state.data.league || !state.data.leagueId) {
      return;
    }
    RouterTools.goToTeamNew(this.router, state.data.leagueId);
  }

  public withDisplayName(event: any): void {
    this.state$.invoke(state => state.withDisplayName(event.target.value));
  }
}
