import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {Message} from "../models/message";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private _notifications$: BehaviorSubject<Message | undefined> = new BehaviorSubject<Message | undefined>(undefined);

  constructor() {
  }

  public get notifications$(): BehaviorSubject<Message | undefined> {
    return this._notifications$;
  }

  public hide(): void {
    this._notifications$.next(undefined);
  }

  public show(notification: Message): void {
    this._notifications$.next(notification);
  }
}
