import {League} from "../../models/league";
import {Team} from "../../models/team";
import {SearchTools} from "../../tools/search.tools";
import {GameInfo} from "../games/games.state";

export interface TeamInfo {
  team: Team;
  league?: League;
}

export interface TeamsData {
  leagues: League[];
  teams: Team[];
  teamInfos: TeamInfo[];
  filteredTeamInfos: TeamInfo[];
  search?: string;
  loading: boolean;
}

export class TeamsState {
  readonly data: TeamsData;

  constructor(data: TeamsData) {
    this.data = data;
  }

  public static create(): TeamsState {
    return new TeamsState({
      leagues: [],
      teams: [],
      teamInfos: [],
      filteredTeamInfos: [],
      loading: true,
    });
  }

  public refresh(leagues: League[], teams: Team[]): TeamsState {
    return new TeamsState({
      ...this.data,
      leagues: leagues,
      teams: teams,
      teamInfos: teams
        .map(team => {
          return {
            team: team,
            league: leagues.find(league => league.id === team.leagueId),
          };
        })
        .sort((a, b) => (a.league?.displayName + a.team.displayName).localeCompare(b.league?.displayName + b.team.displayName)),
      loading: false,
    }).filterTeams();
  }

  public withLoading(value: boolean): TeamsState {
    return new TeamsState({
      ...this.data,
      loading: value,
    });
  }

  public withSearch(value: string | undefined) {
    return new TeamsState({
      ...this.data,
      search: value,
    }).filterTeams();
  }

  private filterTeams(): TeamsState {
    const textF = (teamInfo: TeamInfo) => '' + teamInfo.league?.displayName + teamInfo.team.displayName;
    return new TeamsState({
      ...this.data,
      filteredTeamInfos: SearchTools.filter(this.data.teamInfos, textF, this.data.search),
    });
  }
}
