import {Penalty} from "./penalty";
import {LapInfo} from "./lapInfo";

export class JamExtraData {
  readonly penalties: Penalty[];
  readonly laps: LapInfo[];

  private constructor(penalties: Penalty[], laps: LapInfo[]) {
    this.penalties = penalties;
    this.laps = laps;
  }

  public static fromJson(json: string | undefined): JamExtraData {
    if (!json) {
      return JamExtraData.empty();
    }

    const candidate = JSON.parse(json);
    return new JamExtraData(candidate.penalties, candidate.laps);
  }

  public toJson(): string | undefined {
    // Todo: fix update nulls in backend
    /*if (!!this && this.penalties.length === 0 && this.laps.length === 0) {
      return undefined;
    }*/
    return JSON.stringify(this);
  }

  public static empty(): JamExtraData {
    return new JamExtraData([], []);
  }

  public withPenaltyCount(skaterId: number, count: number): JamExtraData {
    const penaltiesForSkater = this.penalties.filter(penalty => penalty.skaterId === skaterId);
    const otherPenalties = this.penalties.filter(penalty => penalty.skaterId !== skaterId);

    const result = [...otherPenalties];
    for (let i = 0; i < count; i++) {
      let penalty: Penalty;
      if (penaltiesForSkater.length > i) {
        penalty = penaltiesForSkater[i];
      } else {
        penalty = {
          skaterId: skaterId,
          code: '?'
        };
      }
      result.push(penalty);
    }
    return new JamExtraData(result, this.laps);
  }

  public withLap(lap: LapInfo): JamExtraData {
    return new JamExtraData(this.penalties, [...this.laps, lap]);
  }

  public deleteLastLap(skaterId: number): JamExtraData {
    const result = [];
    let found = false;
    for (let i = this.laps.length - 1; i >= 0; i--) {
      const lap = this.laps[i];
      if (!found && lap.skaterId === skaterId) {
        found = true;
        continue;
      }
      result.push(lap);
    }
    return new JamExtraData(this.penalties, result.reverse());
  }
}
