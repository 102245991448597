<div class="page">

  <app-loading *ngIf="(state$ | async)!.data.loading"></app-loading>
  <div class="cards" *ngIf="!(state$ | async)!.data.loading">

    <div class="card" *ngIf="(state$ | async)!.showRealTests">
      <div class="card-header">
        <div class="subtitle">
          <app-icon name="edit"></app-icon>
          Fill In
        </div>
      </div>
      <ng-container *ngFor="let testGroup of (state$ | async)!.data.realTests; let groupIndex = index">
        <div class="list" *ngIf="testGroup.length > 0">
          <ng-container *ngFor="let test of testGroup; let testIndex = index">
            <div class="list-group" *ngIf="testIndex === 0">
              {{ groupIndex === 0 ? 'Mandatory' : 'Optional' }}
            </div>
            <ng-container *ngTemplateOutlet="testTemplate; context: { $implicit: test }"></ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <div class="card" *ngIf="(state$ | async)!.showPracticeTests">
      <div class="card-header">
        <div class="subtitle">
          <app-icon name="cards"></app-icon>
          Study & Practice
        </div>
      </div>
      <ng-container *ngFor="let testGroup of (state$ | async)!.data.practiceTests">
        <div class="list" *ngIf="testGroup.length > 0">
          <ng-container *ngFor="let test of testGroup; let testIndex = index">
            <div class="list-group" *ngIf="testIndex === 0">
              {{ test.category.displayName }}
            </div>
            <ng-container *ngTemplateOutlet="testTemplate; context: { $implicit: test }"></ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <div class="card" *ngIf="(state$ | async)!.showFilteredTests">
      <div class="list">
        <ng-container *ngFor="let test of (state$ | async)!.data.filteredTests">
          <ng-container *ngTemplateOutlet="testTemplate; context: { $implicit: test }"></ng-container>
        </ng-container>
      </div>
    </div>

    <ng-container *ngIf="(state$ | async)!.showEmptyMessage">
      <div class="card">
        <div class="list">
          <div class="list-item-secondary">
            <div class="list-text-message">
              No tests found
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #testTemplate let-test>
  <div class="list-item-secondary" (click)="goToTest(test.id)">
    <div class="list-text-secondary">
      <div>{{ test.displayName }}</div>
      <div *ngIf="test.description">{{ test.description }}</div>
    </div>
    <div class="list-icons" *ngIf="!test.isPublic">
      <div class="list-icon-secondary">
        <app-icon name="invisible"></app-icon>
      </div>
    </div>
    <div class="difficulty" *ngIf="test.difficulty">
      <app-icon name="star_full" *ngFor="let _ of test.difficulty.icons"></app-icon>
    </div>
  </div>
</ng-template>
