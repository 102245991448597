<div class="page">
  <div class="login-card">
    <div class="login-left">
      <div class="login-left-logo">
        <app-icon name="logo"></app-icon>
      </div>
    </div>
    <div class="login-right">
      <div class="login-right-form">
        <div class="login-right-title">
          Log In
        </div>
        <div class="form">
          <div class="field">
            <label class="field-label" for="username">Username</label>
            <input type="text" class="field-text" id="username" #username
                   [value]="(state$ | async)?.username" (keyup.enter)="login()"
                   (input)="withUsername($event)"/>
          </div>
          <div class="field">
            <label class="field-label" for="password">Password</label>
            <input type="password" class="field-text" id="password"
                   [value]="(state$ | async)?.password" (keyup.enter)="login()"
                   (input)="withPassword($event)"/>
          </div>
          <div class="buttons">
            <button class="button-primary" (click)="login()">Next</button>
          </div>
          <div class="login-right-slogan">
            By Antwerp Roller Derby
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
