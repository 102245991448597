import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {forkJoin, Observable} from "rxjs";
import {UserSession} from "../../models/user-session";
import {SessionService} from "../../services/session.service";
import {Scope} from "../../models/scope";
import {Message} from "../../models/message";
import {TestService} from "../../services/test.service";
import {GameService} from "../../services/game.service";
import {StateSubject} from "../../tools/state.subject";
import {HomeState} from "./home.state";
import {NotificationService} from "../../services/notification.service";
import {RouterTools} from "../../tools/router.tools";
import {TeamService} from "../../services/team.service";
import {PageService} from "../../services/page.service";
import {AbstractComponent} from "../abstract.component";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends AbstractComponent implements OnInit {

  state$: StateSubject<HomeState> = new StateSubject<HomeState>(HomeState.create());

  session$: Observable<UserSession | undefined>;
  homeRead$: Observable<boolean>;
  testsRead$: Observable<boolean>;
  liveRead$: Observable<boolean>;

  constructor(private router: Router,
              sessionService: SessionService,
              private testService: TestService,
              private gameService: GameService,
              private teamService: TeamService,
              private notificationService: NotificationService,
              private pageService: PageService) {
    super();
    this.session$ = sessionService.session$;
    this.homeRead$ = sessionService.hasScope(Scope.HOME_READ);
    this.testsRead$ = sessionService.hasScope(Scope.TESTS_READ);
    this.liveRead$ = sessionService.hasScope(Scope.LIVE_READ);
    this.subscriptions.add(this.session$.subscribe(session => this.state$.invoke(state => state.withSession(session))));
    this.state$.subscribe(state => {
      this.pageService.invoke(page => page.reset(state.data.session ? 'Hi, ' + state.data.session.displayName + '!' : '').withShowMenu(true));
    });
  }

  ngOnInit(): void {
    this.refresh();
  }

  public goToTests(): void {
    RouterTools.goToTests(this.router);
  }

  public goToTest(testId: number): void {
    RouterTools.goToTest(this.router, testId);
  }

  public goToLive(): void {
    const state = this.state$.getValue();
    RouterTools.goToGameStats(this.router, state.data.liveGame!.id);
  }

  public goToGame(gameId: number): void {
    RouterTools.goToGame(this.router, gameId);
  }

  public goToGames(): void {
    RouterTools.goToGames(this.router);
  }

  public refresh(): void {
    forkJoin([
      this.testService.all(),
      this.gameService.all(),
      this.teamService.getTeams(),
    ]).subscribe({
      next: ([tests, games, teams]) => {
        this.state$.invoke(state => state.refresh(tests, games, teams));
      },
      error: () => {
        this.notificationService.show(Message.error(`Failed to load home page`));
        this.state$.invoke(state => state.withLoading(false));
      }
    });
  }
}
