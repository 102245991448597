export class ScreenSize {

  public static readonly XXL_MAX = new ScreenSize("XXL", 1400);
  public static readonly XL_1400 = new ScreenSize("XL", 1200);
  public static readonly L_1200 = new ScreenSize("L", 876);
  public static readonly M_876 = new ScreenSize("M", 768);
  public static readonly S_768 = new ScreenSize("S", 0);

  public readonly name: string;
  public readonly width: number;

  private constructor(name: string, width: number) {
    this.name = name;
    this.width = width;
  }

  public isAtLeast(size: ScreenSize): boolean {
    return this.width >= size.width;
  }

  public isAtMost(size: ScreenSize): boolean {
    return this.width <= size.width;
  }
}
