import {Component, OnInit} from "@angular/core";
import {GameState} from "./game.state";
import {GameService} from "../../services/game.service";
import {ActivatedRoute, Router} from "@angular/router";
import {StateSubject} from "../../tools/state.subject";
import {forkJoin, of} from "rxjs";
import {LeagueService} from "../../services/league.service";
import {SkaterService} from "../../services/skater.service";
import {TeamService} from "../../services/team.service";
import {NotificationService} from "../../services/notification.service";
import {Message} from "../../models/message";
import {RouterTools} from "../../tools/router.tools";
import {JamService} from "../../services/jam.service";
import {League} from "../../models/league";
import {Team} from "../../models/team";
import {SelectInfo} from "../../models/select-info";
import {Color} from "../../models/color";
import {DialogService} from "../../services/dialog.service";
import {DialogOptions} from "../../models/dialog-options";
import {Tabs} from "../../models/tabs";
import {Tab} from "../../models/tab";
import {Objects} from "../../tools/objects";
import {PageService} from "../../services/page.service";
import {Game} from "../../models/game";

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss']
})
export class GameComponent implements OnInit {

  state$: StateSubject<GameState> = new StateSubject<GameState>(GameState.create());

  constructor(private leagueService: LeagueService,
              private teamService: TeamService,
              private skaterService: SkaterService,
              private gameService: GameService,
              private jamService: JamService,
              private notificationService: NotificationService,
              private dialogService: DialogService,
              private route: ActivatedRoute,
              private pageService: PageService,
              private router: Router) {
    this.state$.subscribe(state => {
      this.pageService.invoke(page => page.reset(state.gameTitle)
        .withBackButton(() => this.goToGames())
        .withActions([{
          text: 'Save',
          icon: 'save',
          visible: state.canEdit,
          enabled: state.canSave,
          primary: true,
          active: true,
          onClick: () => this.save()
        }, {
          text: 'Delete',
          icon: 'delete',
          visible: state.canDelete,
          enabled: !!state.data.gameId,
          primary: false,
          active: false,
          onClick: () => this.delete()
        }])
        .withTabs(Tabs.of([
          Tab.of('Info'),
          Tab.of('Jams', Objects.isNotNull(state.data.gameId), () => this.goToGameJams()),
          Tab.of('Stats', Objects.isNotNull(state.data.gameId), () => this.goToGameStats()),
        ], 0)))
    });
  }

  ngOnInit(): void {
    RouterTools.observeParamMap(this.route, params => {
      const id = RouterTools.toNumber(params, 'gameId');
      this.state$.invoke(state => state.withQueryId(id));
    });
    this.state$.get(state => '' + state.data.gameId).subscribe(() => {
      this.refresh();
    });
  }

  public refresh(): void {
    const state = this.state$.getValue();
    const gameId = state.data.gameId;

    forkJoin([
      this.leagueService.getLeagues(),
      this.teamService.getTeams(),
      this.skaterService.getSkaters(),
      gameId ? this.gameService.oneById(gameId) : of(undefined),
      gameId ? this.jamService.getJamByGameId(gameId) : of([]),
      this.gameService.getActions(gameId),
    ]).subscribe({
      next: ([leagues, teams, skaters, game, jams, actions]) => {
        this.state$.invoke(state => state.refresh(leagues, teams, skaters, game, jams, actions));
      },
      error: () => {
        this.notificationService.show(Message.error(`Failed to load game`));
        this.state$.invoke(state => state.withLoading(false));
      }
    });
  }

  public save(): void {
    const state = this.state$.getValue();
    const gameId = state.data.gameId;
    let game = {
      startDate: state.data.startDate,
      location: !state.data.location ? undefined : state.data.location,
      teamId1: state.data.team1?.id,
      teamId2: state.data.team2?.id,
      teamColor1: state.data.color1?.code,
      teamColor2: state.data.color2?.code,
      isLive: state.data.isLive,
      isFinished: state.data.isFinished,
      liveStreamUrl: state.data.liveStreamUrl,
    } as Partial<Game>;
    if (!gameId) {
      this.gameService.create(game).subscribe({
        next: game => {
          RouterTools.goToGame(this.router, game.id);
        },
        error: () => {
          this.notificationService.show(Message.error(`Failed to create game`));
        }
      });
      return;
    }

    // Update
    game = {
      id: gameId,
      ...game,
    };
    this.gameService.update(gameId, game).subscribe({
      next: () => this.refresh(),
      error: () => {
        this.notificationService.show(Message.error(`Failed to update game`));
      }
    });
  }

  public delete(): void {
    this.dialogService.show(DialogOptions
      .create('Delete Game', 'Are you sure you want to delete this game?')
      .withConfirm(() => this.doDelete()));
  }

  private doDelete(): void {
    const state = this.state$.getValue();
    const gameId = state.data.gameId;
    if (!gameId) {
      return;
    }

    this.gameService.delete(gameId).subscribe({
      next: () => {
        RouterTools.goToGames(this.router);
        this.notificationService.show(Message.success('Game deleted'));
      },
      error: () => {
        this.notificationService.show(Message.error(`Failed to delete game`));
      }
    });
  }

  public withStartDate(startDate?: Date): void {
    this.state$.invoke(state => state.withStartDate(startDate));
  }

  public withLocation(event: any): void {
    this.state$.invoke(state => state.withLocation(event.target.value));
  }

  public withLeague1(league?: League): void {
    this.state$.invoke(state => state.withLeague1(league));
  }

  public withLeague2(league?: League): void {
    this.state$.invoke(state => state.withLeague2(league));
  }

  public withTeam1(team?: Team): void {
    this.state$.invoke(state => state.withTeam1(team));
  }

  public withTeam2(team?: Team): void {
    this.state$.invoke(state => state.withTeam2(team));
  }

  public withColor1(color?: Color): void {
    this.state$.invoke(state => state.withColor1(color));
  }

  public withColor2(color?: Color): void {
    this.state$.invoke(state => state.withColor2(color));
  }

  public withIsLive(isLive: boolean): void {
    this.state$.invoke(state => state.withIsLive(isLive));
  }

  public withIsFinished(isFinished: boolean): void {
    this.state$.invoke(state => state.withIsFinished(isFinished));
  }

  public withLiveStreamUrl(event: any): void {
    this.state$.invoke(state => state.withLiveStreamUrl(event.target.value));
  }

  public goToGameJams(): void {
    const state = this.state$.getValue();
    RouterTools.goToGameJams(this.router, state.data.gameId!);
  }

  public goToGameStats(): void {
    const state = this.state$.getValue();
    RouterTools.goToGameStats(this.router, state.data.gameId!);
  }

  public goToGames(): void {
    RouterTools.goToGames(this.router);
  }

  /**
   * Opens the live stream in a new tab.
   */
  public goToLiveStream(): void {
    const state = this.state$.getValue();
    const url = state.data.liveStreamUrl;
    if (url) {
      window.open(url, '_blank');
    }
  }

  public leagueRenderer(): (value?: League) => SelectInfo {
    return value => {
      if (!value) {
        return SelectInfo.empty();
      }
      return SelectInfo.of([value.displayName]);
    };
  }

  public teamRenderer(): (value?: Team) => SelectInfo {
    return value => {
      if (!value) {
        return SelectInfo.empty();
      }
      return SelectInfo.of([value.displayName]);
    };
  }

  public colorRenderer(): (value?: Color) => SelectInfo {
    return value => {
      if (!value) {
        return SelectInfo.empty();
      }
      return SelectInfo.of([value.name]).withColor(value.hex);
    };
  }
}
