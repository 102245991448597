import {Component, OnInit} from "@angular/core";
import {StateSubject} from "../../tools/state.subject";
import {TestService} from "../../services/test.service";
import {NotificationService} from "../../services/notification.service";
import {SessionService} from "../../services/session.service";
import {Router} from "@angular/router";
import {Scope} from "../../models/scope";
import {Message} from "../../models/message";
import {RouterTools} from "../../tools/router.tools";
import {TestsState} from "./tests.state";
import {PageService} from "../../services/page.service";
import {AbstractComponent} from "../abstract.component";

@Component({
  selector: 'app-tests',
  templateUrl: './tests.component.html',
  styleUrls: ['./tests.component.scss']
})
export class TestsComponent extends AbstractComponent implements OnInit {

  state$: StateSubject<TestsState> = new StateSubject<TestsState>(TestsState.create());

  constructor(private testService: TestService,
              private notificationService: NotificationService,
              private sessionService: SessionService,
              private pageService: PageService,
              private router: Router) {
    super();
    this.sessionService.hasScope(Scope.TESTS_WRITE).subscribe(canWrite => this.state$.invoke(state => state.withCanWrite(canWrite)));
    this.sessionService.hasScope(Scope.TESTS_MANAGE).subscribe(canWrite => this.state$.invoke(state => state.withCanManage(canWrite)));
    this.state$.subscribe(state => {
      this.pageService.invoke(page => page.reset('Study Corner')
        .withShowMenu(true)
        .withBackButton(() => RouterTools.goHome(this.router))
        .withSearch(query => this.search(query))
        .withActions([
          {
            text: 'New Test',
            icon: 'add',
            visible: state.data.canWrite,
            enabled: true,
            active: true,
            primary: true,
            onClick: () => this.newTest()
          }
        ])
      );
    });
  }

  ngOnInit(): void {
    this.refresh();
  }

  public refresh(): void {
    this.testService.all().subscribe({
      next: tests => this.state$.invoke(state => state.refresh(tests)),
      error: () => {
        this.notificationService.show(Message.error('Failed to load tests'));
        this.state$.invoke(state => state.withLoading(false));
      }
    });
  }

  public goToTest(id: number): void {
    RouterTools.goToTest(this.router, id);
  }

  public newTest(): void {
    RouterTools.goToTestNew(this.router);
  }

  public search(value: string | undefined): void {
    this.state$.invoke(state => state.withSearch(value));
  }
}
