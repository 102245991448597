import {BehaviorSubject, catchError, map, Observable} from "rxjs";
import {Injectable} from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {UserSession} from "../models/user-session";
import {AppStorageService} from "./app-storage.service";
import {environment} from "../../environment";
import {v4 as uuidv4} from 'uuid';
import {Scope} from "../models/scope";
import {Session} from "../models/session";
import {SessionTools} from "../tools/session.tools";
import {Objects} from "../tools/objects";


@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private authApiUrl = environment.backendUrl + '/auth';
  private userApiUrl = environment.backendUrl + '/user';
  private _session$: BehaviorSubject<UserSession | undefined> = new BehaviorSubject<UserSession | undefined>(undefined);

  constructor(private http: HttpClient,
              private appStorageService: AppStorageService) {
    this.updateSession(this.readSession());
  }

  public get session$(): Observable<UserSession | undefined> {
    return this._session$.asObservable();
  }

  public get session(): UserSession | undefined {
    return this._session$.getValue();
  }

  public get loggedIn$(): Observable<boolean> {
    return this.session$.pipe(map(session => !!session));
  }

  public hasScope(scope: Scope): Observable<boolean> {
    return this.session$.pipe(map(session => SessionTools.hasScope(session, scope)));
  }

  public login(username: string, password: string): Observable<UserSession> {
    const clientId = this.getClientId();
    return this.http.post<any>(this.authApiUrl + '/login.php', {
      username: username,
      password: password,
      clientId: clientId
    })
      .pipe(map(session => {
          session = session && session.token ? session : undefined;
          this.updateSession(session);
          return session;
        }),
        catchError(error => {
            this.updateSession(undefined);
            throw error;
          }
        ));
  }

  public logout(): void {
    this.http.post<any>(this.authApiUrl + '/logout.php', {}, this.createHttpOptions())
      .subscribe({
        next: () => {
          this.updateSession(undefined);
        },
        error: error => {
          this.updateSession(undefined);
          if (error.status === 401) {
            console.debug("Already logged out");
          } else {
            console.error("Error logging out: ", error);
          }
        }
      });
  }

  public refresh(): void {
    if (!this._session$.getValue()) {
      return;
    }
    this.http.post<any>(this.authApiUrl + '/refresh.php', {}, this.createHttpOptions())
      .subscribe({
        next: session => {
          this.updateSession(session);
          console.debug("Session refreshed");
        },
        error: error => {
          if (error.status === 401) {
            this.updateSession(undefined);
            console.debug("Session expired");
          } else {
            console.error("Error refreshing session: ", error);
          }
        }
      });
  }

  public createHttpOptions() {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Token', this.getToken()!)
      .set('ClientId', this.getClientId());
    return {
      headers: headers
    };
  }

  public getSessionsByUserId(userId: number): Observable<Session[]> {
    const options = this.createHttpOptions();
    return this.http.get<Session[]>(this.userApiUrl + '/sessions.php?userId=' + userId, options);
  }

  public deleteSession(sessionId: number): Observable<void> {
    const options = this.createHttpOptions();
    return this.http.delete<void>(this.userApiUrl + '/sessions.php?id=' + sessionId, options);
  }

  private getToken(): string | undefined {
    return this._session$.getValue()?.token;
  }

  private readSession(): UserSession | undefined {
    return this.appStorageService.get('session');
  }

  private updateSession(session: UserSession | undefined): void {
    this._session$.next(session);
    if (!session) {
      this.appStorageService.remove('session');
    } else {
      this.appStorageService.set('session', session);
    }
  }

  /**
   * Generates and stores a unique client id. Retrieves the client id from local storage if it already exists.
   */
  private getClientId(): string {
    const clientId = this.appStorageService.get<string>('clientId');
    if (!Objects.isNull(clientId)) {
      return clientId!;
    }
    const newClientId = 'client-' + uuidv4();
    this.appStorageService.set('clientId', newClientId);
    return newClientId;
  }
}
