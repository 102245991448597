import {Component, OnInit} from "@angular/core";
import {TeamsState} from "./teams.state";
import {LeagueService} from "../../services/league.service";
import {TeamService} from "../../services/team.service";
import {Router} from "@angular/router";
import {Team} from "../../models/team";
import {StateSubject} from "../../tools/state.subject";
import {NotificationService} from "../../services/notification.service";
import {Message} from "../../models/message";
import {forkJoin} from "rxjs";
import {PageService} from "../../services/page.service";
import {RouterTools} from "../../tools/router.tools";
import {Tabs} from "../../models/tabs";
import {Tab} from "../../models/tab";

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {

  state$: StateSubject<TeamsState> = new StateSubject<TeamsState>(TeamsState.create());

  constructor(private leagueService: LeagueService,
              private teamService: TeamService,
              private notificationService: NotificationService,
              private pageService: PageService,
              private router: Router) {
    this.pageService.invoke(page => page.reset('Teams')
      .withShowMenu(true)
      .withBackButton(() => RouterTools.goHome(this.router))
      .withSearch((query?: string) => this.search(query))
      .withTabs(Tabs.of([
        Tab.of('Games', true, () => RouterTools.goToGames(this.router)),
        Tab.of('Leagues', true, () => RouterTools.goToLeagues(this.router)),
        Tab.of('Teams', true, () => RouterTools.goToTeams(this.router)),
      ], 2))
    );
  }

  ngOnInit(): void {
    this.refresh();
  }

  public refresh(): void {
    forkJoin({
      leagues: this.leagueService.getLeagues(),
      teams: this.teamService.getTeams()
    }).subscribe({
      next: ({leagues, teams}) => {
        this.state$.invoke(state => state.refresh(leagues, teams));
      },
      error: () => {
        this.notificationService.show(Message.error('Failed to load teams'));
        this.state$.invoke(state => state.withLoading(false));
      }
    });
  }

  public goToTeam(team: Team): void {
    RouterTools.goToTeam(this.router, team.leagueId, team.id);
  }

  public search(value: string | undefined): void {
    this.state$.invoke(state => state.withSearch(value));
  }
}
