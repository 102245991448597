import {Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {SessionService} from "./session.service";
import {environment} from "../../environment";
import {Skater} from "../models/skater";
import {Observable} from "rxjs";
import {Url} from "../models/url";
import {Action} from "../models/action";

@Injectable({
  providedIn: 'root'
})
export class SkaterService {

  private skaters = Url.create(environment.backendUrl + '/stats/skaters');

  constructor(private http: HttpClient,
              private sessionService: SessionService) {
  }

  public getSkaters(): Observable<Skater[]> {
    const options = this.sessionService.createHttpOptions();
    return this.http.get<Skater[]>(this.skaters.php, options);
  }

  public getSkatersByTeamId(teamId: number): Observable<Skater[]> {
    const options = this.sessionService.createHttpOptions();
    return this.http.get<Skater[]>(this.skaters.php + `?teamId=${teamId}`, options);
  }

  public getSkater(id: number): Observable<Skater> {
    const options = this.sessionService.createHttpOptions();
    return this.http.get<Skater>(this.skaters.php + `?id=${id}`, options);
  }

  public createSkater(skater: Partial<Skater>): Observable<Skater> {
    const options = this.sessionService.createHttpOptions();
    return this.http.post<Skater>(this.skaters.php, skater, options);
  }

  public updateSkater(id: number, skater: Skater): Observable<Skater> {
    const options = this.sessionService.createHttpOptions();
    return this.http.put<Skater>(this.skaters.php + `?id=${id}`, skater, options);
  }

  public deleteSkater(id: number): Observable<Skater> {
    const options = this.sessionService.createHttpOptions();
    return this.http.delete<Skater>(this.skaters.php + `?id=${id}`, options);
  }

  public getActions(id?: number): Observable<Action[]> {
    const options = this.sessionService.createHttpOptions();
    return this.http.get<Action[]>(this.skaters.actions.php + (id === undefined ? '' : `?id=${id}`), options);
  }
}
