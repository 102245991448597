<div class="page">
  <app-loading *ngIf="(state$ | async)!.data.loading"></app-loading>
  <div class="card" *ngIf="!(state$ | async)!.data.loading">
    <div class="list">
      <div class="list-item-secondary" *ngFor="let teamInfo of (state$ | async)!.data.filteredTeamInfos" (click)="goToTeam(teamInfo.team)">
        <div class="list-text-secondary">
          <div>
            {{ teamInfo.team.displayName }}
          </div>
          <div>
            {{ teamInfo.league?.displayName }}
          </div>
        </div>
      </div>
      <div class="list-item-secondary" *ngIf="(state$ | async)!.data.filteredTeamInfos.length === 0">
        <div class="list-text-message">
          No teams found
        </div>
      </div>
    </div>
  </div>
</div>
