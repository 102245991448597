import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {StateSubject} from "../../tools/state.subject";
import {forkJoin, of} from "rxjs";
import {TestService} from "../../services/test.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationService} from "../../services/notification.service";
import {DialogService} from "../../services/dialog.service";
import {RouterTools} from "../../tools/router.tools";
import {Message} from "../../models/message";
import {DialogOptions} from "../../models/dialog-options";
import {TestQuestionState} from "./test-question.state";
import {TestQuestionService} from "../../services/test-question.service";
import {TestQuestion} from "../../models/learning/domain/test-question";
import {PageService} from "../../services/page.service";
import {Objects} from "../../tools/objects";

@Component({
  selector: 'app-test-question',
  templateUrl: './test-question.component.html',
  styleUrls: ['./test-question.component.scss']
})
export class TestQuestionComponent  implements OnInit, AfterViewInit {

  @ViewChild("primary") primary!: ElementRef;

  state$: StateSubject<TestQuestionState> = new StateSubject<TestQuestionState>(TestQuestionState.create());

  answerIndices: number[] = [0, 1, 2, 3, 4];

  constructor(private testService: TestService,
              private testQuestionService: TestQuestionService,
              private pageService: PageService,
              private route: ActivatedRoute,
              private router: Router,
              private notificationService: NotificationService,
              private dialogService: DialogService) {
    this.state$.subscribe(state => this.pageService.invoke(page => page.reset(!state.data.testQuestion ? 'New Question' : 'Question')
      .withBackButton(() => RouterTools.goToTestQuestions(this.router, state.data.testId!))
      .withActions([
        {
          text: 'Save',
          icon: 'save',
          visible: state.canEdit,
          enabled: state.canSave,
          active: true,
          primary: true,
          onClick: () => this.save()
        },
        {
          text: 'Save & Add Another',
          icon: 'save_multi',
          visible: state.canEdit && !state.data.testQuestion,
          enabled: state.canSave,
          active: false,
          primary: false,
          onClick: () => this.save(true)
        },
        {
          text: 'Delete',
          icon: 'delete',
          visible: state.canDelete,
          enabled: !!state.data.testQuestion,
          active: false,
          primary: false,
          onClick: () => this.delete()
        }

      ])
    ));
  }

  ngOnInit(): void {
    RouterTools.observeParamMap(this.route, params => {
      const testId = RouterTools.toNumber(params, 'testId');
      const testQuestionId = RouterTools.toNumber(params, 'questionId');
      this.state$.invoke(state => state.withQueryIds(testId, testQuestionId));
    });
    this.state$.get(state => state.data.testId + '' + state.data.testQuestionId).subscribe(() => {
      this.refresh();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.primary.nativeElement.focus(), 500);
  }

  public refresh(): void {
    const state = this.state$.getValue();
    const testId = state.data.testId;
    const testQuestionId = state.data.testQuestionId;

    if (!testId) {
      this.state$.invoke(state => state.refresh(undefined, [], []));
      return;
    }

    forkJoin([
      this.testService.oneById(testId),
      testQuestionId ? this.testQuestionService.allByTestId(testId) : of([]),
      this.testQuestionService.getActions(testQuestionId)
    ]).subscribe({
      next: ([test, questions, actions]) => {
        this.state$.invoke(state => state.refresh(test, questions, actions));
      },
      error: () => {
        this.notificationService.show(Message.error(`Failed to load question`));
        this.state$.invoke(state => state.withLoading(false));
      }
    });
  }

  public save(addAnother: boolean = false): void {
    const state = this.state$.getValue();
    let testQuestion = {
      testId: state.data.testId,
      question: state.data.question,
      rationale: state.data.rationale ? state.data.rationale : undefined,
      answers: state.data.answers
    } as Partial<TestQuestion>;

    if (!state.data.testQuestion) {
      this.testQuestionService.create(testQuestion).subscribe({
        next: testQuestion => {
          if (addAnother) {
            this.notificationService.show(Message.success('Question added successfully'));
            this.refresh();
          } else {
            RouterTools.goToTestQuestion(this.router, state.data.testId!, testQuestion.id);
          }
        },
        error: () => {
          this.notificationService.show(Message.error('Failed to create question'));
        }
      });
      return;
    }

    testQuestion = {
      id: state.data.testQuestion.id,
      ...testQuestion
    };
    this.testQuestionService.update(state.data.testQuestion.id, testQuestion).subscribe({
      next: () => this.refresh(),
      error: () => {
        this.notificationService.show(Message.error('Failed to update question'));
      }
    });
  }

  public delete(): void {
    this.dialogService.show(DialogOptions
      .create('Delete Question', 'Are you sure you want to delete this question?')
      .withConfirm(() => this.doDelete()));
  }

  private doDelete(): void {
    const state = this.state$.getValue();
    if (!state.data.testQuestion) {
      return;
    }
    this.testQuestionService.delete(state.data.testQuestion.id).subscribe({
      next: () => {
        RouterTools.goToTest(this.router, state.data.testId!);
        this.notificationService.show(Message.success('Question deleted'));
      },
      error: () => {
        this.notificationService.show(Message.error('Failed to delete question'));
      }
    });
  }

  public withQuestion(event: any): void {
    this.state$.invoke(state => state.withQuestion(event.target.value));
  }

  public withRationale(event: any): void {
    this.state$.invoke(state => state.withRationale(event.target.value));
  }

  public addAnswer(): void {
    this.state$.invoke(state => state.addAnswer());
  }

  public withAnswerText(index: number, event: any): void {
    this.state$.invoke(state => state.withAnswerText(index, event.target.value));
  }

  public toggleAnswerIsCorrect(index: number): void {
    this.state$.invoke(state => state.withAnswerIsCorrect(index, !state.data.answers[index].isCorrect));
  }

  public deleteAnswer(index: number): void {
    this.state$.invoke(state => state.deleteAnswer(index));
  }

  public goToPreviousQuestion(): void {
    const state = this.state$.getValue();
    const previousQuestionId = state.previousQuestionId;
    if (Objects.isNull(previousQuestionId)) {
      return;
    }
    RouterTools.goToTestQuestion(this.router, state.data.testId!, previousQuestionId!);
  }

  public goToNextQuestion(): void {
    const state = this.state$.getValue();
    const nextQuestionId = state.nextQuestionId;
    if (Objects.isNull(nextQuestionId)) {
      return;
    }
    RouterTools.goToTestQuestion(this.router, state.data.testId!, nextQuestionId!);
  }
}
