import {Injectable} from "@angular/core";
import {AbstractService} from "./abstract-service";
import {HttpClient} from "@angular/common/http";
import {SessionService} from "./session.service";
import {TestSubmission} from "../models/learning/domain/test-submission";
import {Observable} from "rxjs";
import {TestSubmissionDto} from "../models/learning/dto/test-submission-dto";
import {TestMapper} from "../models/learning/domain/test-mapper";

@Injectable({
  providedIn: 'root'
})
export class TestSubmissionService extends AbstractService<TestSubmission, TestSubmissionDto> {

  constructor(http: HttpClient,
              sessionService: SessionService) {
    super(http, sessionService, '/learning/test-submissions', TestMapper.toSubmissionDto, TestMapper.fromSubmissionDto);
  }

  allByTestId(testId: number): Observable<TestSubmission[]> {
    const options = this.sessionService.createHttpOptions();
    return this.http.get<TestSubmissionDto[]>(this.url.php + `?testId=${testId}`, options).pipe(this.mapDtos());
  }

  oneByTestIdAndUserId(testId: number, userId: number): Observable<TestSubmission> {
    const options = this.sessionService.createHttpOptions();
    return this.http.get<TestSubmissionDto>(this.url.php + `?testId=${testId}&userId=${userId}`, options).pipe(this.mapDto());
  }
}
