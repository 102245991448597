import {User} from "../../models/user";
import {SearchTools} from "../../tools/search.tools";


export interface UsersData {
  users: User[];
  filteredUsers: User[];
  search?: string;
  loading: boolean;
}

export class UsersState {
  readonly data: UsersData;

  constructor(data: UsersData) {
    this.data = data;
  }

  public static create(): UsersState {
    return new UsersState({
      users: [],
      filteredUsers: [],
      loading: true,
    });
  }

  public roleNames(user: User): string {
    const roles = [];
    if (user.administrator) {
      roles.push('Administrator');
    }
    if (user.skater) {
      let skater = 'Skater';
      if (user.contactSafe) {
        skater += ' (CS)';
      }
      roles.push(skater);
    }
    if (user.trainer) {
      roles.push('Trainer');
    }
    if (user.bench) {
      roles.push('Bench');
    }
    return roles.join(', ');
  }

  public refresh(users: User[]): UsersState {
    return new UsersState({
      ...this.data,
      users: users,
      loading: false,
    }).filterUsers();
  }

  public withLoading(loading: boolean): UsersState {
    return new UsersState({
      ...this.data,
      loading: loading,
    });
  }

  public withSearch(value: string | undefined) {
    return new UsersState({
      ...this.data,
      search: value,
    }).filterUsers();
  }

  private filterUsers(): UsersState {
    const textF = (user: User) => user.displayName + user.username + this.roleNames(user);
    return new UsersState({
      ...this.data,
      filteredUsers: SearchTools.filter(this.data.users, textF, this.data.search),
    });
  }
}
