import {Test} from "../../models/learning/domain/test";
import {SearchTools} from "../../tools/search.tools";
import {TestCategory} from "../../models/learning/domain/test-category";
import {Objects} from "../../tools/objects";

export interface TestsData {
  loading: boolean;
  tests: Test[];
  realTests: Test[][];
  practiceTests: Test[][];
  filteredTests: Test[];
  search?: string;
  canWrite: boolean;
  canManage: boolean;
}

export class TestsState {
  readonly data: TestsData;

  constructor(data: TestsData) {
    this.data = data;
  }

  public static create(): TestsState {
    return new TestsState({
      loading: true,
      tests: [],
      realTests: [],
      practiceTests: [],
      filteredTests: [],
      canWrite: false,
      canManage: false,
    });
  }

  public get showRealTests(): boolean {
    return Objects.isEmpty(this.data.search) && this.data.realTests.some(tests => tests.length > 0);
  }

  public get showPracticeTests(): boolean {
    return Objects.isEmpty(this.data.search) && this.data.practiceTests.some(tests => tests.length > 0);
  }

  public get showFilteredTests(): boolean {
    return !Objects.isEmpty(this.data.search) && this.data.filteredTests.length > 0;
  }

  public get showEmptyMessage(): boolean {
    return !this.showRealTests && !this.showPracticeTests && !this.showFilteredTests;
  }

  public refresh(tests: Test[]): TestsState {
    return new TestsState({
      ...this.data,
      loading: false,
      tests: tests.sort((a, b) => a.displayName.localeCompare(b.displayName)),
    }).filterTests();
  }

  public withLoading(loading: boolean): TestsState {
    return new TestsState({
      ...this.data,
      loading: loading,
    });
  }

  public withSearch(value: string | undefined) {
    return new TestsState({
      ...this.data,
      search: value,
    }).filterTests();
  }

  public withCanWrite(canWrite: boolean): TestsState {
    return new TestsState({
      ...this.data,
      canWrite: canWrite,
    });
  }

  public withCanManage(canManage: boolean): TestsState {
    return new TestsState({
      ...this.data,
      canManage: canManage,
    }).filterTests();
  }

  private filterTests(): TestsState {
    const tests = this.data.tests;
    const canManage = this.data.canManage;
    const realTests = [
      tests.filter(test => !test.isPractice && test.isMandatory).sort(this.sortFunc()),
      tests.filter(test => !test.isPractice && !test.isMandatory).sort(this.sortFunc()),
    ];
    const practiceTests: Test[][] = [];
    for (let category of TestCategory.VALUES) {
      const testsForCategory = tests.filter(test => test.isPractice && test.category === category);
      practiceTests.push(testsForCategory.sort(this.sortFunc()));
    }
    const filteredTests = SearchTools.filter(tests, test => test.displayName + test.description, this.data.search);
    return new TestsState({
      ...this.data,
      realTests: realTests.map(tests => tests.filter(test => this.isShown(test, canManage))),
      practiceTests: practiceTests.map(tests => tests.filter(test => this.isShown(test, canManage))),
      filteredTests: filteredTests.filter(test => this.isShown(test, canManage)),
    });
  }

  private isShown(test: Test, canManage: boolean) {
    return test.isPublic || canManage;
  }

  private sortFunc() {
    return (a: Test, b: Test) => {
      const difficultyDiff = a.difficulty.order - b.difficulty.order;
      if (difficultyDiff !== 0) {
        return difficultyDiff;
      }
      return a.displayName.localeCompare(b.displayName);
    };
  }
}
