<div class="page-container">

  <div class="page-top-bar-desktop" *ngIf="(page$ | async)!.showTopBarDesktop">
    <ng-container *ngTemplateOutlet="topBarDesktop"></ng-container>
  </div>

  <ng-container *ngIf="(page$ | async)!.showTabsDesktop">
    <ng-container *ngTemplateOutlet="tabs"></ng-container>
  </ng-container>

  <div class="page-content-container"
       [style.height]="(page$ | async)!.toContentHeightStyle()">
    <div class="page-content-scroll-y">
      <ng-container *ngIf="(page$ | async)!.showTopBarMobile">
        <ng-container *ngTemplateOutlet="topBarMobile"></ng-container>
      </ng-container>

      <ng-container *ngIf="(page$ | async)!.showTabsMobile">
        <ng-container *ngTemplateOutlet="tabs"></ng-container>
      </ng-container>

      <div class="page-content-scroll-x">
        <div class="page-content-padding">
          <div class="page-content"
               [class.page-content-not-desktop]="!(page$ | async)!.isDesktop"
               [class.page-content-mobile-medium]="(page$ | async)!.isMobileMedium">
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </div>

    <!-- Actions desktop -->
    <ng-container *ngIf="(page$ | async)!.showActionsDesktop">
      <ng-container *ngTemplateOutlet="actionsDesktop"></ng-container>
    </ng-container>

    <!-- Actions mobile -->
    <ng-container *ngIf="(page$ | async)!.showActionsMobile">
      <ng-container *ngTemplateOutlet="actionsMobile"></ng-container>
    </ng-container>
  </div>

  <!-- Menu desktop -->
  <ng-container *ngIf="(page$ | async)!.showMenuDesktop">
    <ng-container *ngTemplateOutlet="menuDesktop"></ng-container>
  </ng-container>

  <!-- Menu mobile -->
  <ng-container *ngIf="(page$ | async)!.showMenuMobileOrMoreActions">
    <ng-container *ngTemplateOutlet="menuMobile"></ng-container>
  </ng-container>
</div>

<ng-template #topBarDesktop>
  <div class="page-top-bar">
    <div class="page-top-bar-left-container">
      <div class="page-top-bar-side-desktop">
        <div class="page-top-bar-left-home" (click)="goHome()">
          <app-icon name="logo_white"></app-icon>
          <div class="page-top-bar-left-title">
            {{ (page$ | async)!.data.appName }}
          </div>
        </div>
      </div>
    </div>

    <div class="page-top-bar-center" *ngIf="(page$ | async)!.showCenterBar; else centerTitle">
      <ng-container *ngIf="(page$ | async)!.data.backButton; else emptyBackDiv">
        <button class="page-top-bar-center-back" (click)="goBack()">
          <app-icon name="arrow_backward"></app-icon>
        </button>
      </ng-container>
      <ng-template #emptyBackDiv>
        <div></div>
      </ng-template>

      <div class="page-top-bar-center-title-solo">
        {{ (page$ | async)!.data.title }}
      </div>

      <div class="page-top-bar-center-buttons" *ngIf="(page$ | async)!.data.search; else emptySearchDiv">
        <app-search #search1 (searchEvent)="onSearch($event)"></app-search>
      </div>
      <ng-template #emptySearchDiv>
        <div></div>
      </ng-template>
    </div>

    <ng-template #centerTitle>
      <div class="page-top-bar-center-title-solo">
        {{ (page$ | async)!.data.title }}
      </div>
    </ng-template>

    <div class="page-top-bar-right-container">
      <div class="page-top-bar-side-desktop">
        <div class="page-top-bar-right">
          <button class="page-top-bar-right-profile" (click)="goToProfile()">
            <div class="page-top-bar-right-username">
              {{ (page$ | async)!.data.session?.displayName }}
            </div>
            <app-icon name="person"></app-icon>
          </button>
          <button class="page-top-bar-right-profile-mobile" (click)="goToProfile()">
            <app-icon name="person"></app-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!--
  -- Top bar mobile
  -->
<ng-template #topBarMobile>
  <div class="page-top-bar-mobile-container"
       [class.page-top-bar-mobile-container-with-tabs]="(page$ | async)!.showTabsMobile">
    <div class="page-top-bar-mobile-padding"
          [class.page-top-bar-mobile-padding-small]="(page$ | async)!.isMobileMedium">
      <div class="page-top-bar-mobile">
        <div class="page-top-bar-mobile-back" (click)="goHome()" *ngIf="!(page$ | async)!.showBackButton">
          <app-icon name="logo_white"></app-icon>
        </div>
        <div class="page-top-bar-mobile-back" (click)="goBack()" *ngIf="(page$ | async)!.showBackButton">
          <app-icon name="arrow_backward"></app-icon>
        </div>
        <div class="page-top-bar-mobile-title">
          {{ (page$ | async)!.data.title }}
        </div>
        <div class="page-top-bar-mobile-profile" (click)="goToProfile()" *ngIf="!(page$ | async)!.showBackButton">
          <app-icon name="person" class="page-top-bar-mobile-left-logo"></app-icon>
        </div>
        <div class="page-top-bar-center-buttons" *ngIf="(page$ | async)!.data.search; else emptyMobileSearchDiv">
          <app-search #search2 (searchEvent)="onSearch($event)"></app-search>
        </div>
        <ng-template #emptyMobileSearchDiv>
          <div class="page-top-bar-center-empty"></div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>

<!--
  -- Tabs
  -->
<ng-template #tabs>
  <div class="page-tab-container">
    <div class="page-tabs">
      <ng-container *ngFor="let tab of (page$ | async)!.data.tabs.tabs; let i = index">
        <div class="tab"
             [class.tab]="i !== (page$ | async)!.data.tabs.tabIndex"
             [class.tab-selected]="i === (page$ | async)!.data.tabs.tabIndex"
             [class.tab-disabled]="!tab.enabled"
             (click)="onTabClick(tab)">
          {{ tab.title }}
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<!--
  -- Actions desktop
  -->
<ng-template #actionsDesktop>
  <div class="page-actions-container">
    <div class="page-actions">
      <ng-container *ngFor="let button of (page$ | async)!.data.allActions">
        <button *ngIf="button.visible"
                class="page-actions-button-primary"
                [class.page-actions-button-primary]="button.active"
                [class.page-actions-button-secondary]="!button.active"
                [disabled]="!button.enabled"
                (click)="onButtonClick(button)">
          <app-icon [name]="button.icon"></app-icon>
          <span>{{ button.text }}</span>
        </button>
      </ng-container>
    </div>
  </div>
</ng-template>

<!--
  -- Actions mobile
  -->
<ng-template #actionsMobile>
  <div *ngIf="(page$ | async)!.hasPrimaryActions"
       class="page-actions-mobile-primary-container"
       [class.page-actions-mobile-primary-container-small]="(page$ | async)!.showActionsMobileAboveMenu">
    <div class="page-actions-mobile-primary" *ngFor="let button of (page$ | async)!.data.primaryActions">
      <button *ngIf="button.visible"
              class="page-actions-mobile-primary-button-primary"
              [class.page-actions-mobile-primary-button-primary]="button.active"
              [class.page-actions-mobile-primary-button-secondary]="!button.active"
              [disabled]="!button.enabled"
              (click)="onButtonClick(button)">
        <app-icon [name]="button.icon"></app-icon>
      </button>
    </div>
  </div>
</ng-template>

<!--
  -- Menu desktop
  -->
<ng-template #menuDesktop>
  <div class="page-menu-container">
    <div class="page-menu">
      <ng-container *ngFor="let button of (page$ | async)!.data.menuButtons">
        <button *ngIf="!button.isAdmin || button.enabled"
                class="page-menu-button"
                [class.page-menu-button]="!isPathActive(button.path)"
                [class.page-menu-button-active]="isPathActive(button.path)"
                [disabled]="!button.enabled"
                (click)="goTo(button.path)">
          <app-icon [name]="button.icon"></app-icon>
          <span>{{ button.text }}</span>
        </button>
      </ng-container>
    </div>
  </div>
</ng-template>

<!--
  -- Menu mobile
  -->
<ng-template #menuMobile>
  <div class="page-menu-mobile-container">
    <div class="page-menu-mobile"
         [class.page-menu-mobile-small]="(page$ | async)!.isMobileSmall">
      <ng-container *ngIf="(page$ | async)!.data.showMenu">
        <ng-container *ngFor="let button of (page$ | async)!.data.menuButtons">
          <button *ngIf="!button.isAdmin || button.enabled"
                  class="page-menu-mobile-button"
                  [class.page-menu-mobile-button]="!isPathActive(button.path)"
                  [class.page-menu-mobile-button-active]="isPathActive(button.path)"
                  [disabled]="!button.enabled"
                  (click)="goTo(button.path)">
            <app-icon [name]="button.icon"></app-icon>
          </button>
        </ng-container>
      </ng-container>
      <ng-container *ngFor="let button of (page$ | async)!.data.moreActions">
        <button *ngIf="button.visible"
                class="page-menu-mobile-action"
                [class.page-menu-mobile-action]="!button.active"
                [class.page-menu-mobile-action-active]="button.active"
                [class.page-menu-mobile-action-small]="(page$ | async)!.isMobileSmall"
                [disabled]="!button.enabled"
                (click)="onButtonClick(button)">
          <app-icon [name]="button.icon"></app-icon>
          <span>{{ button.text }}</span>
        </button>
      </ng-container>
    </div>
  </div>
</ng-template>
