import {TeamLine} from "../models/team-line";
import {Jam} from "../models/jam";
import {Skater} from "../models/skater";

export class PredictionTools {

  public static predictNextJam(jams: Jam[], allLines: TeamLine[][], allSkaters: Skater[][]): Partial<Jam> {
    const line1 = PredictionTools.predictNextLineId(jams, allLines, 0);
    const line2 = PredictionTools.predictNextLineId(jams, allLines, 1);
    const pivot1 = PredictionTools.predictNextPivotId(jams, allSkaters, 0);
    const pivot2 = PredictionTools.predictNextPivotId(jams, allSkaters, 1);
    return {
      period: jams.length === 0 ? 1 : jams[jams.length - 1].period,
      jamNumber: jams.length === 0 ? 1 : jams[jams.length - 1].jamNumber + 1,
      team1LineId: line1,
      team2LineId: line2,
      team1JammerId: PredictionTools.predictNextJammerId(jams, allSkaters, 0),
      team2JammerId: PredictionTools.predictNextJammerId(jams, allSkaters, 1),
      team1PivotId: pivot1,
      team2PivotId: pivot2,
    };
  }

  public static predictNextLineId(jams: Jam[], allLines: TeamLine[][], teamIndex: number): number | undefined {
    const lines = allLines[teamIndex];
    if (lines.length === 0) {
      return undefined;
    }
    const lineSequence = jams
      .map(jam => teamIndex === 0 ? jam.team1LineId : jam.team2LineId);

    return PredictionTools.predictNextId(lineSequence, lines.map(line => line.id), true, 2);
  }

  public static predictNextJammerId(jams: Jam[], allSkaters: Skater[][], teamIndex: number): number | undefined {
    const jammers = allSkaters[teamIndex].filter(skater => skater.isJammer);
    if (jammers.length === 0) {
      return undefined;
    }
    const jammerSequence = jams
      .map(jam => teamIndex === 0 ? jam.team1JammerId : jam.team2JammerId);

    return PredictionTools.predictNextId(jammerSequence, jammers.map(jammer => jammer.id), false, 2);
  }

  public static predictNextPivotId(jams: Jam[], allSkaters: Skater[][], teamIndex: number): (number | undefined) {
    const skaters = allSkaters[teamIndex];
    if (skaters.length === 0) {
      return undefined;
    }
    const pivotSequence = jams
      .map(jam => teamIndex === 0 ? jam.team1PivotId : jam.team2PivotId);

    return PredictionTools.predictNextId(pivotSequence, skaters.map(skater => skater.id), true, 2);
  }

  public static predictNextId(sequence: (number | undefined)[], candidates: number[], includeSingleOccurrences: boolean, minRotation: number): number | undefined {
    if (sequence.length === 0 || sequence[sequence.length - 1] === undefined) {
      return undefined;
    }

    const enriched = candidates
      .map(candidate => {
        let rotation = 0;
        const lastIndex = sequence.lastIndexOf(candidate);
        let lastIndexThatIsNotAdjacent = lastIndex;
        for (let i = lastIndex - 1; i >= 0 && lastIndex !== -1; i--) {
          if (sequence[i] === sequence[lastIndex]) {
            lastIndexThatIsNotAdjacent = i;
          } else {
            break;
          }
        }

        // Calculate rotation
        const multipleOccurrences = sequence.filter(id => id === candidate).length > 1;
        for (let i = lastIndexThatIsNotAdjacent; i >= 0 && multipleOccurrences; i--) {
          if (i === lastIndexThatIsNotAdjacent || sequence[i] !== candidate) {
            rotation++;
          } else {
            break;
          }
        }

        // Calculate last occurrence (how long ago the candidate was last used)
        const lastOccurrence = lastIndex === -1 ? Number.MAX_VALUE : sequence.length - lastIndex;
        return {
          id: candidate,
          rotation: rotation,
          lastOccurrence: lastOccurrence,
        }
      })
      .filter(candidate => candidate.lastOccurrence !== Number.MAX_VALUE)
      .filter(candidate => candidate.lastOccurrence >= minRotation)
      .filter(candidate => candidate.lastOccurrence < candidates.length * 2) // Only consider candidates that played recently
      .sort((a, b) => a.rotation - b.rotation || b.lastOccurrence - a.lastOccurrence); // Fastest rotation first

    if (enriched.length === 0) {
      console.debug('No candidate found, returning undefined');
      return undefined;
    }

    // Find fastest rotation, if any
    console.debug('Enriched:', enriched);
    const fastRotation = enriched.filter(candidate => candidate.rotation > 0)[0]?.rotation;
    if (fastRotation === 0) {
      if (includeSingleOccurrences) {
        console.debug('Fast rotation is 0, returning first candidate');
        return enriched[0].id;
      }
      console.debug('Fast rotation is 0, returning undefined');
      return undefined;
    }

    // Give priority to fast rotations
    const fastestRotations = enriched
      .filter(candidate => candidate.rotation === fastRotation && candidate.lastOccurrence >= candidate.rotation);
    if (fastestRotations.length > 0) {
      console.debug('Fastest rotations:', fastestRotations);
      return fastestRotations[0].id;
    }

    // For slower rotations, check who was used least recently
    const slowRotation = fastRotation + 1;
    const slowerRotation = enriched
      .filter(candidate => candidate.rotation !== fastRotation && candidate.lastOccurrence >= slowRotation)
      .sort((a, b) => b.lastOccurrence - a.lastOccurrence);
    if (slowerRotation.length > 0) {
      console.debug('Slower rotations:', slowerRotation);
      return slowerRotation[0].id;
    }
    console.debug('No candidate found, returning', includeSingleOccurrences ? enriched[0].id : undefined);
    return includeSingleOccurrences ? enriched[0].id : undefined;
  }
}
