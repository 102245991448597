import {Injectable} from "@angular/core";
import {AbstractService} from "./abstract-service";
import {HttpClient} from "@angular/common/http";
import {SessionService} from "./session.service";
import {TestQuestion} from "../models/learning/domain/test-question";
import {Observable} from "rxjs";
import {TestMapper} from "../models/learning/domain/test-mapper";
import {TestQuestionDto} from "../models/learning/dto/test-question-dto";

@Injectable({
  providedIn: 'root'
})
export class TestQuestionService extends AbstractService<TestQuestion, TestQuestionDto> {

  constructor(http: HttpClient,
              sessionService: SessionService) {
    super(http, sessionService, '/learning/test-questions', TestMapper.toQuestionDto, TestMapper.fromQuestionDto);
  }

  allByTestId(testId: number): Observable<TestQuestion[]> {
    const options = this.sessionService.createHttpOptions();
    return this.http.get<TestQuestionDto[]>(this.url.php + `?testId=${testId}`, options)
      .pipe(this.mapDtos());
  }
}
