<div class="tabs-container">
  <div class="tabs-center"
       [class.tabs-center-with-back]="tabs.goBack !== undefined">
    <div class="tab-back" (click)="goBack()" *ngIf="tabs.goBack !== undefined">
      <app-icon name="arrow_backward"></app-icon>
    </div>
    <div class="tabs">
      <ng-container *ngFor="let tab of tabs.tabs; let i = index">
        <div class="tab"
             [class.tab]="i !== tabs.tabIndex"
             [class.tab-selected]="i === tabs.tabIndex"
             [class.tab-disabled]="!tab.enabled"
             (click)="onClick(tab)">
          {{ tab.title }}
        </div>
      </ng-container>
    </div>
  </div>
</div>
