import {Component, OnInit} from "@angular/core";
import {TestService} from "../../services/test.service";
import {Message} from "../../models/message";
import {CardData, TestStudyState} from "./test-study.state";
import {ActivatedRoute, Router} from "@angular/router";
import {RouterTools} from "../../tools/router.tools";
import {StateSubject} from "../../tools/state.subject";
import {forkJoin} from "rxjs";
import {TestQuestionService} from "../../services/test-question.service";
import {NotificationService} from "../../services/notification.service";
import {Objects} from "../../tools/objects";
import {UserStorageService} from "../../services/user-storage.service";
import {PageService} from "../../services/page.service";
import {DialogService} from "../../services/dialog.service";
import {DialogOptions} from "../../models/dialog-options";

@Component({
  selector: 'app-test-study',
  templateUrl: './test-study.component.html',
  styleUrls: ['./test-study.component.scss']
})
export class TestStudyComponent implements OnInit {

  state$: StateSubject<TestStudyState> = new StateSubject<TestStudyState>(TestStudyState.create());

  constructor(private testService: TestService,
              private testQuestionService: TestQuestionService,
              private notificationService: NotificationService,
              private userStorageService: UserStorageService,
              private pageService: PageService,
              private dialogService: DialogService,
              private router: Router,
              private route: ActivatedRoute) {
    this.state$.subscribe(state => {
      this.pageService.invoke(page => page.reset(state.data.test ? state.data.test.displayName : 'Test')
        .withBackButton(() => this.close()));
    })
    this.state$.subscribe(() => this.persistProgress());
  }

  ngOnInit(): void {
    RouterTools.observeParamMap(this.route, params => {
      const testId = RouterTools.toNumber(params, 'testId');
      this.state$.invoke(state => state.withQueryId(testId));
    });
    this.state$.get(state => '' + state.data.testId).subscribe(() => this.refresh());
  }

  public refresh(): void {
    const state = this.state$.getValue();
    const testId = state.data.testId;
    if (!testId) {
      return;
    }

    forkJoin([
      this.testService.oneById(testId),
      this.testQuestionService.allByTestId(testId),
    ]).subscribe({
      next: ([test, questions]) => {
        const cards = this.retrieveProgress();
        this.state$.invoke(state => state.refresh(test, questions, cards));
      },
      error: () => {
        this.notificationService.show(Message.error(`Failed to load test`));
        this.state$.invoke(state => state.withLoading(false));
      }
    });
  }

  public goBack(): void {
    const state = this.state$.getValue();
    const testId = state.data.testId!;
    RouterTools.goToTest(this.router, testId);
  }

  public close(): void {
    const state = this.state$.getValue();
    if (state.data.cards.length === 0) {
      this.doClose();
      return;
    }
    this.dialogService.show(DialogOptions
      .create('Stop Study Session', 'Are you sure you want to stop this study session?')
      .withConfirm(() => this.doClose()));
  }

  private doClose(): void {
    this.state$.invoke(state => state.refresh(state.data.test, state.data.questions, undefined));
    this.goBack();
  }

  public showAnswer(): void {
    this.state$.invoke(state => state.showAnswer());
  }


  public onHard(): void {
    this.state$.invoke(state => state.onHard());
  }

  public onGood(): void {
    this.state$.invoke(state => state.onGood());
  }

  public onEasy(): void {
    this.state$.invoke(state => state.onEasy());
  }

  private persistedKey(testId: number): string {
    return 'test.study.' + testId;
  }

  private persistProgress(): void {
    const state = this.state$.getValue();
    if (Objects.isNull(state.data.testId) || state.data.loading) {
      return;
    }
    const cards = state.data.cards;
    const questions = state.data.questions;
    const isInitial = cards.length === questions.length &&
      cards.filter(card => card.hard === 0 && card.good === 0 && card.easy === 0).length === cards.length;
    const persistedCards = isInitial || cards.length === 0 ? undefined : cards;
    this.userStorageService.set(this.persistedKey(state.data.testId!), persistedCards);
  }

  private retrieveProgress() {
    const state = this.state$.getValue();
    return this.userStorageService.get<CardData[]>(this.persistedKey(state.data.testId!));
  }
}
