import {Objects} from "./objects";

export class DateTools {


  public static toUtcString(date: Date | undefined): string | undefined {
    return Objects.isNull(date) ? undefined : date!.toISOString();
  }

  public static fromUtcString(date: string | undefined): Date | undefined {
    if (Objects.isNull(date)) {
      return undefined;
    }
    date = date!.replace(' ', 'T');
    date = date!.endsWith('Z') ? date : date + 'Z';
    return Objects.isNull(date) ? undefined : new Date(date!);
  }

  public static toMonthYear(startDate: Date | undefined): string {
    if (!startDate) {
      return '';
    }
    const month = startDate.getMonth();
    const year = startDate.getFullYear();
    return `${DateTools.toMonthName(month)} ${year}`;
  }

  public static toMonthName(month: number): string {
    switch (month) {
      case 0:
        return 'January';
      case 1:
        return 'February';
      case 2:
        return 'March';
      case 3:
        return 'April';
      case 4:
        return 'May';
      case 5:
        return 'June';
      case 6:
        return 'July';
      case 7:
        return 'August';
      case 8:
        return 'September';
      case 9:
        return 'October';
      case 10:
        return 'November';
      case 11:
        return 'December';
      default:
        return '';
    }
  }
}
