import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {Objects} from "./objects";
import {LiveMode} from "../components/live/live.state";

export class RouterTools {

  public static observeParamMap(route: ActivatedRoute, handler: (params: ParamMap) => void): void {
    route.paramMap.subscribe(params => {
      handler(params);
    });
    const params = route.snapshot.paramMap;
    handler(params);
  }

  public static toNumber(map: ParamMap, key: string): number | undefined {
    const value = map.get(key);
    return Objects.toNumber(value);
  }

  public static goHome(router: Router): void {
    router.navigate(['/home']);
  }

  public static goToLeagues(router: Router): void {
    router.navigate(['/games/leagues']);
  }

  public static goToLeague(router: Router, leagueId: number): void {
    router.navigate(['/games/leagues', leagueId]);
  }

  public static goToLeagueNew(router: Router): void {
    router.navigate(['/games/leagues/new']);
  }

  public static goToTeams(router: Router): void {
    router.navigate(['/games/teams']);
  }

  public static goToTeam(router: Router, leagueId: number, teamId: number): void {
    router.navigate(['/games/leagues', leagueId, 'teams', teamId]);
  }

  public static goToTeamNew(router: Router, leagueId: number): void {
    router.navigate(['/games/leagues', leagueId, 'teams', 'new']);
  }

  public static goToTeamLine(router: Router, leagueId: number, teamId: number, lineId: number): void {
    router.navigate(['/games/leagues', leagueId, 'teams', teamId, 'lines', lineId]);
  }

  public static goToTeamLines(router: Router, leagueId: number, teamId: number): void {
    router.navigate(['/games/leagues', leagueId, 'teams', teamId, 'lines']);
  }

  public static goToTeamLineNew(router: Router, leagueId: number, teamId: number): void {
    router.navigate(['/games/leagues', leagueId, 'teams', teamId, 'lines', 'new']);
  }

  public static goToSkater(router: Router, leagueId: number, teamId: number, skaterId: number): void {
    router.navigate(['/games/leagues', leagueId, 'teams', teamId, 'skaters', skaterId]);
  }

  public static goToSkaters(router: Router, leagueId: number, teamId: number): void {
    router.navigate(['/games/leagues', leagueId, 'teams', teamId, 'skaters']);
  }

  public static goToSkaterNew(router: Router, leagueId: number, teamId: number): void {
    router.navigate(['/games/leagues', leagueId, 'teams', teamId, 'skaters', 'new']);
  }

  public static goToGames(router: Router): void {
    router.navigate(['/games']);
  }

  public static goToGame(router: Router, gameId: number): void {
    router.navigate(['/games', gameId]);
  }

  public static goToGameNew(router: Router): void {
    router.navigate(['/games/new']);
  }

  public static goToGameStats(router: Router, gameId: number, mode?: LiveMode): void {
    router.navigate(['/games', gameId, 'stats', mode?.code || '']);
  }

  public static goToGameJams(router: Router, gameId: number): void {
    router.navigate(['/games', gameId, 'jams']);
  }

  public static goToTests(router: Router): void {
    router.navigate(['/tests']);
  }

  public static goToTest(router: Router, testId: number): void {
    router.navigate(['/tests', testId]);
  }

  public static goToTestNew(router: Router): void {
    router.navigate(['/tests/new']);
  }

  public static goToTestQuestions(router: Router, testId: number): void {
    router.navigate(['/tests', testId, 'questions']);
  }

  public static goToTestQuestion(router: Router, testId: number, testQuestionId: number): void {
    router.navigate(['/tests', testId, 'questions', testQuestionId]);
  }

  public static goToTestQuestionNew(router: Router, testId: number): void {
    router.navigate(['/tests', testId, 'questions', 'new']);
  }

  public static goToTestQuestionUpload(router: Router, testId: number): void {
    router.navigate(['/tests', testId, 'questions', 'upload']);
  }

  public static goToTestSubmission(router: Router, testId: number, testSubmissionId: number): void {
    router.navigate(['/tests', testId, 'submissions', testSubmissionId]);
  }

  public static goToTestSubmissionNew(router: Router, testId: number): void {
    router.navigate(['/tests', testId, 'submissions', 'new']);
  }

  public static goToTestStudy(router: Router, testId: number): void {
    router.navigate(['/tests', testId, 'study']);
  }

  public static goToUsers(router: Router): void {
    router.navigate(['/users']);
  }

  public static goToUser(router: Router, userId: number): void {
    router.navigate(['/users', userId]);
  }

  public static goToUserNew(router: Router): void {
    router.navigate(['/users/new']);
  }

  public static goToProfile(router: Router): void {
    router.navigate(['/profile']);
  }
}
