<div class="page">
  <app-loading *ngIf="(state$ | async)!.data.loading"></app-loading>
  <div class="cards" *ngIf="!(state$ | async)!.data.loading">
    <div class="card">
      <div class="card-header">
        <div class="subtitle">
          <app-icon name="skate"></app-icon>
          Skater Info
        </div>
      </div>
      <div class="form">
        <div class="field">
          <label class="field-label" for="derbyNumber">Derby Number</label>
          <input type="text" class="field-text" id="derbyNumber"
                 [disabled]="!(state$ | async)!.canEdit"
                 [value]="(state$ | async)!.data.derbyNumber"
                 (input)="withDerbyNumber($event)"
                 #primary/>
          <div class="field-error" *ngIf="(state$ | async)!.data.derbyNumberError">
            {{(state$ | async)!.data.derbyNumberError}}
          </div>
        </div>
        <div class="field">
          <label class="field-label" for="derbyName">Derby Name</label>
          <input type="text" class="field-text" id="derbyName"
                 [disabled]="!(state$ | async)!.canEdit"
                 [value]="(state$ | async)!.data.derbyName"
                 (input)="withDerbyName($event)"/>
          <div class="field-error" *ngIf="(state$ | async)!.data.derbyNameError">
            {{(state$ | async)!.data.derbyNameError}}
          </div>
        </div>
        <div class="field-group">
          <div class="field">
            <app-checkbox text="Active"
                          textFaded="Available for new games"
                          [disabled]="!(state$ | async)!.canEdit"
                          [checked]="(state$ | async)!.data.isActive"
                          (checked)="withIsActive($event)"></app-checkbox>
          </div>
          <div class="field">
            <app-checkbox text="Jammer"
                          [disabled]="!(state$ | async)!.canEdit"
                          [checked]="(state$ | async)!.data.isJammer"
                          (checked)="withIsJammer($event)"></app-checkbox>
          </div>
          <div class="field">
            <app-checkbox text="Pivot"
                          [disabled]="!(state$ | async)!.canEdit"
                          [checked]="(state$ | async)!.data.isPivot"
                          (checked)="withIsPivot($event)"></app-checkbox>
          </div>
          <div class="field">
            <app-checkbox text="Captain"
                          [disabled]="!(state$ | async)!.canEdit"
                          [checked]="(state$ | async)!.data.isCaptain"
                          (checked)="withIsCaptain($event)"></app-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
