import {Game} from "../../models/game";
import {Team} from "../../models/team";
import {Skater} from "../../models/skater";
import {Jam} from "../../models/jam";
import {Color} from "../../models/color";
import {ColorTools} from "../../tools/color.tools";
import {JamExtraData} from "../../models/jam-extra-data";
import {LapInfo} from "../../models/lapInfo";
import {TeamLine} from "../../models/team-line";
import {Action} from "../../models/action";
import {PredictionTools} from "../../tools/predictionTools";
import {Objects} from "../../tools/objects";
import {JamTools} from "../../tools/jam.tools";

export interface JamsData {
  gameId?: number;
  game?: Game;
  team1?: Team;
  team2?: Team;
  skaters1: Skater[];
  skaters2: Skater[];
  lines1: TeamLine[];
  lines2: TeamLine[];
  jams: Jam[];
  jamExtraDatas: JamExtraData[];
  actions: Action[];
  colors: Color[];
  heatMapVisible: boolean;
  selectedPeriodIndex: number | undefined;
  selectedJamIndex: number | undefined;
  smartCompletion: boolean;
  showPivotCalculated: boolean;
  showPivot: boolean;
  skaterHighlights: (Skater | undefined)[][][];
  lineHighlights: (TeamLine | undefined)[][];
  autoRefresh: boolean;
  periodScores: number[][]; // team x period,
  trackWidth: number;
}

export class JamsState {
  readonly data: JamsData;

  constructor(data: JamsData) {
    this.data = data;
  }

  public static create(): JamsState {
    return new JamsState({
      gameId: undefined,
      game: undefined,
      team1: undefined,
      team2: undefined,
      skaters1: [],
      skaters2: [],
      lines1: [],
      lines2: [],
      jams: [],
      jamExtraDatas: [],
      actions: [],
      colors: ColorTools.getColors(),
      heatMapVisible: false,
      selectedPeriodIndex: undefined,
      selectedJamIndex: undefined,
      smartCompletion: false,
      showPivotCalculated: false,
      showPivot: false,
      skaterHighlights: [],
      lineHighlights: [],
      autoRefresh: true,
      periodScores: [[0, 0], [0, 0]],
      trackWidth: 512,
    });
  }

  public get canEdit(): boolean {
    return this.data.actions.includes(Action.EDIT);
  }

  public get canEditJams(): boolean {
    return this.canEdit && !this.data.game?.isFinished;
  }

  public canDelete(period: number, jamNumber: number): boolean {
    return this.isLastJamPerPeriod(period, jamNumber) && this.data.actions.includes(Action.EDIT);
  }

  public get team1Name(): string {
    return this.data.team1?.displayName ?? 'Team 1';
  }

  public get team2Name(): string {
    return this.data.team2?.displayName ?? 'Team 2';
  }

  public getNewJamNumber(period: number): number {
    const jams = this.data.jams;
    const maxJamNumber = jams.reduce((max, jam) => jam.period === period ? Math.max(max, jam.jamNumber) : max, 0);
    return maxJamNumber + 1;
  }

  public getJammer(teamIndex: number, periodIndex: number, jamNumberIndex: number): Skater | undefined {
    const jam = this.data.jams.find(j => j.period === periodIndex + 1 && j.jamNumber === jamNumberIndex + 1);
    const skaterId = teamIndex === 0 ? jam?.team1JammerId : jam?.team2JammerId;
    return this.data.skaters1.concat(this.data.skaters2).find(s => s.id === skaterId) ?? undefined;
  }

  public isLastJamPerPeriod(period: number, jamNumber: number): boolean {
    if (this.data.jams.length === 0) {
      return false;
    }
    return this.getNewJamNumber(period) === jamNumber + 1;
  }

  public isLastJam(period: number, jamNumber: number): boolean {
    const jam = this.getLastJam();
    return !!jam && jam.period === period && jam.jamNumber === jamNumber;
  }

  public getLastJam(periodIndex?: number): Jam | undefined {
    if (periodIndex === undefined) {
      if (this.data.jams.length === 0) {
        return undefined;
      }
      return this.data.jams[this.data.jams.length - 1];
    }
    return this.data.jams.filter(j => j.period === periodIndex + 1).pop();
  }

  public getTeamColor(teamIndex: number): string {
    const colorCode = teamIndex === 0 ? this.data.game?.teamColor1 : this.data.game?.teamColor2;
    return ColorTools.toHex(colorCode);
  }

  public getHeatMapEditable(periodIndex: number, jamIndex: number, teamIndex: number): boolean {
    if (!this.canEditJams) {
      return false;
    }
    const jam = this.data.jams.find(j => j.period === periodIndex + 1 && j.jamNumber === jamIndex + 1);
    return teamIndex === 0 ? jam?.team1JammerId !== undefined : jam?.team2JammerId !== undefined;
  }

  public getLapInfos(periodIndex: number, jamIndex: number, teamIndex: number): LapInfo[] {
    const skaters = teamIndex === 0 ? this.data.skaters1 : this.data.skaters2;
    const extraDatas = this.data.jamExtraDatas;

    let laps: LapInfo[] = [];
    for (let i = 0; i < extraDatas.length; i++) {
      const extraData = extraDatas[i];
      const jam = this.data.jams[i];
      if (periodIndex === undefined || jamIndex === undefined) {
        laps.push(...extraData.laps);
      } else if (jam.period === periodIndex + 1 && jam.jamNumber === jamIndex + 1) {
        laps.push(...extraData.laps);
      }
    }
    return laps.filter(l => skaters.find(s => s.id === l.skaterId) != null);
  }

  public isMoreVisible(periodIndex: number | undefined, jamIndex: number | undefined): boolean {
    if (this.data.selectedPeriodIndex === undefined) {
      return periodIndex !== undefined && this.isLastJam(periodIndex + 1, jamIndex! + 1);
    }
    return this.data.selectedPeriodIndex === periodIndex && this.data.selectedJamIndex === jamIndex;
  }

  public refreshGame(game?: Game): JamsState {
    return new JamsState({
      ...this.data,
      game: game,
    });
  }

  public refresh(team1: Team, team2: Team, skaters1: Skater[], skaters2: Skater[], lines1: TeamLine[], lines2: TeamLine[], game: Game | undefined, jams: Jam[], actions: Action[]): JamsState {
    return new JamsState({
      ...this.data,
      game: game,
      team1: team1,
      team2: team2,
      skaters1: JamTools.filterAndSortSkaters(skaters1, jams),
      skaters2: JamTools.filterAndSortSkaters(skaters2, jams),
      lines1: JamTools.filterAndSortLines(lines1, jams),
      lines2: JamTools.filterAndSortLines(lines2, jams),
      jams: jams.sort((a, b) => (a.period * 1000 + a.jamNumber) - (b.period * 1000 + b.jamNumber)),
      actions: actions,
      jamExtraDatas: jams.map(j => JamExtraData.fromJson(j.extraData)),
    })
      .maybeShowPivotOrLine()
      .recalculateScores()
      .guessJam();
  }

  refreshJam(updatedJam: Jam): JamsState {
    const updatedJams = this.data.jams.map(j => {
      if (j.gameId === updatedJam.gameId && j.period === updatedJam.period && j.jamNumber === updatedJam.jamNumber) {
        return updatedJam;
      }
      return j;
    });
    if (updatedJams.find(j => j.gameId === updatedJam.gameId && j.period === updatedJam.period && j.jamNumber === updatedJam.jamNumber) == null) {
      updatedJams.push(updatedJam);
    }
    return this.refresh(this.data.team1!, this.data.team2!, this.data.skaters1, this.data.skaters2, this.data.lines1, this.data.lines2, this.data.game, updatedJams, this.data.actions);
  }

  deleteJam(period: number, jamNumber: number): JamsState {
    const updatedJams = this.data.jams.filter(j => j.period !== period || j.jamNumber !== jamNumber);
    return this.refresh(this.data.team1!, this.data.team2!, this.data.skaters1, this.data.skaters2, this.data.lines1, this.data.lines2, this.data.game, updatedJams, this.data.actions);
  }

  withQueryId(gameId: number | undefined): JamsState {
    return new JamsState({
      ...this.data,
      gameId: gameId,
    });
  }

  withSmartCompletion(smartCompletion: boolean): JamsState {
    return new JamsState({
      ...this.data,
      smartCompletion: smartCompletion,
    });
  }

  withShowPivot(showPivot: boolean): JamsState {
    return new JamsState({
      ...this.data,
      showPivot: showPivot,
    });
  }

  withSelection(periodIndex: number | undefined, jamIndex: number | undefined): JamsState {
    const toggle = this.isMoreVisible(periodIndex, jamIndex);
    const isLastJam = periodIndex !== undefined && this.isLastJam(periodIndex! + 1, jamIndex! + 1);
    return new JamsState({
      ...this.data,
      selectedPeriodIndex: toggle || isLastJam ? undefined : periodIndex,
      selectedJamIndex: toggle || isLastJam ? undefined : jamIndex,
    });
  }

  withAutoRefresh(autoRefresh: boolean): JamsState {
    return new JamsState({
      ...this.data,
      autoRefresh: autoRefresh,
    });
  }

  withTrackWidth(trackWidth: number): JamsState {
    return new JamsState({
      ...this.data,
      trackWidth: trackWidth,
    });
  }

  public toTeam1Score(period: number, jamNumber?: number): number {
    if (jamNumber == null) {
      return this.data.jams.reduce((total, jam) => jam.period === period ? total + (jam.team1Score ?? 0) : total, 0);
    }
    const jam = this.data.jams.find(j => j.period === period && j.jamNumber === jamNumber);
    return jam?.team1Score ?? 0;
  }

  public toTeam2Score(period: number, jamNumber?: number): number {
    if (jamNumber == null) {
      return this.data.jams.reduce((total, jam) => jam.period === period ? total + (jam.team2Score ?? 0) : total, 0);
    }
    const jam = this.data.jams.find(j => j.period === period && j.jamNumber === jamNumber);
    return jam?.team2Score ?? 0;
  }

  private guessJam(): JamsState {
    const jams = this.data.jams;
    if (jams.length === 0) {
      return this;
    }
    const allLines = [this.data.lines1, this.data.lines2];
    const allSkaters = [this.data.skaters1, this.data.skaters2];
    const jamGuess = PredictionTools.predictNextJam(jams.slice(0, jams.length - 1), allLines, allSkaters);
    const jammer1 = Objects.isNull(jamGuess.team1JammerId) ? undefined : this.data.skaters1.find(s => s.id === jamGuess.team1JammerId);
    const jammer2 = Objects.isNull(jamGuess.team2JammerId) ? undefined : this.data.skaters2.find(s => s.id === jamGuess.team2JammerId);
    const pivot1 = Objects.isNull(jamGuess.team1PivotId) ? undefined : this.data.skaters1.find(s => s.id === jamGuess.team1PivotId);
    const pivot2 = Objects.isNull(jamGuess.team2PivotId) ? undefined : this.data.skaters2.find(s => s.id === jamGuess.team2PivotId);
    const blocker11 = Objects.isNull(jamGuess.team1Blocker1Id) ? undefined : this.data.skaters1.find(s => s.id === jamGuess.team1Blocker1Id);
    const blocker12 = Objects.isNull(jamGuess.team1Blocker2Id) ? undefined : this.data.skaters1.find(s => s.id === jamGuess.team1Blocker2Id);
    const blocker13 = Objects.isNull(jamGuess.team1Blocker3Id) ? undefined : this.data.skaters1.find(s => s.id === jamGuess.team1Blocker3Id);
    const blocker21 = Objects.isNull(jamGuess.team2Blocker1Id) ? undefined : this.data.skaters2.find(s => s.id === jamGuess.team2Blocker1Id);
    const blocker22 = Objects.isNull(jamGuess.team2Blocker2Id) ? undefined : this.data.skaters2.find(s => s.id === jamGuess.team2Blocker2Id);
    const blocker23 = Objects.isNull(jamGuess.team2Blocker3Id) ? undefined : this.data.skaters2.find(s => s.id === jamGuess.team2Blocker3Id);
    const line1 = this.data.lines1.find(l => l.id === jamGuess.team1LineId);
    const line2 = this.data.lines2.find(l => l.id === jamGuess.team2LineId);
    return new JamsState({
      ...this.data,
      skaterHighlights: [
        [[jammer1], [pivot1], [blocker11], [blocker12], [blocker13]],
        [[jammer2], [pivot2], [blocker21], [blocker22], [blocker23]],
      ],
      lineHighlights: [
        [line1],
        [line2],
      ],
    });
  }

  private maybeShowPivotOrLine(): JamsState {
    if (this.data.showPivotCalculated) {
      return this;
    }
    const lines = this.data.jams.filter(j => Objects.isNotNull(j.team1LineId) || Objects.isNotNull(j.team2LineId)).length;
    const pivots = this.data.jams.filter(j => Objects.isNotNull(j.team1PivotId) || Objects.isNotNull(j.team2PivotId)).length;
    const morePivotsThanLinesFilledIn = pivots > lines;
    return new JamsState({
      ...this.data,
      showPivotCalculated: true,
      showPivot: morePivotsThanLinesFilledIn,
    });
  }

  private recalculateScores(): JamsState {
    return new JamsState({
      ...this.data,
      periodScores: [
        [this.toTeam1Score(1), this.toTeam1Score(2)],
        [this.toTeam2Score(1), this.toTeam2Score(2)]
      ]
    });
  }
}
