import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {SessionService} from "./session.service";
import {Test} from "../models/learning/domain/test";
import {AbstractService} from "./abstract-service";
import {TestDto} from "../models/learning/dto/test-dto";
import {TestMapper} from "../models/learning/domain/test-mapper";

@Injectable({
  providedIn: 'root'
})
export class TestService extends AbstractService<Test, TestDto> {

  constructor(http: HttpClient,
              sessionService: SessionService) {
    super(http, sessionService, '/learning/tests', TestMapper.toDto, TestMapper.fromDto);
  }
}
