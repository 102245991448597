<ng-container *ngIf="!!(dialogOptions$ | async)">
  <div class="dialog-glass-pane" #glassPane>
    <div class="dialog" #dialog>
      <div class="dialog-header">
        {{ (dialogOptions$ | async)!.title }}
        <div class="dialog-close">
          <button class="button-icon-secondary" (click)="onCancel()">
            <app-icon name="close"></app-icon>
          </button>
        </div>
      </div>
      <div class="dialog-content">
        <div class="dialog-scroll">
          <div>
            <div class="dialog-message">
              {{ (dialogOptions$ | async)!.message }}
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-actions">
        <button class="button-primary" (click)="onConfirm()" #confirm>
          {{ (dialogOptions$ | async)!.confirmText }}
        </button>
        <button class="button-secondary" (click)="onCancel()">
          {{ (dialogOptions$ | async)!.cancelText }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
