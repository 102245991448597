export class LoginState {
    readonly username: string;
    readonly password: string;

    constructor(username: string, password: string) {
        this.username = username;
        this.password = password;
    }

    public static create(): LoginState {
        return new LoginState('', '');
    }

    public withUsername(username: string): LoginState {
        return new LoginState(username, this.password);
    }

    public withPassword(password: string): LoginState {
        return new LoginState(this.username, password);
    }
}
