<div class="page" *ngIf="homeRead$ | async">
  <app-loading *ngIf="(state$ | async)!.data.loading"></app-loading>
  <div class="cards" *ngIf="!(state$ | async)!.data.loading">

    <div class="home-card" *ngIf="liveRead$ | async">
      <div class="home-title">
        <app-icon name="whistle"></app-icon>
        Games
      </div>

      <div class="list">
        <ng-container *ngIf="(liveRead$ | async) && (state$ | async)!.data.liveGame as game">
          <div class="list-item-secondary" (click)="goToLive()">
            <div class="list-text-secondary">
              <div>
                {{ (state$ | async)!.gameTeam1(game.id) }}
                <span class="text-faded-secondary">vs</span>
                {{ (state$ | async)!.gameTeam2(game.id) }}
              </div>
              <div>{{ game.startDate | date }} &#64; {{ game.location }}</div>
            </div>
            <div class="list-icons">
              <div class="list-icon-primary">
                <app-icon name="live"></app-icon>
                Live Now!
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="(liveRead$ | async) && (state$ | async)!.data.upcomingGame as game">
          <div class="list-item-secondary" (click)="goToGame(game.id)">
            <div class="list-text-secondary">
              <div>
                {{ (state$ | async)!.gameTeam1(game.id) }}
                <span class="text-faded-secondary">vs</span>
                {{ (state$ | async)!.gameTeam2(game.id) }}
              </div>
              <div>{{ game.startDate | date:"yyyy-MM-dd HH:mm" }} &#64; {{ game.location }}</div>
            </div>
            <div class="list-icons">
              <div class="list-icon-secondary">
                <app-icon name="whistle"></app-icon>
                Upcoming
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="(liveRead$ | async) && (state$ | async)!.data.otherGames.length > 0">
          <div class="list-item-secondary" (click)="goToGames()">
            <div class="list-text-more">
              Explore all games
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="home-card" *ngIf="(testsRead$ | async)">
      <div class="home-title">
        <app-icon name="psychology"></app-icon>
        Study Corner
      </div>

      <div class="list">
        <ng-container *ngIf="(state$ | async)!.data.tests.length > 0">
          <div *ngFor="let test of (state$ | async)!.data.mandatoryTests; let i = index"
               class="list-item-secondary"
               (click)="goToTest(test.id)">
            <div class="list-text-secondary">
              <div>{{ test.displayName }}</div>
              <div>{{ test.description }}</div>
            </div>
            <div class="list-icons">
              <div class="list-icon-primary">
                <app-icon name="edit"></app-icon>
                Fill In
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="(liveRead$ | async) && (state$ | async)!.data.otherTests.length > 0">
          <div class="list-item-secondary" (click)="goToTests()">
            <div class="list-text-more">
              Discover all practice tests
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
