import {Component, ElementRef, HostListener, OnDestroy, ViewChild} from "@angular/core";
import {DialogService} from "../../services/dialog.service";
import {BehaviorSubject} from "rxjs";
import {DialogOptions} from "../../models/dialog-options";
import {Subscriptions} from "../../tools/subscriptions";


@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnDestroy {

  @ViewChild("dialog") dialog!: ElementRef;
  @ViewChild("glassPane") glassPane!: ElementRef;
  @ViewChild("confirm") confirm!: ElementRef;

  dialogOptions$: BehaviorSubject<DialogOptions | undefined> = new BehaviorSubject<DialogOptions | undefined>(undefined);
  private subscriptions: Subscriptions = new Subscriptions();

  constructor(private dialogService: DialogService) {
    this.subscriptions.add(this.dialogService.dialogOptions$.subscribe(dialogOptions => {
      this.dialogOptions$.next(dialogOptions);
      if (!dialogOptions) {
        return;
      }
      setTimeout(() => this.confirm.nativeElement.focus());
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onConfirm(): void {
    const onConfirm = this.dialogOptions$.getValue()?.onConfirm;
    this.dialogService.reset();
    if (onConfirm) {
      onConfirm();
    }
  }

  public onCancel(): void {
    const onCancel = this.dialogOptions$.getValue()?.onCancel;
    this.dialogService.reset();
    if (onCancel) {
      onCancel();
    }
  }

  @HostListener('document:mouseup', ['$event'])
  handleDocumentClick(event: MouseEvent): void {
    if (!this.dialog || !this.glassPane) {
      return;
    }
    const glassClick = this.glassPane.nativeElement.contains(event.target);
    const dialogClick = this.dialog.nativeElement.contains(event.target);
    if (glassClick && !dialogClick) {
      this.onCancel();
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleEscapePress(event: KeyboardEvent): void {
    if (!this.dialog || !this.glassPane) {
      return;
    }
    this.onCancel();
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterPress(event: KeyboardEvent): void {
    if (!this.dialog || !this.glassPane) {
      return;
    }
    this.onConfirm();
  }
}
