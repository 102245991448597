<div class="date-container">
  <div class="date-fields">
    <input type="number" class="field-text width-4"
           [disabled]="disabled$ | async"
           [value]="yearString"
           (input)="withYearString($event)"/>
    <span>-</span>
    <input type="number" class="field-text width-2"
           [disabled]="disabled$ | async"
           [value]="monthString"
           (input)="withMonthString($event)"/>
    <span>-</span>
    <input type="number" class="field-text width-2"
           [disabled]="disabled$ | async"
           [value]="dayString"
           (input)="withDayString($event)"/>
    <span>&nbsp;</span>
    <input type="number" class="field-text width-2"
           [disabled]="disabled$ | async"
           [value]="hourString"
           (input)="withHourString($event)"/>
    <span>:</span>
    <input type="number" class="field-text width-2"
           [disabled]="disabled$ | async"
           [value]="minuteString"
           (input)="withMinuteString($event)"/>

  </div>
  <div class="date-buttons" *ngIf="!(disabled$ | async)">
    <button class="button-icon-secondary" (click)="today()">
      <app-icon name="today"></app-icon>
    </button>
    <button class="button-icon-secondary" (click)="clear()">
      <app-icon name="clear"></app-icon>
    </button>
  </div>
</div>
