<div class="page">
  <app-loading *ngIf="(state$ | async)!.data.loading"></app-loading>
  <ng-container *ngIf="!(state$ | async)!.data.loading">
    <ng-container *ngTemplateOutlet="user"></ng-container>
  </ng-container>
</div>

<ng-template #user>
  <div class="card">
    <div class="form">
      <div class="field">
        <app-auto-complete-distraction></app-auto-complete-distraction>
        <label class="field-label" for="profile-test">Username</label>
        <input type="text" class="field-text" id="profile-test"
               autocomplete="profile-test"
               [value]="(state$ | async)!.data.username"
               (input)="withUsername($event)"/>
        <div class="field-error" *ngIf="(state$ | async)!.data.usernameError">
          {{(state$ | async)!.data.usernameError}}
        </div>
      </div>
      <div class="field">
        <label class="field-label" for="displayName">Derby Name</label>
        <input type="text" class="field-text" id="displayName"
               [value]="(state$ | async)!.data.displayName"
               (input)="withDisplayName($event)"/>
        <div class="field-error" *ngIf="(state$ | async)!.data.displayNameError">
          {{(state$ | async)!.data.displayNameError}}
        </div>
      </div>
      <div class="field">
        <label class="field-label" for="pronouns">Pronouns</label>
        <input type="text" class="field-text" id="pronouns"
               [value]="(state$ | async)!.data.pronouns"
               (input)="withPronouns($event)"/>
      </div>
      <div class="field">
        <label class="field-label" for="profile-test2">
          Password
        </label>
        <div class="field-text-with-actions">
          <input type="password" class="field-text" id="profile-test2"
                 autocomplete="profile-test2"
                 [value]="(state$ | async)!.data.password"
                 (input)="withPassword($event)"/>
          <div class="field">
            <app-checkbox text="Temporary" [checked]="(state$ | async)!.data.isTemporaryPassword"
                          (checked)="withIsTemporaryPassword($event)"></app-checkbox>
          </div>
          <button class="button-icon-secondary" (click)="regeneratePassword()">
            <app-icon name="refresh_auto"></app-icon>
          </button>
        </div>
        <div class="field-info" *ngIf="(state$| async)!.data.isTemporaryPassword">
          This is a temporary password that must be changed on first login.
        </div>
      </div>
      <div class="field">
        <div class="field-columns-50-50">
          <div class="field-group">
            <div class="field">
              <app-checkbox text="Administrator" [checked]="(state$ | async)!.data.isAdministrator"
                            (checked)="withIsAdministrator($event)"></app-checkbox>
            </div>
            <div class="field">
              <app-checkbox text="Skater" [checked]="(state$ | async)!.data.isSkater"
                            (checked)="withIsSkater($event)"></app-checkbox>
            </div>
            <div class="field">
              <app-checkbox text="Contact Safe" [checked]="(state$ | async)!.data.isContactSafe"
                            [disabled]="!(state$ | async)!.data.isSkater"
                            (checked)="withIsContactSafe($event)"></app-checkbox>
            </div>
          </div>
          <div class="field-group">
            <div class="field">
              <app-checkbox text="Trainer" [checked]="(state$ | async)!.data.isTrainer"
                            (checked)="withIsTrainer($event)"></app-checkbox>
            </div>
            <div class="field">
              <app-checkbox text="Bench Crew" [checked]="(state$ | async)!.data.isBench"
                            (checked)="withIsBench($event)"></app-checkbox>
            </div>
          </div>
        </div>
        <div class="field-error" *ngIf="(state$| async)!.data.isRoleError">
          {{(state$ | async)!.data.isRoleError}}
        </div>
      </div>
    </div>
  </div>

  <div class="card" *ngIf="(state$ | async)!.data.user">
    <div class="card-header">
      <div class="subtitle">
        <app-icon name="session"></app-icon>
        Active Sessions
      </div>
    </div>

    <div class="list">
      <div class="session-item" *ngFor="let session of (state$ | async)!.data.sessions">
        <div class="list-text-secondary">
          <div>{{ session.ipAddress }}</div>
          <div>{{ session.clientId }}</div>
        </div>
        <div class="list-text-secondary">
          {{ session.activeOn }}
        </div>
        <div class="list-actions">
          <button class="list-action-icon" (click)="deleteSession(session.id)">
            <app-icon name="delete"></app-icon>
          </button>
        </div>
      </div>
      <div class="list-item-secondary" *ngIf="(state$ | async)!.data.sessions.length === 0">
        <div class="list-text-message">
          No active sessions found
        </div>
      </div>
    </div>
  </div>
</ng-template>
