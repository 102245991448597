import {UserSession} from "../models/user-session";
import {Scope} from "../models/scope";

export class SessionTools {

  public static hasScope(session: UserSession | undefined, scope: Scope): boolean {
    const scopeString = scope as string;
    if (!scopeString) {
      console.error("Unknown scope: ", scope);
      return false;
    }
    return !!session && session.scopes.includes(scopeString);
  }
}
