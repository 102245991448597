import {Component} from "@angular/core";
import {StateSubject} from "../../tools/state.subject";
import {NotificationService} from "../../services/notification.service";
import {Router} from "@angular/router";
import {Message} from "../../models/message";
import {UserService} from "../../services/user.service";
import {UsersState} from "./users.state";
import {RouterTools} from "../../tools/router.tools";
import {PageService} from "../../services/page.service";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent {

  state$: StateSubject<UsersState> = new StateSubject<UsersState>(UsersState.create());

  constructor(private userService: UserService,
              private notificationService: NotificationService,
              private pageService: PageService,
              private router: Router) {
    this.pageService.invoke(page => page.reset('Manage Users')
      .withShowMenu(true)
      .withBackButton(() => RouterTools.goHome(this.router))
      .withSearch((query?: string) => this.search(query))
      .withActions([
        {
          text: 'Add User',
          icon: 'add',
          visible: true,
          enabled: true,
          active: true,
          primary: true,
          onClick: () => this.newUser()
        }
      ])
    );
  }

  ngOnInit(): void {
    this.refresh();
  }

  public refresh(): void {
    this.userService.getUsers().subscribe({
      next: users => this.state$.invoke(state => state.refresh(users)),
      error: () => {
        this.notificationService.show(Message.error('Failed to load users'));
        this.state$.invoke(state => state.withLoading(false));
      }
    });
  }

  public search(value: string | undefined): void {
    this.state$.invoke(state => state.withSearch(value));
  }

  public goToUser(userId: number): void {
    RouterTools.goToUser(this.router, userId);
  }

  public newUser(): void {
    RouterTools.goToUserNew(this.router);
  }
}
