import {Injectable} from "@angular/core";
import {DialogOptions} from "../models/dialog-options";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  private _dialogOptions$: BehaviorSubject<DialogOptions | undefined> = new BehaviorSubject<DialogOptions | undefined>(undefined);

  constructor() {
  }

  public get dialogOptions$(): Observable<DialogOptions | undefined> {
    return this._dialogOptions$.asObservable();
  }

  public reset(): void {
    this._dialogOptions$.next(undefined);
  }

  public show(options: DialogOptions): void {
    this._dialogOptions$.next(options);
  }
}
