import {Component, OnInit} from "@angular/core";
import {TeamState} from "./team.state";
import {LeagueService} from "../../services/league.service";
import {TeamService} from "../../services/team.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Message} from "../../models/message";
import {NotificationService} from "../../services/notification.service";
import {RouterTools} from "../../tools/router.tools";
import {Skater} from "../../models/skater";
import {StateSubject} from "../../tools/state.subject";
import {forkJoin, of} from "rxjs";
import {TeamLine} from "../../models/team-line";
import {DialogOptions} from "../../models/dialog-options";
import {DialogService} from "../../services/dialog.service";
import {PageService} from "../../services/page.service";
import {Tabs} from "../../models/tabs";
import {Tab} from "../../models/tab";

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  state$: StateSubject<TeamState> = new StateSubject<TeamState>(TeamState.create());

  constructor(private leagueService: LeagueService,
              private teamService: TeamService,
              private route: ActivatedRoute,
              private pageService: PageService,
              private router: Router,
              private notificationService: NotificationService,
              private dialogService: DialogService) {
    this.state$.subscribe(state => {
      this.pageService.invoke(page => page.reset(state.data.team?.displayName || 'New Team')
        .withBackButton(() => RouterTools.goToLeague(this.router, state.data.leagueId!))
        .withTabs(Tabs.of([
          Tab.of("Info", true),
          Tab.of("Lines", !!state.data.team, () => RouterTools.goToTeamLines(this.router, state.data.leagueId!, state.data.teamId!)),
          Tab.of("Skaters", !!state.data.team, () => RouterTools.goToSkaters(this.router, state.data.leagueId!, state.data.teamId!)),
        ], 0))
        .withActions([
          {
            text: 'Save',
            icon: 'save',
            visible: state.canEdit,
            enabled: state.canSave,
            active: true,
            primary: true,
            onClick: () => this.save()
          },
          {
            text: 'Delete',
            icon: 'delete',
            visible: state.canDelete,
            enabled: !!state.data.team?.id,
            active: false,
            primary: false,
            onClick: () => this.delete()
          }
        ]))
    });
  }

  ngOnInit(): void {
    RouterTools.observeParamMap(this.route, params => {
      const leagueId = RouterTools.toNumber(params, 'leagueId');
      const teamId = RouterTools.toNumber(params, 'teamId');
      this.state$.invoke(state => state.withQueryIds(leagueId, teamId));
    });
    this.state$.get(state => state.data.leagueId + ' ' + state.data.teamId).subscribe(() => {
      this.refresh();
    });
  }

  public refresh(): void {
    const state = this.state$.getValue();
    const leagueId = state.data.leagueId;
    const teamId = state.data.teamId;

    forkJoin({
      league: !!leagueId ? this.leagueService.getLeague(leagueId) : of(undefined),
      team: !!teamId ? this.teamService.getTeam(teamId) : of(undefined),
      actions: this.teamService.getActions(teamId),
    }).subscribe({
      next: ({league, team, actions}) => {
        this.state$.invoke(state => state.refresh(league, team, actions));
      },
      error: () => {
        this.notificationService.show(Message.error('Failed to load team'));
        this.state$.invoke(state => state.withLoading(false));
      }
    });
  }

  public withDisplayName(event: any): void {
    this.state$.invoke(state => state.withDisplayName(event.target.value));
  }

  public save(): void {
    const state = this.state$.getValue();
    if (!state.data.team) {
      const newTeam = {
        displayName: state.data.displayName,
        leagueId: state.data.leagueId,
      };
      this.teamService.createTeam(newTeam).subscribe({
        next: team => {
          RouterTools.goToTeam(this.router, state.data.leagueId!, team.id);
        },
        error: () => {
          this.notificationService.show(Message.error('Failed to create team'));
        }
      });
      return;
    }

    const updatedTeam = {
      id: state.data.team.id,
      displayName: state.data.displayName,
      leagueId: state.data.leagueId,
    };
    this.teamService.updateTeam(state.data.team.id, updatedTeam).subscribe({
      next: () => this.refresh(),
      error: () => {
        this.notificationService.show(Message.error('Failed to update team'));
      }
    });
  }

  public delete(): void {
    this.dialogService.show(DialogOptions
      .create('Delete Team', 'Are you sure you want to delete this team?')
      .withConfirm(() => this.doDelete()));
  }

  private doDelete(): void {
    const state = this.state$.getValue();
    if (!state.data.team) {
      return;
    }
    this.teamService.deleteTeam(state.data.team.id).subscribe({
      next: () => {
        RouterTools.goToLeague(this.router, state.data.leagueId!);
        this.notificationService.show(Message.success('Team deleted'));
      },
      error: () => {
        this.notificationService.show(Message.error('Failed to delete team'));
      }
    });
  }

  public goToTeamLine(teamLine: TeamLine) {
    const leagueId = this.state$.getValue().data.leagueId!;
    const teamId = this.state$.getValue().data.teamId!;
    RouterTools.goToTeamLine(this.router, leagueId, teamId, teamLine.id);
  }

  public goToSkater(skater: Skater) {
    const leagueId = this.state$.getValue().data.leagueId!;
    RouterTools.goToSkater(this.router, leagueId, skater.teamId, skater.id);
  }
}
