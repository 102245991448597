<div class="page">
  <app-loading *ngIf="(state$ | async)!.data.loading"></app-loading>
  <div class="card" *ngIf="!(state$ | async)!.data.loading">
    <div class="form">
      <div class="field-columns-50-50">
        <div class="fields">
          <div class="field">
            <label class="field-label">Team 1</label>
            <app-select title="Select League"
                        [disabled]="!(state$ | async)!.canEdit"
                        [selection]="(state$ | async)!.data.league1"
                        [renderer]="leagueRenderer()"
                        (selectEvent)="withLeague1($event)"
                        [values]="(state$ | async)!.data.leagues"></app-select>
          </div>
          <app-select title="Select Team"
                      [disabled]="!(state$ | async)!.canEdit"
                      [selection]="(state$ | async)!.data.team1"
                      [renderer]="teamRenderer()"
                      (selectEvent)="withTeam1($event)"
                      [values]="(state$ | async)!.data.teams1"></app-select>
        </div>
        <div class="fields">
          <div class="field">
            <label class="field-label">Team 2</label>
            <app-select title="Select League"
                        [disabled]="!(state$ | async)!.canEdit"
                        [selection]="(state$ | async)!.data.league2"
                        [renderer]="leagueRenderer()"
                        (selectEvent)="withLeague2($event)"
                        [values]="(state$ | async)!.data.leagues"></app-select>
          </div>
          <app-select title="Select Team"
                      [disabled]="!(state$ | async)!.canEdit"
                      [selection]="(state$ | async)!.data.team2"
                      [renderer]="teamRenderer()"
                      (selectEvent)="withTeam2($event)"
                      [values]="(state$ | async)!.data.teams2"></app-select>
        </div>
      </div>

      <div class="field-columns-50-50">
        <div class="field">
          <label class="field-label">Team 1 Color</label>
          <app-select title="Select Color"
                      [disabled]="!(state$ | async)!.canEdit"
                      [selection]="(state$ | async)!.data.color1"
                      [renderer]="colorRenderer()"
                      (selectEvent)="withColor1($event)"
                      [values]="(state$ | async)!.data.colors"></app-select>
        </div>
        <div class="field">
          <label class="field-label">Team 2 Color</label>
          <app-select title="Select Color"
                      [disabled]="!(state$ | async)!.canEdit"
                      [selection]="(state$ | async)!.data.color2"
                      [renderer]="colorRenderer()"
                      (selectEvent)="withColor2($event)"
                      [values]="(state$ | async)!.data.colors"></app-select>
        </div>
      </div>

      <div class="field">
        <label class="field-label" for="displayName">Location</label>
        <input type="text" class="field-text" id="displayName"
               [disabled]="!(state$ | async)!.canEdit"
               [value]="(state$ | async)!.data.location"
               (input)="withLocation($event)"/>
        <div class="field-error" *ngIf="(state$ | async)!.data.locationError">
          {{(state$ | async)!.data.locationError}}
        </div>
      </div>
      <div class="field">
        <label class="field-label">Start Date</label>
        <app-date [date]="(state$ | async)!.data.startDate"
                  (date)="withStartDate($event)"
                  [disabled]="!(state$ | async)!.canEdit"></app-date>
      </div>


      <div class="field">
        <label class="field-label">Live Stream URL</label>
        <div class="field-text-with-actions">
          <input type="text" class="field-text"
                 [disabled]="!(state$ | async)!.canEdit"
                 [value]="(state$ | async)!.data.liveStreamUrl"
                 (input)="withLiveStreamUrl($event)"/>
          <button class="button-icon-secondary"
                  [disabled]="!(state$ | async)!.data.liveStreamUrl"
                  (click)="goToLiveStream()">
            <app-icon name="open"></app-icon>
          </button>
        </div>
        <div class="field-error" *ngIf="(state$ | async)!.data.liveStreamUrlError">
          {{(state$ | async)!.data.liveStreamUrlError}}
        </div>
      </div>
    </div>

    <div class="field-group">
      <div class="field">
        <app-checkbox text="Live"
                      textFaded="Show in Live Game Stats"
                      [disabled]="!(state$ | async)!.canEdit"
                      [checked]="(state$ | async)!.data.isLive"
                      (checked)="withIsLive($event)"></app-checkbox>
      </div>
      <div class="field">
        <app-checkbox text="Finished"
                      textFaded="Disable editing jam data"
                      [disabled]="!(state$ | async)!.canEdit"
                      [checked]="(state$ | async)!.data.isFinished"
                      (checked)="withIsFinished($event)"></app-checkbox>
      </div>
    </div>
  </div>
</div>
