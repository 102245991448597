import {Test} from "../../models/learning/domain/test";
import {TestQuestion} from "../../models/learning/domain/test-question";
import {Action} from "../../models/action";
import {TestQuestionAnswer} from "../../models/learning/domain/test-question-answer";

export interface TestQuestionUploadData {
  testId?: number;
  test?: Test;
  questions: TestQuestion[];
  actions: Action[];
  text?: string;
  textError?: string;
  textInfo?: string;
  candidates: Partial<TestQuestion>[];
  dirty: boolean;
  isUploading: boolean;
  loading: boolean;
}

export class TestQuestionUploadState {
  readonly data: TestQuestionUploadData;

  constructor(data: TestQuestionUploadData) {
    this.data = data;
  }

  public static create(): TestQuestionUploadState {
    return new TestQuestionUploadState({
      testId: undefined,
      test: undefined,
      questions: [],
      actions: [],
      text: '',
      candidates: [],
      dirty: false,
      isUploading: false,
      loading: true,
    });
  }

  public get canEdit(): boolean {
    return this.data.actions.includes(Action.EDIT) && !this.data.isUploading;
  }

  public get canSave(): boolean {
    return this.canEdit && this.data.dirty && !this.data.textError;
  }

  public candidateAnswers(index: number): TestQuestionAnswer[] {
    const candidate = this.data.candidates[index];
    return candidate.answers || [];
  }

  public questionAnswers(index: number): TestQuestionAnswer[] {
    const question = this.data.questions[index];
    return question.answers || [];
  }

  public refresh(test: Test | undefined, questions: TestQuestion[], actions: Action[]): TestQuestionUploadState {
    return new TestQuestionUploadState({
      ...this.data,
      test: test,
      actions: actions,
      questions: questions,
      text: '',
      candidates: [],
      dirty: false,
      isUploading: false,
      loading: false,
    });
  }

  public withLoading(loading: boolean): TestQuestionUploadState {
    return new TestQuestionUploadState({
      ...this.data,
      loading: loading,
    });
  }

  public withQueryId(testId: number | undefined): TestQuestionUploadState {
    return new TestQuestionUploadState({
      ...this.data,
      testId: testId,
    });
  }

  public withIsUploading(isUploading: boolean): TestQuestionUploadState {
    return new TestQuestionUploadState({
      ...this.data,
      isUploading: isUploading,
    });
  }

  public withCandidateUploaded(candidate: Partial<TestQuestion>, question: TestQuestion): TestQuestionUploadState {
    return new TestQuestionUploadState({
      ...this.data,
      candidates: this.data.candidates.filter(c => c !== candidate),
      questions: [...this.data.questions, question].sort((a, b) => a.question.localeCompare(b.question)),
      dirty: true,
    });
  }

  public withText(text: string): TestQuestionUploadState {
    return new TestQuestionUploadState({
      ...this.data,
      text: text,
    }).calculateCandidates();
  }

  private calculateCandidates(): TestQuestionUploadState {
    if (!this.data.test) {
      return new TestQuestionUploadState({
        ...this.data,
        candidates: [],
      });
    }

    const text = this.data.text || '';
    const lines = text.trim().split('\n');
    const candidates: Partial<TestQuestion>[] = [];
    const questionTexts = this.data.questions.map(q => q.question.toLowerCase());
    let incompleteLines = [];
    let duplicateQuestions = [];
    for (let i = 0; i < lines.length; i++) {

      // Skip empty lines
      const line = lines[i].trim();
      if (!line) {
        continue;
      }

      // Skip incomplete lines
      const parts = line.split(';');
      if (parts.length <= 1) {
        incompleteLines.push(i + 1);
        continue;
      }

      // Skip incomplete parts
      let incompletePart = false;
      for (let j = 0; j < parts.length && !incompletePart; j++) {
        parts[j] = parts[j].trim();
        if (!parts[j]) {
          incompletePart = true;
        }
      }
      if (incompletePart) {
        incompleteLines.push(i + 1);
        continue;
      }

      // Skip duplicate questions
      if (questionTexts.includes(parts[0].toLowerCase())) {
        duplicateQuestions.push(i + 1);
        continue;
      }

      const questionText = parts[0];
      const answers = parts.slice(1);
      const answerArray: TestQuestionAnswer[] = [];
      let hasCorrect = false;
      for (let j = 0; j < answers.length; j++) {
        const answer = answers[j];
        let answerText = answer;
        let isCorrect = false;
        if (answer.startsWith('correct:')) {
          answerText = answer.substring(8);
          isCorrect = true;
          hasCorrect = true;
        }
        answerArray.push({
          text: answerText,
          isCorrect: isCorrect,
        });
      }

      if (!hasCorrect) {
        incompleteLines.push(i + 1);
        continue;
      }

      const candidate: Partial<TestQuestion> = {
        id: undefined,
        testId: this.data.test.id,
        question: questionText,
        answers: answerArray,
      } as Partial<TestQuestion>;
      candidates.push(candidate);
      questionTexts.push(questionText.toLowerCase());
    }
    return new TestQuestionUploadState({
      ...this.data,
      candidates: candidates,
      dirty: candidates.length > 0,
      textError: incompleteLines.length > 0 ? 'Incomplete or faulty questions found on lines: ' + incompleteLines.join(', ') : undefined,
      textInfo: duplicateQuestions.length > 0 ? 'Skipped duplicate questions found on lines: ' + duplicateQuestions.join(', ') : undefined,
    });
  }
}
