import {League} from "../../models/league";
import {Team} from "../../models/team";
import {Action} from "../../models/action";

export interface LeagueData {
  leagueId?: number;
  league?: League;
  teams: Team[];
  actions: Action[];
  displayName: string;
  displayNameError: string;
  home: boolean;
  dirty: boolean;
  valid: boolean;
  loading: boolean;
}

export class LeagueState {
  readonly data: LeagueData;

  constructor(data: LeagueData) {
    this.data = data;
  }

  public static create(): LeagueState {
    return new LeagueState({
      leagueId: undefined,
      league: undefined,
      teams: [],
      actions: [],
      displayName: '',
      displayNameError: '',
      home: false,
      dirty: false,
      valid: true,
      loading: true,
    });
  }

  public get canDelete(): boolean {
    return this.data.actions.includes(Action.DELETE);
  }

  public get canEdit(): boolean {
    return this.data.actions.includes(Action.EDIT);
  }

  public get canSave(): boolean {
    return this.data.dirty && this.data.valid;
  }

  public refresh(league: League | undefined, teams: Team[], actions: Action[]): LeagueState {
    return new LeagueState({
      ...this.data,
      league: league,
      teams: teams.sort((a, b) => a.displayName.localeCompare(b.displayName)),
      actions: actions,
      displayName: !league ? '' : league.displayName,
      home: !league ? false : league.home,
      dirty: false,
      loading: false,
    }).validate();
  }

  public withLoading(loading: boolean): LeagueState {
    return new LeagueState({
      ...this.data,
      loading: loading,
    });
  }

  public withQueryId(leagueId?: number): LeagueState {
    return new LeagueState({
      ...this.data,
      leagueId: leagueId,
    });
  }

  public withDisplayName(displayName: string): LeagueState {
    return new LeagueState({
      ...this.data,
      displayName: displayName,
      dirty: true,
    }).validate();
  }

  private validate(): LeagueState {
    let displayNameError = '';
    if (!this.data.displayName) {
      displayNameError = 'League name is required';
    } else if (this.data.displayName.length < 3) {
      displayNameError = 'League name must be at least 3 characters';
    }

    return new LeagueState({
      ...this.data,
      displayNameError: displayNameError,
      valid: !displayNameError,
    });
  }
}
