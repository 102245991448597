<div class="page">
  <app-loading *ngIf="(state$ | async)!.data.loading"></app-loading>
  <div class="cards" *ngIf="!(state$ | async)!.data.loading">
    <div class="card">
      <div class="card-header">
        <div class="subtitle">
          <app-icon name="skate"></app-icon>
          Line Info
        </div>
      </div>
      <div class="form">
        <div class="field">
          <label class="field-label" for="displayName">Display Name</label>
          <input type="text" class="field-text" id="displayName"
                 [disabled]="!(state$ | async)!.canEdit"
                 [value]="(state$ | async)!.data.displayName"
                 (input)="withDisplayName($event)"/>
          <div class="field-error" *ngIf="(state$ | async)!.data.displayNameError">
            {{(state$ | async)!.data.displayNameError}}
          </div>
        </div>
        <div class="field-group">
          <div class="field">
            <app-checkbox text="Active"
                          textFaded="Available for new games"
                          [disabled]="!(state$ | async)!.canEdit"
                          [checked]="(state$ | async)!.data.isActive"
                          (checked)="withIsActive($event)"></app-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
