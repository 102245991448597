import {Component, OnInit} from "@angular/core";
import {TestService} from "../../services/test.service";
import {NotificationService} from "../../services/notification.service";
import {Message} from "../../models/message";
import {ActivatedRoute, Router} from "@angular/router";
import {RouterTools} from "../../tools/router.tools";
import {StateSubject} from "../../tools/state.subject";
import {forkJoin, Observable, of} from "rxjs";
import {TestQuestionService} from "../../services/test-question.service";
import {SessionService} from "../../services/session.service";
import {Scope} from "../../models/scope";
import {Objects} from "../../tools/objects";
import {Tabs} from "../../models/tabs";
import {Tab} from "../../models/tab";
import {PageService} from "../../services/page.service";
import {PageButton} from "../../models/page.state";
import {TestQuestionsState} from "./test-questions.state";

@Component({
  selector: 'app-test-questions',
  templateUrl: './test-questions.component.html',
  styleUrls: ['./test-questions.component.scss']
})
export class TestQuestionsComponent implements OnInit {

  state$: StateSubject<TestQuestionsState> = new StateSubject<TestQuestionsState>(TestQuestionsState.create());
  testsManage$: Observable<boolean>;

  constructor(private testService: TestService,
              private testQuestionService: TestQuestionService,
              private notificationService: NotificationService,
              private sessionService: SessionService,
              private pageService: PageService,
              private route: ActivatedRoute,
              private router: Router) {
    this.state$.subscribe(state => this.pageService.invoke(page => {
      const tabs: Tab[] = [
        Tab.of("Info", true, () => RouterTools.goToTest(this.router, state.data.testId!)),
        Tab.of("Questions", true)
      ];
      return page.reset(Objects.isNotNull(state.data.testId) ? state.data.test?.displayName : 'Test')
        .withBackButton(() => RouterTools.goToTests(this.router))
        .withTabs(Tabs.of(tabs, 1))
        .withActions(this.getButtons(state));
    }));
    this.testsManage$ = this.sessionService.hasScope(Scope.TESTS_MANAGE);
  }

  private getButtons(state: TestQuestionsState): PageButton[] {
    return [
      {
        text: 'New Question',
        icon: 'add',
        visible: state.canEdit,
        enabled: true,
        active: true,
        primary: true,
        onClick: () => this.newQuestion()
      },
      {
        text: 'Copy All',
        icon: 'copy',
        visible: state.canEdit,
        enabled: true,
        active: false,
        primary: false,
        onClick: () => this.downloadQuestions()
      },
      {
        text: 'Upload Questions',
        icon: 'upload',
        visible: state.canEdit,
        enabled: true,
        active: false,
        primary: false,
        onClick: () => this.uploadQuestions()
      }
    ];
  }

  ngOnInit(): void {
    RouterTools.observeParamMap(this.route, params => {
      const testId = RouterTools.toNumber(params, 'testId');
      this.state$.invoke(state => state.withQueryId(testId));
    });
    this.state$.get(state => '' + state.data.testId).subscribe(() => {
      this.refresh();
    });
  }

  public refresh(): void {
    const state = this.state$.getValue();
    const testId = state.data.testId;
    const userId = Objects.toNumber(this.sessionService.session!.userId)!;

    forkJoin([
      testId ? this.testService.oneById(testId) : of(undefined),
      testId ? this.testQuestionService.allByTestId(testId) : of([]),
      this.testService.getActions(testId),
    ]).subscribe({
      next: ([test, questions, actions]) => {
        this.state$.invoke(state => state.refresh(userId, test, questions, actions));
      },
      error: () => {
        this.notificationService.show(Message.error(`Failed to load test`));
        this.state$.invoke(state => state.withLoading(false));
      }
    });
  }

  public goToQuestion(testQuestionId: number): void {
    const state = this.state$.getValue();
    RouterTools.goToTestQuestion(this.router, state.data.testId!, testQuestionId);
  }

  public newQuestion(): void {
    const state = this.state$.getValue();
    if (!state.data.test || !state.data.testId) {
      return;
    }
    RouterTools.goToTestQuestionNew(this.router, state.data.testId);
  }

  public downloadQuestions(): void {
    const state = this.state$.getValue();
    if (!state.data.test || !state.data.testId) {
      return;
    }

    const questionTest = state.toQuestionText();
    navigator.clipboard.writeText(questionTest || '').then(() => {
        this.notificationService.show(Message.success('Copied questions to clipboard'));
      }
    );
  }

  public uploadQuestions(): void {
    const state = this.state$.getValue();
    if (!state.data.test || !state.data.testId) {
      return;
    }
    RouterTools.goToTestQuestionUpload(this.router, state.data.testId);
  }
}
