import {Test} from "../../models/learning/domain/test";
import {TestQuestion} from "../../models/learning/domain/test-question";
import {Objects} from "../../tools/objects";

export interface CardData {
  questionIndex: number;
  hard: number;
  good: number;
  easy: number;
}

export interface TestStudyData {
  testId?: number;
  test?: Test;
  questions: TestQuestion[];
  cards: CardData[];
  question: string;
  answers: string[];
  rationale: string;
  isAnswerVisible: boolean;
  isRationaleVisible: boolean;
  loading: boolean;
}

export class TestStudyState {

  public static HARD_DELTA = 10;
  public static GOOD_DELTA = 50;

  readonly data: TestStudyData;

  constructor(data: TestStudyData) {
    this.data = data;
  }

  public static create(): TestStudyState {
    return new TestStudyState({
      testId: undefined,
      test: undefined,
      questions: [],
      cards: [],
      question: '',
      answers: [],
      rationale: '',
      isAnswerVisible: false,
      isRationaleVisible: false,
      loading: true,
    });
  }

  public get newCount(): number {
    return this.data.cards.filter(card => card.hard === 0 && card.good === 0 && card.easy === 0).length;
  }

  public get progressCount(): number {
    return this.data.cards.length - this.newCount;
  }

  public get doneCount(): number {
    return this.data.questions.length - this.data.cards.length;
  }

  public get question(): string {
    return this.data.cards.length > 0 ? this.data.questions[this.data.cards[0].questionIndex].question : '';
  }

  public get answers(): string[] {
    if (this.data.cards.length === 0) {
      return [];
    }
    const question = this.data.questions[this.data.cards[0].questionIndex];
    return question.answers.filter(answer => answer.isCorrect).map(answer => answer.text).sort((a, b) => a.localeCompare(b));
  }

  public get rationale(): string {
    return this.data.cards.length > 0 && this.data.questions[this.data.cards[0].questionIndex].rationale !== undefined ? this.data.questions[this.data.cards[0].questionIndex].rationale! : '';
  }

  public refresh(test: Test | undefined, questions: TestQuestion[], cards: CardData[] | undefined): TestStudyState {
    return new TestStudyState({
      ...this.data,
      test: test,
      questions: questions.sort((a, b) => a.question.localeCompare(b.question)),
      cards: this.validateCards(cards) ? cards! : this.newCards(questions),
      isAnswerVisible: false,
      isRationaleVisible: false,
      loading: false,
    });
  }

  public withLoading(loading: boolean): TestStudyState {
    return new TestStudyState({
      ...this.data,
      loading: loading,
    });
  }

  public withQueryId(testId?: number): TestStudyState {
    return new TestStudyState({
      ...this.data,
      testId: testId,
    });
  }

  public showAnswer(): TestStudyState {
    return new TestStudyState({
      ...this.data,
      isAnswerVisible: true,
      isRationaleVisible: !Objects.isEmpty(this.rationale)
    });
  }

  public onHard(): TestStudyState {
    return this.moveFirstCard(TestStudyState.HARD_DELTA, 1, 0, 0);
  }

  public onGood(): TestStudyState {
    return this.moveFirstCard(TestStudyState.GOOD_DELTA, 0, 1, 0);
  }

  public onEasy(): TestStudyState {
    return this.moveFirstCard(Number.MAX_VALUE, 0, 0, 1);
  }

  private validateCards(candidates: CardData[] | undefined): boolean {
    return !!candidates && candidates.length > 0 && candidates.filter(card => {
      const hasProperties = card && card.hasOwnProperty('questionIndex') && card.hasOwnProperty('hard') && card.hasOwnProperty('good') && card.hasOwnProperty('easy');
      if (!hasProperties) {
        return false;
      }
      const isQuestionNumber = Objects.toNumber(card.questionIndex);
      const isHardNumber = Objects.toNumber(card.hard);
      const isGoodNumber = Objects.toNumber(card.good);
      const isEasyNumber = Objects.toNumber(card.easy);
      return isQuestionNumber !== undefined && isHardNumber !== undefined && isGoodNumber !== undefined && isEasyNumber !== undefined;
    }).length === candidates.length;
  }

  private newCards(questions: TestQuestion[]): CardData[] {
    return questions.map((question, i) => ({
      questionIndex: i,
      hard: 0,
      good: 0,
      easy: 0,
    }));
  }

  private moveFirstCard(delta: number, hardDelta: number, goodDelta: number, easyDelta: number): TestStudyState {
    const cards = this.data.cards.slice();
    const card = cards.shift();
    if (!card) {
      return this;
    }

    if (card.easy + easyDelta < 1) {
      const index = Math.min(delta, cards.length);
      cards.splice(index, 0, {
        ...card,
        hard: card.hard + hardDelta,
        good: card.good + goodDelta,
        easy: card.easy + easyDelta,
      });
    }
    return new TestStudyState({
      ...this.data,
      cards: cards,
      isAnswerVisible: false,
      isRationaleVisible: false,
    });
  }
}
