import {User} from "../../models/user";
import {Session} from "../../models/session";

export interface UserData {
  userId?: number;
  username: string;
  password?: string;
  user?: User;
  sessions: Session[];
  displayName: string;
  pronouns: string;
  isAdministrator: boolean;
  isSkater: boolean;
  isContactSafe: boolean;
  isTrainer: boolean;
  isBench: boolean;
  isTemporaryPassword: boolean;
  usernameError?: string;
  displayNameError?: string;
  isRoleError?: string;
  valid: boolean;
  loading: boolean;
}

export class UserState {
  readonly data: UserData;

  public static readonly PLACEHOLDER_PASSWORD = '********';

  constructor(data: UserData) {
    this.data = data;
  }

  public static create(): UserState {
    return new UserState({
      userId: undefined,
      user: undefined,
      sessions: [],
      username: '',
      password: undefined,
      displayName: '',
      pronouns: '',
      isAdministrator: false,
      isSkater: false,
      isContactSafe: false,
      isTrainer: false,
      isBench: false,
      isTemporaryPassword: false,
      usernameError: '',
      displayNameError: '',
      isRoleError: '',
      valid: false,
      loading: true,
    });
  }

  public get canDelete(): boolean {
    return this.data.user?.id !== undefined && this.data.sessions.length === 0;
  }

  public get canSave(): boolean {
    return this.dirty && this.data.valid;
  }

  public get usernameDirty(): boolean {
    return this.data.username !== this.data.user?.username;
  }

  public get passwordDirty(): boolean {
    return this.data.password !== UserState.PLACEHOLDER_PASSWORD;
  }

  public get isTemporaryPasswordDirty(): boolean {
    return this.data.isTemporaryPassword !== this.data.user?.isTemporaryPassword;
  }

  public get displayNameDirty(): boolean {
    return this.data.displayName !== this.data.user?.displayName;
  }

  public get pronounsDirty(): boolean {
    return this.data.pronouns !== this.data.user?.pronouns;
  }

  public get isAdministratorDirty(): boolean {
    return this.data.isAdministrator !== this.data.user?.administrator;
  }

  public get isSkaterDirty(): boolean {
    return this.data.isSkater !== this.data.user?.skater;
  }

  public get isContactSafeDirty(): boolean {
    return this.data.isContactSafe !== this.data.user?.contactSafe;
  }

  public get isTrainerDirty(): boolean {
    return this.data.isTrainer !== this.data.user?.trainer;
  }

  public get isBenchDirty(): boolean {
    return this.data.isBench !== this.data.user?.bench;
  }

  public get dirty(): boolean {
    return this.usernameDirty
      || this.passwordDirty
      || this.isTemporaryPasswordDirty
      || this.displayNameDirty
      || this.pronounsDirty
      || this.isAdministratorDirty
      || this.isSkaterDirty
      || this.isContactSafeDirty
      || this.isTrainerDirty
      || this.isBenchDirty;
  }

  public refresh(user: User | undefined, sessions: Session[]): UserState {
    user = this.normalize(user);
    return new UserState({
      ...this.data,
      user: user,
      userId: user?.id,
      sessions: sessions,
      username: user?.username || '',
      password: !user ? this.generatePassword() : UserState.PLACEHOLDER_PASSWORD,
      displayName: user?.displayName || '',
      pronouns: user?.pronouns || '',
      isAdministrator: user?.administrator || false,
      isSkater: user?.skater || false,
      isContactSafe: user?.contactSafe || false,
      isTrainer: user?.trainer || false,
      isBench: user?.bench || false,
      isTemporaryPassword: !!user ? user?.isTemporaryPassword || false : true,
      loading: false,
    })
      .validate();
  }

  public withLoading(loading: boolean): UserState {
    return new UserState({
      ...this.data,
      loading: loading,
    });
  }

  public withQueryId(userId: number | undefined): UserState {
    return new UserState({
      ...this.data,
      userId: userId,
    });
  }

  public withUsername(username: string): UserState {
    return new UserState({
      ...this.data,
      username: username,
    }).validate();
  }

  public regeneratePassword(): UserState {
    return new UserState({
      ...this.data,
      password: this.generatePassword(),
      isTemporaryPassword: true,
    }).validate();
  }

  public withDisplayName(displayName: string): UserState {
    return new UserState({
      ...this.data,
      displayName: displayName,
    }).validate();
  }

  public withPronouns(pronouns: string): UserState {
    return new UserState({
      ...this.data,
      pronouns: pronouns,
    }).validate();
  }

  public withPassword(password: string): UserState {
    return new UserState({
      ...this.data,
      password: password,
    }).validate();
  }

  public withIsTemporaryPassword(isTemporaryPassword: boolean): UserState {
    return new UserState({
      ...this.data,
      isTemporaryPassword: isTemporaryPassword,
    }).validate();
  }

  public withIsAdministrator(isAdministrator: boolean): UserState {
    return new UserState({
      ...this.data,
      isAdministrator: isAdministrator,
    }).validate();
  }

  public withIsSkater(isSkater: boolean): UserState {
    return new UserState({
      ...this.data,
      isSkater: isSkater,
      isContactSafe: isSkater ? this.data.isContactSafe : false,
    }).validate();
  }

  public withIsContactSafe(isContactSafe: boolean): UserState {
    return new UserState({
      ...this.data,
      isContactSafe: isContactSafe,
    }).validate();
  }

  public withIsTrainer(isTrainer: boolean): UserState {
    return new UserState({
      ...this.data,
      isTrainer: isTrainer,
    }).validate();
  }

  public withIsBench(isBench: boolean): UserState {
    return new UserState({
      ...this.data,
      isBench: isBench,
    }).validate();
  }

  private validate(): UserState {
    let usernameError = '';
    if (this.usernameDirty) {
      if (!this.data.username) {
        usernameError = 'Username is required';
      } else if (this.data.username.length < 3) {
        usernameError = 'Username must be at least 3 characters';
      } else if (this.data.username.length > 32) {
        usernameError = 'Username must be at most 32 characters';
      } else if (!/^[a-zA-Z0-9_]+$/.test(this.data.username)) {
        usernameError = 'Username must contain only letters, numbers, and underscores';
      }
    }

    let displayNameError = '';
    if (this.displayNameDirty) {
      if (!this.data.displayName) {
        displayNameError = 'Display name is required';
      } else if (this.data.displayName.length < 3) {
        displayNameError = 'Display name must be at least 3 characters';
      }
    }

    let isRoleError = '';
    if (!this.data.isAdministrator && !this.data.isSkater && !this.data.isTrainer && !this.data.isBench) {
      isRoleError = 'At least one role is required';
    } else if (!this.data.isSkater && this.data.isContactSafe) {
      isRoleError = 'Contact safe can only be set for skaters';
    }

    return new UserState({
      ...this.data,
      usernameError: usernameError,
      displayNameError: displayNameError,
      isRoleError: isRoleError,
      valid: !usernameError && !displayNameError && !isRoleError,
    })
  }

  private generatePassword(): string {
    const verbs = [
      'sprinting',
      'skating',
      'blocking',
      'whipping',
      'jamming',
      'juking',
      'speeding',
      'leaping',
      'pivoting',
      'dodging',
      'rolling',
      'jumping',
      'spinning',
      'twisting',
      'turning',
      'sliding',
      'shuffling',
      'hopping',
    ];
    const animals = [
      'tiger',
      'lion',
      'wolf',
      'fox',
      'dragon',
      'falcon',
      'hawk',
      'eagle',
      'cobra',
      'viper',
      'panther',
      'leopard',
      'cheetah',
      'lynx',
    ];
    const verb = verbs[Math.floor(Math.random() * verbs.length)];
    const animal = animals[Math.floor(Math.random() * animals.length)];
    const number = Math.floor(Math.random() * 10);
    return verb + animal + number;
  }

  private normalize(user: User | undefined): User | undefined {
    if (!user) {
      return undefined;
    }

    return {
      id: user.id,
      username: user.username || '',
      displayName: user.displayName || '',
      pronouns: user.pronouns || '',
      administrator: user.administrator || false,
      skater: user.skater || false,
      contactSafe: user.contactSafe || false,
      trainer: user.trainer || false,
      bench: user.bench || false,
      isTemporaryPassword: user.isTemporaryPassword || false,
    };
  }
}
