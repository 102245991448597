import {AfterViewInit, Directive, DoCheck, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appAutoResize]'
})
export class AutoResizeDirective implements AfterViewInit, DoCheck {

  private curValue: string | undefined = undefined;

  constructor(private elementRef: ElementRef<HTMLTextAreaElement>) {}

  ngAfterViewInit() {
    this.adjustHeight();
    setTimeout(() => this.adjustHeight(), 100);
    setTimeout(() => this.adjustHeight(), 500);
  }

  ngDoCheck() {
    const newValue = this.elementRef.nativeElement.value;
    if (this.curValue !== newValue) {
      this.curValue = newValue;
      this.adjustHeight();
    }
  }

  @HostListener('input', ['$event.target'])
  onInput(textArea: HTMLTextAreaElement): void {
    this.adjustHeight();
  }

  private adjustHeight(): void {
    const textArea = this.elementRef.nativeElement;
    textArea.style.overflow = 'hidden';
    textArea.style.resize = 'none';
    // Reset height to 0 to properly reduce size if needed
    textArea.style.height = '0';

    // Calculate the total scroll height by subtracting padding and borders
    const borderHeight = textArea.offsetHeight - textArea.clientHeight;
    textArea.style.height = `${textArea.scrollHeight + borderHeight}px`;
  }
}
