import {Objects} from "../../../tools/objects";

export class TestCategory {

  public static readonly OFFICIATING_RULES = new TestCategory('OFFICIATING_RULES', 'Officiating');
  public static readonly RULES = new TestCategory('RULES', 'Rules');
  public static readonly STRATEGIES = new TestCategory('STRATEGIES', 'Strategy');
  public static readonly OTHER = new TestCategory(undefined, 'Other');

  public static readonly VALUES = [
    TestCategory.RULES,
    TestCategory.STRATEGIES,
    TestCategory.OFFICIATING_RULES,
    TestCategory.OTHER,
  ]

  public readonly code?: string;
  public readonly displayName: string;

  private constructor(code: string | undefined, displayName: string) {
    this.code = code;
    this.displayName = displayName;
  }

  public static fromCode(code?: string): TestCategory {
    return this.VALUES.find(category => category.code === code) || this.OTHER;
  }
}
