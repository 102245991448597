<div class="select"
     (click)="showDialog()"
     [class.select-transparent]="transparent"
     [class.select-disabled]="disabled">
  <div class="select-text-container">
    <div class="select-text">
      <div *ngIf="render(selection) as renderer">
        <div *ngFor="let text of renderer.text;"
             class="select-text-item"
             [style]="!renderer.color ? '' : 'color: ' + renderer.color + ';'">
          {{ text }}
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="dialogVisible">
  <div class="dialog-glass-pane" #glassPane>
    <div class="dialog" #dialog>
      <div class="dialog-header">
        {{ title }}
        <div class="dialog-close">
          <button class="button-icon-secondary" (click)="closeDialog()">
            <app-icon name="close"></app-icon>
          </button>
        </div>
      </div>
      <div class="dialog-content">
        <div class="dialog-scroll" #scroll>
          <div>
            <div class="list">
              <div [class.list-item-secondary]="selection !== undefined"
                   [class.list-item-primary]="selection === undefined"
                   *ngIf="includeEmpty">
                <div [class.list-text-secondary-empty]="selection !== undefined"
                     [class.list-text-primary-empty]="selection === undefined"
                     (click)="onSelect(undefined)">
                  Empty
                </div>
              </div>

              <div class="list-item-separator" *ngIf="includeEmpty"></div>

              <div [class.list-item-secondary]="selection !== value"
                   [class.list-item-primary]="selection === value"
                   (click)="onSelect(value)"
                   *ngFor="let value of values">
                <div *ngIf="render(value) as renderer"
                     [class.list-text-secondary]="selection !== value"
                     [class.list-text-primary]="selection === value">
                  <div *ngFor="let text of renderer.text;"
                       class="select-text-item"
                       [style]="!renderer.color ? '' : 'color: ' + renderer.color + ';'">
                    {{ text }}
                  </div>
                  <app-icon name="magic" class="select-highlight" *ngIf="isHighlighted(value)"></app-icon>
                </div>
              </div>

              <ng-container *ngIf="moreValues.length > 0">
                <div class="list-item-separator" *ngIf="values.length > 0"></div>
                <div class="list-item-secondary" *ngIf="!moreVisible">
                  <div class="list-text-secondary" (click)="showMore()">
                    More...
                  </div>
                </div>

                <ng-container *ngIf="moreVisible || (values.length === 0 && moreValues.length > 0)">
                  <ng-container *ngFor="let value of moreValues">
                    <div [class.list-item-secondary]="selection !== value"
                         [class.list-item-primary]="selection === value"
                         (click)="onSelect(value)"
                         *ngIf="!isPresentInValues(value)">
                      <div *ngIf="render(value) as renderer"
                           [class.list-text-secondary]="selection !== value"
                           [class.list-text-primary]="selection === value">
                        <div *ngFor="let text of renderer.text;"
                             class="select-text-item"
                             [style]="!renderer.color ? '' : 'color: ' + renderer.color + ';'">
                          {{ text }}
                        </div>
                        <app-icon name="magic" class="select-highlight" *ngIf="isHighlighted(value)"></app-icon>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
