import {Test} from "../../models/learning/domain/test";
import {TestQuestion} from "../../models/learning/domain/test-question";
import {Objects} from "../../tools/objects";
import {TestTools} from "../../tools/test.tools";
import {TestSubmission} from "../../models/learning/domain/test-submission";
import {User} from "../../models/user";
import {TestQuestionAnswer} from "../../models/learning/domain/test-question-answer";
import {TestAnswer} from "../../models/learning/domain/test-answer";

export interface CardData {
  question: TestQuestion;
  answer: TestAnswer;
  shuffledAnswers: TestQuestionAnswer[];
}

export interface TestSubmissionData {
  testId?: number;
  test?: Test;
  submissionId?: number;
  submission?: TestSubmission;
  questions: TestQuestion[];
  cards: CardData[];
  user?: User;
  cardIndex: number;
  question: string;
  answer: string;
  loading: boolean;
  finished: boolean;
  showSummary: boolean;
}

export class TestSubmissionState {

  readonly data: TestSubmissionData;

  constructor(data: TestSubmissionData) {
    this.data = data;
  }

  public static create(): TestSubmissionState {
    return new TestSubmissionState({
      testId: undefined,
      test: undefined,
      submissionId: undefined,
      submission: undefined,
      questions: [],
      cards: [],
      cardIndex: 0,
      question: '',
      answer: '',
      loading: true,
      finished: false,
      showSummary: false,
    });
  }

  public get finishButtonText(): string {
    if (this.data.finished) {
      return 'Summary';
    } else if (this.data.test?.isPractice) {
      return 'Finish';
    }
    return 'Submit';
  }

  public get showSummary(): boolean {
    return this.data.showSummary;
  }

  public get currentCard(): CardData | undefined {
    return this.data.cardIndex < this.data.cards.length
      ? this.data.cards[this.data.cardIndex]
      : undefined;
  }

  public get currentQuestion(): TestQuestion | undefined {
    return this.currentCard?.question;
  }

  public get currentQuestionText(): string {
    return this.currentQuestion ? this.currentQuestion.question : '';
  }

  public get currentRationaleText(): string {
    return this.currentQuestion ? this.currentQuestion.rationale! : '';
  }

  public get shuffledAnswers(): TestQuestionAnswer[] {
    const currentCard = this.currentCard;
    return currentCard ? currentCard.shuffledAnswers : [];
  }

  public get hasMultipleCorrectAnswers(): boolean {
    const currentQuestion = this.currentQuestion;
    return currentQuestion ? currentQuestion.answers.filter(answer => answer.isCorrect).length > 1 : false;
  }

  public isAnswerSelected(answer: TestQuestionAnswer): boolean {
    const currentCard = this.currentCard;
    if (!currentCard || currentCard.answer.answers.length === 0) {
      return false;
    }
    const indices = currentCard.answer.answers;
    return indices.includes(currentCard.shuffledAnswers.indexOf(answer));
  }

  public showRationale(): boolean {
    const currentCard = this.currentCard;
    if (!currentCard || !this.data.test?.isPractice) {
      return false;
    }
    const rationale = currentCard.question.rationale;
    if (Objects.isNull(rationale)) {
      return false;
    }
    return TestTools.isCorrect([currentCard.question], currentCard.answer) || this.hasMistakes(false);
  }

  public showAsCorrectAnswer(answer: TestQuestionAnswer): boolean {
    if (this.data.test?.isMandatory) {
      return false;
    }
    const isFinished = !!this.data.submission?.isFinished;
    const hasMistakes = this.hasMistakes(isFinished);
    const hasAnswered = this.hasAnswered;
    const isAnswerSelected = this.isAnswerSelected(answer);
    const isShowAnswersAllowed = this.data.test?.isPractice || isFinished;
    return ((isAnswerSelected && !hasMistakes) || (hasAnswered && hasMistakes)) && answer.isCorrect && isShowAnswersAllowed;
  }

  public showAsWrongAnswer(answer: TestQuestionAnswer): boolean {
    if (this.data.test?.isMandatory) {
      return false;
    }
    const isShowAnswersAllowed = this.data.test?.isPractice || !!this.data.submission?.isFinished;
    return this.isAnswerSelected(answer) && this.hasMistakes(false) && !answer.isCorrect && isShowAnswersAllowed;
  }

  private hasMistakes(completeAnswer: boolean): boolean {
    const currentCard = this.currentCard;
    if (!currentCard) {
      return false;
    }
    return !TestTools.isCorrect([currentCard.question], currentCard.answer, completeAnswer);
  }

  public correctAnswers(): number {
    const submission = this.data.submission;
    if (!submission) {
      return 0;
    }
    return submission.answers.filter(answer => TestTools.isCorrect(this.data.questions, answer)).length;
  }

  public get scoreTitle(): string {
    const user = this.data.user;
    if (Objects.isNull(user)) {
      return 'Score:';
    }
    return `${user!.displayName}'s score:`;
  }

  public get score(): number {
    const correctAnswers = this.correctAnswers();
    const totalAnswers = this.data.submission?.answers?.length || 0;
    return totalAnswers > 0 ? Math.round(correctAnswers / totalAnswers * 100) : 0;
  }

  public get scoreSlogan(): string {
    const score = this.score;
    if (score === 100) {
      return 'Perfect!';
    } else if (score >= 90) {
      return 'Excellent!';
    } else if (score >= 70) {
      return 'Well done!';
    } else if (score >= 30) {
      return 'Keep practicing!';
    }
    return 'Better luck next time!';
  }

  public get hasAnswered(): boolean {
    const currentCard = this.currentCard;
    return !!currentCard && currentCard.answer.answers.length > 0;
  }

  public get canGoNext(): boolean {
    const currentCard = this.currentCard;
    if (!currentCard) {
      return false;
    }
    if (this.data.test?.isPractice) {
      return (this.hasAnswered && this.hasMistakes(false)) || !this.hasMistakes(true);
    }
    return this.hasAnswered;
  }

  public refresh(userId: number, test: Test | undefined, questions: TestQuestion[], cards: CardData[] | undefined, submission: TestSubmission | undefined, users: User[]): TestSubmissionState {
    userId = Objects.isNull(submission) ? userId : submission!.userId;
    const user = users.find(user => user.id === userId);
    return new TestSubmissionState({
      ...this.data,
      test: test,
      questions: questions.sort((a, b) => a.question.localeCompare(b.question)),
      loading: false,
      user: user,
    }).start(cards, submission);
  }

  public withLoading(loading: boolean): TestSubmissionState {
    return new TestSubmissionState({
      ...this.data,
      loading: loading,
    });
  }

  private start(cards: CardData[] | undefined, submission?: TestSubmission): TestSubmissionState {
    // Load submitted results
    if (!Objects.isNull(submission)) {
      cards = this.data.questions.map((question) => {
        const answer = submission!.answers.find(answer => answer.testQuestionId === question.id);
        return {
          question: question,
          answer: answer || {testQuestionId: question.id, answers: []},
          shuffledAnswers: this.shuffleAnswers(question),
        };
      });
      return new TestSubmissionState({
        ...this.data,
        cards: cards,
        cardIndex: cards.length,
        finished: true,
        showSummary: true,
        submission: submission,
      });
    }

    // Load persisted progress
    return new TestSubmissionState({
      ...this.data,
      cards: this.validateCards(cards) ? cards! : this.newCards(this.data.questions),
    }).goToFirstEmptyQuestion();
  }

  public reset(): TestSubmissionState {
    return new TestSubmissionState({
      ...this.data,
      cards: this.newCards(this.data.questions),
    }).goToFirstEmptyQuestion(0);
  }

  public finish(submission: TestSubmission): TestSubmissionState {
    return new TestSubmissionState({
      ...this.data,
      submission: submission,
      finished: true,
      showSummary: true,
    }).goTo(this.data.cards.length);
  }

  public withQueryIds(testId?: number, testSubmissionId?: number): TestSubmissionState {
    return new TestSubmissionState({
      ...this.data,
      testId: testId,
      submissionId: testSubmissionId,
    });
  }

  public withAnswer(answer: TestQuestionAnswer): TestSubmissionState {
    return new TestSubmissionState({
      ...this.data,
      cards: this.data.cards.map((card, index) => {
        if (index === this.data.cardIndex) {
          return {
            ...card,
            answer: {
              testQuestionId: card.answer.testQuestionId,
              answers: this.toggleAnswer(card.answer.answers, card.shuffledAnswers.indexOf(answer)),
            },
          };
        }
        return card;
      }),
    });
  }

  private goToFirstEmptyQuestion(startIndex: number = 0): TestSubmissionState {
    const index = this.data.cards.findIndex(card => card.answer.answers.length === 0);
    return this.goTo(index >= 0 ? index : startIndex);
  }

  public goTo(cardIndex: number): TestSubmissionState {
    return new TestSubmissionState({
      ...this.data,
      cardIndex: cardIndex >= 0 && cardIndex <= this.data.cards.length ? cardIndex : this.data.cardIndex,
      showSummary: this.data.finished && cardIndex === this.data.cards.length,
    });
  }

  private validateCards(candidates: CardData[] | undefined): boolean {
    return !!candidates && candidates.length > 0 && candidates.filter(card => {
      const hasProperties = card && card.hasOwnProperty('question') && card.hasOwnProperty('answer') && card.hasOwnProperty('shuffledAnswers');
      if (!hasProperties) {
        return false;
      }
      const isQuestionValid = card.question.hasOwnProperty('question') && card.question.hasOwnProperty('answers');
      const isAnswerValid = card.answer.hasOwnProperty('testQuestionId') && card.answer.hasOwnProperty('answers');
      const isShuffledAnswersValid = Array.isArray(card.shuffledAnswers);
      return isQuestionValid && isAnswerValid && isShuffledAnswersValid;
    }).length === candidates.length;
  }

  private newCards(questions: TestQuestion[]): CardData[] {
    return Objects.shuffle(questions.map((question) => ({
      question: question,
      answer: {testQuestionId: question.id, answers: []},
      shuffledAnswers: this.shuffleAnswers(question),
    })));
  }

  private shuffleAnswers(question: TestQuestion): TestQuestionAnswer[] {
    return question.answers.sort((a, b) => a.text.localeCompare(b.text));
  }

  public toSubmission(userId: number): TestSubmission {
    const answers = this.data.cards.map(card => card.answer);
    return {
      id: this.data.submission?.id,
      testId: this.data.testId!,
      userId: userId,
      isFinished: true,
      answers: answers,
    };
  }

  private toggleAnswer(answers: number[], index: number): number[] {
    if (answers.includes(index)) {
      return answers.filter(i => i !== index);
    }
    return [...answers, index].sort();
  }
}
