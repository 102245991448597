<div class="page-with-actions">
  <app-loading *ngIf="(state$ | async)!.data.loading"></app-loading>
  <div class="cards" *ngIf="!(state$ | async)!.data.loading">
    <div class="card">
      <div class="card-header">
        <div class="subtitle">
          <app-icon name="cards"></app-icon>
          Study
        </div>
        <div class="header-actions">
          <div class="progress-container">
            <div class="progress-left">
              {{ (state$ | async)!.newCount }}
            </div>
            <div class="progress-middle">
              {{ (state$ | async)!.progressCount }}
            </div>
            <div class="progress-right">
              {{ (state$ | async)!.doneCount }}
            </div>
          </div>
        </div>
      </div>

      <div class="list" *ngIf="(state$ | async)!.data.cards.length === 0">
        <div class="list-item-secondary" *ngIf="(state$ | async)!.data.questions.length === 0">
          <div class="list-text-message">
            No cards found
          </div>
        </div>
        <div class="list-item-secondary"
             *ngIf="(state$ | async)!.data.questions.length > 0"
             (click)="goBack()">
          <div class="list-text-message-clickable">
            All done, good job!
          </div>
        </div>
      </div>

      <div class="form" *ngIf="(state$ | async)!.data.cards.length > 0">
        <div class="field">
          <label class="field-label" for="displayName">Question</label>
          <textarea class="field-text" id="displayName"
                    disabled appAutoResize
                    [value]="(state$ | async)!.question"></textarea>
        </div>

        <div class="field" *ngIf="(state$ | async)!.data.isAnswerVisible">
          <label class="field-label">
            Answer
          </label>
          <div class="list">
            <ng-container *ngFor="let answer of (state$ | async)!.answers">
              <div class="list-item-secondary list-item-disabled">
                <div class="list-text-secondary">
                  {{ answer }}
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="field" *ngIf="(state$ | async)!.data.isRationaleVisible">
          <label class="field-label" for="rationale">Rationale</label>
          <textarea class="field-text" id="rationale"
                    disabled appAutoResize
                    [value]="(state$ | async)!.rationale"></textarea>
        </div>

        <div class="field-actions">
          <ng-container *ngIf="!(state$ | async)!.data.isAnswerVisible">
            <button class="field-action-secondary" (click)="showAnswer()">
              Show Answer
            </button>
          </ng-container>
          <ng-container *ngIf="(state$ | async)!.data.isAnswerVisible">
            <button class="field-action-secondary" (click)="onHard()">
              Hard
            </button>
            <button class="field-action-secondary" (click)="onGood()">
              Good
            </button>
            <button class="field-action-secondary" (click)="onEasy()">
              Easy
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
