import {Objects} from "../../../tools/objects";

export class TestDifficulty {

  public static readonly EASY = new TestDifficulty(1, 0, 'Easy', [0]);
  public static readonly MEDIUM = new TestDifficulty(2, 1, 'Medium', [0, 1]);
  public static readonly HARD = new TestDifficulty(3, 2, 'Hard', [0, 1, 2]);
  public static readonly UNKNOWN = new TestDifficulty(undefined, 3, 'Unknown', []);

  public static readonly VALUES = [
    TestDifficulty.EASY,
    TestDifficulty.MEDIUM,
    TestDifficulty.HARD,
    TestDifficulty.UNKNOWN,
  ];

  public readonly value?: number;
  public readonly order: number;
  public readonly displayName: string;
  public readonly icons: number[];

  private constructor(value: number | undefined, order: number, displayName: string, icons: number[]) {
    this.value = value;
    this.order = order;
    this.displayName = displayName;
    this.icons = icons;
  }

  public static fromValue(value?: number): TestDifficulty {
    return this.VALUES.find(difficulty => difficulty.value === value) || this.UNKNOWN;
  }
}
