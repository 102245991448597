import {Component, EventEmitter, Input, Output} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent {

  @Input()
  public set date(date: Date | undefined) {
    console.log("Reading date", date, !date ? undefined : new Date(date));
    date = !!date ? new Date(date) : undefined;
    this.tryDateToString(date);
  }

  @Input()
  public set disabled(disabled: boolean) {
    this.disabled$.next(disabled);
  }

  @Output('date')
  dateEvent: EventEmitter<Date> = new EventEmitter<Date>();

  disabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _date: Date | undefined = undefined;
  dateString: string = '';

  yearString: string = '';
  monthString: string = '';
  dayString: string = '';
  hourString: string = '';
  minuteString: string = '';

  public today(): void {
    const today = new Date();
    today.setHours(12, 0, 0, 0);
    this.updateDate(today);
  }

  public clear(): void {
    this.updateDate(undefined);
  }

  public withYearString(event: any): void {
    this.yearString = event.target.value;
    this.tryStringToDate();
  }

  public withMonthString(event: any): void {
    this.monthString = event.target.value;
    this.tryStringToDate();
  }

  public withDayString(event: any): void {
    this.dayString = event.target.value;
    this.tryStringToDate();
  }

  public withHourString(event: any): void {
    this.hourString = event.target.value;
    this.tryStringToDate();
  }

  public withMinuteString(event: any): void {
    this.minuteString = event.target.value;
    this.tryStringToDate();
  }

  private updateDate(date: Date | undefined): void {
    this.tryDateToString(date);
    this.dateEvent.emit(date);
  }

  private tryStringToDate(): void {
    if (this.yearString.length === 0 || this.monthString.length === 0 || this.dayString.length === 0 || this.hourString.length === 0 || this.minuteString.length === 0) {
      return;
    }

    const year = Number(this.yearString);
    const month = Number(this.monthString);
    const day = Number(this.dayString);
    const hour = Number(this.hourString);
    const minute = Number(this.minuteString);
    if (isNaN(year) || isNaN(month) || isNaN(day) || isNaN(hour) || isNaN(minute)) {
      return;
    }
    if (year < 1000 || year > 9999 || month < 1 || month > 12 || day < 1 || day > 31 || hour < 0 || hour > 23 || minute < 0 || minute > 59) {
      return;
    }
    const candidate = new Date(year, month - 1, day, hour, minute);
    if (!isNaN(candidate.getTime()) && JSON.stringify(candidate) !== JSON.stringify(this._date)) {
      this.dateEvent.emit(candidate);
    }
  }

  private tryDateToString(date?: Date): void {
    this._date = date;
    if (!date) {
      this.dateString = '';
      this.yearString = '';
      this.monthString = '';
      this.dayString = '';
      this.hourString = '';
      this.minuteString = '';
      return;
    }
    const month = date.getMonth() + 1;
    this.yearString = date.getFullYear().toString();
    this.monthString = (month < 10 ? '0' : '') + month.toString();
    this.dayString = (date.getDate() < 10 ? '0' : '') + date.getDate().toString();
    this.hourString = (date.getHours() < 10 ? '0' : '') + date.getHours().toString();
    this.minuteString = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes().toString();
    this.dateString = this.yearString + '-' + this.monthString + '-' + this.dayString + ' ' + this.hourString + ':' + this.minuteString;
  }
}
