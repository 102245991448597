export class Subscriptions {

  private readonly subscriptions: any[] = [];

  public add(subscription: any): void {
    this.subscriptions.push(subscription);
  }

  public unsubscribe(): void {
    this.subscriptions.forEach(subscription => {
      if (subscription && subscription.unsubscribe) {
        subscription.unsubscribe();
      }
    });
    this.subscriptions.length = 0;
  }
}
