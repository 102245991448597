import {TestDto} from "../dto/test-dto";
import {Test} from "./test";
import {TestCategory} from "./test-category";
import {TestQuestionDto} from "../dto/test-question-dto";
import {TestQuestion} from "./test-question";
import {TestSubmissionDto} from "../dto/test-submission-dto";
import {TestSubmission} from "./test-submission";
import {TestDifficulty} from "./test-difficulty";

export class TestMapper {

  public static fromDto(test: TestDto): Test {
    return {
      ...test,
      category: TestCategory.fromCode(test.category),
      difficulty: TestDifficulty.fromValue(test.difficulty)
    };
  }

  public static toDto(test: Partial<Test>): Partial<TestDto> {
    return {
      ...test,
      category: test.category?.code,
      difficulty: test.difficulty?.value,
    }
  }

  public static fromQuestionDto(testQuestion: TestQuestionDto): TestQuestion {
    return {
      ...testQuestion,
      answers: testQuestion.answers ? JSON.parse(testQuestion.answers) : [],
    };
  }

  public static toQuestionDto(testQuestion: Partial<TestQuestion>): Partial<TestQuestionDto> {
    return {
      ...testQuestion,
      answers: JSON.stringify(testQuestion.answers),
    };
  }

  public static fromSubmissionDto(testSubmission: TestSubmissionDto): TestSubmission {
    return {
      ...testSubmission,
      answers: testSubmission.answers ? JSON.parse(testSubmission.answers) : []
    };
  }

  public static toSubmissionDto(testSubmission: Partial<TestSubmission>): Partial<TestSubmissionDto> {
    return {
      ...testSubmission,
      answers: JSON.stringify(testSubmission.answers)
    }
  }
}
