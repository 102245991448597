<div class="app-page">
  <app-page>
    <ng-container *ngIf="loggedIn$ | async; else loggedOut">
      <app-icon name="logo_white" class="app-background-logo" />
      <router-outlet ></router-outlet>
    </ng-container>
    <ng-template  #loggedOut>
      <app-login></app-login>
    </ng-template>
  </app-page>
</div>
<app-notifications></app-notifications>
<app-dialog></app-dialog>
