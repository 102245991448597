import {Component, OnDestroy, OnInit} from "@angular/core";
import {StateSubject} from "../../tools/state.subject";
import {LiveMode, LiveState, VersusMode} from "./live.state";
import {TeamService} from "../../services/team.service";
import {SkaterService} from "../../services/skater.service";
import {GameService} from "../../services/game.service";
import {JamService} from "../../services/jam.service";
import {forkJoin, Observable} from "rxjs";
import {ColorTools} from "../../tools/color.tools";
import {TeamLineService} from "../../services/team-line.service";
import {RouterTools} from "../../tools/router.tools";
import {ActivatedRoute, Router} from "@angular/router";
import {Objects} from "../../tools/objects";
import {Game} from "../../models/game";
import {Tabs} from "../../models/tabs";
import {Tab} from "../../models/tab";
import {PageService} from "../../services/page.service";

@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.scss']
})
export class LiveComponent implements OnInit, OnDestroy {

  protected readonly VersusMode = VersusMode;

  state$: StateSubject<LiveState> = new StateSubject<LiveState>(LiveState.create());
  backgroundColors$: Observable<string[]>;

  private interval: any;

  constructor(private teamService: TeamService,
              private teamLineService: TeamLineService,
              private skaterService: SkaterService,
              private gameService: GameService,
              private jamService: JamService,
              private pageService: PageService,
              private route: ActivatedRoute,
              private router: Router) {
    this.backgroundColors$ = this.state$.get(state => {
      const color1 = state.data.game?.teamColor1 || 'white';
      const color2 = state.data.game?.teamColor2 || 'black';
      return [ColorTools.toHex(color1), ColorTools.toHex(color2)];
    });
    this.state$.subscribe(state => {
      this.pageService.invoke(page => page.reset(state.teamName(0) + ' vs ' + state.teamName(1))
        .withBackButton(() => this.goToGames())
        .withTabs(Tabs.of([
          Tab.of('Info', true, () => this.goToGame()),
          Tab.of('Jams', true, () => this.goToGameJams()),
          Tab.of('Stats'),
        ], 2))
        .withActions([
          {
            text: 'Performance',
            icon: 'versus',
            visible: true,
            enabled: state.skaterPerformanceAvailable(),
            primary: false,
            active: state.data.mode === LiveMode.SKATER_PERFORMANCE,
            onClick: () => this.goTo(LiveMode.SKATER_PERFORMANCE)
          },
          {
            text: 'Jams',
            icon: 'laps',
            visible: true,
            enabled: state.jamsAvailable(),
            primary: false,
            active: state.data.mode === LiveMode.JAMS,
            onClick: () => this.goTo(LiveMode.JAMS)
          },
          {
            text: 'Escapes',
            icon: 'fire',
            visible: true,
            enabled: state.escapesAvailable(),
            primary: false,
            active: state.data.mode === LiveMode.ESCAPES,
            onClick: () => this.goTo(LiveMode.ESCAPES)
          }
        ])
      )
    });
  }

  ngOnInit(): void {
    RouterTools.observeParamMap(this.route, params => {
      const gameId = RouterTools.toNumber(params, 'gameId');
      const mode = Objects.ifNull(params.get('mode'), undefined);
      this.state$.invoke(state => state.withQueryParams(gameId, mode));
    });
    this.state$.get(state => state.data.gameId).subscribe(() => {
      this.refresh();
    });
  }

  ngOnDestroy(): void {
    this.resetPeriodicRefresh();
  }

  public refresh(): void {
    this.resetPeriodicRefresh();
    const gameObserver = {
      next: (game: Game | undefined) => {
        this.state$.invoke(state => state.refreshGame(game));
        this.refreshPeriodically();
      },
      error: () => {
        this.state$.invoke(state => state.refreshGame(undefined));
      }
    };

    const gameId = this.state$.getValue().data.gameId;
    if (Objects.isNull(gameId)) {
      this.gameService.oneByLiveIsTrue().subscribe(gameObserver);
    } else {
      this.gameService.oneById(gameId!).subscribe(gameObserver);
    }
  }

  private resetPeriodicRefresh(): void {
    clearInterval(this.interval);
    this.interval = undefined;
  }

  private refreshPeriodically(): void {
    if (!this.interval) {
      this.interval = setInterval(() => this.refreshPeriodically(), 1000);
    }

    const state = this.state$.getValue();
    const game = state.data.game;
    if (!game) {
      return;
    }

    forkJoin([
      this.teamService.getTeam(game.teamId1),
      this.teamService.getTeam(game.teamId2),
      this.skaterService.getSkatersByTeamId(game.teamId1),
      this.skaterService.getSkatersByTeamId(game.teamId2),
      this.teamLineService.getTeamLinesByTeamId(game.teamId1),
      this.teamLineService.getTeamLinesByTeamId(game.teamId2),
      this.gameService.oneById(game.id),
      this.jamService.getJamByGameId(game.id),
    ]).subscribe({
      next: ([team1, team2, skaters1, skaters2, lines1, lines2, game, jams]) => {
        this.state$.invoke(state => state.refresh(team1, team2, skaters1, skaters2, lines1, lines2, game, jams));
      },
      error: () => {
        console.error(`Failed to load jams`);
      }
    });
  }

  public goToGame(): void {
    const state = this.state$.getValue();
    const gameId = Objects.ifNull(state.data.gameId, state.data.game?.id);
    RouterTools.goToGame(this.router, gameId);
  }

  public goToGameJams(): void {
    const state = this.state$.getValue();
    const gameId = Objects.ifNull(state.data.gameId, state.data.game?.id);
    RouterTools.goToGameJams(this.router, gameId);
  }

  public goToGames(): void {
    RouterTools.goToGames(this.router);
  }

  public withJammerVsJammerMode(mode: VersusMode): void {
    this.state$.invoke(state => state.withJammerVsJammerMode(mode));
  }

  public withJammerVsLineMode(mode: VersusMode): void {
    this.state$.invoke(state => state.withJammerVsLineMode(mode));
  }

  public withJammerVsPivotMode(mode: VersusMode): void {
    this.state$.invoke(state => state.withJammerVsPivotMode(mode));
  }

  private goTo(mode: LiveMode): void {
    const state = this.state$.getValue();
    const gameId = Objects.ifNull(state.data.gameId, state.data.game?.id);
    RouterTools.goToGameStats(this.router, gameId!, mode);
  }
}
