<div class="page-with-actions">
  <app-loading *ngIf="(state$ | async)!.data.loading"></app-loading>
  <div class="cards" *ngIf="!(state$ | async)!.data.loading">
    <div class="card">
      <div class="card-header">
        <div class="subtitle">
          <app-icon name="edit"></app-icon>
          {{ (state$ | async)!.data.test?.isPractice ? 'Practice' : 'Fill in' }}
        </div>
      </div>

      <div class="list" *ngIf="(state$ | async)!.data.cards.length === 0">
        <div class="list-item-secondary" *ngIf="(state$ | async)!.data.questions.length === 0">
          <div class="list-text-message">
            No cards found
          </div>
        </div>
      </div>

      <div class="form" *ngIf="(state$ | async)!.data.cards.length > 0">
        <ng-container *ngIf="!(state$ | async)!.showSummary; else showSummary">
          <div class="field">
            <label class="field-label" for="displayName">Question</label>
            <textarea class="field-text" id="displayName"
                      disabled appAutoResize
                      [value]="(state$ | async)!.currentQuestionText"></textarea>
            <div class="field-info" *ngIf="(state$ | async)!.hasMultipleCorrectAnswers">
              Choose all that apply!
            </div>
          </div>

          <div class="field">
            <label class="field-label">
              Choose the correct
              {{ (state$ | async)!.hasMultipleCorrectAnswers ? 'answers' : 'answer' }}
            </label>
            <div class="list">
              <ng-container *ngFor="let answer of (state$ | async)!.shuffledAnswers">
                <div [class.list-item-secondary]="!(state$ | async)!.isAnswerSelected(answer)"
                     [class.list-item-primary]="(state$ | async)!.isAnswerSelected(answer)"
                     [class.list-item-disabled]="(state$ | async)!.data.finished"
                     (click)="withAnswer(answer)">
                  <div [class.list-text-secondary]="!(state$ | async)!.isAnswerSelected(answer)"
                       [class.list-text-primary]="(state$ | async)!.isAnswerSelected(answer)">
                    {{ answer.text }}
                  </div>
                  <div class="list-icons">
                    <div *ngIf="(state$ | async)!.showAsCorrectAnswer(answer)"
                         [class.list-icon-primary]="!(state$ | async)!.isAnswerSelected(answer)"
                         [class.list-icon-primary-inverted]="(state$ | async)!.isAnswerSelected(answer)">
                      <app-icon name="check_circle"></app-icon>
                    </div>
                    <div *ngIf="(state$ | async)!.showAsWrongAnswer(answer)"
                         [class.list-icon-primary]="!(state$ | async)!.isAnswerSelected(answer)"
                         [class.list-icon-primary-inverted]="(state$ | async)!.isAnswerSelected(answer)">
                      <app-icon name="error"></app-icon>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>

          <div class="field" *ngIf="(state$ | async)!.showRationale()">
            <label class="field-label" for="rationale">Rationale</label>
            <textarea class="field-text" id="rationale"
                      disabled appAutoResize
                      [value]="(state$ | async)!.currentRationaleText"></textarea>
          </div>
        </ng-container>
        <ng-template #showSummary>
          <div class="summary">
            <div class="summary-title">{{ (state$ | async)!.scoreTitle }}</div>
            <div class="summary-score">
              {{ (state$ | async)!.score }}%
            </div>
            <div class="summary-title">{{ (state$ | async)!.scoreSlogan }}</div>
          </div>
        </ng-template>

        <div class="field-actions">
          <button class="field-action-secondary"
                  [disabled]="(state$ | async)!.data.cardIndex <= 0"
                  (click)="goToPrevious()">
            <app-icon name="arrow_backward"></app-icon>
          </button>
          <div class="progress">
            <div *ngIf="!(state$ | async)!.showSummary">
              {{ ((state$ | async)!.data.cardIndex + 1) }}
              of
              {{ (state$ | async)!.data.cards.length }}
            </div>
          </div>
          <ng-container
            *ngIf="(state$ | async)!.data.cardIndex >= ((state$ | async)!.data.cards.length - 1); else nextButton">
            <button class="field-action-secondary"
                    [class.field-action-primary]="(state$ | async)!.hasAnswered"
                    [class.field-action-secondary]="!(state$ | async)!.hasAnswered"
                    [disabled]="(state$ | async)!.showSummary"
                    (click)="finish()">
              {{ (state$ | async)!.finishButtonText }}
            </button>
          </ng-container>
          <ng-template #nextButton>
            <button class="field-action-secondary"
                    [class.field-action-primary]="(state$ | async)!.hasAnswered"
                    [class.field-action-secondary]="!(state$ | async)!.hasAnswered"
                    [disabled]="!(state$ | async)!.canGoNext"
                    (click)="goToNext()">
              <app-icon name="arrow_forward"></app-icon>
            </button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
