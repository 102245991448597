import {Component, OnInit} from "@angular/core";
import {TestService} from "../../services/test.service";
import {Message} from "../../models/message";
import {CardData, TestSubmissionState} from "./test-submission.state";
import {ActivatedRoute, Router} from "@angular/router";
import {RouterTools} from "../../tools/router.tools";
import {StateSubject} from "../../tools/state.subject";
import {forkJoin, of} from "rxjs";
import {TestQuestionService} from "../../services/test-question.service";
import {NotificationService} from "../../services/notification.service";
import {Objects} from "../../tools/objects";
import {UserStorageService} from "../../services/user-storage.service";
import {TestSubmissionService} from "../../services/test-submission.service";
import {TestSubmission} from "../../models/learning/domain/test-submission";
import {SessionService} from "../../services/session.service";
import {UserService} from "../../services/user.service";
import {TestQuestionAnswer} from "../../models/learning/domain/test-question-answer";
import {PageService} from "../../services/page.service";
import {DialogOptions} from "../../models/dialog-options";
import {DialogService} from "../../services/dialog.service";

@Component({
  selector: 'app-test-fill-in',
  templateUrl: './test-submission.component.html',
  styleUrls: ['./test-submission.component.scss']
})
export class TestSubmissionComponent implements OnInit {

  state$: StateSubject<TestSubmissionState> = new StateSubject<TestSubmissionState>(TestSubmissionState.create());

  constructor(private testService: TestService,
              private testQuestionService: TestQuestionService,
              private testSubmissionService: TestSubmissionService,
              private notificationService: NotificationService,
              private userStorageService: UserStorageService,
              private sessionService: SessionService,
              private userService: UserService,
              private pageService: PageService,
              private dialogService: DialogService,
              private router: Router,
              private route: ActivatedRoute) {
    this.state$.subscribe(() => this.persistProgress());
    this.state$.subscribe(state => {
      this.pageService.invoke(page => page.reset(state.data.test ? state.data.test.displayName : 'Test')
        .withBackButton(() => this.close())
      );
    });
  }

  ngOnInit(): void {
    RouterTools.observeParamMap(this.route, params => {
      const testId = RouterTools.toNumber(params, 'testId');
      const submissionId = RouterTools.toNumber(params, 'submissionId');
      this.state$.invoke(state => state.withQueryIds(testId, submissionId));
    });
    this.state$.get(state => '' + state.data.testId).subscribe(() => this.refresh());
  }

  public refresh(): void {
    const state = this.state$.getValue();
    const testId = state.data.testId;
    if (!testId) {
      return;
    }

    const submissionId = state.data.submissionId;
    const userId = Objects.toNumber(this.sessionService.session!.userId)!;
    forkJoin([
      this.testService.oneById(testId),
      this.testQuestionService.allByTestId(testId),
      Objects.isNull(submissionId) ? of(undefined) : this.testSubmissionService.oneById(submissionId!),
      this.userService.getUsers(),
    ]).subscribe({
      next: ([test, questions, submission, users]) => {
        const cards = this.retrieveProgress();
        this.state$.invoke(state => state.refresh(userId, test, questions, cards, submission, users));
      },
      error: () => {
        this.notificationService.show(Message.error(`Failed to load test`));
        this.state$.invoke(state => state.withLoading(false));
      }
    });
  }

  public close(): void {
    const state = this.state$.getValue();
    if (!!state.data.submission?.isFinished) {
      this.doClose();
      return;
    }
    this.dialogService.show(DialogOptions
      .create('Stop Test', 'Are you sure you want to stop filling in this test?')
      .withConfirm(() => this.doClose()));
  }

  private doClose(): void {
    this.state$.invoke(state => state.reset());
    this.goBack();
  }

  public withAnswer(answer: TestQuestionAnswer): void {
    if (this.state$.getValue().data.finished) {
      return;
    }
    this.state$.invoke(state => state.withAnswer(answer));
  }

  public goToPrevious(): void {
    this.state$.invoke(state => state.goTo(state.data.cardIndex - 1));
  }

  public goToNext(): void {
    this.state$.invoke(state => state.goTo(state.data.cardIndex + 1));
  }

  public finish(): void {
    const state = this.state$.getValue();
    const test = state.data.test!;
    const userId = Objects.toNumber(this.sessionService.session!.userId)!;
    const submission = state.toSubmission(userId);

    // Finish practice
    if (test.isPractice || !Objects.isNull(submission.id)) {
      this.doFinish(submission, false);
      return;
    }

    // Create submission
    if (Objects.isNull(submission.id)) {
      this.testSubmissionService.create(submission).subscribe({
        next: submission => {
          this.doFinish(submission, true);
        },
        error: () => {
          this.notificationService.show(Message.error(`Failed to save test result`));
        }
      });
      return;
    }
  }

  public goBack(): void {
    const state = this.state$.getValue();
    const testId = state.data.testId!;
    RouterTools.goToTest(this.router, testId);
  }

  private doFinish(submission: TestSubmission, isNew: boolean): void {
    if (isNew) {
      this.notificationService.show(Message.success(`Submitted ${submission.answers.length} answers`));
    }
    this.state$.invoke(state => state.finish(submission));
  }

  private persistedKey(testId: number): string {
    return 'test.fill-in.' + testId;
  }

  private persistProgress(): void {
    const state = this.state$.getValue();
    if (Objects.isNull(state.data.testId) || state.data.loading) {
      return;
    }
    const cards = state.data.cards;
    const questions = state.data.questions;
    const isInitial = cards.length === questions.length &&
      cards.filter(card => card.answer.answers.length === 0).length === cards.length;
    const persistedCards = state.data.finished || isInitial || cards.length === 0 ? undefined : cards;
    this.userStorageService.set(this.persistedKey(state.data.testId!), persistedCards);
  }

  private retrieveProgress() {
    const state = this.state$.getValue();
    return this.userStorageService.get<CardData[]>(this.persistedKey(state.data.testId!));
  }
}
