<div class="page">
  <app-loading *ngIf="(state$ | async)!.data.loading"></app-loading>
  <div class="cards" *ngIf="!(state$ | async)!.data.loading">
    <div class="card">
      <div class="list">
        <div class="list-items-users" *ngFor="let user of (state$ | async)!.data.filteredUsers" (click)="goToUser(user.id)">
          <div class="list-text-secondary">
            <div>{{ user.displayName }}</div>
            <div>{{ user.username }}</div>
          </div>
          <div class="list-text-secondary">
            {{ (state$ | async)!.roleNames(user) }}
          </div>
        </div>
        <div class="list-item-secondary" *ngIf="(state$ | async)!.data.filteredUsers.length === 0">
          <div class="list-text-message">
            No users found
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
