<div class="page">
  <app-loading *ngIf="(state$ | async)!.data.loading"></app-loading>
  <div class="cards" *ngIf="!(state$ | async)!.data.loading">
    <div class="card">
      <div class="list">
        <div class="list-item-secondary" *ngFor="let line of (state$ | async)!.filteredLines" (click)="goToTeamLine(line)">
          <div class="list-text-secondary">
            <div>{{ line.displayName }}</div>
          </div>
          <div class="list-icons" *ngIf="!line.isActive">
            <button class="list-action-icon" disabled *ngIf="!line.isActive">
              <app-icon name="invisible"></app-icon>
            </button>
          </div>
        </div>

        <ng-container *ngIf="(state$ | async)! as state">
          <div class="list-item-secondary" *ngIf="state.inactiveLines.length > 0 && !state.data.showInactiveLines" (click)="withShowInactiveLines(true)">
            <div class="list-text-more">
              Show {{ state.inactiveLines.length }} inactive line(s) ...
            </div>
          </div>
        </ng-container>

        <div class="list-item-secondary" *ngIf="(state$ | async)!.data.lines.length === 0">
          <div class="list-text-message">
            No lines found
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
