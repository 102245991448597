import {Test} from "../../models/learning/domain/test";
import {Action} from "../../models/action";
import {TestQuestion} from "../../models/learning/domain/test-question";
import {TestQuestionAnswer} from "../../models/learning/domain/test-question-answer";
import {Objects} from "../../tools/objects";


export interface TestQuestionData {
  testId?: number;
  test?: Test;
  testQuestionId?: number;
  testQuestion?: TestQuestion;
  questions: TestQuestion[];
  actions: Action[];
  question: string;
  questionError: string;
  answers: TestQuestionAnswer[];
  answersError: string,
  rationale: string;
  isAddMulti: boolean;
  dirty: boolean;
  valid: boolean;
  loading: boolean;
}

export class TestQuestionState {
  readonly data: TestQuestionData;

  constructor(data: TestQuestionData) {
    this.data = data;
  }

  public static create(): TestQuestionState {
    return new TestQuestionState({
      testId: undefined,
      test: undefined,
      testQuestionId: undefined,
      testQuestion: undefined,
      questions: [],
      actions: [],
      question: '',
      questionError: '',
      answers: [],
      answersError: '',
      rationale: '',
      isAddMulti: false,
      dirty: false,
      valid: true,
      loading: true,
    });
  }

  public get canEdit(): boolean {
    return this.data.actions.includes(Action.EDIT);
  }

  public get canSave(): boolean {
    return this.data.dirty && this.data.valid;
  }

  public get canDelete(): boolean {
    return this.data.actions.includes(Action.DELETE);
  }

  public get canAddAnswer(): boolean {
    return this.canEdit && this.data.answers.length < 10;
  }

  public get canDeleteAnswer(): boolean {
    return this.canEdit && this.data.answers.length > 1;
  }

  public get previousQuestionId(): number | undefined {
    const index = this.data.questions.findIndex(q => q.id === this.data.testQuestionId);
    return index > 0 ? this.data.questions[index - 1].id : undefined;
  }

  public get nextQuestionId(): number | undefined {
    const index = this.data.questions.findIndex(q => q.id === this.data.testQuestionId);
    return index < this.data.questions.length - 1 ? this.data.questions[index + 1].id : undefined;
  }

  public refresh(test: Test | undefined, questions: TestQuestion[], actions: Action[]): TestQuestionState {
    const testQuestion = questions.find(q => q.id === this.data.testQuestionId);
    return new TestQuestionState({
      ...this.data,
      test: test,
      testQuestion: testQuestion,
      questions: questions.sort((a, b) => a.question.localeCompare(b.question)),
      actions: actions,
      question: testQuestion?.question || '',
      questionError: '',
      answers: Objects.ifNull(testQuestion?.answers, [{text: '', isCorrect: true}]),
      answersError: '',
      rationale: testQuestion?.rationale || '',
      dirty: false,
      loading: false,
    }).validate();
  }

  public withLoading(value: boolean): TestQuestionState {
    return new TestQuestionState({
      ...this.data,
      loading: value,
    });
  }

  public withQueryIds(testId: number | undefined, testQuestionId: number | undefined): TestQuestionState {
    return new TestQuestionState({
      ...this.data,
      testId: testId,
      testQuestionId: testQuestionId,
    });
  }

  public withQuestion(question: string): TestQuestionState {
    return new TestQuestionState({
      ...this.data,
      question: question,
      dirty: true,
    }).validate();
  }

  public withRationale(rationale: string): TestQuestionState {
    return new TestQuestionState({
      ...this.data,
      rationale: rationale,
      dirty: true,
    }).validate();
  }

  public addAnswer(): TestQuestionState {
    return new TestQuestionState({
      ...this.data,
      answers: [...this.data.answers, {text: '', isCorrect: this.data.answers.length === 0}],
      dirty: true,
    }).validate();
  }

  public withAnswerText(index: number, wrongAnswer: string): TestQuestionState {
    return new TestQuestionState({
      ...this.data,
      answers: this.data.answers.map((a, i) => i === index ? {text: wrongAnswer, isCorrect: a.isCorrect} : a),
      dirty: true,
    }).validate();
  }

  public withAnswerIsCorrect(index: number, isCorrect: boolean): TestQuestionState {
    return new TestQuestionState({
      ...this.data,
      answers: this.data.answers.map((a, i) => i === index ? {text: a.text, isCorrect: isCorrect} : a),
      dirty: true,
    }).validate();
  }

  public deleteAnswer(index: number): TestQuestionState {
    const answers = [...this.data.answers];
    answers.splice(index, 1);
    return new TestQuestionState({
      ...this.data,
      answers: answers,
      dirty: true,
    }).validate();
  }

  private validate(): TestQuestionState {
    let questionError = '';
    if (!this.data.question) {
      questionError = 'Question is required';
    } else if (this.data.question.length < 3) {
      questionError = 'Question must be at least 3 characters';
    }

    let answersError = '';
    if (this.data.answers.length === 0) {
      answersError = 'At least one answer is required';
    } else {
      let correctCount = 0;
      this.data.answers.forEach(answer => {
        if (Objects.isEmpty(answer.text)) {
          answersError = 'All answers must have text';
        }
        if (answer.isCorrect) {
          correctCount++;
        }
      });
      if (correctCount === 0) {
        answersError = 'At least one correct answer is required';
      }
    }

    return new TestQuestionState({
      ...this.data,
      questionError: questionError,
      answersError: answersError,
      valid: !questionError && !answersError,
    });
  }
}
