import {Component} from '@angular/core';
import {Observable} from "rxjs";
import {SessionService} from "./services/session.service";
import {RouterTools} from "./tools/router.tools";
import {Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  loggedIn$: Observable<boolean>;

  constructor(private sessionService: SessionService,
              router: Router) {
    this.loggedIn$ = sessionService.loggedIn$;
    sessionService.session$.subscribe(session => {
      if (session?.isTemporaryPassword) {
        RouterTools.goToProfile(router);
      }
    });

    setInterval(() => {
      this.sessionService.refresh();
    }, 60000);
  }

  currentYear(): string {
    return new Date().getFullYear().toString();
  }
}
