import {Injectable} from "@angular/core";
import {environment} from "../../environment";
import { HttpClient } from "@angular/common/http";
import {SessionService} from "./session.service";
import {Observable} from "rxjs";
import {Team} from "../models/team";
import {Action} from "../models/action";
import {Url} from "../models/url";

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  private teams = Url.create(environment.backendUrl + '/stats/teams');

  constructor(private http: HttpClient,
              private sessionService: SessionService) {
  }

  public getTeams(): Observable<Team[]> {
    const options = this.sessionService.createHttpOptions();
    return this.http.get<Team[]>(this.teams.php, options);
  }

  public getTeamsByLeagueId(leagueId: number): Observable<Team[]> {
    const options = this.sessionService.createHttpOptions();
    return this.http.get<Team[]>(this.teams.php + `?leagueId=${leagueId}`, options);
  }

  public getTeam(id: number): Observable<Team> {
    const options = this.sessionService.createHttpOptions();
    return this.http.get<Team>(this.teams.php + `?id=${id}`, options);
  }

  public createTeam(team: Partial<Team>): Observable<Team> {
    const options = this.sessionService.createHttpOptions();
    return this.http.post<Team>(this.teams.php, team, options);
  }

  public updateTeam(id: number, team: Partial<Team>): Observable<Team> {
    const options = this.sessionService.createHttpOptions();
    return this.http.put<Team>(this.teams.php + `?id=${id}`, team, options);
  }

  public deleteTeam(id: number): Observable<Team> {
    const options = this.sessionService.createHttpOptions();
    return this.http.delete<Team>(this.teams.php + `?id=${id}`, options);
  }

  public getActions(id?: number): Observable<Action[]> {
    const options = this.sessionService.createHttpOptions();
    return this.http.get<Action[]>(this.teams.actions.php + (id === undefined ? '' : `?id=${id}`), options);
  }
}
