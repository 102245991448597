import {Tab} from "./tab";

export class Tabs {
  readonly tabs: Tab[];
  readonly tabIndex: number;
  readonly goBack?: () => void;

  private constructor(tabs: Tab[], tabIndex: number, goBack?: () => void) {
    this.tabs = tabs;
    this.tabIndex = tabIndex;
    this.goBack = goBack;
  }

  public static of(tabs: Tab[], tabIndex: number = 0, goBack?: () => void): Tabs {
    return new Tabs(tabs, tabIndex, goBack);
  }

  public withTabIndex(tabIndex: number): Tabs {
    return new Tabs(this.tabs, tabIndex, this.goBack);
  }

  public withTabEnabled(index: number, enabled: boolean): Tabs {
    const newTabs = this.tabs.map((tab, i) => {
      return i === index ? tab.withEnabled(enabled) : tab;
    });
    return new Tabs(newTabs, this.tabIndex, this.goBack);
  }
}
