import {Injectable} from "@angular/core";
import {environment} from "../../environment";
import { HttpClient } from "@angular/common/http";
import {SessionService} from "./session.service";
import {Observable} from "rxjs";
import {Jam} from "../models/jam";
import {Action} from "../models/action";
import {Url} from "../models/url";

@Injectable({
  providedIn: 'root'
})
export class JamService {

  private jams = Url.create(environment.backendUrl + '/stats/jams');

  constructor(private http: HttpClient,
              private sessionService: SessionService) {
  }

  public getJamByGameId(gameId: number): Observable<Jam[]> {
    const options = this.sessionService.createHttpOptions();
    return this.http.get<Jam[]>(this.jams.php + `?gameId=${gameId}`, options);
  }

  public createOrUpdateJam(jam: Partial<Jam>): Observable<Jam> {
    const options = this.sessionService.createHttpOptions();
    return this.http.post<Jam>(this.jams.php, jam, options);
  }

  public deleteJam(gameId: number, period: number, jamNumber: number): Observable<void> {
    const options = this.sessionService.createHttpOptions();
    return this.http.delete<void>(this.jams.php + `?gameId=${gameId}&period=${period}&jamNumber=${jamNumber}`, options);
  }

  public getActions(id?: number): Observable<Action[]> {
    const options = this.sessionService.createHttpOptions();
    return this.http.get<Action[]>(this.jams.actions.php + (id === undefined ? '' : `?id=${id}`), options);
  }
}
