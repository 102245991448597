<div class="page">
  <app-loading *ngIf="(state$ | async)!.data.loading"></app-loading>
  <div class="cards" *ngIf="!(state$ | async)!.data.loading">
    <div class="card">
      <div class="list">
        <div class="list-item-secondary" *ngFor="let league of (state$ | async)!.data.filteredLeagues" (click)="goToLeague(league.id)">
          <div class="list-text-secondary">
            {{ league.displayName }}
          </div>
          <div class="list-icons" *ngIf="league.home">
            <div class="list-icon-secondary">
              <app-icon name="home"></app-icon>
            </div>
          </div>
        </div>
        <div class="list-item-secondary" *ngIf="(state$ | async)!.data.filteredLeagues.length === 0">
          <div class="list-text-message">
            No leagues found
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
