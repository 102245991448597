import {Component, OnInit} from "@angular/core";
import {StateSubject} from "../../tools/state.subject";
import {forkJoin} from "rxjs";
import {UserState} from "./user.state";
import {UserService} from "../../services/user.service";
import {SessionService} from "../../services/session.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationService} from "../../services/notification.service";
import {RouterTools} from "../../tools/router.tools";
import {Message} from "../../models/message";
import {DialogOptions} from "../../models/dialog-options";
import {DialogService} from "../../services/dialog.service";
import {PageService} from "../../services/page.service";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  state$: StateSubject<UserState> = new StateSubject<UserState>(UserState.create());

  constructor(private userService: UserService,
              private sessionService: SessionService,
              private pageService: PageService,
              private route: ActivatedRoute,
              private router: Router,
              private notificationService: NotificationService,
              private dialogService: DialogService) {
    this.state$.subscribe(state => {
      this.pageService.invoke(page => page.reset(state.data.user?.displayName || 'New User')
        .withBackButton(() => RouterTools.goToUsers(this.router))
        .withActions([
          {
            text: 'Save',
            icon: 'save',
            visible: true,
            enabled: state.canSave,
            active: true,
            primary: true,
            onClick: () => this.save()
          },
          {
            text: 'Delete',
            icon: 'delete',
            visible: !!state.data.user?.id,
            enabled: state.canDelete,
            active: false,
            primary: false,
            onClick: () => this.delete()
          }
        ])
      );
    });
  }

  ngOnInit(): void {
    RouterTools.observeParamMap(this.route, params => {
      const userId = RouterTools.toNumber(params, 'userId');
      this.state$.invoke(state => state.withQueryId(userId));
    });
    this.state$.get(state => state.data.userId).subscribe(() => {
      this.refresh();
    });
  }

  public refresh(): void {
    const state = this.state$.getValue();
    const userId = state.data.userId;
    if (!userId) {
      this.state$.invoke(state => state.refresh(undefined, []));
      return;
    }

    forkJoin({
      user: this.userService.getUser(userId),
      sessions: this.sessionService.getSessionsByUserId(userId),
    }).subscribe({
      next: ({user, sessions}) => {
        this.state$.invoke(state => state.refresh(user, sessions));
      },
      error: () => {
        this.notificationService.show(Message.error('Failed to load user'));
        this.state$.invoke(state => state.withLoading(false));
      }
    });
  }

  public save(): void {
    const state = this.state$.getValue();
    if (!state.data.user) {
      const newUser = {
        username: state.data.username,
        password: state.data.password,
        displayName: state.data.displayName,
        pronouns: state.data.pronouns,
        administrator: state.data.isAdministrator,
        skater: state.data.isSkater,
        contactSafe: state.data.isContactSafe,
        trainer: state.data.isTrainer,
        bench: state.data.isBench,
        isTemporaryPassword: state.data.isTemporaryPassword,
      };
      this.userService.createUser(newUser).subscribe({
        next: user => {
          this.copyNewUserText(user.username, user.displayName, state.data.password!);
          RouterTools.goToUser(this.router, user.id);
        },
        error: () => {
          this.notificationService.show(Message.error('Failed to save user'));
        }
      });
      return;
    }

    let updatedUser = {
      id: state.data.userId,
      username: state.data.username,
      password: state.data.password === UserState.PLACEHOLDER_PASSWORD ? undefined : state.data.password,
      displayName: state.data.displayName,
      pronouns: state.data.pronouns,
      administrator: state.data.isAdministrator,
      skater: state.data.isSkater,
      contactSafe: state.data.isContactSafe,
      trainer: state.data.isTrainer,
      bench: state.data.isBench,
      isTemporaryPassword: state.data.isTemporaryPassword,
    };
    this.userService.updateUser(state.data.user.id, updatedUser).subscribe({
      next: user => {
        this.state$.invoke(state => state.refresh(user, state.data.sessions));
      },
      error: () => {
        this.notificationService.show(Message.error('Failed to save user'));
      }
    });
  }

  public delete(): void {
    this.dialogService.show(DialogOptions
      .create('Delete User', 'Are you sure you want to delete this user?')
      .withConfirm(() => this.doDelete()));
  }

  private doDelete(): void {
    const state = this.state$.getValue();
    if (!state.data.user) {
      return;
    }
    this.userService.deleteUser(state.data.user.id).subscribe({
      next: () => {
        RouterTools.goToUsers(this.router);
        this.notificationService.show(Message.success('User deleted'));
      },
      error: () => {
        this.notificationService.show(Message.error('Failed to delete user'));
      }
    });
  }

  public deleteSession(sessionId: number): void {
    this.dialogService.show(DialogOptions
      .create('Delete Session', 'Are you sure you want to delete this session?')
      .withConfirm(() => this.doDeleteSession(sessionId)));
  }

  private doDeleteSession(sessionId: number): void {
    this.sessionService.deleteSession(sessionId).subscribe({
      next: () => {
        this.refresh();
      },
      error: () => {
        this.notificationService.show(Message.error('Failed to delete session'));
      }
    });
  }

  public copyPassword(): void {
    const state = this.state$.getValue();
    const password = state.data.password;
    navigator.clipboard.writeText(password || '').then(() => {
        this.notificationService.show(Message.success('Password copied to clipboard'));
      }
    );
  }

  private copyNewUserText(username: string, displayName: string, password: string): void {
    const url = 'https://' + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
    const text = `Hi, ${displayName}! I've created an account for you on ${url}\n\nUsername: ${username}\nPassword: ${password}\nOn first login, you'll be asked to change your password.\n\nPlease let me know if you have any questions!`;
    navigator.clipboard.writeText(text).then(() => {
      this.notificationService.show(Message.success('New user information copied to clipboard'));
    });
  }

  public withUsername(event: any): void {
    this.state$.invoke(state => state.withUsername(event.target.value));
  }

  public regeneratePassword(): void {
    this.state$.invoke(state => state.regeneratePassword());
    setTimeout(() => this.copyPassword());
  }

  public withDisplayName(event: any): void {
    this.state$.invoke(state => state.withDisplayName(event.target.value));
  }

  public withPronouns(event: any): void {
    this.state$.invoke(state => state.withPronouns(event.target.value));
  }

  public withPassword(event: any): void {
    this.state$.invoke(state => state.withPassword(event.target.value));
  }

  public withIsAdministrator(checked: boolean): void {
    this.state$.invoke(state => state.withIsAdministrator(checked));
  }

  public withIsSkater(checked: boolean): void {
    this.state$.invoke(state => state.withIsSkater(checked));
  }

  public withIsContactSafe(checked: boolean): void {
    this.state$.invoke(state => state.withIsContactSafe(checked));
  }

  public withIsTrainer(checked: boolean): void {
    this.state$.invoke(state => state.withIsTrainer(checked));
  }

  public withIsBench(checked: boolean): void {
    this.state$.invoke(state => state.withIsBench(checked));
  }

  public withIsTemporaryPassword(checked: boolean): void {
    this.state$.invoke(state => state.withIsTemporaryPassword(checked));
  }
}
