<div class="search" [class.search-visible]="visible">
  <div class="search-field-container" [class.search-field-visible]="visible">
    <input type="text" class="search-field" #search placeholder="Search ..." (input)="onInput($event)" />
  </div>
  <button class="search-button" (click)="toggle()" *ngIf="!visible">
    <app-icon name="search"></app-icon>
  </button>
  <button class="search-button" (click)="clear()" *ngIf="visible">
    <app-icon name="close"></app-icon>
  </button>
</div>
