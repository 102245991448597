import {League} from "../../models/league";
import {Team} from "../../models/team";
import {Skater} from "../../models/skater";
import {Action} from "../../models/action";
import {SearchTools} from "../../tools/search.tools";
import {Objects} from "../../tools/objects";

export interface TeamData {
  leagueId?: number;
  league?: League;
  teamId?: number;
  team?: Team;
  skaters: Skater[];
  filteredSkaters: Skater[];
  actions: Action[];
  showInactiveSkaters: boolean;
  search?: string;
  loading: boolean;
}

export class TeamState {
  readonly data: TeamData;

  constructor(data: TeamData) {
    this.data = data;
  }

  public static create(): TeamState {
    return new TeamState({
      leagueId: undefined,
      league: undefined,
      teamId: undefined,
      team: undefined,
      skaters: [],
      filteredSkaters: [],
      actions: [],
      showInactiveSkaters: false,
      loading: true,
    });
  }

  public get canEdit(): boolean {
    return this.data.actions.includes(Action.EDIT);
  }

  public get inactiveSkaters(): Skater[] {
    return this.data.skaters.filter(s => !s.isActive);
  }

  public refresh(league: League | undefined, team: Team | undefined, skaters: Skater[], actions: Action[]): TeamState {
    return new TeamState({
      ...this.data,
      league: league,
      team: team,
      skaters: skaters.sort((a, b) => {
        if (a.isActive === b.isActive) {
          return a.derbyNumber.localeCompare(b.derbyNumber);
        }
        return a.isActive ? -1 : 1;
      }),
      actions: actions,
      loading: false,
    }).filterSkaters();
  }

  public withLoading(loading: boolean): TeamState {
    return new TeamState({
      ...this.data,
      loading: loading,
    });
  }

  public withQueryIds(leagueId: number | undefined, teamId: number | undefined): TeamState {
    return new TeamState({
      ...this.data,
      leagueId: leagueId,
      teamId: teamId,
    });
  }

  public withShowInactiveSkaters(showInactiveSkaters: boolean): TeamState {
    return new TeamState({
      ...this.data,
      showInactiveSkaters: showInactiveSkaters,
    }).filterSkaters();
  }

  public withSearch(value: string | undefined): TeamState {
    return new TeamState({
      ...this.data,
      search: value,
    }).filterSkaters();
  }

  private filterSkaters(): TeamState {
    const textF = (skater: Skater) => skater.derbyName + skater.derbyNumber + (skater.isJammer ? 'Jammer' : '') + (skater.isCaptain ? 'Captain' : '');
    return new TeamState({
      ...this.data,
      showInactiveSkaters: Objects.isNull(this.data.search) ? this.data.showInactiveSkaters : true,
      filteredSkaters: SearchTools.filter(this.data.skaters.filter(s => this.data.showInactiveSkaters || s.isActive), textF, this.data.search),
    });
  }
}
