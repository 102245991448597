<div class="page">
  <app-loading *ngIf="(state$ | async)!.data.loading"></app-loading>
  <div class="cards" *ngIf="!(state$ | async)!.data.loading">
    <div class="card">
      <div class="list">
        <div class="list-item-secondary" *ngFor="let skater of (state$ | async)!.data.filteredSkaters"
             (click)="goToSkater(skater)">
          <div class="list-text-secondary">
            <div>{{ skater.derbyNumber }}</div>
            <div>{{ skater.derbyName }}</div>
          </div>
          <div class="list-icons">
            <div class="list-icon-secondary" *ngIf="skater.isJammer">
              <app-icon name="jammer"></app-icon>
            </div>
            <div class="list-icon-secondary" *ngIf="skater.isPivot">
              <app-icon name="pivot"></app-icon>
            </div>
            <div class="list-icon-secondary" *ngIf="skater.isCaptain">
              <app-icon name="captain"></app-icon>
            </div>
            <div class="list-icon-secondary" *ngIf="!skater.isActive">
              <app-icon name="invisible"></app-icon>
            </div>
          </div>
        </div>

        <ng-container *ngIf="(state$ | async)! as state">
          <div class="list-item-secondary" *ngIf="state.inactiveSkaters.length > 0 && !state.data.showInactiveSkaters"
               (click)="withShowInactiveSkaters(true)">
            <div class="list-text-more">
              Show {{ state.inactiveSkaters.length }} inactive skater(s) ...
            </div>
          </div>
        </ng-container>

        <div class="list-item-secondary" *ngIf="(state$ | async)!.data.filteredSkaters.length === 0">
          <div class="list-text-message">
            No skaters found
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
