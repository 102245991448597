import {AfterViewInit, Component, ElementRef, ViewChild} from "@angular/core";
import {Observable} from "rxjs";
import {UserSession} from "../../models/user-session";
import {SessionService} from "../../services/session.service";
import {LoginState} from "./login.state";
import {NotificationService} from "../../services/notification.service";
import {Message} from "../../models/message";
import {Router} from "@angular/router";
import {StateSubject} from "../../tools/state.subject";
import {RouterTools} from "../../tools/router.tools";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements AfterViewInit {

  @ViewChild('username') usernameField!: ElementRef;

  session$: Observable<UserSession | undefined>;
  loggedIn$: Observable<boolean>;

  state$: StateSubject<LoginState> = new StateSubject(LoginState.create());

  constructor(private sessionService: SessionService,
              private notificationService: NotificationService,
              private router: Router) {
    this.session$ = sessionService.session$;
    this.loggedIn$ = sessionService.loggedIn$;
  }

  ngAfterViewInit() {
    this.usernameField.nativeElement.focus();
  }

  public withUsername(event: any): void {
    this.state$.invoke(state => state.withUsername(event.target.value));
  }

  public withPassword(event: any): void {
    this.state$.invoke(state => state.withPassword(event.target.value));
  }

  public login(): void {
    const state = this.state$.getValue();
    this.sessionService.login(state.username, state.password).subscribe({
      next: () => {
        this.notificationService.hide();
        RouterTools.goHome(this.router);
      },
      error: () => {
        this.notificationService.show(Message.error('Invalid username or password'));
      }
    })
  }
}
