import {Message} from "../../models/message";

export class NotificationsState {
  readonly message: Message | undefined;
  readonly timestamp: number;
  readonly delay: number = 5000;

  constructor(message: Message | undefined, timestamp: number) {
    this.message = message;
    this.timestamp = timestamp;
  }

  public static create(): NotificationsState {
    return new NotificationsState(undefined, 0);
  }

  public withMessage(message: Message | undefined): NotificationsState {
    if (!message) {
      return new NotificationsState(undefined, 0);
    }
    return new NotificationsState(message, Date.now());
  }
}
