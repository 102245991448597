<div class="page">
  <div class="cards">
    <div class="card">
      <div class="list">
        <div class="list-text-message">
          You do not have access do this page.
        </div>
      </div>
    </div>
  </div>
</div>
