import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProfileComponent} from "./components/profile/profile.component";
import {SkillsComponent} from "./components/skills/skills.component";
import {EventsComponent} from "./components/events/events.component";
import {ClassesComponent} from "./components/classes/classes.component";
import {TestsComponent} from "./components/tests/tests.component";
import {UsersComponent} from "./components/users/users.component";
import {LeaguesComponent} from "./components/leagues/leagues.component";
import {LeagueComponent} from "./components/league/league.component";
import {TeamComponent} from "./components/team/team.component";
import {TeamsComponent} from "./components/teams/teams.component";
import {GamesComponent} from "./components/games/games.component";
import {GameComponent} from "./components/game/game.component";
import {SkaterComponent} from "./components/skater/skater.component";
import {LiveComponent} from "./components/live/live.component";
import {JamsComponent} from "./components/jams/jams.component";
import {AuthGuard} from "./services/auth.guard";
import {Scope} from "./models/scope";
import {AccessDeniedComponent} from "./components/access-denied/access-denied.component";
import {TeamLineComponent} from "./components/team-line/team-line.component";
import {UserComponent} from "./components/user/user.component";
import {TestComponent} from "./components/test/test.component";
import {TestQuestionComponent} from "./components/test-question/test-question.component";
import {TestSubmissionComponent} from "./components/test-submission/test-submission.component";
import {TestStudyComponent} from "./components/test-study/test-study.component";
import {TestQuestionUploadComponent} from "./components/test-question-upload/test-question-upload.component";
import {HomeComponent} from "./components/home/home.component";
import {TeamLinesComponent} from "./components/team-lines/team-lines.component";
import {SkatersComponent} from "./components/skaters/skaters.component";
import {TestQuestionsComponent} from "./components/test-questions/test-questions.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.PROFILE_READ}
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.HOME_READ}
  },
  {
    path: 'classes',
    component: ClassesComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.CLASSES_READ}
  },
  {
    path: 'events',
    component: EventsComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.EVENTS_READ}
  },
  {
    path: 'games/leagues',
    component: LeaguesComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.LEAGUES_READ}
  },
  {
    path: 'games/teams',
    component: TeamsComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.TEAMS_READ}
  },
  {
    path: 'games/leagues/new',
    component: LeagueComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.LEAGUES_WRITE}
  },
  {
    path: 'games/leagues/:leagueId',
    component: LeagueComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.LEAGUES_READ}
  },
  {
    path: 'games/leagues/:leagueId/teams/new',
    component: TeamComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.TEAMS_WRITE}
  },
  {
    path: 'games/leagues/:leagueId/teams/:teamId',
    component: TeamComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.TEAMS_READ}
  },
  {
    path: 'games/leagues/:leagueId/teams/:teamId/lines',
    component: TeamLinesComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.SKATERS_WRITE}
  },
  {
    path: 'games/leagues/:leagueId/teams/:teamId/lines/new',
    component: TeamLineComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.TEAMS_WRITE}
  },
  {
    path: 'games/leagues/:leagueId/teams/:teamId/lines/:lineId',
    component: TeamLineComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.TEAMS_READ}
  },
  {
    path: 'games/leagues/:leagueId/teams/:teamId/skaters',
    component: SkatersComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.SKATERS_WRITE}
  },
  {
    path: 'games/leagues/:leagueId/teams/:teamId/skaters/new',
    component: SkaterComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.SKATERS_WRITE}
  },
  {
    path: 'games/leagues/:leagueId/teams/:teamId/skaters/:skaterId',
    component: SkaterComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.SKATERS_READ}
  },
  {
    path: 'games',
    component: GamesComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.GAMES_READ}
  },
  {
    path: 'games/new',
    component: GameComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.GAMES_WRITE}
  },
  {
    path: 'games/:gameId',
    component: GameComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.GAMES_READ}
  },
  {
    path: 'games/:gameId/jams',
    component: JamsComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.GAMES_READ}
  },
  {
    path: 'games/:gameId/stats',
    component: LiveComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.GAMES_READ}
  },
  {
    path: 'games/:gameId/stats/:mode',
    component: LiveComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.GAMES_READ}
  },
  {
    path: 'skills',
    component: SkillsComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.SKILLS_READ}
  },
  {
    path: 'tests',
    component: TestsComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.TESTS_READ}
  },
  {
    path: 'tests/new',
    component: TestComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.TESTS_WRITE}
  },
  {
    path: 'tests/:testId',
    component: TestComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.TESTS_READ}
  },
  {
    path: 'tests/:testId/questions',
    component: TestQuestionsComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.TESTS_READ}
  },
  {
    path: 'tests/:testId/questions/upload',
    component: TestQuestionUploadComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.TESTS_WRITE}
  },
  {
    path: 'tests/:testId/questions/new',
    component: TestQuestionComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.TESTS_WRITE}
  },
  {
    path: 'tests/:testId/questions/:questionId',
    component: TestQuestionComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.TESTS_READ}
  },
  {
    path: 'tests/:testId/submissions/new',
    component: TestSubmissionComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.TESTS_WRITE},
  },
  {
    path: 'tests/:testId/submissions/:submissionId',
    component: TestSubmissionComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.TESTS_READ},
  },
  {
    path: 'tests/:testId/study',
    component: TestStudyComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.TESTS_READ},
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.USERS_WRITE}
  },
  {
    path: 'users/new',
    component: UserComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.USERS_WRITE}
  },
  {
    path: 'users/:userId',
    component: UserComponent,
    canActivate: [AuthGuard],
    data: {scope: Scope.USERS_WRITE}
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
