export class Message {
  readonly type: string; // error, success
  readonly text: string;

  constructor(type: string, text: string) {
    this.type = type;
    this.text = text;
  }

  public static error(text: string): Message {
    return new Message('error', text);
  }

  public static success(text: string): Message {
    return new Message('success', text);
  }
}
