import {Component, OnDestroy} from "@angular/core";

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnDestroy {

  visible: boolean = false;

  private timeout: any;

  constructor() {
    this.timeout = setTimeout(() => this.visible = true, 100);
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeout);
  }
}
