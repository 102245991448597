import {Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {BehaviorSubject, map, Observable, of} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SvgService {

  private cache: Map<string, Observable<string>> = new Map<string, Observable<string>>();

  constructor(private http: HttpClient) {
  }

  public oneByName(name: string): Observable<string> {
    if (this.cache.has(name)) {
      return this.cache.get(name) as Observable<string>;
    }
    const url = 'assets/img/icon_' + name + '.svg';
    const httpGet = this.http.get(url, {responseType: 'text'})
    const subject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    this.cache.set(name, subject);
    httpGet.subscribe(svg => {
      subject.next(svg);
    });
    return subject.asObservable();
  }
}
