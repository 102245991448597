<div class="page">
  <app-loading *ngIf="(state$ | async)!.data.loading"></app-loading>
  <ng-container *ngIf="!(state$ | async)!.data.loading">
    <div class="card" *ngIf="!(state$ | async)!.data.isTemporaryPassword">
      <div class="card-header">
        <div class="subtitle">
          <app-icon name="person"></app-icon>
          Profile
        </div>
      </div>
      <div class="form">
        <div class="field">
          <app-auto-complete-distraction></app-auto-complete-distraction>
          <label class="field-label" for="profile-test">Username</label>
          <input type="text" class="field-text" id="profile-test"
                 autocomplete="profile-test"
                 [value]="(state$ | async)!.data.username"
                 (input)="withUsername($event)"/>
          <div class="field-error" *ngIf="(state$ | async)!.data.usernameError">
            {{(state$ | async)!.data.usernameError}}
          </div>
        </div>
        <div class="field">
          <label class="field-label" for="displayName">Derby Name</label>
          <input type="text" class="field-text" id="displayName"
                 [value]="(state$ | async)!.data.displayName"
                 (input)="withDisplayName($event)"/>
          <div class="field-error" *ngIf="(state$ | async)!.data.displayNameError">
            {{(state$ | async)!.data.displayNameError}}
          </div>
        </div>
        <div class="field">
          <label class="field-label" for="pronouns">Pronouns</label>
          <input type="text" class="field-text" id="pronouns"
                 [value]="(state$ | async)!.data.pronouns"
                 (input)="withPronouns($event)"/>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <div class="subtitle">
          <app-icon name="password"></app-icon>
          {{ (state$ | async)!.data.isTemporaryPassword ? 'Change Temporary Password' : 'Change Password' }}
        </div>
      </div>
      <div class="form">
        <div class="field">
          <label class="field-label" for="old-p">Current password</label>
          <input type="password" class="field-text" id="old-p"
                 [value]="(state$ | async)!.data.oldPassword"
                 (input)="withOldPassword($event)"/>
          <div class="field-error" *ngIf="(state$ | async)!.data.oldPasswordError">
            {{(state$ | async)!.data.oldPasswordError}}
          </div>
        </div>
        <div class="field">
          <label class="field-label" for="new-p">New password</label>
          <input type="password" class="field-text" id="new-p"
                 [value]="(state$ | async)!.data.newPassword"
                 (input)="withNewPassword($event)"/>
          <div class="field-error" *ngIf="(state$ | async)!.data.newPasswordError">
            {{(state$ | async)!.data.newPasswordError}}
          </div>
        </div>
        <div class="field">
          <label class="field-label" for="new-p2">Repeat new password</label>
          <input type="password" class="field-text" id="new-p2"
                 [value]="(state$ | async)!.data.newPassword2"
                 (input)="withNewPassword2($event)"/>
          <div class="field-error" *ngIf="(state$ | async)!.data.newPassword2Error">
            {{(state$ | async)!.data.newPassword2Error}}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
