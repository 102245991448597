import {Injectable} from "@angular/core";
import {environment} from "../../environment";
import {HttpClient} from "@angular/common/http";
import {SessionService} from "./session.service";
import {catchError, Observable, of} from "rxjs";
import {Game} from "../models/game";
import {Url} from "../models/url";
import {AbstractService} from "./abstract-service";
import {GameDto} from "../models/stats/dto/game-dto";
import {GameMapper} from "../models/stats/domain/game-mapper";

@Injectable({
  providedIn: 'root'
})
export class GameService extends AbstractService<Game, GameDto> {

  private games = Url.create(environment.backendUrl + '/stats/games');

  constructor(http: HttpClient,
              sessionService: SessionService) {
    super(http, sessionService, '/stats/games', GameMapper.toDto, GameMapper.fromDto);
  }

  public oneByLiveIsTrue(): Observable<Game | undefined> {
    const options = this.sessionService.createHttpOptions();
    return this.http.get<GameDto>(this.games.slash('live').php, options)
      .pipe(
        this.mapDto(),
        catchError(err => {
          if (err.status === 404) {
            return of(undefined);
          }
          throw err;
        })
      );
  }
}
