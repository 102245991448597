<div class="page">
  <div class="cards">
    <div class="card">
      <div class="card-header">
        <div class="subtitle">
          <app-icon name="calendar"></app-icon>
          April 2024
        </div>
        <div class="header-actions">
          <div class="header-action-group">
            <button class="button-icon-secondary">
              <app-icon name="arrow_backward"></app-icon>
            </button>
            <button class="button-icon-secondary">
              <app-icon name="arrow_forward"></app-icon>
            </button>
          </div>
          <button class="button-icon-secondary">
            <app-icon name="search"></app-icon>
          </button>
        </div>
      </div>
      <div class="list">
        <div class="list-group">Week 14</div>
        <div class="list-item-secondary">
          <div class="list-text-secondary">
            <div>02 04 2024 &nbsp;&nbsp;&nbsp; Contact Safe</div>
            <div>19:30 - 22:00</div>
          </div>
          <div class="list-actions">
            <button class="list-action-secondary">
              <app-icon name="pin_full"></app-icon>
              Attended
            </button>
          </div>
        </div>
        <div class="list-item-secondary">
          <div class="list-text-secondary">
            <div>02 04 2024 &nbsp;&nbsp;&nbsp; Start to Scrimmage</div>
            <div>19:30 - 21:30</div>
          </div>
          <div class="list-actions">
            <button class="list-action-secondary">
              <app-icon name="pin"></app-icon>
              Check In
            </button>
          </div>
        </div>
        <div class="list-item-secondary">
          <div class="list-text-secondary">
            <div>07 04 2024 &nbsp;&nbsp;&nbsp; Start to Scrimmage</div>
            <div>16:00 - 18:00</div>
          </div>
        </div>
        <div class="list-item-secondary">
          <div class="list-text-secondary">
            <div>07 04 2024 &nbsp;&nbsp;&nbsp; Jammer Class</div>
            <div>17:00 - 18:00</div>
          </div>
        </div>
        <div class="list-item-secondary">
          <div class="list-text-secondary">
            <div>07 04 2024 &nbsp;&nbsp;&nbsp; Contact Safe</div>
            <div>18:00 - 21:00</div>
          </div>
        </div>
        <div class="list-group">Week 15</div>
        <div class="list-item-secondary">
          <div class="list-text-secondary">
            <div>09 04 2024 &nbsp;&nbsp;&nbsp; Contact Safe</div>
            <div>19:30 - 22:00</div>
          </div>
        </div>
        <div class="list-item-secondary">
          <div class="list-text-secondary">
            <div>14 04 2024 &nbsp;&nbsp;&nbsp; Jammer Class</div>
            <div>17:00 - 18:00</div>
          </div>
        </div>
        <div class="list-item-secondary">
          <div class="list-text-secondary">
            <div>14 04 2024 &nbsp;&nbsp;&nbsp; Contact Safe</div>
            <div>18:00 - 21:00</div>
          </div>
        </div>
        <div class="list-group">Week 16</div>
        <div class="list-item-secondary">
          <div class="list-text-secondary">
            <div>16 04 2024 &nbsp;&nbsp;&nbsp; Contact Safe</div>
            <div>19:30 - 22:00</div>
          </div>
        </div>
        <div class="list-item-secondary">
          <div class="list-text-secondary">
            <div>21 04 2024 &nbsp;&nbsp;&nbsp; One Love vs Rainy City &#64; Manchester</div>
            <div>13:00 - 18:00</div>
          </div>
          <div class="list-actions">
            <button class="list-action-primary">
              <app-icon name="volunteer"></app-icon>
              Volunteer
            </button>
          </div>
        </div>
        <div class="list-item-secondary">
          <div class="list-text-secondary">
            <div>21 04 2024 &nbsp;&nbsp;&nbsp; Jammer Class</div>
            <div>17:00 - 18:00</div>
          </div>
        </div>
        <div class="list-item-secondary">
          <div class="list-text-secondary">
            <div>21 04 2024 &nbsp;&nbsp;&nbsp; Contact Safe</div>
            <div>18:00 - 21:00</div>
          </div>
        </div>
        <div class="list-group">Week 17</div>
        <div class="list-item-secondary">
          <div class="list-text-secondary">
            <div>23 04 2024 &nbsp;&nbsp;&nbsp; Contact Safe</div>
            <div>19:30 - 22:00</div>
          </div>
        </div>
        <div class="list-item-secondary">
          <div class="list-text-secondary">
            <div>28 04 2024 &nbsp;&nbsp;&nbsp; POD vs Utrecht &#64; De Polder</div>
            <div>13:00 - 18:00</div>
          </div>
          <div class="list-actions">
            <button class="list-action-primary">
              <app-icon name="volunteer"></app-icon>
              Volunteer
            </button>
          </div>
        </div>
        <div class="list-group">Week 18</div>
        <div class="list-item-secondary">
          <div class="list-text-secondary">
            <div>30 04 2024 &nbsp;&nbsp;&nbsp; Contact Safe</div>
            <div>19:30 - 22:00</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
