import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {LoginComponent} from "./components/login/login.component";
import {HomeComponent} from "./components/home/home.component";
import {NotificationsComponent} from "./components/notifications/notifications.component";
import {ProfileComponent} from "./components/profile/profile.component";
import {SkillsComponent} from "./components/skills/skills.component";
import {
  AutoCompleteDistractionComponent
} from "./components/auto-complete-distraction/auto-complete-distraction.component";
import {AuthInterceptor} from "./interceptors/auth.interceptor";
import {EventsComponent} from "./components/events/events.component";
import {ClassesComponent} from "./components/classes/classes.component";
import {TestsComponent} from "./components/tests/tests.component";
import {UsersComponent} from "./components/users/users.component";
import {LeaguesComponent} from "./components/leagues/leagues.component";
import {LeagueComponent} from "./components/league/league.component";
import {TeamComponent} from "./components/team/team.component";
import {TeamsComponent} from "./components/teams/teams.component";
import {GamesComponent} from "./components/games/games.component";
import {GameComponent} from "./components/game/game.component";
import {SkaterComponent} from "./components/skater/skater.component";
import {LiveComponent} from "./components/live/live.component";
import {CheckboxComponent} from "./components/checkbox/checkbox.component";
import {DateComponent} from "./components/date/date.component";
import {JamsComponent} from "./components/jams/jams.component";
import {TrackComponent} from "./components/track/track.component";
import {AccessDeniedComponent} from "./components/access-denied/access-denied.component";
import {TeamLineComponent} from "./components/team-line/team-line.component";
import {UserComponent} from "./components/user/user.component";
import {SelectComponent} from "./components/select/select.component";
import {DialogComponent} from "./components/dialog/dialog.component";
import {SearchComponent} from "./components/search/search.component";
import {TestComponent} from "./components/test/test.component";
import {TestQuestionComponent} from "./components/test-question/test-question.component";
import {AutoResizeDirective} from "./directives/auto-resize.directive";
import {TestStudyComponent} from "./components/test-study/test-study.component";
import {TestSubmissionComponent} from "./components/test-submission/test-submission.component";
import {TestQuestionUploadComponent} from "./components/test-question-upload/test-question-upload.component";
import {IconComponent} from "./components/icon/icon.component";
import {TabsComponent} from "./components/tabs/tabs.component";
import {PageComponent} from "./components/page/page.component";
import {TeamLinesComponent} from "./components/team-lines/team-lines.component";
import {SkatersComponent} from "./components/skaters/skaters.component";
import {TestQuestionsComponent} from "./components/test-questions/test-questions.component";
import {LoadingComponent} from "./components/loading/loading.component";

@NgModule({
  declarations: [
    AccessDeniedComponent,
    AppComponent,
    AutoCompleteDistractionComponent,
    AutoResizeDirective,
    CheckboxComponent,
    ClassesComponent,
    DateComponent,
    DialogComponent,
    EventsComponent,
    GameComponent,
    GamesComponent,
    HomeComponent,
    IconComponent,
    JamsComponent,
    LeagueComponent,
    LeaguesComponent,
    LiveComponent,
    LoadingComponent,
    LoginComponent,
    NotificationsComponent,
    PageComponent,
    ProfileComponent,
    SearchComponent,
    SelectComponent,
    SkaterComponent,
    SkatersComponent,
    SkillsComponent,
    TabsComponent,
    TeamComponent,
    TeamLineComponent,
    TeamLinesComponent,
    TeamsComponent,
    TestComponent,
    TestsComponent,
    TestQuestionComponent,
    TestQuestionsComponent,
    TestQuestionUploadComponent,
    TestStudyComponent,
    TestSubmissionComponent,
    TrackComponent,
    UserComponent,
    UsersComponent
  ],
  bootstrap: [AppComponent], imports: [BrowserModule,
    AppRoutingModule], providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
}
