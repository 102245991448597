import {Game} from "../../models/game";
import {Team} from "../../models/team";
import {Skater} from "../../models/skater";
import {Jam} from "../../models/jam";
import {JamExtraData} from "../../models/jam-extra-data";
import {Color} from "../../models/color";
import {ColorTools} from "../../tools/color.tools";
import {LapInfo} from "../../models/lapInfo";
import {TeamLine} from "../../models/team-line";
import {JamTools} from "../../tools/jam.tools";
import {PredictionTools} from "../../tools/predictionTools";
import {Objects} from "../../tools/objects";

export interface PerformanceInfo {
  jamsPlayed: number;
  leads: number;
  penalties: number;
  score: number;
  scoreRel: number; // 0  - 1
  scoreMin: number;
  scoreMinRel: number; // 0 - 1
  scoreAvg: number;
}

export interface UnitInfo extends PerformanceInfo {
  id: number;
  displayName: string;
  extraName: string;
}

export interface JamInfo {
  title: string;
  jammer1?: Skater;
  jammer2?: Skater;
  score: string;
  score1?: number;
  score2?: number;
  lead?: number;
  isPredictedJammer1: boolean;
  isPredictedJammer2: boolean;
  isCurrentJammer1: boolean;
  isCurrentJammer2: boolean;
  isPredictedScore: boolean;
  period?: number;
  showSeparator?: boolean;
}

export interface ComboInfo extends PerformanceInfo {
  jammer: Skater;
  line?: TeamLine; // Either line or pivot
  pivot?: Skater;
}

export interface SkaterVsSkaterInfo {
  score: number;
  scoreAvg: number;
  jamsPlayed: number;
}

export class VersusMode {
  public static AVG_SCORE = new VersusMode("Avg score diff");
  public static SCORE = new VersusMode("Score diff");
  public static JAMS_PLAYED = new VersusMode("Jams Played");
  public static VALUES = [VersusMode.AVG_SCORE, VersusMode.SCORE, VersusMode.JAMS_PLAYED];

  constructor(public readonly displayName: string) {
  }
}

export class LiveMode {
  public static JAMS = new LiveMode("jams");
  public static SKATER_PERFORMANCE = new LiveMode("performance");
  public static ESCAPES = new LiveMode("escapes");
  public static VALUES = [LiveMode.SKATER_PERFORMANCE, LiveMode.JAMS, LiveMode.ESCAPES];

  constructor(public readonly code: string) {
  }

  public static fromCode(code: string | undefined): LiveMode | undefined {
    return LiveMode.VALUES.find(v => v.code === code);
  }
}

export interface LiveData {
  gameId: number | undefined;
  game?: Game;
  teams: (Team | undefined)[];
  skaters: Skater[][];
  lines: TeamLine[][];
  jams: Jam[];
  jamExtraDatas: JamExtraData[];
  colors: Color[];

  periodScores: number[][];
  totalScores: number[];

  jammerInfos: UnitInfo[][];
  lineInfos: UnitInfo[][];
  pivotInfos: UnitInfo[][];

  teamLapInfos: LapInfo[][];
  jammerLapInfos: LapInfo[][][];
  lineLapInfos: LapInfo[][][];
  pivotLapInfos: LapInfo[][][];

  jamInfos: JamInfo[][];
  currentJamInfo?: JamInfo;

  jammerVsJammerInfos: SkaterVsSkaterInfo[][];
  jammerVsLineInfos: SkaterVsSkaterInfo[][][];
  jammerVsPivotInfos: SkaterVsSkaterInfo[][][];

  comboInfos: ComboInfo[][];

  mode: LiveMode;
  jammerVsJammerMode: VersusMode;
  jammerVsLineMode: VersusMode;
  jammerVsPivotMode: VersusMode;
}

export class LiveState {

  readonly data: LiveData;

  constructor(data: LiveData) {
    this.data = data;
  }

  public static create(): LiveState {
    return new LiveState({
      gameId: undefined,
      game: undefined,
      teams: [undefined, undefined],
      skaters: [[], []],
      lines: [[], []],
      jams: [],
      jamExtraDatas: [],
      colors: ColorTools.getColors(),
      periodScores: [[], []],
      totalScores: [0, 0],
      jammerInfos: [],
      lineInfos: [],
      pivotInfos: [],
      teamLapInfos: [[], []],
      jammerLapInfos: [[], []],
      lineLapInfos: [[], []],
      pivotLapInfos: [[], []],
      jamInfos: [],
      jammerVsJammerInfos: [],
      jammerVsLineInfos: [[], []],
      jammerVsPivotInfos: [[], []],
      comboInfos: [[], []],
      mode: LiveMode.SKATER_PERFORMANCE,
      jammerVsJammerMode: VersusMode.AVG_SCORE,
      jammerVsLineMode: VersusMode.AVG_SCORE,
      jammerVsPivotMode: VersusMode.AVG_SCORE,
    });
  }

  public teamName(teamIndex: number): string {
    return this.data.teams[teamIndex]?.displayName || 'Team 1';
  }

  public get showData(): boolean {
    return this.jamsAvailable()
      || this.escapesAvailable()
      || this.skaterPerformanceAvailable();
  }

  public jamsAvailable(): boolean {
    return this.data.jamInfos.some(jams => jams.length > 0);
  }

  public escapesAvailable(): boolean {
    return this.escapesPerTeamAvailable() || this.escapesPerJammerAvailable() || this.escapesPerLineAvailable() || this.escapesPerPivotAvailable();
  }

  public escapesPerTeamAvailable(): boolean {
    return this.data.teamLapInfos.some(infos => infos.length > 0);
  }

  public escapesPerJammerAvailable(): boolean {
    return this.data.jammerLapInfos.some(jammers => jammers.some(infos => infos.length > 0));
  }

  public escapesPerLineAvailable(): boolean {
    return this.data.lineLapInfos.some(lines => lines.some(infos => infos.length > 0));
  }

  public escapesPerPivotAvailable(): boolean {
    return this.data.pivotLapInfos.some(pivots => pivots.some(infos => infos.length > 0));
  }

  public skaterPerformanceAvailable(): boolean {
    return this.jammersAvailable()
      || this.linesAvailable()
      || this.pivotsAvailable()
      || this.jammerVsJammerAvailable()
      || this.jammerVsLineAvailable(0)
      || this.jammerVsLineAvailable(1)
      || this.jammerVsPivotAvailable(0)
      || this.jammerVsPivotAvailable(1);
  }

  public jammersAvailable(): boolean {
    return this.data.jammerInfos.some(teamJammers => teamJammers.length > 0);
  }

  public linesAvailable(): boolean {
    return this.data.lineInfos.some(teamLines => teamLines.length > 0);
  }

  public pivotsAvailable(): boolean {
    return this.data.pivotInfos.some(teamPivots => teamPivots.length > 0);
  }

  public combosAvailable(): boolean {
    return this.data.comboInfos.some(teamCombos => teamCombos.length > 0);
  }

  public jammerVsJammerAvailable(): boolean {
    return this.data.jammerVsJammerInfos.length > 0;
  }

  public jammerVsLineAvailable(teamIndex: number): boolean {
    return this.data.jammerVsLineInfos[teamIndex].length > 0;
  }

  public jammerVsPivotAvailable(teamIndex: number): boolean {
    return this.data.jammerVsPivotInfos[teamIndex].length > 0;
  }

  public get showJams(): boolean {
    return this.data.mode === LiveMode.JAMS && this.jamsAvailable();
  }

  public get showSkaterPerformance(): boolean {
    return this.data.mode === LiveMode.SKATER_PERFORMANCE && this.skaterPerformanceAvailable();
  }

  public get showJammers(): boolean {
    return this.data.mode === LiveMode.SKATER_PERFORMANCE && this.jammersAvailable();
  }

  public get showLines(): boolean {
    return this.data.mode === LiveMode.SKATER_PERFORMANCE && this.linesAvailable();
  }

  public get showPivots(): boolean {
    return this.data.mode === LiveMode.SKATER_PERFORMANCE && this.pivotsAvailable();
  }

  public get showCombos(): boolean {
    return this.data.mode === LiveMode.SKATER_PERFORMANCE && this.combosAvailable();
  }

  public get showJammerVsJammer(): boolean {
    return this.data.mode === LiveMode.SKATER_PERFORMANCE && this.jammerVsJammerAvailable();
  }

  public get showJammerVsLine(): boolean {
    return this.data.mode === LiveMode.SKATER_PERFORMANCE && (this.jammerVsLineAvailable(0) || this.jammerVsLineAvailable(1));
  }

  public get showJammerVsPivot(): boolean {
    return this.data.mode === LiveMode.SKATER_PERFORMANCE && (this.jammerVsPivotAvailable(0) || this.jammerVsPivotAvailable(1));
  }

  public get showEscapes(): boolean {
    return this.data.mode === LiveMode.ESCAPES && this.escapesAvailable();
  }

  public get showEscapesPerTeam(): boolean {
    return this.data.mode === LiveMode.ESCAPES && this.escapesPerTeamAvailable();
  }

  public get showEscapesPerJammer(): boolean {
    return this.data.mode === LiveMode.ESCAPES && this.escapesPerJammerAvailable();
  }

  public get showEscapesPerLine(): boolean {
    return this.data.mode === LiveMode.ESCAPES && this.escapesPerLineAvailable();
  }

  public get showEscapesPerPivot(): boolean {
    return this.data.mode === LiveMode.ESCAPES && this.escapesPerPivotAvailable();
  }

  public get hasJamsInBothPeriods(): boolean {
    return this.data.jams.some(j => j.period === 1) && this.data.jams.some(j => j.period === 2);
  }

  public refreshGame(game?: Game): LiveState {
    return new LiveState({
      ...this.data,
      game: game,
    });
  }

  public refresh(team1: Team, team2: Team, skaters1: Skater[], skaters2: Skater[], lines1: TeamLine[], lines2: TeamLine[], game: Game | undefined, jams: Jam[]): LiveState {
    return new LiveState({
      ...this.data,
      game: game,
      teams: [team1, team2],
      skaters: [
        JamTools.filterAndSortSkaters(skaters1, jams),
        JamTools.filterAndSortSkaters(skaters2, jams)
      ],
      lines: [
        JamTools.filterAndSortLines(lines1, jams),
        JamTools.filterAndSortLines(lines2, jams)
      ],
      jams: jams.sort((a, b) => (a.period * 1000 + a.jamNumber) - (b.period * 1000 + b.jamNumber)),
      jamExtraDatas: jams.map(j => JamExtraData.fromJson(j.extraData)),
    })
      .updateScores()
      .updateJammerInfos()
      .updateLineInfos()
      .updatePivotInfos()
      .updateJammerVsJammerInfos()
      .updateJammerVsLineInfos()
      .updateJammerVsPivotInfos()
      .updateComboInfos()
      .updateJamInfos()
      .updateLapInfos();
  }

  public withQueryParams(gameId: number | undefined, mode?: string): LiveState {
    return new LiveState({
      ...this.data,
      gameId: gameId,
      mode: LiveMode.fromCode(mode) || this.data.mode,
    });
  }

  public withJammerVsJammerMode(mode: VersusMode): LiveState {
    return new LiveState({
      ...this.data,
      jammerVsJammerMode: mode,
    });
  }

  public withJammerVsLineMode(mode: VersusMode): LiveState {
    return new LiveState({
      ...this.data,
      jammerVsLineMode: mode,
    });
  }

  public withJammerVsPivotMode(mode: VersusMode): LiveState {
    return new LiveState({
      ...this.data,
      jammerVsPivotMode: mode,
    });
  }

  private updateScores(): LiveState {
    const team1PeriodScores = this.calculatePeriodScores(0);
    const team2PeriodScores = this.calculatePeriodScores(1);
    return new LiveState({
      ...this.data,
      periodScores: [
        team1PeriodScores,
        team2PeriodScores,
      ],
      totalScores: [
        team1PeriodScores.reduce((a, b) => a + b, 0),
        team2PeriodScores.reduce((a, b) => a + b, 0),
      ]
    });
  }

  private calculatePeriodScores(teamIndex: number): number[] {
    const result: number[] = [0, 0];
    for (let i = 0; i < this.data.jams.length; i++) {
      const jam = this.data.jams[i];
      const score = JamTools.score(jam, teamIndex) || 0;
      result[jam.period - 1] += score;
    }
    return result;
  }

  private updateJammerInfos(): LiveState {
    return new LiveState({
      ...this.data,
      jammerInfos: [
        this.getJammerInfos(0),
        this.getJammerInfos(1)
      ]
    }).updateJammerRelScores();
  }

  private getJammerInfos(teamIndex: number): UnitInfo[] {
    const skaters = this.data.skaters[teamIndex];
    const result: UnitInfo[] = [];
    const jamCount = this.data.jams.length;
    for (const skater of skaters) {
      const jamsPlayed = this.data.jams.filter(j => JamTools.jammerId(j, teamIndex) === skater.id).length;
      const jamsPlayedPercent = jamCount === 0 ? 0 : Math.round(jamsPlayed * 100 / jamCount);
      if (jamsPlayedPercent < 5) {
        continue;
      }

      const leads = this.data.jams.filter(j => JamTools.jammerId(j, teamIndex) === skater.id && j.lead === 1 + teamIndex).length;
      const penalties = this.calculatePenalties((_, s) => s === skater.id, this.data.jams, this.data.jamExtraDatas);
      const score = this.data.jams
        .filter(j => JamTools.jammerId(j, teamIndex) === skater.id)
        .map(j => JamTools.score(j, teamIndex) || 0)
        .reduce((a, b) => a + b, 0);
      const scoreMin = this.data.jams
        .filter(j => JamTools.jammerId(j, teamIndex) === skater.id)
        .map(j => JamTools.score(j, 1 - teamIndex) || 0)
        .reduce((a, b) => a + b, 0);

      result.push({
        id: skater.id,
        displayName: skater.derbyName,
        extraName: skater.derbyNumber.toString(),
        jamsPlayed: jamsPlayed,
        leads: leads,
        penalties: penalties,
        score: score,
        scoreRel: 0,
        scoreMin: scoreMin,
        scoreMinRel: 0,
        scoreAvg: jamsPlayed === 0 ? 0 : Math.round((score - scoreMin) / jamsPlayed),
      });
    }
    return result;
  }

  private updateJammerRelScores(): LiveState {
    let maxScore = 1;
    for (let teamIndex = 0; teamIndex < this.data.teams.length; teamIndex++) {
      const teamJammers = this.data.jammerInfos[teamIndex];
      for (const info of teamJammers) {
        maxScore = Math.max(maxScore, info.score);
        maxScore = Math.max(maxScore, info.scoreMin);
      }
    }
    return new LiveState({
      ...this.data,
      jammerInfos: this.data.jammerInfos.map(teamJammers => teamJammers.map(info => ({
        ...info,
        scoreRel: info.score / maxScore,
        scoreMinRel: info.scoreMin / maxScore,
      }))),
    });
  }

  private updateLineInfos(): LiveState {
    return new LiveState({
      ...this.data,
      lineInfos: [
        this.getLineInfos(0),
        this.getLineInfos(1),
      ]
    }).updateLineRelScores();
  }

  private getLineInfos(teamIndex: number): UnitInfo[] {
    const lines = this.data.lines[teamIndex];
    const result: UnitInfo[] = [];
    for (const line of lines) {
      const jamsPlayed = this.data.jams.filter(j => JamTools.lineId(j, teamIndex) === line.id).length;
      if (jamsPlayed === 0) {
        continue;
      }

      const leads = this.data.jams.filter(j => JamTools.lineId(j, teamIndex) === line.id && j.lead === 1 + teamIndex).length;
      const penalties = this.calculatePenalties((j, s) => JamTools.lineId(j, teamIndex) === line.id && this.isSkaterOfTeam(s, teamIndex, false), this.data.jams, this.data.jamExtraDatas);
      const score = this.data.jams
        .filter(j => JamTools.lineId(j, teamIndex) === line.id)
        .map(j => JamTools.score(j, teamIndex) || 0)
        .reduce((a, b) => a + b, 0);
      const scoreMin = this.data.jams
        .filter(j => JamTools.lineId(j, teamIndex) === line.id)
        .map(j => JamTools.score(j, 1 - teamIndex) || 0)
        .reduce((a, b) => a + b, 0);
      result.push({
        id: line.id,
        displayName: line.displayName,
        extraName: '',
        jamsPlayed: jamsPlayed,
        leads: leads,
        penalties: penalties,
        score: score,
        scoreRel: 0,
        scoreMin: scoreMin,
        scoreMinRel: 0,
        scoreAvg: jamsPlayed === 0 ? 0 : Math.round((score - scoreMin) / jamsPlayed),
      });
    }
    return result;
  }

  private updateLineRelScores(): LiveState {
    let maxScore = 1;
    for (let teamLines of this.data.lineInfos) {
      for (const lineInfo of teamLines) {
        maxScore = Math.max(maxScore, lineInfo.score);
        maxScore = Math.max(maxScore, lineInfo.scoreMin);
      }
    }
    return new LiveState({
      ...this.data,
      lineInfos: this.data.lineInfos.map(teamLines => teamLines.map(info => ({
        ...info,
        scoreRel: info.score / maxScore,
        scoreMinRel: info.scoreMin / maxScore,
      }))),
    });
  }

  private updatePivotInfos(): LiveState {
    return new LiveState({
      ...this.data,
      pivotInfos: [
        this.getPivotInfos(0),
        this.getPivotInfos(1),
      ]
    }).updatePivotRelScores();
  }

  private getPivotInfos(teamIndex: number): UnitInfo[] {
    const skaters = this.data.skaters[teamIndex];
    const result: UnitInfo[] = [];
    const jamCount = this.data.jams.length;
    for (const skater of skaters) {
      const jamsPlayed = this.data.jams.filter(j => JamTools.pivotId(j, teamIndex) === skater.id).length;
      const jamsPlayedPercent = jamCount === 0 ? 0 : Math.round(jamsPlayed * 100 / jamCount);
      if (jamsPlayedPercent < 5) {
        continue;
      }

      const leads = this.data.jams.filter(j => JamTools.pivotId(j, teamIndex) === skater.id && j.lead === 1 + teamIndex).length;
      const penalties = this.calculatePenalties((j, s) => JamTools.pivotId(j, teamIndex) === skater.id && this.isSkaterOfTeam(s, teamIndex, false), this.data.jams, this.data.jamExtraDatas);
      const score = this.data.jams
        .filter(j => JamTools.pivotId(j, teamIndex) === skater.id)
        .map(j => JamTools.score(j, teamIndex) || 0)
        .reduce((a, b) => a + b, 0);
      const scoreMin = this.data.jams
        .filter(j => JamTools.pivotId(j, teamIndex) === skater.id)
        .map(j => JamTools.score(j, 1 - teamIndex) || 0)
        .reduce((a, b) => a + b, 0);

      result.push({
        id: skater.id,
        displayName: skater.derbyName,
        extraName: skater.derbyNumber.toString(),
        jamsPlayed: jamsPlayed,
        leads: leads,
        penalties: penalties,
        score: score,
        scoreRel: 0,
        scoreMin: scoreMin,
        scoreMinRel: 0,
        scoreAvg: jamsPlayed === 0 ? 0 : Math.round((score - scoreMin) / jamsPlayed),
      });
    }

    return result;
  }

  private updatePivotRelScores(): LiveState {
    let maxScore = 1;
    for (let teamIndex = 0; teamIndex < this.data.teams.length; teamIndex++) {
      const teamPivots = this.data.pivotInfos[teamIndex];
      for (const info of teamPivots) {
        maxScore = Math.max(maxScore, info.score);
        maxScore = Math.max(maxScore, info.scoreMin);
      }
    }
    return new LiveState({
      ...this.data,
      pivotInfos: this.data.pivotInfos.map(teamPivots => teamPivots.map(info => ({
        ...info,
        scoreRel: info.score / maxScore,
        scoreMinRel: info.scoreMin / maxScore,
      }))),
    });
  }

  private updateJammerVsJammerInfos(): LiveState {
    const jammers1 = this.data.jammerInfos[0].map(j => j.id);
    const jammers2 = this.data.jammerInfos[1].map(j => j.id);
    const hasJammers = jammers1.length > 0 && jammers2.length > 0 && this.data.jams.some(j => Objects.isNotNull(j.team1JammerId) && Objects.isNotNull(j.team2JammerId));
    const result: SkaterVsSkaterInfo[][] = hasJammers
      ? Array(jammers1.length).fill([]).map(() => Array(jammers2.length).fill({}))
      : [];
    for (let i = 0; i < jammers1.length && hasJammers; i++) {
      for (let j = 0; j < jammers2.length; j++) {
        const jams = this.data.jams
          .filter(jam => JamTools.jammerId(jam, 0) === jammers1[i] && JamTools.jammerId(jam, 1) === jammers2[j]);
        const jamsPlayed = jams.length;
        const score = jams
          .map(j => (JamTools.score(j, 0) || 0) - (JamTools.score(j, 1) || 0))
          .reduce((a, b) => a + b, 0);
        result[i][j] = {
          jamsPlayed: jamsPlayed,
          score: score,
          scoreAvg: jamsPlayed === 0 ? 0 : Math.round(score / jamsPlayed),
        }
      }
    }
    return new LiveState({
      ...this.data,
      jammerVsJammerInfos: result,
    });
  }

  private updateJammerVsLineInfos(): LiveState {
    // Todo
    return new LiveState({
      ...this.data,
      jammerVsLineInfos: [
        this.getJammerVsLineInfos(0),
        this.getJammerVsLineInfos(1),
      ],
    });
  }

  private getJammerVsLineInfos(teamIndex: number): SkaterVsSkaterInfo[][] {
    const lineTeamIndex = 1 - teamIndex;
    const jammers = this.data.jammerInfos[teamIndex].map(s => s.id);
    const otherLines = this.data.lineInfos[lineTeamIndex].map(l => l.id);
    const hasSkaters = jammers.length > 0 && otherLines.length > 0 && this.data.jams.some(j => Objects.isNotNull(JamTools.jammerId(j, teamIndex)) && Objects.isNotNull(JamTools.lineId(j, lineTeamIndex)));
    const result: SkaterVsSkaterInfo[][] = hasSkaters
      ? Array(jammers.length).fill([]).map(() => Array(otherLines.length).fill({}))
      : [];
    for (let i = 0; i < jammers.length && hasSkaters; i++) {
      for (let j = 0; j < otherLines.length; j++) {
        const jams = this.data.jams
          .filter(jam => JamTools.jammerId(jam, teamIndex) === jammers[i] && JamTools.lineId(jam, lineTeamIndex) === otherLines[j]);
        const jamsPlayed = jams.length;
        const score = jams
          .map(j => (JamTools.score(j, teamIndex) || 0) - (JamTools.score(j, lineTeamIndex) || 0))
          .reduce((a, b) => a + b, 0);
        result[i][j] = {
          jamsPlayed: jamsPlayed,
          score: score,
          scoreAvg: jamsPlayed === 0 ? 0 : Math.round(score / jamsPlayed),
        }
      }
    }
    return result;
  }

  private updateJammerVsPivotInfos(): LiveState {
    return new LiveState({
      ...this.data,
      jammerVsPivotInfos: [
        this.getJammerVsPivotInfos(0),
        this.getJammerVsPivotInfos(1),
      ],
    });
  }

  private getJammerVsPivotInfos(teamIndex: number): SkaterVsSkaterInfo[][] {
    const pivotTeamIndex = 1 - teamIndex;
    const jammers = this.data.jammerInfos[teamIndex].map(i => i.id);
    const pivots = this.data.pivotInfos[pivotTeamIndex].map(p => p.id);
    const hasSkaters = jammers.length > 0 && pivots.length > 0 && this.data.jams.some(j => Objects.isNotNull(JamTools.jammerId(j, teamIndex)) && Objects.isNotNull(JamTools.pivotId(j, pivotTeamIndex)));
    const result: SkaterVsSkaterInfo[][] = hasSkaters
      ? Array(jammers.length).fill([]).map(() => Array(pivots.length).fill({}))
      : [];
    for (let i = 0; i < jammers.length && hasSkaters; i++) {
      for (let j = 0; j < pivots.length; j++) {
        const jams = this.data.jams
          .filter(jam => JamTools.jammerId(jam, teamIndex) === jammers[i] && JamTools.pivotId(jam, pivotTeamIndex) === pivots[j]);
        const jamsPlayed = jams.length;
        const score = jams
          .map(j => (JamTools.score(j, teamIndex) || 0) - (JamTools.score(j, pivotTeamIndex) || 0))
          .reduce((a, b) => a + b, 0);
        result[i][j] = {
          jamsPlayed: jamsPlayed,
          score: score,
          scoreAvg: jamsPlayed === 0 ? 0 : Math.round(score / jamsPlayed),
        }
      }
    }
    return result;
  }

  private updateComboInfos(): LiveState {
    return new LiveState({
      ...this.data,
      comboInfos: [
        this.getComboInfos(0),
        this.getComboInfos(1),
      ],
    }).updateComboRelScores();
  }

  private getComboInfos(teamIndex: number): ComboInfo[] {
    const jammers = this.data.jammerInfos[teamIndex].map(j => j.id);
    const lines = this.data.lineInfos[teamIndex].map(l => l.id);
    const pivots = this.data.pivotInfos[teamIndex].map(p => p.id);
    const result: ComboInfo[] = [];
    for (let j = 0; j < jammers.length; j++) {
      const jammerId = jammers[j];
      const jammer = this.data.skaters[teamIndex].find(s => s.id === jammerId);
      if (!jammer) {
        continue;
      }

      for (let l = 0; l < lines.length; l++) {
        const lineId = lines[l];
        const line = this.data.lines[teamIndex].find(l => l.id === lineId);
        if (!line) {
          continue;
        }

        const performanceInfo = this.calculatePerformanceInfo(
          teamIndex, this.data.jams, this.data.jamExtraDatas,
          jam => JamTools.jammerId(jam, teamIndex) === jammerId && JamTools.lineId(jam, teamIndex) === lineId,
        );
        result.push({
          jammer: jammer,
          line: line,
          ...performanceInfo,
        });
      }

      for (let p = 0; p < pivots.length; p++) {
        const pivotId = pivots[p];
        const pivot = this.data.skaters[teamIndex].find(s => s.id === pivotId);
        if (!pivot) {
          continue;
        }

        const performanceInfo = this.calculatePerformanceInfo(
          teamIndex, this.data.jams, this.data.jamExtraDatas,
        jam => JamTools.jammerId(jam, teamIndex) === jammerId && JamTools.pivotId(jam, teamIndex) === pivotId,
        );
        result.push({
          jammer: jammer,
          pivot: pivot,
          ...performanceInfo,
        });
      }
    }
    return result;
  }

  private calculatePerformanceInfo(teamIndex: number, jams: Jam[], jamExtraDatas: JamExtraData[], predicate: (jam: Jam) => boolean): PerformanceInfo {
    let leads = 0;
    let penalties = 0;
    let score = 0;
    let scoreMin = 0;
    let jamsPlayed = 0;
    for (let i = 0; i < jams.length; i++) {
      const jam = jams[i];
      if (!predicate(jam)) {
        continue;
      }

      jamsPlayed++;
      const extraData = jamExtraDatas[i];
      leads += jam.lead === 1 + teamIndex ? 1 : 0;
      score += JamTools.score(jam, teamIndex) || 0;
      scoreMin += JamTools.score(jam, 1 - teamIndex) || 0;
      penalties += extraData.penalties.filter(l => l.skaterId === JamTools.jammerId(jam, teamIndex)).length;
    }

    return {
      jamsPlayed: jamsPlayed,
      leads: leads,
      penalties: penalties,
      score: score,
      scoreRel: 0, // Will be calculated later
      scoreMin: scoreMin,
      scoreMinRel: 0, // Will be calculated later
      scoreAvg: jamsPlayed === 0 ? 0 : Math.round((score - scoreMin) / jamsPlayed),
    };
  }

  private updateComboRelScores(): LiveState {
    let maxScore = 1;
    for (let teamIndex = 0; teamIndex < this.data.teams.length; teamIndex++) {
      const infos = this.data.comboInfos[teamIndex];
      for (const info of infos) {
        maxScore = Math.max(maxScore, info.score);
        maxScore = Math.max(maxScore, info.scoreMin);
      }
    }
    return new LiveState({
      ...this.data,
      comboInfos: this.data.comboInfos.map(teamJammers => teamJammers.map(info => ({
        ...info,
        scoreRel: info.score / maxScore,
        scoreMinRel: info.scoreMin / maxScore,
      }))),
    });
  }

  private updateLapInfos(): LiveState {
    return new LiveState({
      ...this.data,
      teamLapInfos: [
        this.getTeamLapInfos(0),
        this.getTeamLapInfos(1)
      ],
      jammerLapInfos: [
        this.getJammerLapInfos(0),
        this.getJammerLapInfos(1),
      ],
      lineLapInfos: [
        this.getLineLapInfos(0),
        this.getLineLapInfos(1),
      ],
      pivotLapInfos: [
        this.getPivotLapInfos(0),
        this.getPivotLapInfos(1),
      ],
    });
  }

  private getTeamLapInfos(teamIndex: number): LapInfo[] {
    const skaters = this.data.skaters[teamIndex];
    const result: LapInfo[] = [];
    for (let i = 0; i < this.data.jams.length; i++) {
      const extraData = this.data.jamExtraDatas[i];
      result.push(...extraData.laps.filter(l => !!skaters.find(s => s.id === l.skaterId)));
    }
    return result;
  }

  private getJammerLapInfos(teamIndex: number): LapInfo[][] {
    const jammerIds = this.data.jammerInfos[teamIndex].map(l => l.id);
    const result: LapInfo[][] = [];
    for (const jammerId of jammerIds) {
      const laps: LapInfo[] = [];
      for (let i = 0; i < this.data.jams.length; i++) {
        const extraData = this.data.jamExtraDatas[i];
        laps.push(...extraData.laps.filter(l => l.skaterId === jammerId));
      }
      result.push(laps);
    }
    return result;
  }

  private getLineLapInfos(teamIndex: number): LapInfo[][] {
    const lines = this.data.lines[teamIndex];
    const result: LapInfo[][] = [];
    for (const line of lines) {
      const laps: LapInfo[] = [];
      for (let i = 0; i < this.data.jams.length; i++) {
        const jam = this.data.jams[i];
        const isLinePlayingInJam = JamTools.lineId(jam, teamIndex) === line.id;
        const extraData = this.data.jamExtraDatas[i];
        const jammerId = JamTools.jammerId(jam, 1 - teamIndex);
        if (isLinePlayingInJam) {
          laps.push(...extraData.laps.filter(l => l.skaterId === jammerId));
        }
      }
      result.push(laps);
    }
    return result;
  }

  private getPivotLapInfos(teamIndex: number): LapInfo[][] {
    const pivotIds = this.data.pivotInfos[teamIndex].map(p => p.id);
    const result: LapInfo[][] = [];
    for (const pivotId of pivotIds) {
      const laps: LapInfo[] = [];
      for (let i = 0; i < this.data.jams.length; i++) {
        const jam = this.data.jams[i];
        const extraData = this.data.jamExtraDatas[i];
        const isSkaterPlayingInJam = JamTools.pivotId(jam, teamIndex) === pivotId;
        const jammerId = JamTools.jammerId(jam, 1 - teamIndex);
        if (isSkaterPlayingInJam) {
          laps.push(...extraData.laps.filter(l => l.skaterId === jammerId));
        }
      }
      result.push(laps);
    }
    return result;
  }

  private updateJamInfos(): LiveState {
    const jams = this.data.jams;
    if (jams.length === 0) {
      return this;
    }

    // Current jam
    const finished = this.data.game?.isFinished;
    const currentJam = jams.length === 0 || finished ? undefined : jams[jams.length - 1];
    const currentJammerId1 = currentJam?.team1JammerId;
    const currentJammerId2 = currentJam?.team2JammerId;

    // Jams
    const jamInfos: JamInfo[][] = [[], []]; // Period 1/2
    const jamCopies: Jam[] = [];
    const predictedJamCount = 2;
    let currentJamInfo: JamInfo | undefined;
    for (let i = 0; i <= jams.length - 1 + predictedJamCount; i++) {
      const jam = i === jams.length ? undefined : jams[i];
      const jamCopy: Partial<Jam> = !jam ? {} : {...jam};
      jamCopies.push(jamCopy as Jam);

      if (i === jams.length && finished) {
        continue;
      }

      let scores = [jamCopy?.team1Score, jamCopy?.team2Score];
      if (i >= jams.length - 1 && !finished) {
        // Predict jammers?
        const predictedJam = PredictionTools.predictNextJam(jamCopies.slice(0, i), this.data.lines, this.data.skaters);
        jamCopy.team1JammerId = Objects.isNull(jam?.team1JammerId) ? predictedJam.team1JammerId : jam?.team1JammerId;
        jamCopy.team2JammerId = Objects.isNull(jam?.team2JammerId) ? predictedJam.team2JammerId : jam?.team2JammerId;
        jamCopy.jamNumber = jam?.jamNumber || predictedJam.jamNumber;
        jamCopy.period = jam?.period || predictedJam.period;

        // Predict scores?
        if (!jamCopy?.team1Score && !jamCopy?.team2Score) {
          scores = this.predictScore(jamCopy);
          jamCopy.team1Score = scores[0];
          jamCopy.team2Score = scores[1];
        }
      }

      if (i <= jams.length - 1 || !finished) {
        const predictedJammer1 = !jam || jam.team1JammerId !== jamCopy.team1JammerId;
        const predictedJammer2 = !jam || jam.team2JammerId !== jamCopy.team2JammerId;
        const predictedScore = !jam || jam.team1Score !== jamCopy.team1Score || jam.team2Score !== jamCopy.team2Score;
        const score = this.toScores(scores, predictedScore);
        const jamNumber = jamCopy.jamNumber;
        const period = jamCopy.period || 1;
        const jammer1 = this.data.skaters[0].find(s => s.id === jamCopy.team1JammerId);
        const jammer2 = this.data.skaters[1].find(s => s.id === jamCopy.team2JammerId);
        const jamInfo = {
          title: '' + jamNumber + (predictedJammer1 || predictedJammer2 || predictedScore ? ' *' : ''),
          jammer1: jammer1,
          jammer2: jammer2,
          lead: jamCopy.lead,
          score: score,
          score1: scores[0],
          score2: scores[1],
          isPredictedJammer1: predictedJammer1,
          isPredictedJammer2: predictedJammer2,
          isCurrentJammer1: finished || jammer1?.id === currentJammerId1,
          isCurrentJammer2: finished || jammer2?.id === currentJammerId2,
          isPredictedScore: predictedScore,
          period: jamCopy.period,
          showSeparator: jamCopy.period === 2 && jamNumber === 1,
        };
        jamInfos[period - 1].push(jamInfo);
        if (i === jams.length - 1 && !finished) {
          currentJamInfo = jamInfo;
        }
      }
    }

    return new LiveState({
      ...this.data,
      jamInfos: jamInfos,
      currentJamInfo: currentJamInfo,
    });
  }

  private calculatePenalties(predicate: (jam: Jam, skaterId: number) => boolean,
                             jams: Jam[],
                             jamExtraDatas: JamExtraData[]): number {
    let count = 0;
    for (let i = 0; i < jams.length; i++) {
      const jam = jams[i];
      const extraData = jamExtraDatas[i];
      count += extraData.penalties.filter(p => predicate(jam, p.skaterId)).length;
    }
    return count;
  }

  private isSkaterOfTeam(s: number, teamIndex: number, jammerAllowed: boolean): boolean {
    const skaters = this.data.skaters[teamIndex];
    return !!skaters.find(skater => skater.id === s && (jammerAllowed || !skater.isJammer));
  }

  private toScores(scores: (number | undefined)[], guess: boolean): string {
    if (!guess) {
      return this.toScore(scores[0]) + ' - ' + this.toScore(scores[1]);
    } else if (Objects.isNull(scores[0]) || Objects.isNull(scores[1])) {
      return '?';
    } else if (scores[0] === scores[1]) {
      return 'Tie';
    } else {
      return scores[0]! > scores[1]! ? 'Win' : 'Loss';
    }
  }

  private toScore(score: number | undefined): string {
    return score === undefined ? '?' : score.toString();
  }

  private predictScore(jam: Partial<Jam>): (number | undefined)[] {
    const jammers1 = this.data.jammerInfos[0].map(j => j.id);
    const jammers2 = this.data.jammerInfos[1].map(j => j.id);
    const jammerVsJammerInfos = this.data.jammerVsJammerInfos;
    const jammerId1 = JamTools.jammerId(jam, 0);
    const jammerId2 = JamTools.jammerId(jam, 1);
    if (jammerId1 === undefined || jammerId2 === undefined) {
      return [undefined, undefined];
    }
    const jammerIndex1 = jammers1.indexOf(jammerId1);
    const jammerIndex2 = jammers2.indexOf(jammerId2);
    const scoreAvg = jammerVsJammerInfos[jammerIndex1][jammerIndex2].scoreAvg;
    const score1 = scoreAvg > 0 ? scoreAvg : 0;
    const score2 = scoreAvg < 0 ? -scoreAvg : 0;
    return [score1, score2];
  }
}
