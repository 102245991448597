import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {

  @Input()
  text: string = '';

  @Input()
  textFaded: string = '';

  @Input()
  disabled: boolean = false;

  @Input()
  checked: boolean = false;

  @Output('checked')
  checkedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();


  public toggle(): void {
    if (this.disabled) {
      return;
    }
    this.checked = !this.checked;
    this.checkedEvent.emit(this.checked);
  }
}
