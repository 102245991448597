import {Component, OnInit} from "@angular/core";
import {NotificationService} from "../../services/notification.service";
import {Observable} from "rxjs";
import {NotificationsState} from "./notifications.state";
import {Message} from "../../models/message";
import {StateSubject} from "../../tools/state.subject";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  state$: StateSubject<NotificationsState> = new StateSubject<NotificationsState>(NotificationsState.create());
  type$: Observable<string>;

  constructor(private notificationService: NotificationService) {
    this.type$ = this.state$.get(state => !state.message || !state.message.type ? '' : state.message!.type);
  }

  ngOnInit(): void {
    this.notificationService.notifications$.subscribe(notification => {
      this.show(notification);
    });
  }

  private show(message: Message | undefined): void {
    const state = this.state$.getValue();
    this.state$.invoke(state => state.withMessage(message));
    setTimeout(() => this.cleanUp(), state.delay);
  }

  private cleanUp(): void {
    const state = this.state$.getValue();
    const notificationTimestamp = state.timestamp;
    if (notificationTimestamp === 0) {
      return;
    }

    const now = Date.now();
    if (now - notificationTimestamp > state.delay) {
      this.state$.invoke(state => state.withMessage(undefined));
    }
  }
}
