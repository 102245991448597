import {Url} from "../models/url";
import {HttpClient} from "@angular/common/http";
import {SessionService} from "./session.service";
import {map, Observable} from "rxjs";
import {Action} from "../models/action";
import {environment} from "../../environment";

export abstract class AbstractService<D, T> {

  protected readonly url: Url;
  protected readonly toDto: (e: Partial<D>) => Partial<T>;
  protected readonly fromDto: (e: T) => D;

  protected constructor(protected http: HttpClient,
                        protected sessionService: SessionService,
                        endpoint: string,
                        toDto: (e: Partial<D>) => Partial<T>,
                        fromDto: (e: T) => D) {
    this.url = Url.create(environment.backendUrl + endpoint)
    this.toDto = toDto;
    this.fromDto = fromDto;
  }

  public all(): Observable<D[]> {
    const options = this.sessionService.createHttpOptions();
    return this.http.get<T[]>(this.url.php, options).pipe(map(es => es.map(this.fromDto)));
  }

  public oneById(id: number): Observable<D> {
    const options = this.sessionService.createHttpOptions();
    return this.http.get<T>(this.url.php + `?id=${id}`, options).pipe(map(this.fromDto));
  }

  public create(object: Partial<D>): Observable<D> {
    const options = this.sessionService.createHttpOptions();
    const serialized = this.toDto(object);
    return this.http.post<T>(this.url.php, serialized, options).pipe(map(this.fromDto));
  }

  public update(id: number, object: Partial<D>): Observable<D> {
    const options = this.sessionService.createHttpOptions();
    const serialized = this.toDto(object);
    return this.http.put<T>(this.url.php + `?id=${id}`, serialized, options).pipe(map(this.fromDto));
  }

  public delete(id: number): Observable<void> {
    const options = this.sessionService.createHttpOptions();
    return this.http.delete<void>(this.url.php + `?id=${id}`, options);
  }

  public getActions(id?: number): Observable<Action[]> {
    const options = this.sessionService.createHttpOptions();
    return this.http.get<Action[]>(this.url.actions.php + (id === undefined ? '' : `?id=${id}`), options);
  }

  protected mapDtos() {
    return map((dtos: T[]) => dtos.map(dto => this.fromDto(dto)));
  }

  protected mapDto() {
    return map((dto: T) => this.fromDto(dto));
  }
}
