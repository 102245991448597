<div class="track-container">
  <div class="track">
    <div>
      <div [style]="getCellStyle()">
        <div [style]="getStyle(4, 3)" (click)="onClick(4, 3)">{{ getDisplayValue(4, 3) }}</div>
        <div [style]="getStyle(4, 2)" (click)="onClick(4, 2)">{{ getDisplayValue(4, 2) }}</div>
        <div [style]="getStyle(4, 1)" (click)="onClick(4, 1)">{{ getDisplayValue(4, 1) }}</div>
        <div class="inside" [style]="getStyle(4, 0)"></div>
      </div>
      <div [style]="getCellStyle()">
        <div [style]="getStyle(5, 3)" (click)="onClick(5, 3)">{{ getDisplayValue(5, 3) }}</div>
        <div [style]="getStyle(5, 2)" (click)="onClick(5, 2)">{{ getDisplayValue(5, 2) }}</div>
        <div [style]="getStyle(5, 1)" (click)="onClick(5, 1)">{{ getDisplayValue(5, 1) }}</div>
        <div class="inside" [style]="getStyle(5, 0)"></div>
      </div>
    </div>
    <div>
      <div [style]="getCellStyle()">
        <div [style]="getStyle(3, 3)" (click)="onClick(3, 3)">{{ getDisplayValue(3, 3) }}</div>
        <div [style]="getStyle(3, 2)" (click)="onClick(3, 2)">{{ getDisplayValue(3, 2) }}</div>
        <div [style]="getStyle(3, 1)" (click)="onClick(3, 1)">{{ getDisplayValue(3, 1) }}</div>
        <div class="inside" [style]="getStyle(3, 0)"></div>
      </div>
      <div [style]="getCellStyle()">
        <div class="inside" [style]="getStyle(0, 0)"></div>
        <div [style]="getStyle(0, 1)" (click)="onClick(0, 1)">{{ getDisplayValue(0, 1) }}</div>
        <div [style]="getStyle(0, 2)" (click)="onClick(0, 2)">{{ getDisplayValue(0, 2) }}</div>
        <div [style]="getStyle(0, 3)" (click)="onClick(0, 3)">{{ getDisplayValue(0, 3) }}</div>
      </div>
    </div>
    <div>
      <div [style]="getCellStyle()">
        <div [style]="getStyle(2, 3)" (click)="onClick(2, 3)">{{ getDisplayValue(2, 3) }}</div>
        <div [style]="getStyle(2, 2)" (click)="onClick(2, 2)">{{ getDisplayValue(2, 2) }}</div>
        <div [style]="getStyle(2, 1)" (click)="onClick(2, 1)">{{ getDisplayValue(2, 1) }}</div>
        <div class="inside" [style]="getStyle(2, 0)"></div>
      </div>
      <div [style]="getCellStyle()">
        <div [style]="getStyle(1, 3)" (click)="onClick(1, 3)">{{ getDisplayValue(1, 3) }}</div>
        <div [style]="getStyle(1, 2)" (click)="onClick(1, 2)">{{ getDisplayValue(1, 2) }}</div>
        <div [style]="getStyle(1, 1)" (click)="onClick(1, 1)">{{ getDisplayValue(1, 1) }}</div>
        <div class="inside" [style]="getStyle(1, 0)"></div>
      </div>
    </div>
  </div>
  <div class="action-container">
    <button class="button-more" (click)="onUndo()"
            *ngIf="editable && lapInfoMap.size > 0">
      <app-icon name="undo"></app-icon>
    </button>
  </div>
</div>
