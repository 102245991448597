export class Url {

  readonly url: string;

  private constructor(url: string) {
    this.url = url
  }

  public static create(url: string): Url {
    return new Url(url)
  }

  public get php(): string {
    return this.url + '.php';
  }

  public slash(path: string): Url {
    return Url.create(this.url + '/' + path);
  }

  public get actions(): Url {
    return this.slash('actions');
  }
}
