import {Injectable} from "@angular/core";
import {environment} from "../../environment";
import { HttpClient } from "@angular/common/http";
import {SessionService} from "./session.service";
import {Observable} from "rxjs";
import {TeamLine} from "../models/team-line";
import {Action} from "../models/action";
import {Url} from "../models/url";


@Injectable({
  providedIn: 'root'
})
export class TeamLineService {

  private teamLines = Url.create(environment.backendUrl + '/stats/team-lines');

  constructor(private http: HttpClient,
              private sessionService: SessionService) {
  }

  public getTeamLines(): Observable<TeamLine[]> {
    const options = this.sessionService.createHttpOptions();
    return this.http.get<TeamLine[]>(this.teamLines.php, options);
  }

  public getTeamLine(id: number): Observable<TeamLine> {
    const options = this.sessionService.createHttpOptions();
    return this.http.get<TeamLine>(this.teamLines.php + `?id=${id}`, options);
  }

  public getTeamLinesByTeamId(teamId: number): Observable<TeamLine[]> {
    const options = this.sessionService.createHttpOptions();
    return this.http.get<TeamLine[]>(this.teamLines.php + `?teamId=${teamId}`, options);
  }

  public createTeamLine(teamLine: Partial<TeamLine>): Observable<TeamLine> {
    const options = this.sessionService.createHttpOptions();
    return this.http.post<TeamLine>(this.teamLines.php, teamLine, options);
  }

  public updateTeamLine(id: number, teamLine: Partial<TeamLine>): Observable<TeamLine> {
    const options = this.sessionService.createHttpOptions();
    return this.http.put<TeamLine>(this.teamLines.php + `?id=${id}`, teamLine, options);
  }

  public deleteTeamLine(id: number): Observable<TeamLine> {
    const options = this.sessionService.createHttpOptions();
    return this.http.delete<TeamLine>(this.teamLines.php + `?id=${id}`, options);
  }

  public getActions(id?: number): Observable<Action[]> {
    const options = this.sessionService.createHttpOptions();
    return this.http.get<Action[]>(this.teamLines.actions.php + (id === undefined ? '' : `?id=${id}`), options);
  }
}
