<div class="page">
  <div class="card" *ngIf="!(state$ | async)!.data.loading">
    <div class="list">
      <ng-container *ngFor="let gameInfo of (state$ | async)!.data.filteredGameInfos">
        <ng-container *ngIf="!!gameInfo.group">
          <div class="list-group">
            {{ gameInfo.group }}
          </div>
        </ng-container>

        <div class="list-item-secondary"
             (click)="goToGame(gameInfo.game)">
          <div class="list-text-secondary">
            <div>{{ gameInfo.team1?.displayName }} <span
              class="list-text-faded">vs</span> {{ gameInfo.team2?.displayName }}</div>
            <div>{{ gameInfo.game.startDate | date:"yyyy-MM-dd HH:mm" }} &#64; {{ gameInfo.game.location }}</div>
          </div>
          <div class="list-icons" *ngIf="gameInfo.game.isLive || gameInfo.hasScore">
            <div class="list-icon-primary" *ngIf="gameInfo.game.isLive">
              <app-icon name="live"></app-icon>
            </div>
            <div class="score" *ngIf="gameInfo.hasScore">
              <div class="score1">{{ gameInfo.game.score1 }}</div>
              <div class="score-separator">-</div>
              <div class="score2">{{ gameInfo.game.score2 }}</div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="list-item-secondary" *ngIf="(state$ | async)!.data.filteredGameInfos.length === 0">
        <div class="list-text-message">
          No games found
        </div>
      </div>
    </div>
  </div>
  <app-loading *ngIf="(state$ | async)!.data.loading"></app-loading>
</div>
