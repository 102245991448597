import {Injectable} from "@angular/core";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";
import {ScreenSize} from "../models/screen-size";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ScreenService {

  private static readonly SCREEN_SIZES = [
    ScreenSize.S_768, // 0 - 768
    ScreenSize.M_876, // 768 - 1032
    ScreenSize.L_1200, // 1032 - 1200
    ScreenSize.XL_1400, // 1200 - 1400
    ScreenSize.XXL_MAX, // 1400 - max
  ];
  private static readonly MEDIA_QUERIES = ScreenService.toMediaQueries();

  private _size$: BehaviorSubject<ScreenSize> = new BehaviorSubject<ScreenSize>(ScreenSize.XXL_MAX);

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver.observe(ScreenService.MEDIA_QUERIES).subscribe(result => {
      this.updateScreenSize(result);
    });
    this._size$.subscribe(size => {
      console.debug(`Screen size: ${size.name} (breakpoint: ${size.width})`);
    });
    this.updateInitialScreenSize();
  }

  private updateInitialScreenSize() {
    for (let i = 0; i < ScreenService.SCREEN_SIZES.length; i++) {
      const mediaQuery = ScreenService.MEDIA_QUERIES[i];
      const screenSize = ScreenService.SCREEN_SIZES[i];
      if (this.breakpointObserver.isMatched(mediaQuery)) {
        this._size$.next(screenSize);
        return;
      }
    }
  }

  private updateScreenSize(result: BreakpointState): void {
    for (let i = 0; i < ScreenService.SCREEN_SIZES.length; i++) {
      if (result.breakpoints[ScreenService.MEDIA_QUERIES[i]]) {
        this._size$.next(ScreenService.SCREEN_SIZES[i]);
        return;
      }
    }
  }

  public get size$(): Observable<ScreenSize> {
    return this._size$.asObservable();
  }

  public get size(): ScreenSize {
    return this._size$.getValue();
  }

  private static toMediaQueries(): string[] {
    const result: string[] = [];
    for (let i = 0; i < ScreenService.SCREEN_SIZES.length; i++) {
      const size = ScreenService.SCREEN_SIZES[i];
      if (i === ScreenService.SCREEN_SIZES.length - 1) {
        result.push(`(min-width: ${size.width}px)`);
      } else {
        const nextSize = ScreenService.SCREEN_SIZES[i + 1];
        result.push(`(min-width: ${size.width}px) and (max-width: ${nextSize.width - 1}px)`);
      }
    }
    return result;
  }
}
