import {Component} from "@angular/core";
import {PageService} from "../../services/page.service";
import {RouterTools} from "../../tools/router.tools";
import {Router} from "@angular/router";

@Component({
  selector: 'app-classes',
  templateUrl: './classes.component.html',
  styleUrls: ['./classes.component.scss']
})
export class ClassesComponent {

  constructor(private pageService: PageService,
              private router: Router) {
    this.pageService.invoke(page => page.reset('Classes')
      .withBackButton(() => RouterTools.goHome(this.router)));
  }
}
