import {Component, OnInit} from "@angular/core";
import {TeamState} from "./team.state";
import {LeagueService} from "../../services/league.service";
import {TeamService} from "../../services/team.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Message} from "../../models/message";
import {NotificationService} from "../../services/notification.service";
import {RouterTools} from "../../tools/router.tools";
import {Skater} from "../../models/skater";
import {SkaterService} from "../../services/skater.service";
import {StateSubject} from "../../tools/state.subject";
import {forkJoin, of} from "rxjs";
import {PageService} from "../../services/page.service";
import {Tabs} from "../../models/tabs";
import {Tab} from "../../models/tab";

@Component({
  selector: 'app-skaters',
  templateUrl: './skaters.component.html',
  styleUrls: ['./skaters.component.scss']
})
export class SkatersComponent implements OnInit {

  state$: StateSubject<TeamState> = new StateSubject<TeamState>(TeamState.create());

  constructor(private leagueService: LeagueService,
              private teamService: TeamService,
              private skaterService: SkaterService,
              private route: ActivatedRoute,
              private pageService: PageService,
              private router: Router,
              private notificationService: NotificationService) {
    this.state$.subscribe(state => {
        this.pageService.invoke(page => page.reset(state.data.team?.displayName || 'New Team')
          .withBackButton(() => RouterTools.goToLeague(this.router, state.data.leagueId!))
          .withTabs(Tabs.of([
            Tab.of("Info", true, () => RouterTools.goToTeam(this.router, state.data.leagueId!, state.data.teamId!)),
            Tab.of("Lines", true, () => RouterTools.goToTeamLines(this.router, state.data.leagueId!, state.data.teamId!)),
            Tab.of("Skaters", true),
          ], 2))
          .withActions([
            {
              text: 'New Skater',
              icon: 'add',
              visible: state.canEdit,
              enabled: true,
              active: true,
              primary: true,
              onClick: () => this.newSkater()
            }
          ])
          .withSearch((query?: string) => this.search(query)))
      }
    );
  }

  ngOnInit(): void {
    RouterTools.observeParamMap(this.route, params => {
      const leagueId = RouterTools.toNumber(params, 'leagueId');
      const teamId = RouterTools.toNumber(params, 'teamId');
      this.state$.invoke(state => state.withQueryIds(leagueId, teamId));
    });
    this.state$.get(state => state.data.leagueId + ' ' + state.data.teamId).subscribe(() => {
      this.refresh();
    });
  }

  public refresh(): void {
    const state = this.state$.getValue();
    const leagueId = state.data.leagueId;
    const teamId = state.data.teamId;

    forkJoin({
      league: !!leagueId ? this.leagueService.getLeague(leagueId) : of(undefined),
      team: !!teamId ? this.teamService.getTeam(teamId) : of(undefined),
      skaters: !!teamId ? this.skaterService.getSkatersByTeamId(teamId) : of([]),
      actions: this.teamService.getActions(teamId),
    }).subscribe({
      next: ({league, team, skaters, actions}) => {
        this.state$.invoke(state => state.refresh(league, team, skaters, actions));
      },
      error: () => {
        this.notificationService.show(Message.error('Failed to load team'));
        this.state$.invoke(state => state.withLoading(false));
      }
    });
  }

  public withShowInactiveSkaters(showInactiveSkaters: boolean): void {
    this.state$.invoke(state => state.withShowInactiveSkaters(showInactiveSkaters));
  }

  public newSkater(): void {
    RouterTools.goToSkaterNew(this.router, this.state$.getValue().data.leagueId!, this.state$.getValue().data.teamId!);
  }

  public goToSkater(skater: Skater) {
    const leagueId = this.state$.getValue().data.leagueId!;
    RouterTools.goToSkater(this.router, leagueId, skater.teamId, skater.id);
  }

  public search(value: string | undefined): void {
    this.state$.invoke(state => state.withSearch(value));
  }
}
