import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {League} from "../models/league";
import { HttpClient } from "@angular/common/http";
import {SessionService} from "./session.service";
import {environment} from "../../environment";
import {Action} from "../models/action";
import {Url} from "../models/url";

@Injectable({
  providedIn: 'root'
})
export class LeagueService {

  private leagues = Url.create(environment.backendUrl + '/stats/leagues');

  constructor(private http: HttpClient,
              private sessionService: SessionService) {
  }

  public getLeagues(): Observable<League[]> {
    const options = this.sessionService.createHttpOptions();
    return this.http.get<League[]>(this.leagues.php, options);
  }

  public getLeague(id: number): Observable<League> {
    const options = this.sessionService.createHttpOptions();
    return this.http.get<League>(this.leagues.php + `?id=${id}`, options);
  }

  public createLeague(league: Partial<League>): Observable<League> {
    const options = this.sessionService.createHttpOptions();
    return this.http.post<League>(this.leagues.php, league, options);
  }

  public updateLeague(id: number, league: Partial<League>): Observable<League> {
    const options = this.sessionService.createHttpOptions();
    return this.http.put<League>(this.leagues.php + `?id=${id}`, league, options);
  }

  public deleteLeague(id: number): Observable<void> {
    const options = this.sessionService.createHttpOptions();
    return this.http.delete<void>(this.leagues.php + `?id=${id}`, options);
  }

  public getActions(id?: number): Observable<Action[]> {
    const options = this.sessionService.createHttpOptions();
    return this.http.get<Action[]>(this.leagues.actions.php + (id === undefined ? '' : `?id=${id}`), options);
  }
}
