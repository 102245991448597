import {Component, OnInit} from "@angular/core";
import {StateSubject} from "../../tools/state.subject";
import {forkJoin} from "rxjs";
import {TestService} from "../../services/test.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationService} from "../../services/notification.service";
import {DialogService} from "../../services/dialog.service";
import {TestQuestionUploadState} from "./test-question-upload.state";
import {TestQuestionService} from "../../services/test-question.service";
import {RouterTools} from "../../tools/router.tools";
import {Message} from "../../models/message";
import {DialogOptions} from "../../models/dialog-options";
import {PageService} from "../../services/page.service";

@Component({
  selector: 'app-test-question-upload',
  templateUrl: './test-question-upload.component.html',
  styleUrls: ['./test-question-upload.component.scss']
})
export class TestQuestionUploadComponent implements OnInit {

  state$: StateSubject<TestQuestionUploadState> = new StateSubject<TestQuestionUploadState>(TestQuestionUploadState.create());

  constructor(private testService: TestService,
              private testQuestionService: TestQuestionService,
              private route: ActivatedRoute,
              private pageService: PageService,
              private router: Router,
              private notificationService: NotificationService,
              private dialogService: DialogService) {
    this.state$.subscribe(state => {
      this.pageService.invoke(page => page.reset(state.data.test?.displayName)
        .withBackButton(() => RouterTools.goToTestQuestions(this.router, state.data.testId!))
        .withActions([
          {
            text: 'Save',
            icon: 'save',
            visible: state.canEdit,
            enabled: state.canSave,
            active: true,
            primary: true,
            onClick: () => this.save()
          }
        ]));
    });
  }

  ngOnInit(): void {
    RouterTools.observeParamMap(this.route, params => {
      const testId = RouterTools.toNumber(params, 'testId');
      this.state$.invoke(state => state.withQueryId(testId));
    });
    this.state$.get(state => state.data.testId).subscribe(() => {
      this.refresh();
    });
  }

  public refresh(): void {
    const state = this.state$.getValue();
    const testId = state.data.testId;
    if (!testId) {
      return;
    }

    forkJoin([
      this.testService.oneById(testId),
      this.testQuestionService.allByTestId(testId),
      this.testQuestionService.getActions()
    ]).subscribe({
      next: ([test, questions, actions]) => {
        this.state$.invoke(state => state.refresh(test, questions, actions));
      },
      error: () => {
        this.notificationService.show(Message.error(`Failed to load test`));
        this.state$.invoke(state => state.withLoading(false));
      }
    });
  }

  public withText(event: any): void {
    this.state$.invoke(state => state.withText(event.target.value));
  }

  public save(): void {
    const state = this.state$.getValue();
    const candidates = state.data.candidates;
    if (candidates.length === 0) {
      return;
    }
    this.dialogService.show(DialogOptions
      .create('Add Questions', `Are you sure you want to add ${candidates.length} questions?`)
      .withConfirm(() => this.doSave()));
  }

  private doSave(): void {
    const state = this.state$.getValue();
    const candidates = state.data.candidates;
    const testId = state.data.testId;
    if (!testId && candidates.length === 0) {
      this.state$.invoke(state => state.withIsUploading(false));
      return;
    }

    const firstCandidate = candidates[0];
    const isLastCandidate = candidates.length === 1;
    this.state$.invoke(state => state.withIsUploading(true));

    this.testQuestionService.create(firstCandidate).subscribe({
      next: testQuestion => {
        this.state$.invoke(state => state.withCandidateUploaded(firstCandidate, testQuestion));

        if (isLastCandidate) {
          this.notificationService.show(Message.success('Questions uploaded successfully'));
          this.refresh();
        } else {
          this.doSave();
        }
      },
      error: () => {
        this.notificationService.show(Message.error('Failed to upload questions'));
        this.state$.invoke(state => state.withIsUploading(false));
      }
    });
  }
}
