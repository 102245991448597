import {Component, OnInit} from "@angular/core";
import {LeagueService} from "../../services/league.service";
import {LeaguesState} from "./leagues.state";
import {NotificationService} from "../../services/notification.service";
import {Message} from "../../models/message";
import {Router} from "@angular/router";
import {StateSubject} from "../../tools/state.subject";
import {RouterTools} from "../../tools/router.tools";
import {Scope} from "../../models/scope";
import {SessionService} from "../../services/session.service";
import {PageService} from "../../services/page.service";
import {AbstractComponent} from "../abstract.component";
import {Tabs} from "../../models/tabs";
import {Tab} from "../../models/tab";

@Component({
  selector: 'app-leagues',
  templateUrl: './leagues.component.html',
  styleUrls: ['./leagues.component.scss']
})
export class LeaguesComponent extends AbstractComponent implements OnInit {

  state$: StateSubject<LeaguesState> = new StateSubject<LeaguesState>(LeaguesState.create());

  constructor(private leagueService: LeagueService,
              private notificationService: NotificationService,
              private sessionService: SessionService,
              private pageService: PageService,
              private router: Router) {
    super();
    this.subscriptions.add(this.sessionService.hasScope(Scope.LEAGUES_WRITE).subscribe(canWrite => this.state$.invoke(state => state.withCanWrite(canWrite))));
    this.state$.subscribe(state => {
      this.pageService.invoke(page => page.reset('Leagues')
        .withShowMenu(true)
        .withBackButton(() => RouterTools.goHome(this.router))
        .withSearch((query?: string) => this.search(query))
        .withTabs(Tabs.of([
          Tab.of('Games', true, () => RouterTools.goToGames(this.router)),
          Tab.of('Leagues', true, () => RouterTools.goToLeagues(this.router)),
          Tab.of('Teams', true, () => RouterTools.goToTeams(this.router)),
        ], 1))
        .withActions([{
          text: 'New League',
          icon: 'add',
          visible: state.data.canWrite,
          enabled: true,
          active: true,
          primary: true,
          onClick: () => this.newLeague()
        }])
      )
    })
  }

  ngOnInit(): void {
    this.refresh();
  }

  public refresh(): void {
    this.leagueService.getLeagues().subscribe({
      next: leagues => this.state$.invoke(state => state.refresh(leagues)),
      error: () => {
        this.notificationService.show(Message.error('Failed to load leagues'));
        this.state$.invoke(state => state.withLoading(false));
      }
    });
  }

  public goToLeague(id: number): void {
    RouterTools.goToLeague(this.router, id);
  }

  public newLeague(): void {
    RouterTools.goToLeagueNew(this.router);
  }

  public search(value: string | undefined): void {
    this.state$.invoke(state => state.withSearch(value));
  }
}
