import {Test} from "../../models/learning/domain/test";
import {Game} from "../../models/game";
import {Team} from "../../models/team";
import {Objects} from "../../tools/objects";
import {UserSession} from "../../models/user-session";

export interface HomeData {
  tests: Test[],
  mandatoryTests: Test[],
  otherTests: Test[],
  games: Game[],
  liveGame?: Game,
  upcomingGame?: Game,
  otherGames: Game[],
  teams?: Team[]
  session?: UserSession;
  loading: boolean;
}

export class HomeState {
  readonly data: HomeData;

  constructor(data: HomeData) {
    this.data = data;
  }

  public static create(): HomeState {
    return new HomeState({
      tests: [],
      mandatoryTests: [],
      otherTests: [],
      games: [],
      liveGame: undefined,
      upcomingGame: undefined,
      otherGames: [],
      teams: [],
      session: undefined,
      loading: true,
    });
  }

  public gameTeam1(gameId: number): Team | undefined {
    const game = this.data.games.find(game => game.id === gameId);
    const team = this.data.teams?.find(team => team.id === game?.teamId1);
    return Objects.ifNull(team?.displayName, 'Team 1');
  }

  public gameTeam2(gameId: number): Team | undefined {
    const game = this.data.games.find(game => game.id === gameId);
    const team = this.data.teams?.find(team => team.id === game?.teamId2);
    return Objects.ifNull(team?.displayName, 'Team 2');
  }

  public refresh(tests: Test[], games: Game[], teams: Team[]): HomeState {
    const mandatoryTests = tests.filter(test => test.isMandatory);
    const liveGame = this.findLiveGame(games);
    const upcomingGame = this.findUpcomingGame(games);
    return new HomeState({
      ...this.data,
      tests: tests,
      games: games,
      mandatoryTests: mandatoryTests,
      otherTests: tests.filter(test => !test.isMandatory),
      liveGame: liveGame,
      upcomingGame: upcomingGame,
      otherGames: games.filter(game => game !== liveGame && game !== upcomingGame),
      teams: teams,
      loading: false
    });
  }

  public withLoading(loading: boolean): HomeState {
    return new HomeState({
      ...this.data,
      loading: loading,
    });
  }

  public withSession(session?: UserSession): HomeState {
    return new HomeState({
      ...this.data,
      session: session
    });
  }

  private findLiveGame(games: Game[]): Game | undefined {
    return games.find(game => game.isLive);
  }

  private findUpcomingGame(games: Game[]): Game | undefined {
    const nowTime = new Date().getTime();
    let game = undefined;
    let gameTime = undefined;
    for (let i = 0; i < games.length; i++) {
      const candidate = games[i];
      const gameDate = candidate.startDate;
      const candidateTime = gameDate?.getTime();
      if (!candidate.isLive && candidateTime !== undefined && candidateTime > nowTime && (gameTime === undefined || gameTime > candidateTime)) {
        gameTime = candidateTime;
        game = candidate;
      }
    }
    return game;
  }
}
