import {League} from "../../models/league";
import {Team} from "../../models/team";
import {Skater} from "../../models/skater";
import {TeamLine} from "../../models/team-line";
import {Action} from "../../models/action";
import {SearchTools} from "../../tools/search.tools";
import {Objects} from "../../tools/objects";

export interface TeamLinesData {
  leagueId?: number;
  league?: League;
  teamId?: number;
  team?: Team;
  lines: TeamLine[];
  filteredLines: TeamLine[];
  actions: Action[];
  showInactiveLines: boolean;
  search?: string;
  loading: boolean;
}

export class TeamLinesState {
  readonly data: TeamLinesData;

  constructor(data: TeamLinesData) {
    this.data = data;
  }

  public static create(): TeamLinesState {
    return new TeamLinesState({
      leagueId: undefined,
      league: undefined,
      teamId: undefined,
      team: undefined,
      lines: [],
      filteredLines: [],
      actions: [],
      showInactiveLines: false,
      loading: true,
    });
  }

  public get canDelete(): boolean {
    return this.data.actions.includes(Action.DELETE);
  }

  public get canEdit(): boolean {
    return this.data.actions.includes(Action.EDIT);
  }

  public get filteredLines(): TeamLine[] {
    return this.data.filteredLines.filter(l => this.data.showInactiveLines || l.isActive);
  }

  public get inactiveLines(): TeamLine[] {
    return this.data.lines.filter(l => !l.isActive);
  }

  public refresh(league: League | undefined, team: Team | undefined, lines: TeamLine[], actions: Action[]): TeamLinesState {
    return new TeamLinesState({
      ...this.data,
      league: league,
      team: team,
      lines: lines.sort((a, b) => {
        if (a.isActive === b.isActive) {
          return a.displayName.localeCompare(b.displayName);
        }
        return a.isActive ? -1 : 1;
      }),
      actions: actions,
      loading: false,
    }).filterLines();
  }

  public withLoading(loading: boolean): TeamLinesState {
    return new TeamLinesState({
      ...this.data,
      loading: loading,
    });
  }

  public withQueryIds(leagueId: number | undefined, teamId: number | undefined): TeamLinesState {
    return new TeamLinesState({
      ...this.data,
      leagueId: leagueId,
      teamId: teamId,
    });
  }

  public withShowInactiveLines(showInactiveLines: boolean): TeamLinesState {
    return new TeamLinesState({
      ...this.data,
      showInactiveLines: showInactiveLines,
    });
  }

  public withSearch(value: string | undefined): TeamLinesState {
    return new TeamLinesState({
      ...this.data,
      search: value,
    }).filterLines();
  }

  private filterLines(): TeamLinesState {
    const textF = (line: TeamLine) => line.displayName;
    return new TeamLinesState({
      ...this.data,
      showInactiveLines: Objects.isNull(this.data.search) ? this.data.showInactiveLines : true,
      filteredLines: SearchTools.filter(this.data.lines.filter(s => this.data.showInactiveLines || s.isActive), textF, this.data.search),
    });
  }
}
