import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {SkaterState} from "./skater.state";
import {LeagueService} from "../../services/league.service";
import {TeamService} from "../../services/team.service";
import {SkaterService} from "../../services/skater.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationService} from "../../services/notification.service";
import {RouterTools} from "../../tools/router.tools";
import {Message} from "../../models/message";
import {Skater} from "../../models/skater";
import {StateSubject} from "../../tools/state.subject";
import {forkJoin, of} from "rxjs";
import {DialogOptions} from "../../models/dialog-options";
import {DialogService} from "../../services/dialog.service";
import {PageService} from "../../services/page.service";

@Component({
  selector: 'app-skater',
  templateUrl: './skater.component.html',
  styleUrls: ['./skater.component.scss']
})
export class SkaterComponent implements OnInit, AfterViewInit {

  @ViewChild("primary") primary!: ElementRef;

  state$: StateSubject<SkaterState> = new StateSubject<SkaterState>(SkaterState.create());

  constructor(private leagueService: LeagueService,
              private teamService: TeamService,
              private skaterService: SkaterService,
              private route: ActivatedRoute,
              private router: Router,
              private notificationService: NotificationService,
              private pageService: PageService,
              private dialogService: DialogService) {
    this.state$.subscribe(state => {
      this.pageService.invoke(page => page.reset(state.data.skater?.derbyName || 'New Skater')
        .withBackButton(() => RouterTools.goToSkaters(this.router, state.data.leagueId!, state.data.teamId!))
        .withActions([
          {
            text: 'Save',
            icon: 'save',
            visible: state.canEdit,
            enabled: state.canSave,
            active: true,
            primary: true,
            onClick: () => this.save()
          },
          {
            text: 'Save & Add Another',
            icon: 'save_multi',
            visible: state.canAddAnother,
            enabled: state.canSave,
            active: false,
            primary: false,
            onClick: () => this.save(true)
          },
          {
            text: 'Delete',
            icon: 'delete',
            visible: state.canDelete,
            enabled: !!state.data.skater?.id,
            active: false,
            primary: false,
            onClick: () => this.delete()
          }
        ])
      );
    });
  }

  ngOnInit(): void {
    RouterTools.observeParamMap(this.route, params => {
      const leagueId = RouterTools.toNumber(params, 'leagueId');
      const teamId = RouterTools.toNumber(params, 'teamId');
      const skaterId = RouterTools.toNumber(params, 'skaterId');
      this.state$.invoke(state => state.withQueryIds(leagueId, teamId, skaterId));
    });
    this.state$.get(state => state.data.leagueId + '' + state.data.teamId + state.data.skaterId).subscribe(() => {
      this.refresh();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.primary.nativeElement.focus(), 500);
  }

  public refresh(): void {
    const state = this.state$.getValue();
    const leagueId = state.data.leagueId;
    const teamId = state.data.teamId;
    const skaterId = state.data.skaterId;

    if (!leagueId) {
      this.state$.invoke(state => state.refresh(undefined, undefined, undefined, []));
      return;
    }

    forkJoin([
      this.leagueService.getLeague(leagueId),
      teamId ? this.teamService.getTeam(teamId) : of(undefined),
      skaterId ? this.skaterService.getSkater(skaterId) : of(undefined),
      this.skaterService.getActions(skaterId)
    ]).subscribe({
      next: ([league, team, skater, actions]) => {
        this.state$.invoke(state => state.refresh(league, team, skater, actions));
      },
      error: () => {
        this.notificationService.show(Message.error(`Failed to load skater`));
      }
    });
  }

  public save(addAnother: boolean = false): void {
    const state = this.state$.getValue();
    if (!state.data.skater) {
      const newSkater = {
        teamId: state.data.teamId,
        derbyName: state.data.derbyName,
        derbyNumber: state.data.derbyNumber,
        isActive: state.data.isActive,
        isJammer: state.data.isJammer,
        isCaptain: state.data.isCaptain,
        isPivot: state.data.isPivot,
      };
      this.skaterService.createSkater(newSkater).subscribe({
        next: skater => {
          if (addAnother) {
            this.notificationService.show(Message.success('Skater added: ' + skater.derbyName));
            this.refresh();
          } else {
            RouterTools.goToSkater(this.router, state.data.leagueId!, state.data.teamId!, skater.id);
          }
        },
        error: () => {
          this.notificationService.show(Message.error('Failed to create skater'));
        }
      });
      return;
    }

    const updatedSkater = {
      id: state.data.skater.id,
      teamId: state.data.teamId,
      derbyName: state.data.derbyName,
      derbyNumber: state.data.derbyNumber,
      isActive: state.data.isActive,
      isJammer: state.data.isJammer,
      isCaptain: state.data.isCaptain,
      isPivot: state.data.isPivot,
    } as Skater;
    this.skaterService.updateSkater(state.data.skater.id, updatedSkater).subscribe({
      next: () => this.refresh(),
      error: () => {
        this.notificationService.show(Message.error('Failed to update skater'));
      }
    });
  }

  public delete(): void {
    this.dialogService.show(DialogOptions
      .create('Delete Skater', 'Are you sure you want to delete this skater?')
      .withConfirm(() => this.doDelete()));
  }

  private doDelete(): void {
    const state = this.state$.getValue();
    if (!state.data.skater) {
      return;
    }
    this.skaterService.deleteSkater(state.data.skater.id).subscribe({
      next: () => {
        RouterTools.goToTeam(this.router, state.data.leagueId!, state.data.teamId!);
        this.notificationService.show(Message.success('Skater deleted'));
      },
      error: () => {
        this.notificationService.show(Message.error('Failed to delete skater'));
      }
    });
  }

  public withDerbyName(event: any): void {
    this.state$.invoke(state => state.withDerbyName(event.target.value));
  }

  public withDerbyNumber(event: any): void {
    this.state$.invoke(state => state.withDerbyNumber(event.target.value));
  }

  public withIsActive(checked: boolean): void {
    this.state$.invoke(state => state.withIsActive(checked));
  }

  public withIsJammer(checked: boolean): void {
    this.state$.invoke(state => state.withIsJammer(checked));
  }

  public withIsCaptain(checked: boolean): void {
    this.state$.invoke(state => state.withIsCaptain(checked));
  }

  public withIsPivot(checked: boolean): void {
    this.state$.invoke(state => state.withIsPivot(checked));
  }
}
