import {Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild} from "@angular/core";
import {SelectInfo} from "../../models/select-info";
import {Objects} from "../../tools/objects";

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent {

  @ViewChild("dialog") dialog!: ElementRef;
  @ViewChild("glassPane") glassPane!: ElementRef;
  @ViewChild("scroll") scroll!: ElementRef;

  @Input()
  public title: string = 'Choose';

  @Input()
  public selection: any;

  @Input()
  public values: any[] = [];

  @Input()
  public moreValues: any[] = [];

  @Input()
  public highlights: any[] = [];

  @Input()
  public renderer: (value: any) => SelectInfo = (value) => SelectInfo.of([value === undefined ? '' : value.toString()]);

  @Input()
  public disabled: boolean = false;

  @Input()
  public includeEmpty: boolean = true;

  @Input()
  public transparent: boolean = false;

  @Output()
  public selectEvent: EventEmitter<any> = new EventEmitter<any>();

  public dialogVisible: boolean = false;
  public moreVisible: boolean = false;

  private cache: Map<any, SelectInfo> = new Map<any, SelectInfo>();


  public showDialog() {
    if (this.disabled) {
      return;
    }

    this.dialogVisible = true;
    this.moreVisible = !Objects.isNull(this.selection) && !this.isPresentInValues(this.selection);
    setTimeout(() => {
      const index = this.values.indexOf(this.selection);
      this.scroll.nativeElement.scrollTop = index * 42;
    });
  }

  public closeDialog() {
    this.cache.clear();
    this.dialogVisible = false;
    this.moreVisible = false;
  }

  public onSelect(value: any): void {
    this.closeDialog()
    this.selectEvent.emit(value);
  }

  public showMore() {
    this.moreVisible = true;
  }

  @HostListener('document:mouseup', ['$event'])
  handleDocumentClick(event: MouseEvent): void {
    if (!this.dialog || !this.glassPane) {
      return;
    }
    const glassClick = this.glassPane.nativeElement.contains(event.target);
    const dialogClick = this.dialog.nativeElement.contains(event.target);
    if (glassClick && !dialogClick) {
      this.closeDialog();
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleEscapePress(event: KeyboardEvent): void {
    this.closeDialog();
  }

  public isPresentInValues(value: any): boolean {
    return this.values.includes(value);
  }

  public isHighlighted(value: any): boolean {
    return this.highlights.includes(value);
  }

  public render(value: any): SelectInfo {
    if (this.cache.has(value)) {
      return this.cache.get(value)!;
    }
    const result = this.renderer(value);
    this.cache.set(value, result);
    return result;
  }
}
