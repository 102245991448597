import {Game} from "../../models/game";
import {League} from "../../models/league";
import {Team} from "../../models/team";
import {Skater} from "../../models/skater";
import {Color} from "../../models/color";
import {ColorTools} from "../../tools/color.tools";
import {Jam} from "../../models/jam";
import {Action} from "../../models/action";

export interface GameData {
  gameId?: number;
  game?: Game;
  leagues: League[];
  teams: Team[];
  skaters: Skater[];
  jams: Jam[];
  actions: Action[];
  colors: Color[];
  startDate?: Date;
  startDateError: string;
  location: string;
  locationError: string;
  league1?: League;
  league2?: League;
  team1?: Team;
  team2?: Team;
  teams1: Team[];
  teams2: Team[];
  color1?: Color;
  color2?: Color;
  isLive: boolean;
  isFinished: boolean;
  liveStreamUrl: string;
  liveStreamUrlError: string;
  dirty: boolean;
  valid: boolean;
  loading: boolean;
}

export class GameState {
  readonly data: GameData;

  constructor(data: GameData) {
    this.data = data;
  }

  public static create(): GameState {
    return new GameState({
      gameId: undefined,
      game: undefined,
      leagues: [],
      teams: [],
      skaters: [],
      jams: [],
      actions: [],
      colors: ColorTools.getColors(),
      startDate: undefined,
      startDateError: '',
      location: '',
      locationError: '',
      league1: undefined,
      league2: undefined,
      team1: undefined,
      team2: undefined,
      teams1: [],
      teams2: [],
      color1: undefined,
      color2: undefined,
      isLive: false,
      isFinished: false,
      liveStreamUrl: '',
      liveStreamUrlError: '',
      dirty: false,
      valid: true,
      loading: true,
    });
  }

  public refresh(leagues: League[], teams: Team[], skaters: Skater[], game: Game | undefined, jams: Jam[], actions: Action[]): GameState {
    const team1 = teams.find(team => team.id === game?.teamId1);
    const team2 = teams.find(team => team.id === game?.teamId2);
    const league1 = !team1 ? undefined : leagues.find(league => league.id === team1.leagueId);
    const league2 = !team2 ? undefined : leagues.find(league => league.id === team2.leagueId);
    const color1 = !game?.teamColor1 ? undefined : this.data.colors.find(color => color.code === game.teamColor1);
    const color2 = !game?.teamColor2 ? undefined : this.data.colors.find(color => color.code === game.teamColor2);
    return new GameState({
      ...this.data,
      game: game,
      leagues: leagues.sort((a, b) => a.displayName.localeCompare(b.displayName)),
      teams: teams,
      skaters: skaters,
      jams: jams,
      actions: actions,
      startDate: game?.startDate,
      startDateError: '',
      location: game?.location || '',
      locationError: '',
      league1: league1,
      league2: league2,
      team1: team1,
      team2: team2,
      teams1: !league1 ? [] : teams.filter(team => team.leagueId === league1.id),
      teams2: !league2 ? [] : teams.filter(team => team.leagueId === league2.id),
      color1: color1,
      color2: color2,
      isLive: game?.isLive || false,
      isFinished: game?.isFinished || false,
      liveStreamUrl: game?.liveStreamUrl || '',
      dirty: false,
      loading: false,
    }).validate();
  }

  public withLoading(loading: boolean): GameState {
    return new GameState({
      ...this.data,
      loading: loading,
    });
  }

  public get canEdit(): boolean {
    return this.data.actions.includes(Action.EDIT);
  }

  public get canDelete(): boolean {
    return this.data.actions.includes(Action.DELETE);
  }

  public get canSave(): boolean {
    return this.data.dirty && this.data.valid;
  }

  public get gameTitle(): string {
    const teamId1 = this.data.game?.teamId1;
    const teamId2 = this.data.game?.teamId2;
    if (teamId1 != null && teamId2 != null) {
      const team1 = this.data.teams.find(team => team.id === teamId1);
      const team2 = this.data.teams.find(team => team.id === teamId2);
      if (team1 != null && team2 != null) {
        return `${team1.displayName} vs ${team2.displayName}`;
      }
    }
    return "New Game";
  }

  withQueryId(gameId: number | undefined): GameState {
    return new GameState({
      ...this.data,
      gameId: gameId,
    });
  }

  withStartDate(startDate?: Date): GameState {
    return new GameState({
      ...this.data,
      startDate: startDate,
      dirty: true,
    }).validate();
  }

  withLocation(location?: string): GameState {
    return new GameState({
      ...this.data,
      location: location || '',
      dirty: true,
    }).validate();
  }

  withLeague1(league1?: League): GameState {
    const id = league1?.id;
    const teams1 = !id ? [] : this.data.teams.filter(team => team.leagueId === id);
    const team1 = this.data.team1 && teams1.find(team => team.id === this.data.team1!.id) ? this.data.team1 : teams1.length === 1 ? teams1[0] : undefined;
    return new GameState({
      ...this.data,
      league1: league1,
      teams1: teams1,
      team1: team1,
      dirty: true,
    }).validate();
  }

  withLeague2(league2?: League): GameState {
    const id = league2?.id;
    const teams2 = !id ? [] : this.data.teams.filter(team => team.leagueId === id);
    const team2 = this.data.team2 && teams2.find(team => team.id === this.data.team2!.id) ? this.data.team2 : teams2.length === 1 ? teams2[0] : undefined;
    return new GameState({
      ...this.data,
      league2: league2,
      teams2: teams2,
      team2: team2,
      dirty: true,
    }).validate();
  }

  withTeam1(team1?: Team): GameState {
    return new GameState({
      ...this.data,
      team1: team1,
      dirty: true,
    }).validate();
  }

  withTeam2(team2?: Team): GameState {
    return new GameState({
      ...this.data,
      team2: team2,
      dirty: true,
    }).validate();
  }

  withColor1(color?: Color): GameState {
    return new GameState({
      ...this.data,
      color1: color,
      dirty: true,
    }).validate();
  }

  withColor2(color?: Color): GameState {
    return new GameState({
      ...this.data,
      color2: color,
      dirty: true,
    }).validate();
  }

  withIsLive(isLive: boolean): GameState {
    return new GameState({
      ...this.data,
      isLive: isLive,
      dirty: true,
    });
  }

  withIsFinished(isFinished: boolean): GameState {
    return new GameState({
      ...this.data,
      isFinished: isFinished,
      dirty: true,
    });
  }

  withLiveStreamUrl(liveStreamUrl?: string): GameState {
    return new GameState({
      ...this.data,
      liveStreamUrl: liveStreamUrl || '',
      dirty: true,
    }).validate();
  }

  private validate(): GameState {
    let locationError = '';
    if (this.data.location && this.data.location.length > 128) {
      locationError = 'Location must be 128 characters or less';
    }

    let liveStreamUrlError = '';
    if (this.data.liveStreamUrl) {
      if (this.data.liveStreamUrl.length > 255) {
        liveStreamUrlError = 'Live Stream URL must be 255 characters or less';
      } else if (!this.data.liveStreamUrl.startsWith('https://')) {
        liveStreamUrlError = 'Live Stream URL must start with https://';
      } else if (!this.data.liveStreamUrl.includes('.')) {
        liveStreamUrlError = 'Live Stream URL must contain a domain';
      } else if (this.data.liveStreamUrl.includes(' ')) {
        liveStreamUrlError = 'Live Stream URL must not contain spaces';
      }
    }

    return new GameState({
      ...this.data,
      locationError: locationError,
      liveStreamUrlError: liveStreamUrlError,
      valid: !locationError && !liveStreamUrlError,
    });
  }
}
