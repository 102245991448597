import {Component, OnInit} from "@angular/core";
import {forkJoin} from "rxjs";
import {GamesState} from "./games.state";
import {Game} from "../../models/game";
import {Router} from "@angular/router";
import {GameService} from "../../services/game.service";
import {LeagueService} from "../../services/league.service";
import {TeamService} from "../../services/team.service";
import {NotificationService} from "../../services/notification.service";
import {Message} from "../../models/message";
import {RouterTools} from "../../tools/router.tools";
import {StateSubject} from "../../tools/state.subject";
import {SessionService} from "../../services/session.service";
import {Scope} from "../../models/scope";
import {PageService} from "../../services/page.service";
import {AbstractComponent} from "../abstract.component";
import {Tabs} from "../../models/tabs";
import {Tab} from "../../models/tab";

@Component({
  selector: 'app-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.scss']
})
export class GamesComponent extends AbstractComponent implements OnInit {

  state$: StateSubject<GamesState> = new StateSubject<GamesState>(GamesState.create());

  constructor(private gameService: GameService,
              private leagueService: LeagueService,
              private teamService: TeamService,
              private notificationService: NotificationService,
              sessionService: SessionService,
              private pageService: PageService,
              private router: Router) {
    super();
    this.subscriptions.add(sessionService.hasScope(Scope.GAMES_WRITE).subscribe(canWrite => this.state$.invoke(state => state.withCanWrite(canWrite))));
    this.state$.subscribe(state => {
      this.pageService.invoke(page => page.reset('Games')
        .withShowMenu(true)
        .withBackButton(() => RouterTools.goHome(this.router))
        .withSearch((query?: string) => this.search(query))
        .withTabs(Tabs.of([
          Tab.of('Games', true, () => RouterTools.goToGames(this.router)),
          Tab.of('Leagues', true, () => RouterTools.goToLeagues(this.router)),
          Tab.of('Teams', true, () => RouterTools.goToTeams(this.router)),
        ], 0))
        .withActions([{
          text: 'New Game',
          icon: 'add',
          visible: state.data.canWrite,
          primary: true,
          enabled: true,
          active: true,
          onClick: () => this.newGame()
        }])
      );
    })
  }

  ngOnInit(): void {
    this.refresh();
  }

  public refresh(): void {
    forkJoin([
      this.leagueService.getLeagues(),
      this.teamService.getTeams(),
      this.gameService.all()
    ]).subscribe({
      next: ([leagues, teams, games]) => {
        this.state$.invoke(state => state.refresh(leagues, teams, games));
      },
      error: () => {
        this.notificationService.show(Message.error('Failed to load games'));
        this.state$.invoke(state => state.withLoading(false));
      }
    });
  }

  public search(value: string | undefined): void {
    this.state$.invoke(state => state.withSearch(value));
  }

  public goToGame(game: Game): void {
    if (game.isLive) {
      RouterTools.goToGameStats(this.router, game.id);
    } else {
      RouterTools.goToGame(this.router, game.id);
    }
  }

  public newGame(): void {
    RouterTools.goToGameNew(this.router);
  }
}
