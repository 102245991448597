<div class="page">
  <app-loading *ngIf="(state$ | async)!.data.loading"></app-loading>
  <div class="cards" *ngIf="!(state$ | async)!.data.loading">
    <div class="card">
      <div class="card-header">
        <div class="subtitle">
          <app-icon name="upload"></app-icon>
          Upload Questions
        </div>
      </div>
      <div class="form">
        <div class="field">
          <label class="field-label" for="question">Questions text</label>
          <textarea class="field-text" id="question"
                    [disabled]="!(state$ | async)!.canEdit"
                    [value]="(state$ | async)!.data.text"
                    (input)="withText($event)"
                    appAutoResize></textarea>
          <div class="field-error" *ngIf="(state$ | async)!.data.textError">
            {{(state$ | async)!.data.textError}}
          </div>
          <div class="field-error" *ngIf="(state$ | async)!.data.textInfo">
            {{(state$ | async)!.data.textInfo}}
          </div>
        </div>
      </div>

      <div class="list">
        <ng-container *ngFor="let candidate of (state$ | async)!.data.candidates; let i = index">
          <ng-container *ngIf="i === 0">
            <div class="list-candidates-header">
              <div>Question</div>
              <div>Answer</div>
            </div>
          </ng-container>

          <div class="list-candidates-item">
            <div>{{candidate.question}}</div>
            <div>
              <ng-container *ngFor="let answer of (state$ | async)!.candidateAnswers(i)">
                <div [class.list-candidate-correct]="answer.isCorrect"
                     [class.list-candidate-incorrect]="!answer.isCorrect">
                  {{ answer.text }}
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="(state$ | async)!.data.candidates.length === 0">
          <div class="list-item-secondary list-item-disabled">
            <div class="list-text-message">
              No candidate questions found
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <div class="subtitle">
          Existing Questions
        </div>
      </div>
      <div class="list">
        <ng-container *ngFor="let candidate of (state$ | async)!.data.questions; let i = index">
          <ng-container *ngIf="i === 0">
            <div class="list-candidates-header">
              <div>Question</div>
              <div>Answer</div>
            </div>
          </ng-container>

          <div class="list-candidates-item list-item-disabled">
            <div>{{candidate.question}}</div>
            <div>
              <ng-container *ngFor="let answer of (state$ | async)!.questionAnswers(i)">
                <div [class.list-candidate-correct]="answer.isCorrect"
                     [class.list-candidate-incorrect]="!answer.isCorrect">
                  {{ answer.text }}
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="(state$ | async)!.data.questions.length === 0">
          <div class="list-item-secondary list-item-disabled">
            <div class="list-text-message">
              No saved questions found
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
