import {Tabs} from "./tabs";
import {UserSession} from "./user-session";
import {Scope} from "./scope";
import {SessionTools} from "../tools/session.tools";
import {Objects} from "../tools/objects";
import {Router} from "@angular/router";
import {RouterTools} from "../tools/router.tools";
import {ScreenSize} from "./screen-size";

export interface PageButton {
  text: string;
  icon: string;
  visible: boolean;
  enabled: boolean;
  primary: boolean;
  active: boolean;
  onClick?: () => void;
}

export interface MenuButton {
  text: string;
  icon: string;
  visible: boolean;
  enabled: boolean;
  scope: Scope,
  path: string;
  isAdmin: boolean;
  onClick?: () => void;
}

export interface BackButton {
  onClick: () => void;
}

export interface PageData {
  screenSize: ScreenSize;
  appName: string;
  session?: UserSession;
  showLogo: boolean;
  showProfile: boolean;
  backButton?: BackButton;
  search?: (search?: string) => void;
  title: string;
  tabs: Tabs;
  showMenu: boolean;
  allActions: PageButton[];
  primaryActions: PageButton[];
  moreActions: PageButton[];
  menuButtons: MenuButton[];
}

export class PageState {
  readonly data: PageData;

  constructor(data: PageData) {
    this.data = data;
  }

  public static create(router: Router): PageState {
    return new PageState({
      screenSize: ScreenSize.XXL_MAX,
      appName: 'Roller Derby PRO',
      session: undefined,
      showLogo: true,
      showProfile: true,
      backButton: undefined,
      search: undefined,
      title: '',
      tabs: Tabs.of([]),
      allActions: [],
      primaryActions: [],
      moreActions: [],
      showMenu: false,
      menuButtons: [
        {
          text: 'Home',
          icon: 'home',
          visible: true,
          enabled: false,
          scope: Scope.HOME_READ,
          path: '/home',
          isAdmin: false,
          onClick: () => RouterTools.goHome(router),
        },
        {
          text: 'Trainings',
          icon: 'sprint',
          visible: true,
          enabled: false,
          scope: Scope.CLASSES_READ,
          path: '/trainings',
          isAdmin: false,
          onClick: undefined,
        },
        {
          text: 'Skills',
          icon: 'bolt',
          visible: true,
          enabled: false,
          scope: Scope.SKILLS_READ,
          path: '/skills',
          isAdmin: false,
          onClick: undefined,
        },
        {
          text: 'Study Corner',
          icon: 'psychology',
          visible: true,
          enabled: false,
          scope: Scope.TESTS_READ,
          path: '/tests',
          isAdmin: false,
          onClick: () => RouterTools.goToTests(router),
        },
        {
          text: 'Games',
          icon: 'whistle',
          visible: true,
          enabled: false,
          scope: Scope.GAMES_READ,
          path: '/games',
          isAdmin: false,
          onClick: () => RouterTools.goToGames(router),
        },
        {
          text: 'Users',
          icon: 'accounts',
          visible: true,
          enabled: false,
          scope: Scope.USERS_WRITE,
          path: '/users',
          isAdmin: true,
          onClick: () => RouterTools.goToUsers(router),
        }
      ],
    });
  }

  public get isDesktop(): boolean {
    return this.data.screenSize.isAtLeast(ScreenSize.XXL_MAX);
  }

  public get isMobile(): boolean {
    return !this.isDesktop;
  }

  public get isMobileMedium(): boolean {
    return this.data.screenSize.isAtMost(ScreenSize.M_876);
  }

  public get isMobileSmall(): boolean {
    return this.data.screenSize.isAtMost(ScreenSize.S_768);
  }

  public get hasTabs(): boolean {
    return this.data.tabs.tabs.length > 0;
  }

  public get showTabsDesktop(): boolean {
    return this.hasTabs && this.isDesktop;
  }

  public get showTabsMobile(): boolean {
    return this.hasTabs && this.isMobile;
  }

  public get isLoggedIn(): boolean {
    return !!this.data.session;
  }

  public get showCenterBar(): boolean {
    return Objects.isNotNull(this.data.backButton) || Objects.isNotNull(this.data.search);
  }

  public get showTopBarDesktop(): boolean {
    return this.isLoggedIn
      && this.isDesktop;
  }

  public get showTopBarMobile(): boolean {
    return this.isLoggedIn
      && this.isMobile;
  }

  public get showActionsDesktop(): boolean {
    return this.isLoggedIn
      && this.data.allActions.length > 0
      && this.data.allActions.some(button => button.visible)
      && this.isDesktop;
  }

  public get showActionsMobile(): boolean {
    return this.isLoggedIn
      && this.data.primaryActions.length === 1
      && this.data.primaryActions.some(button => button.visible)
      && this.isMobile;
  }

  public get showActionsMobileAboveMenu(): boolean {
    return this.isMobileSmall && this.showMenuMobileOrMoreActions;
  }

  public get showMenuMobileOrMoreActions(): boolean {
    return this.isLoggedIn && this.isMobile && (this.data.showMenu || this.hasMoreActions());
  }

  public hasPrimaryActions(): boolean {
    return this.isLoggedIn
      && this.data.primaryActions.length > 0
      && this.data.primaryActions.some(button => button.visible);
  }

  public hasMoreActions(): boolean {
    return this.isLoggedIn
      && this.data.moreActions.length > 0
      && this.data.moreActions.some(button => button.visible);
  }

  public get showMenuDesktop(): boolean {
    return this.isLoggedIn && this.isDesktop;
  }

  public get showBackButton(): boolean {
    return Objects.isNotNull(this.data.backButton);
  }

  public toContentHeightStyle(): string {
    const isMobile = this.isMobile;
    const hasTabs = this.hasTabs;
    if (isMobile) {
      return "100%";
    }
    return hasTabs ? "calc(100% - 60px - 48px)" : "calc(100% - 48px)";
  }

  public reset(title: string = ''): PageState {
    return new PageState({
      ...this.data,
      showLogo: false,
      showProfile: false,
      backButton: undefined,
      search: undefined,
      title: title,
      tabs: Tabs.of([]),
      allActions: [],
      primaryActions: [],
      moreActions: [],
      showMenu: false,
    });
  }

  public withScreenSize(screenSize: ScreenSize): PageState {
    return new PageState({
      ...this.data,
      screenSize: screenSize,
    });
  }

  public withShowMenu(showMenu: boolean): PageState {
    return new PageState({
      ...this.data,
      showMenu: showMenu,
    });
  }

  public withUserSession(session?: UserSession): PageState {
    return new PageState({
      ...this.data,
      session: session,
    }).refreshAccess();
  }

  public withBackButton(onClick?: () => void): PageState {
    return new PageState({
      ...this.data,
      backButton: !onClick ? undefined : {onClick: onClick},
    });
  }

  public withSearch(search?: (search?: string) => void): PageState {
    return new PageState({
      ...this.data,
      search: search,
    });
  }

  public withTabs(tabs: Tabs): PageState {
    return new PageState({
      ...this.data,
      tabs: tabs,
    });
  }

  public withActions(buttons: PageButton[]): PageState {
    return new PageState({
      ...this.data,
      allActions: buttons,
      primaryActions: buttons.filter(button => button.primary),
      moreActions: buttons.filter(button => !button.primary),
    });
  }

  public hasScope(scope: Scope): boolean {
    const hasScope = SessionTools.hasScope(this.data.session, scope);
    if (!hasScope) {
      return false;
    }

    const temporaryPassword = this.data.session?.isTemporaryPassword;
    return !temporaryPassword || scope === Scope.PROFILE_READ;
  }

  private refreshAccess(): PageState {
    const menuButtons = this.data.menuButtons.map(button => {
      return {
        ...button,
        enabled: !!button.onClick && this.hasScope(button.scope),
      };
    });

    return new PageState({
      ...this.data,
      menuButtons: menuButtons,
    });
  }
}
